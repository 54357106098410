import { FileCopy, Payment } from '@mui/icons-material';
import axios from 'axios';
import { ButtonAction, LinkAction } from '@/classes';
import { getLedgerEntriesAction } from '@/components/Accounting/ModelLedgerEntries';
import { Transaction } from '@/types';
import { getSingleHeldAction } from '@/utils/disabled';

export default function (values: Transaction) {
  const actions = [];

  if (values.type === 'bill' && values.balance !== 0) {
    actions.push(
      new LinkAction(
        'Pay Bill',
        `/transactions/create?type=bill_payment&bill_id=${values.id}`,
      ).withIcon(Payment),
    );
  }

  if (values.type === 'bill' || values.is_disabled) {
    actions.push(getSingleHeldAction('Bill', 'transactions', values));
  }

  if (values.type !== 'bill_payment') {
    actions.push(
      new ButtonAction(
        'Duplicate',
        ({ dialogs, toast, navigate }) => {
          dialogs
            .confirm({
              title: `Duplicate ${values.label}`,
              description: 'Are you sure you want to duplicate this transaction?',
            })
            .then(() => {
              axios.post(`/api/transactions/${values.id}/duplicate`).then(({ data }) => {
                toast('Transaction has been duplicated');
                navigate(`/transactions/${data.id}`);
              });
            });
        },
        FileCopy,
      ),
    );
  }

  actions.push(getLedgerEntriesAction('transactions', values.id));

  return actions;
}
