import { Card, CardHeader, Table, TableBody, TableCell, TableRow } from '@mui/material';
import OnOrderCount from '@/components/Inventory/OnOrderCount';
import TextButton from '@/components/Text/TextButton';
import { InventoryTotals } from '@/types';
import curr from '@/utils/curr';

export default function InventoryTotalsCard({
  variantId,
  totals,
  onViewCostLayers,
}: {
  variantId: number;
  totals: InventoryTotals;
  onViewCostLayers?: () => void;
}) {
  return (
    <Card>
      <CardHeader title="Inventory Counts" />
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell variant="head">On Hand</TableCell>
            <TableCell>
              {totals.on_hand}
              {totals.on_hand !== totals.on_hand_pickable && (
                <span> ({totals.on_hand_pickable} Pickable)</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Allocated</TableCell>
            <TableCell>{totals.allocated}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Backordered</TableCell>
            <TableCell>{totals.backordered}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Available</TableCell>
            <TableCell>
              <span>{totals.available}</span>
              {totals.available !== totals.available_pickable && (
                <span> ({totals.available_pickable} Pickable)</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">On Order</TableCell>
            <TableCell>
              <OnOrderCount variantId={variantId}>{totals.on_order}</OnOrderCount>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Average Cost</TableCell>
            <TableCell>
              <TextButton onClick={onViewCostLayers} disabled={!onViewCostLayers}>
                {curr(totals.average_cost)}
              </TextButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}
