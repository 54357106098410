import { Print } from '@mui/icons-material';
import axios from 'axios';
import { AnchorAction, ButtonAction } from '@/classes';
import SubMenu from '@/components/Shared/SubMenu';
import { useConfig } from '@/contexts/AppConfigContext';
import { useLabelType, usePrintZpl } from '@/contexts/PrintContext';
import getApiUrl from '@/utils/getApiUrl';

export default function PrintMenu({
  model,
  id,
  size,
}: {
  model: string;
  id: string | number;
  size?: 'small' | 'medium';
}) {
  const printables = useConfig().printables.filter((p) => p.model === model);
  const labelType = useLabelType();
  const printZpl = usePrintZpl();

  return (
    <SubMenu
      size={size}
      primaryIcon={Print}
      items={printables.map((p) => {
        const apiPath = `/api/printables/${p.id}/render/${id}`;

        if (p.supports_zpl && labelType === 'ZPLII') {
          return new ButtonAction(p.title, () =>
            axios
              .get<string>(apiPath, { params: { format: 'zpl' } })
              .then(({ data }) => printZpl(data)),
          );
        }

        return new AnchorAction(p.title, getApiUrl(apiPath));
      })}
    />
  );
}
