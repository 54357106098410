import PromptDialog from '@/components/Dialogs/PromptDialog';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { PromptDialogConfig } from '@/contexts/DialogContext';

export default function PromptDrawerContainer({
  promptDrawer,
  setPromptDrawer,
}: {
  promptDrawer: PromptDialogConfig<any, any> | undefined;
  setPromptDrawer: (state: PromptDialogConfig<any, any>) => void;
}) {
  if (!promptDrawer) {
    return null;
  }

  const { options, open, promise } = promptDrawer;
  const { fields, ...nonFields } = options;

  const onClose = () => {
    setPromptDrawer({ ...promptDrawer, open: false });
  };

  const onSuccess = (v: any) => {
    promise.resolve(v);
    onClose();
  };

  const onCancel = () => {
    promise.reject();
    onClose();
  };

  return (
    <ClosableDrawer open={open} onClose={onCancel} title={options.title} width={options.width}>
      <PromptDialog
        {...nonFields}
        promptFields={fields}
        onSuccess={onSuccess}
        onCancel={onCancel}
        mode="drawer"
        open={open}
      />
    </ClosableDrawer>
  );
}
