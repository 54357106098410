import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { InventoryLevel } from '@/types';

export function useGetInventoryLevels(
  filterKey: 'variant_id' | 'location_id',
  filterValue: number | undefined,
) {
  return useQuery(
    ['inventoryLevels', filterKey, filterValue],
    () =>
      axios
        .get<{
          data: InventoryLevel[];
        }>(`/api/inventory-levels?filter[${filterKey}]=${filterValue}&sort=location.path&count=250`)
        .then(({ data }) => data.data),
    {
      enabled: Boolean(filterValue),
    },
  );
}

export function useGetInventoryLevelsForVariant(variantId: number | undefined) {
  return useGetInventoryLevels('variant_id', variantId);
}

export function useGetInventoryLevelsForLocation(locationId: number | undefined) {
  return useGetInventoryLevels('location_id', locationId);
}
