import { ReactNode } from 'react';
import { Typography } from '@mui/material';

export default function WithLabel({ label, children }: { label: ReactNode; children: ReactNode }) {
  return (
    <Typography>
      <Typography variant="subtitle2" gutterBottom>
        {label}
      </Typography>
      {children}
    </Typography>
  );
}
