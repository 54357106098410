import { OpenInNew, ArrowForward } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  IconButton,
  LinearProgress,
  List,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import OrderListItem from '@/components/Orders/OrderListItem';
import PrintMenu from '@/components/Print/PrintMenu';
import StatusChip from '@/components/Shared/StatusChip';
import { PROJECT_STATUS_COLORS } from '@/constants';
import usePushWithContext from '@/hooks/usePushWithContext';
import { AvailProject } from '@/types';
import curr from '@/utils/curr';

export default function ProjectCard({ projectId }: { projectId: number }) {
  const { data: project, isLoading } = useQuery(['project', projectId], () =>
    axios.get<AvailProject>(`/api/projects/${projectId}`).then(({ data }) => data),
  );

  const push = usePushWithContext(
    project?.orders.map((o) => ({
      key: o.id,
      label: o.description,
      href: `/orders/${o.id}`,
    })) || [],
  );

  return (
    <Card>
      <CardHeader
        title="Project"
        subheader={project?.name}
        action={
          <>
            <PrintMenu model="project" id={projectId} />
            <IconButton component={Link} to={`/projects/${projectId}`}>
              <ArrowForward />
            </IconButton>
          </>
        }
      />
      {isLoading && <LinearProgress />}
      {project && (
        <>
          <Table>
            <TableRow>
              <TableCell variant="head">Status</TableCell>
              <TableCell>
                <StatusChip status={project.status} colors={PROJECT_STATUS_COLORS} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" sx={{ verticalAlign: 'top' }}>
                Description
              </TableCell>
              <TableCell>{project.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">In-Hands</TableCell>
              <TableCell>{moment(project.in_hands_date).format('ll')}</TableCell>
            </TableRow>
          </Table>

          <Typography variant="subtitle2" sx={{ p: 2, pb: 1 }}>
            Orders
          </Typography>
          {project.orders.length > 0 && (
            <List disablePadding>
              {project.orders.map((order) => (
                <OrderListItem key={order.id} order={order} push={push} />
              ))}
            </List>
          )}

          {project.total != null && (
            <Typography variant="subtitle2" sx={{ p: 2 }}>
              Total: {curr(project.total)}
            </Typography>
          )}
          <CardActions>
            <Button component="a" target="_blank" href={project.portal_url}>
              View in the Portal <OpenInNew sx={{ ml: 1 }} />
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
}
