import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { CustomerBrandColor, CustomerBrandFile, CustomerDetail } from '@/types';

export function useGetCustomer(customerId: number | undefined) {
  return useQuery(
    ['customer', customerId],
    () => axios.get<CustomerDetail>(`/api/customers/${customerId}`).then(({ data }) => data),
    {
      enabled: Boolean(customerId),
      staleTime: 5 * 60 * 1000,
    },
  );
}

export function useGetCustomerBrand(customerId: number | undefined) {
  return useQuery(
    ['customerBrand', customerId],
    () =>
      axios
        .get<{
          id: number;
          is_parent_data: boolean;
          name: string;
          brand_colors: CustomerBrandColor[];
          brand_files: CustomerBrandFile[];
        }>(`/api/customers/${customerId}/brand-data`)
        .then(({ data }) => data),
    {
      staleTime: 1000 * 60 * 5,
      enabled: !!customerId,
    },
  );
}
