import { UseControllerReturn } from 'react-hook-form';
import { Field } from '@/classes';
import { FieldProps } from '@/classes/types';
import ColorChooser from '@/components/Designs/ColorChooser';
import { ComplexityUnit, Customer } from '@/types';

function ColorChooserFieldComponent({
  fieldModel,
  field,
  fieldState,
}: FieldProps<ColorChooserField>) {
  return (
    <ColorChooser
      decorationUnit={fieldModel.decorationUnit}
      customer={fieldModel.customer}
      freeSolo={fieldModel.freeSolo}
      onChange={field.onChange}
      value={field.value}
      label={fieldModel.label}
      size={fieldModel.size}
      textFieldProps={fieldModel.getTextFieldProps(fieldState)}
    />
  );
}

export default class ColorChooserField extends Field {
  decorationUnit: ComplexityUnit | null = null;
  customer?: Customer;
  freeSolo = false;

  renderEditComponent(props: UseControllerReturn) {
    return <ColorChooserFieldComponent {...props} fieldModel={this} />;
  }
}
