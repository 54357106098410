import { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import useDebounce from '@/hooks/useDebounce';

export default function ProductSearchField({
  onChange,
  initialValue = '',
}: {
  onChange: (q: string) => void;
  initialValue?: string;
}) {
  const [query, setQuery] = useState(initialValue);
  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    onChange(debouncedQuery);
  }, [debouncedQuery]);

  return (
    <Box mb={1}>
      <TextField
        fullWidth
        label="Search"
        value={query}
        type="search"
        onChange={(e) => setQuery(e.target.value)}
      />
    </Box>
  );
}
