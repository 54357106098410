import { PaymentMethod } from '@stripe/stripe-js';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export function useGetPaymentMethods(customerId: number) {
  return useQuery(['customerPaymentMethods', customerId], () =>
    axios
      .get<{
        data: PaymentMethod[];
        cash_balance: {
          available: { [key: string]: number } | null;
        };
      }>(`/api/customers/${customerId}/payment-methods`)
      .then(({ data }) => data),
  );
}

export function useCreatePaymentMethod(customerId: number) {
  return useMutation((payload: { url: string }) =>
    axios
      .post<{ url: string }>(`/api/customers/${customerId}/payment-methods`, payload)
      .then(({ data }) => {
        window.location.href = data.url;
      }),
  );
}

export function useDeletePaymentMethod(customerId: number) {
  const queryClient = useQueryClient();
  return useMutation((methodId: string) =>
    axios.delete(`/api/customers/${customerId}/payment-methods/${methodId}`).then(() => {
      queryClient.invalidateQueries(['customerPaymentMethods', customerId]);
    }),
  );
}
