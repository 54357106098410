import { z } from 'zod';
import { FieldFactory, Resource } from '@/classes';
import { Tag } from '@/types';

export default function tags() {
  return new Resource<Tag>('Tags')
    .withSchema(
      z.object({
        tag: z.string().min(1),
      }),
    )
    .getLabelUsing((t) => t.tag)
    .withFieldsAndColumns([FieldFactory.text('tag')]);
}
