import { useState } from 'react';
import { Box, ClickAwayListener, styled, TextField } from '@mui/material';
import { CompactPicker } from 'react-color';
import { UseControllerReturn } from 'react-hook-form';
import { FieldProps } from '@/classes/types';
import Field from '../Field';

const ColorBlock = styled('div')({
  height: 20,
  width: 20,
  display: 'inline-block',
  marginRight: 4,
  verticalAlign: 'middle',
});

function Color({ field, fieldModel, fieldState }: FieldProps<ColorField>) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div>
        <TextField
          {...field}
          value={field.value || ''}
          {...fieldModel.getTextFieldProps(fieldState)}
          onFocus={() => setIsOpen(true)}
        />

        {isOpen && (
          <Box position="absolute" zIndex="drawer">
            <CompactPicker
              color={field.value || ''}
              onChangeComplete={({ hex }) => field.onChange(hex)}
            />
          </Box>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default class ColorField extends Field {
  renderEditComponent(props: UseControllerReturn) {
    return <Color {...props} fieldModel={this} />;
  }

  renderCell(value: any) {
    return (
      <div>
        <ColorBlock
          style={{
            background: value,
          }}
        />
        {value}
      </div>
    );
  }
}
