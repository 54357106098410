import sumBy from 'lodash/sumBy';
import OrderShipping from '@/components/Shipping/OrderShipping';
import ShipActions from '@/components/Shipping/ShipActions';
import numString from '@/utils/numString';

export default function OrderShippingSimple({
  orderId,
  customerId,
}: {
  orderId: number;
  customerId: number;
}) {
  return (
    <OrderShipping
      orderId={orderId}
      getActions={({ selected, address, onSuccess }) => {
        const qty = sumBy(selected, (s) => Number(s.qty_shipped));
        return (
          <ShipActions
            payload={{
              shippable_type: 'order',
              shippable_id: orderId,
              address_id: address.id,
              items: selected,
            }}
            customerId={customerId}
            title={`Shipping ${numString(qty, 'Items')}`}
            qty={qty}
            onSuccess={onSuccess}
            shipButtonProps={{
              children: `Ship ${numString(
                sumBy(selected, (s) => s.qty_shipped),
                'Items',
              )}`,
            }}
          />
        );
      }}
    />
  );
}
