import { useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, List, Stack } from '@mui/material';
import axios from 'axios';
import startCase from 'lodash/startCase';
import { z } from 'zod';
import { Field, FieldFactory } from '@/classes';
import BankTransactionListItem from '@/components/Banking/BankTransactionListItem';
import DrawerButtons from '@/components/Form/DrawerButtons';
import Form from '@/components/Form/Form';
import FormField from '@/components/Form/FormField';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { TRANSACTION_TYPES } from '@/constants';
import {
  BankTransaction,
  bankTransactionCreatePayloadSchema,
  genericModelReferenceSchema,
  BankTransactionCreatePayload,
  Transaction,
} from '@/types';
import { useZodForm } from '@/utils/form';

const formSchema = bankTransactionCreatePayloadSchema
  .omit({
    account_id: true,
    vendor_id: true,
  })
  .extend({
    account: genericModelReferenceSchema,
    vendor: z
      .object({
        id: z.number(),
        default_accounts: z.array(genericModelReferenceSchema),
      })
      .nullish(),
  });

interface BankCreateTransactionDrawerProps {
  reviewing: BankTransaction | null;
  setReviewing: (t: BankTransaction | null) => void;
  onSuccess: (t: Transaction) => void;
}

export default function BankCreateTransactionDrawer({
  reviewing,
  setReviewing,
  onSuccess,
}: BankCreateTransactionDrawerProps) {
  const form = useZodForm(formSchema);
  const vendor = form.watch('vendor');
  const account = form.watch('account');
  const ruleCreate = form.watch('rule_create');
  const transactionType = form.watch('transaction_type');

  useEffect(() => {
    if (vendor && !account) {
      form.setValue('account', vendor.default_accounts[0]);
    }
  }, [vendor]);

  useEffect(() => {
    if (reviewing) {
      form.reset({
        transaction_type: reviewing.is_transfer ? 'transfer' : 'expense',
        account: reviewing?.rule?.account || undefined,
        vendor: reviewing?.rule?.vendor,
        rule_create: false,
        rule_auto_create: false,
      });
    }
  }, [reviewing?.id]);

  let fields: Field[] = [];

  if (transactionType === 'transfer') {
    fields = [
      FieldFactory.belongsTo('account', 'accounts').withHelp('Generally a bank or credit card'),
    ];
  } else if (transactionType === 'expense') {
    fields = [
      FieldFactory.belongsTo('account', 'accounts').withLabel('Account (Category)').setRequired(),
      FieldFactory.belongsTo('vendor', 'vendors').withRequestParams({
        with: 'default_accounts',
      }),
    ];
  }

  return (
    <ClosableDrawer
      open={!!reviewing}
      onClose={() => setReviewing(null)}
      title="Create New Transaction"
    >
      {reviewing && (
        <List subheader="Creating Transaction For" sx={{ mb: 2 }}>
          <BankTransactionListItem transaction={reviewing} />
        </List>
      )}
      <Form
        form={form}
        onSubmit={(values) => {
          const payload: BankTransactionCreatePayload = {
            ...values,
            account_id: values.account.id,
            vendor_id: values.vendor?.id || null,
          };
          return axios
            .post(`/api/bank-transactions/${reviewing!.id}/create`, payload)
            .then(({ data }) => {
              onSuccess(data);
            });
        }}
      >
        <Stack spacing={1}>
          <FormField
            field={FieldFactory.select('transaction_type', TRANSACTION_TYPES).setRequired()}
          />

          {fields.length === 0 ? (
            <Alert severity="warning">
              Creating a {startCase(transactionType)} is not supported through the banking module.
              Please create it manually, and then come back to match it.
            </Alert>
          ) : (
            <>
              {fields.map((f) => (
                <FormField key={f.name} field={f} />
              ))}
              <FormField
                field={FieldFactory.boolean('rule_create', 'Create rule using this transaction')}
              />
              {ruleCreate && (
                <FormField
                  field={FieldFactory.boolean(
                    'rule_auto_create',
                    'Auto-create expenses if rule matches',
                  )}
                />
              )}
            </>
          )}
        </Stack>

        <DrawerButtons>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={form.formState.isSubmitting}
            disabled={fields.length === 0}
          >
            Create Transaction
          </LoadingButton>
        </DrawerButtons>
      </Form>
    </ClosableDrawer>
  );
}
