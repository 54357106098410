import { FieldFactory, GroupLayout, Resource } from '@/classes';
import { Address } from '@/types';
import { addressPayloadSchema } from '@/types';
import { AppResponse } from '@/types';

export default function addresses(appConfig: AppResponse) {
  return new Resource<Address>('Addresses')
    .withSchema(addressPayloadSchema)
    .withDefaultSort('-id')
    .withFieldsAndColumns([
      FieldFactory.select(
        'method',
        appConfig.shippingMethods.map((m) => m.name),
      )
        .withLabel('Shipping Method')
        .withColumnSpan(12),
      FieldFactory.text('name').withColumnSpan(12),
      FieldFactory.text('company').withColumnSpan(12),
      FieldFactory.text('address_1').withColumnSpan(12),
      FieldFactory.text('address_2').withColumnSpan(12),
      FieldFactory.select('country', appConfig.countries).withColumnSpan(12),
      FieldFactory.text('city').withColumnSpan(5),
      FieldFactory.select('state', []).withColumnSpan(4),
      FieldFactory.text('zip').withColumnSpan(3),
      FieldFactory.text('phone').withColumnSpan(12),
      new GroupLayout('settings', [
        FieldFactory.boolean('is_residential', 'Residential Address'),
        FieldFactory.boolean('is_billing', 'Billing Address'),
        FieldFactory.boolean('is_taxable_address', 'Primary Shipping Address'),
      ])
        .withoutLabel()
        .withColumnSpan(12),
    ]);
}
