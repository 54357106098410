import { ReactNode } from 'react';
import { ListItemButton, ListItemText, Checkbox, Box, ListItem } from '@mui/material';
import moment from 'moment';
import { Deposit, Transaction } from '@/types';
import curr from '@/utils/curr';

export default function TransactionListItem({
  transaction,
  onSelect,
  onClick,
  selected,
  children,
}: {
  transaction: Transaction | Deposit;
  onSelect?: () => void;
  onClick?: () => void;
  selected?: boolean;
  children?: ReactNode;
}) {
  let date: string;
  let total: number;
  let description: string;

  if ('transaction_date' in transaction) {
    date = transaction.transaction_date;
    total = transaction.total;
    description = transaction.label;
  } else {
    date = transaction.deposit_date;
    total = transaction.total;
    description = transaction.label;
  }

  const inner = (
    <>
      <ListItemText
        sx={{ flexGrow: '1', flexShrink: 0 }}
        primary={description}
        secondary={`${moment(date).format('l')} - ${curr(total)}`}
      />
      {onSelect && (
        <Box ml="auto" pl={2}>
          <Checkbox
            onChange={(e) => {
              e.stopPropagation();
              onSelect();
            }}
            checked={selected}
          />
        </Box>
      )}
      {children && (
        <Box ml="auto" pl={2}>
          {children}
        </Box>
      )}
    </>
  );

  if (onSelect || onClick) {
    return (
      <ListItemButton onClick={onSelect || onClick} dense>
        {inner}
      </ListItemButton>
    );
  }

  return (
    <ListItem disableGutters component="div" dense>
      {inner}
    </ListItem>
  );
}
