import { Check, Error, Warning } from '@mui/icons-material';
import { InventoryPickItem, FulfillmentOrderItem } from '@/types';

export default function PickItemAvailablility({
  item,
}: {
  item: InventoryPickItem | FulfillmentOrderItem;
}) {
  if (Number(item.pickable_qty) >= item.qty) {
    return (
      <>
        <Check sx={{ color: 'success.main', mr: 1, verticalAlign: 'bottom' }} />
        {item.pickable_qty} Pickable
      </>
    );
  }

  if (Number(item.inventory_qty) >= item.qty) {
    return (
      <>
        <Warning sx={{ color: 'warning.main', mr: 1, verticalAlign: 'bottom' }} />
        <span>{item.pickable_qty} Pickable</span>
        {item.inventory_qty !== item.pickable_qty && <span> ({item.inventory_qty} On-Hand)</span>}
      </>
    );
  }

  return (
    <>
      <Error sx={{ color: 'error.main', mr: 1, verticalAlign: 'bottom' }} />
      {item.inventory_qty} On-Hand
    </>
  );
}
