import { Stack } from '@mui/material';
import KitsTable from '@/components/Kitting/KitsTable';
import ShipmentsCard from '@/components/Shipping/ShipmentsCard';
import { useRecord } from '@/utils/genericResource';

export default function PackoutCompleteStatus() {
  const { order } = useRecord('orderPackouts', true);

  return (
    <Stack spacing={2}>
      <KitsTable orderId={order.id} customerId={order.customer_id} />
      <ShipmentsCard shippableId={order.id} shippableType="order" />
    </Stack>
  );
}
