import { useEffect } from 'react';
import { LicenseInfo } from '@mui/x-license';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import LaravelEcho from 'laravel-echo';
import Pusher from 'pusher-js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { useSessionStorage } from 'usehooks-ts';
import { z } from 'zod';
import AuthenticatedApp from '@/AuthenticatedApp';
import AgreementScreen from '@/components/Agreements/AgreementScreen';
import ForgotPassword from '@/pages/auth/ForgotPassword';
import Login from '@/pages/auth/Login';
import RedirectToLogin from '@/pages/auth/RedirectToLogin';
import Register from '@/pages/auth/Register';
import ResetPassword from '@/pages/auth/ResetPassword';
import { AppResponse } from '@/types';
import SplashScreen from './SlashScreen';

declare global {
  interface Window {
    Pusher: typeof Pusher;
    Echo: LaravelEcho;
    artRequestMeta:
      | {
          _customerProofCount: number[];
          _productionProofCount: number[];
          _completedAts: (string | null)[];
        }
      | undefined;
  }
}

LicenseInfo.setLicenseKey(
  'b5c2d293b9c338749259ef1769104feeTz05NzMyNSxFPTE3NTY4MzE2MjQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
);

window.Pusher = Pusher;

z.setErrorMap((error, ctx) => {
  if (error.code === z.ZodIssueCode.invalid_type) {
    if (error.received === 'nan' || error.received === 'undefined' || error.received === 'null') {
      return { message: 'This field is required' };
    }
  }

  return { message: ctx.defaultError };
});

function getEchoWithConfig(config: AppResponse): LaravelEcho {
  return new LaravelEcho({
    broadcaster: 'reverb',
    key: config.reverbKey,
    wsHost: config.reverbHost,
    wsPort: 8080,
    wssPort: 8080,
    forceTLS: config.reverbScheme === 'https',
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel: { name: string }) => ({
      authorize: (socketId: string, callback: (error: boolean, data: any) => void) => {
        axios
          .post('/api/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((response) => {
            callback(false, response.data);
          })
          .catch((error) => {
            callback(true, error);
          });
      },
    }),
  });
}

export default function App() {
  const queryClient = useQueryClient();
  const [businessId, setBusinessId] = useSessionStorage<number | null>('business_id', null);

  useEffect(() => {
    axios.defaults.headers.common['X-Business-Id'] = businessId;
  }, [businessId]);

  const { data: touched } = useQuery(['xsrf'], () => axios.get('/api/touch').then(() => true));

  const { data: config, isLoading } = useQuery(
    ['appConfig'],
    () =>
      axios.get<AppResponse>('/api/app').then(({ data }) => {
        window.Echo = getEchoWithConfig(data);
        if (data.businesses.length === 1) {
          setBusinessId(data.businesses[0].id);
        }
        return data;
      }),
    {
      enabled: Boolean(touched),
    },
  );

  const setConfig = (config: AppResponse) => {
    queryClient.setQueryData(['appConfig'], config);
  };

  if (isLoading) {
    return <SplashScreen />;
  }

  if (config?.agreement) {
    return (
      <AgreementScreen
        agreement={config.agreement}
        onSuccess={() => setConfig({ ...config, agreement: null })}
      />
    );
  }

  return (
    <GoogleOAuthProvider clientId="944741344614-o51br5nmah6uc6k07r00i3onlukjvn4b.apps.googleusercontent.com">
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register/:code" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route
              path="*"
              element={
                config ? (
                  <AuthenticatedApp
                    config={config}
                    businessId={businessId}
                    onBusinessChange={(newId: number | null) => {
                      setBusinessId(newId);
                      window.location.reload();
                    }}
                  />
                ) : (
                  <RedirectToLogin />
                )
              }
            />
          </Routes>
        </QueryParamProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}
