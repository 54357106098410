import { useState, useEffect, useRef, ReactNode, MouseEventHandler } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Drawer, CardHeader, CardContent, Box, styled } from '@mui/material';

const Dragger = styled('a')({
  width: '5px',
  cursor: 'ew-resize',
  padding: '4px 0 0',
  borderTop: '1px solid #ddd',
  position: 'absolute',
  display: 'block',
  top: 0,
  left: 0,
  bottom: 0,
  zIndex: '100',
  backgroundColor: '#f4f7f9',
});

export default function ClosableDrawer({
  children,
  title,
  open,
  onClose,
  closeOnClickOutside = true,
  icons,
  resizable = true,
  width: initialWidth = 500,
  sticky = false,
}: {
  children: ReactNode;
  title: string;
  open: boolean;
  onClose: () => void;
  closeOnClickOutside?: boolean;
  icons?: ReactNode;
  resizable?: boolean;
  width?: number;
  sticky?: boolean;
}) {
  const lastDownX = useRef<number | null>(null);
  const isResizing = useRef(false);
  const [width, setWidth] = useState(initialWidth);

  const handleMousedown: MouseEventHandler<HTMLAnchorElement> = (e) => {
    isResizing.current = true;
    lastDownX.current = e.clientX;
  };

  const handleMousemove = (e: MouseEvent) => {
    // we don't want to do anything if we aren't resizing.
    if (!isResizing.current) {
      return;
    }

    const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    const minWidth = 300;
    const maxWidth = window.innerWidth - 200;
    if (offsetRight > minWidth && offsetRight < maxWidth) {
      setWidth(offsetRight);
    }
  };

  const handleMouseup = () => {
    isResizing.current = false;
  };

  const removeListeners = () => {
    document.removeEventListener('mousemove', handleMousemove);
    document.removeEventListener('mouseup', handleMouseup);
  };

  useEffect(() => {
    if (open && resizable) {
      document.addEventListener('mousemove', handleMousemove);
      document.addEventListener('mouseup', handleMouseup);
    } else {
      removeListeners();
    }
    return removeListeners;
  }, [resizable, open]);

  useEffect(() => {
    setWidth(initialWidth);
  }, [initialWidth, setWidth]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeOnClickOutside ? onClose : () => null}
      elevation={0}
      sx={{ '& .MuiDrawer-paper': { maxWidth: 1 } }}
    >
      <Box maxWidth="100%" px={1} style={{ width, position: 'relative' }}>
        {resizable ? <Dragger id="dragger" onMouseDown={handleMousedown} role="button" /> : null}

        <CardHeader
          title={title}
          sx={
            sticky && {
              position: 'sticky',
              top: 0,
              bgcolor: 'background.paper',
              zIndex: 1000,
            }
          }
          action={
            <>
              {icons}
              <IconButton onClick={onClose} size="large">
                <CloseIcon />
              </IconButton>
            </>
          }
        />
        <CardContent>{children}</CardContent>
      </Box>
    </Drawer>
  );
}
