import { ListItemText, ListItem } from '@mui/material';
import moment from 'moment';
import { BankTransaction } from '@/types';
import curr from '@/utils/curr';

export default function BankTransactionListItem({ transaction }: { transaction: BankTransaction }) {
  return (
    <ListItem>
      <ListItemText
        sx={{ flexGrow: '1', flexShrink: 0 }}
        primary={transaction.description}
        secondary={`${moment(transaction.date).format('l')} - ${curr(transaction.amount)}`}
      />
    </ListItem>
  );
}
