import AddIcon from '@mui/icons-material/Add';
import { Box, Fab } from '@mui/material';
import { InputProps } from '@mui/material';
import { ZIndex } from '@mui/material/styles/zIndex';

export default function CreateButton({
  onClick,
  size = 'medium',
  position = 'fixed',
  margin = 2,
  zIndex = 'mobileStepper',
}: {
  onClick: () => void;
  size?: InputProps['size'];
  position?: 'fixed' | 'absolute';
  margin?: number;
  zIndex?: keyof ZIndex;
}) {
  return (
    <Box m={margin} bottom="0" right="0" position={position} zIndex={zIndex}>
      <Fab color="secondary" aria-label="Create" onClick={onClick} size={size}>
        <AddIcon />
      </Fab>
    </Box>
  );
}
