import { Delete } from '@mui/icons-material';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  CardContent,
  Card,
  CardHeader,
  LinearProgress,
  Stack,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import { useConfig } from '@/contexts/AppConfigContext';
import { useDialogs } from '@/contexts/DialogContext';
import { Certificate } from '@/types';
import { useRecordId } from '@/utils/genericResource';

export default function CustomerCertificates() {
  const customerId = useRecordId();
  const { confirm, drawerPrompt, prompt } = useDialogs();
  const { exemptionReasons, states } = useConfig();

  const {
    data: certificates,
    isFetching,
    refetch,
  } = useQuery(['customerCerts', customerId], () =>
    axios
      .get<{ data: Certificate[] }>(`/api/customers/${customerId}/certificates`)
      .then(({ data }) => data.data),
  );

  const deleteRequest = useMutation(
    (cert: Certificate) => axios.delete(`/api/customers/${customerId}/certificates/${cert.id}`),
    {
      onSuccess: () => refetch(),
    },
  );

  const isLoading = isFetching || deleteRequest.isLoading;

  const onCreate = () => {
    drawerPrompt({
      title: 'Create Certificate',
      fields: [
        FieldFactory.text('exemptionNumber').withLabel('Exemption Number'),
        FieldFactory.select('exemptionReason', exemptionReasons).withLabel('Exemption Reason'),
        FieldFactory.date('signedDate').withLabel('Signed Date'),
        FieldFactory.date('expirationDate').withLabel('Expiration Date'),
        FieldFactory.select(
          'exposureZone.name',
          states.US.map((s) => s.name),
        ).withLabel('Region'),
        FieldFactory.file('filename').withLabel('PDF of Certificate').with({
          accept: 'application/pdf, image/jpeg',
        }),
      ],
      schema: z.object({
        exemptionNumber: z.string(),
        exemptionReason: z.string(),
        signedDate: z.string(),
        expirationDate: z.string(),
        exposureZone: z.object({
          name: z.string(),
        }),
        filename: z.string(),
      }),
      onSubmit: (v) => axios.post(`/api/customers/${customerId}/certificates`, v),
    }).then(() => {
      refetch();
    });
  };

  const onDelete = (cert: Certificate) => {
    confirm({
      title: 'Delete Certificate',
      description:
        'This will revoke and delete the certificate. Revoked certificates can no longer be used.',
    }).then(() => {
      deleteRequest.mutate(cert);
    });
  };

  const onInvite = () => {
    prompt({
      title: 'Request Certificate from Customer',
      fields: [FieldFactory.email('email').withLabel('Email')],
      schema: z.object({
        email: z.string().email(),
      }),
      onSubmit: (v) => axios.post(`/api/customers/${customerId}/certificates/invite`, v),
    });
  };

  const actions = (
    <Stack spacing={2} direction="row">
      <Button type="button" onClick={onInvite}>
        Request from Customer
      </Button>
      <Button type="button" variant="contained" onClick={onCreate}>
        Create
      </Button>
    </Stack>
  );

  return (
    <Card>
      <CardHeader title="Exemption Certificates" action={actions} />
      {isLoading && <LinearProgress />}

      {certificates && certificates.length === 0 && (
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            This customer has no sales tax exemption certificates.
          </Typography>
        </CardContent>
      )}

      {certificates && certificates.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Exemption Reason</TableCell>
              <TableCell>Exemption Number</TableCell>
              <TableCell>Signed Date</TableCell>
              <TableCell>Expiration Date</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>File</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {certificates.map((cert) => (
              <TableRow key={cert.id}>
                <TableCell>{cert.exemptionReason.name}</TableCell>
                <TableCell>{cert.exemptionNumber}</TableCell>
                <TableCell>{cert.signedDate}</TableCell>
                <TableCell>{cert.expirationDate}</TableCell>
                <TableCell>{cert.exposureZone.name}</TableCell>
                <TableCell>
                  {cert.filename ? (
                    <Button component="a" target="_blank" href={cert.filename}>
                      View Certificate
                    </Button>
                  ) : null}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => onDelete(cert)} size="large">
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Card>
  );
}
