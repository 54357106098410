import { Typography, TypographyVariant } from '@mui/material';
import { OrderType } from '@/types';

export default function OrderTypeLabel({
  type,
  variant,
}: {
  type: OrderType;
  variant?: TypographyVariant;
}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {type?.color && (
        <div
          style={{
            height: 32,
            width: 32,
            backgroundColor: type.color,
            marginRight: 8,
          }}
        />
      )}
      <Typography variant={variant} color="textPrimary">
        {type?.name}
      </Typography>
    </div>
  );
}
