import { useCallback } from 'react';
import { TableState } from '@tanstack/react-table';
import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import { DateRangeField, RelationField, Resource } from '@/classes';
import useGetResource from '@/hooks/useGetResource';
import wrap from '@/utils/wrap';

const toDateString = (v: any) => moment(v).toISOString();
const isEmpty = (v: any) => v == null || v === '';

export default function useGetParamsForIndexRequest() {
  const getResource = useGetResource();

  return useCallback(
    (resource: Resource, state: TableState) => {
      const params: Record<string, string> = {
        count: String(state.pagination.pageSize),
        page: String(state.pagination.pageIndex + 1),
        sort: state.sorting
          .map((s) => {
            const field = resource.columns.find((f) => f.name === s.id);
            return `${s.desc ? '-' : ''}${field?.sortableBy || s.id}`;
          })
          .join(','),
        query: state.globalFilter,
      };

      const filters = state.columnFilters.reduce(
        (acc, cf) => {
          const field = resource.getFilterableFields().find((f) => f.name === cf.id);
          if (!field) {
            acc[cf.id] = String(cf.value);
            return acc;
          }
          const filterKey = field.getFilterKey();

          if (field instanceof RelationField) {
            const pk = getResource(field.resource).primaryKey;
            acc[filterKey] = wrap(cf.value)
              .map((v) => v[pk])
              .join(',');
            return acc;
          }

          if (field instanceof DateRangeField) {
            if (Array.isArray(cf.value)) {
              acc[filterKey] = cf.value
                .slice(0, 2)
                .filter((v) => !!v)
                .map(toDateString)
                .join(',');
              return acc;
            }
          }

          acc[filterKey] = Array.isArray(cf.value) ? cf.value.join(',') : String(cf.value);
          return acc;
        },
        {} as Record<string, string>,
      );

      const aggregations = {
        with_sum: Object.keys(pickBy(state.sums)).join(','),
        with_avg: Object.keys(pickBy(state.avgs)).join(','),
      };

      return omitBy(
        {
          ...params,
          ...filters,
          ...aggregations,
          ...resource.getQueryParamsForIndex(),
        },
        isEmpty,
      );
    },
    [getResource],
  );
}
