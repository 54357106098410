import { useState } from 'react';
import { Business } from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Popover,
  ListSubheader,
  ListItemButton,
} from '@mui/material';
import { useConfig } from '@/contexts/AppConfigContext';
import getBusinessName from '@/utils/getBusinessName';

export interface BusinessChooserProps {
  businessId: number | null;
  onBusinessChange: (id: number | null) => void;
}

export default function BusinessChooser({ businessId, onBusinessChange }: BusinessChooserProps) {
  const { businesses, user } = useConfig();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const business = businesses.find((b) => b.id === businessId);
  const otherBusinesses = businesses.filter((b) => b.id !== businessId);
  const currentBusinessName = getBusinessName(business, 'Avail HQ');

  return (
    <>
      <ListItemButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ flexGrow: 0 }}>
        <ListItemIcon
          sx={{
            color: 'inherit',
            minWidth: 48,
          }}
        >
          <Business color="inherit" />
        </ListItemIcon>
        <ListItemText primary={currentBusinessName} />
      </ListItemButton>

      <Popover
        id="business-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List component="div" disablePadding>
          <ListItem>
            <ListItemIcon>
              <Avatar>{currentBusinessName[0]}</Avatar>
            </ListItemIcon>
            <ListItemText primary={currentBusinessName} secondary="Currently Selected" />
          </ListItem>
          {!user.business_id && businessId && (
            <ListItemButton onClick={() => onBusinessChange(null)} dense>
              <ListItemText primary="Deselect" sx={{ color: 'error.main' }} />
            </ListItemButton>
          )}
        </List>
        {otherBusinesses.length > 0 && (
          <List subheader={<ListSubheader>Switch Business</ListSubheader>} dense>
            {otherBusinesses.map((b) => (
              <ListItemButton onClick={() => onBusinessChange(b.id)} key={b.id}>
                <ListItemText primary={getBusinessName(b)} />
              </ListItemButton>
            ))}
          </List>
        )}
      </Popover>
    </>
  );
}
