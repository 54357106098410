import { Receipt } from '@mui/icons-material';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CircularProgress,
  Typography,
  Chip,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import startCase from 'lodash/startCase';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import { ButtonAction } from '@/classes';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import TableCardContainer from '@/components/Shared/TableCardContainer';
import { LedgerEntry, Collection, PartiallyRequired } from '@/types';
import curr from '@/utils/curr';

interface ModelLedgerEntriesProps {
  resource: string;
  resourceId: string | number;
}

export default function ModelLedgerEntries({ resource, resourceId }: ModelLedgerEntriesProps) {
  const { data: entries } = useQuery(['modelLedgerEntries', resource, resourceId], () =>
    axios
      .get<
        Collection<PartiallyRequired<LedgerEntry, 'account'>>
      >(`/api/${resource}/${resourceId}/ledger-entries`)
      .then(({ data }) => data.data),
  );

  return (
    <div style={{ maxWidth: '100%', width: 800 }}>
      {entries ? (
        <TableCardContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Account</TableCell>
                <TableCell>Debit</TableCell>
                <TableCell>Credit</TableCell>
                <TableCell>Net</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.map((gl) => (
                <TableRow key={gl.id}>
                  <TableCell>{moment(gl.transaction_date).format('L')}</TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      #{gl.account.number} - {gl.account.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {startCase(gl.account.type)}
                    </Typography>
                  </TableCell>
                  <TableCell>{gl.debit_amount ? curr(gl.debit_amount) : null}</TableCell>
                  <TableCell>{gl.credit_amount ? curr(gl.credit_amount) : null}</TableCell>
                  <TableCell>
                    <ColoredCurrency amount={gl.amount} onlyNegative />
                  </TableCell>
                  <TableCell>
                    {gl.is_reconciled && <Chip label="Reconciled" color="success" size="small" />}
                  </TableCell>
                </TableRow>
              ))}
              {entries.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography variant="body2" color="textSecondary">
                      There are no GL entries for this resource.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell variant="head">TOTALS</TableCell>
                  <TableCell />
                  <TableCell>{curr(sumBy(entries, 'debit_amount'))}</TableCell>
                  <TableCell>{curr(sumBy(entries, 'credit_amount'))}</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableCardContainer>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export function getLedgerEntriesAction(resource: string, resourceId: string | number) {
  return new ButtonAction(
    'View GL',
    ({ dialogs }) => {
      dialogs.alert({
        title: 'GL Entries for Transaction',
        description: <ModelLedgerEntries resource={resource} resourceId={resourceId} />,
        maxWidth: 'md',
      });
    },
    Receipt,
  );
}
