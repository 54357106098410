interface CurrencyOptions {
  minDecimals: number;
  maxDecimals: number;
  divideBy: number;
  locale: 'en-US';
  currency: 'USD';
}

const defaults: CurrencyOptions = {
  minDecimals: 2,
  maxDecimals: 2,
  divideBy: 1,
  locale: 'en-US',
  currency: 'USD',
};

export default function curr(
  amount: number | null | undefined,
  opt: Partial<CurrencyOptions> | number = 2,
): string {
  if (amount == null) return '-';
  if (typeof opt === 'number') {
    opt = { minDecimals: opt, maxDecimals: opt };
  }
  const options: CurrencyOptions = { ...defaults, ...opt };

  const { minDecimals, maxDecimals, locale, currency, divideBy } = options;

  const double = amount / divideBy;
  if (currency === 'USD' && (minDecimals !== 2 || maxDecimals !== 2)) {
    return `$${double.toLocaleString(locale, {
      minimumFractionDigits: minDecimals,
      maximumFractionDigits: maxDecimals,
    })}`;
  }
  return double.toLocaleString(locale, {
    style: 'currency',
    currency,
  });
}
