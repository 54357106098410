import { ReactElement } from 'react';
import { SxProps } from '@mui/material';
import startCase from 'lodash/startCase';
import { Fieldable, NullableFieldable, RequiresPermission } from '@/classes/types';
import { EditFormFieldsProps } from '@/components/Form/EditFormFields';
import truthy from '@/utils/truthy';

export default class Layout implements RequiresPermission {
  name: string;
  label?: string;
  editable: boolean | number;
  creatable: boolean | number;
  readOnly: boolean | number;
  columnSpan: number;
  spacing: number;
  fields: Fieldable[];
  sx: SxProps;
  defaultLayout?: typeof Layout;
  padding?: boolean;
  requiresPermission?: string;
  requiresRole?: string;

  constructor(name: string, fields: NullableFieldable[] = []) {
    this.name = name;
    this.label = startCase(name);
    this.editable = 1;
    this.creatable = 1;
    this.readOnly = 0;
    this.columnSpan = 4;
    this.spacing = 2;
    this.fields = truthy(fields);
    this.sx = {};
  }

  withSpacing(spacing: number) {
    this.spacing = spacing;
    return this;
  }

  withoutPadding() {
    this.padding = false;
    return this;
  }

  withoutDefaultLayout() {
    this.defaultLayout = undefined;
    return this;
  }

  withoutLabel() {
    this.label = undefined;
    return this;
  }

  withFields(fields: NullableFieldable[]) {
    this.fields = truthy(fields);
    return this;
  }

  withLabel(label: string) {
    this.label = label;
    return this;
  }

  withColumnSpan(columnSpan: number) {
    this.columnSpan = columnSpan;
    return this;
  }

  setEditable(bool = true) {
    this.editable = bool;
    return this;
  }

  setCreatable(bool = true) {
    this.creatable = bool;
    return this;
  }

  withPermission(permission: string) {
    this.requiresPermission = permission;
    return this;
  }

  withRole(role: string) {
    this.requiresRole = role;
    return this;
  }

  withSx(object: SxProps) {
    this.sx = object;
    return this;
  }

  renderLayout(props: Partial<EditFormFieldsProps>): ReactElement | null {
    return null;
  }
}
