import { MonetizationOn } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { COMMISSION_HIT_OPTIONS } from '@/constants';

export default function CommissionHitIcon({ hit }: { hit: number }) {
  const option = COMMISSION_HIT_OPTIONS.find((opt) => opt.value === hit);
  if (!option) {
    return null;
  }

  return (
    <Tooltip title={option.label}>
      <MonetizationOn
        style={{ verticalAlign: 'middle', marginRight: 4, color: 'rgba(0,0,0,.54)' }}
      />
    </Tooltip>
  );
}
