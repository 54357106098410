import { useEffect } from 'react';
import { Box, Dialog } from '@mui/material';
import { Issue } from '@/types';
import IssueDetails from './IssueDetails';

export default function IssueDialog({
  issue,
  open,
  onClose,
}: {
  issue?: Issue;
  open: boolean;
  onClose: () => void;
}) {
  useEffect(() => {
    if (!issue && open) {
      onClose();
    }
  }, [issue]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <Box sx={{ width: 1000, minHeight: 700, p: 1 }}>
        {issue && <IssueDetails issue={issue} size="small" elevation={0} />}
      </Box>
    </Dialog>
  );
}
