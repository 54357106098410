import moment, { Moment } from 'moment';

export function getMoment(date: string | null): Moment | null {
  return date ? moment(date) : null;
}

export function getDateString(date: Moment | null): string | null {
  return date ? date.format('YYYY-MM-DD') : null;
}

export function formatDate(d: string | null) {
  return d ? moment(d).format('ll') : '-';
}

export function formatTimestamp(d: string | null) {
  return d ? moment(d).format('lll') : '-';
}
