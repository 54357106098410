import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LoadingBackdrop from '@/components/Shared/LoadingBackdrop';
import useScanDetection from '@/hooks/useScanDetection';
import { getIdFromIncrementId } from '@/utils/shipping';

export default function ShippingScanDetection() {
  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation((data: string) => {
    if (data.startsWith('BOX-')) {
      return axios.get(`/api/order-boxes/${getIdFromIncrementId(data)}`).then(({ data }) => {
        navigate(`/shipping/${data.order_id}?box=${data.id}`);
      });
    }
    if (data.startsWith('SO-')) {
      navigate(`/shipping/${getIdFromIncrementId(data)}`);
      return Promise.resolve();
    }
    return Promise.resolve();
  });

  useScanDetection({
    onComplete: (data) => {
      mutate(data);
    },
  });

  return <LoadingBackdrop isLoading={isLoading} />;
}
