import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FulfillmentOrderItem, FulfillmentOrderItemPayload } from '@/types';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';

export function useUpdateFulfillmentOrderItem(id: number) {
  const order = useRecord('fulfillmentOrders');
  const onReload = useOnReloadRecord();

  return useMutation((payload: Partial<FulfillmentOrderItemPayload>) =>
    axios
      .put<FulfillmentOrderItem>(`/api/fulfillment-orders/${order.id}/items/${id}`, payload)
      .then(() => {
        onReload();
      }),
  );
}
