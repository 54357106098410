import BackIcon from '@mui/icons-material/KeyboardBackspace';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar as MaterialAppBar,
  Toolbar,
  Typography,
  IconButton,
  Hidden,
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppContext } from '@/contexts/AppContext';

const StyledAppBar = styled(MaterialAppBar)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: `calc(100% - ${theme.drawerWidth}px)`,
  },
  marginLeft: theme.drawerWidth,
  background: theme.palette.background.paper,
}));

export default function AppBar() {
  const { setIsMenuOpen, appBar, appBarRef } = useAppContext();
  const { title, actions, back } = appBar;

  return (
    <StyledAppBar color="default" position="fixed">
      <Toolbar>
        {back ? (
          <IconButton color="inherit" component={Link} to={back} replace size="large">
            <BackIcon />
          </IconButton>
        ) : (
          <Hidden mdUp>
            <IconButton color="inherit" onClick={() => setIsMenuOpen(true)} size="large">
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}

        <Typography
          variant="h6"
          color="inherit"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flexGrow: 1,
          }}
        >
          {title}
        </Typography>

        <div ref={appBarRef}></div>
        {actions}
      </Toolbar>
    </StyledAppBar>
  );
}
