import { CardLayout, FieldFactory, Resource } from '@/classes';
import { AppResponse, CommissionModel, commissionModelPayloadSchema } from '@/types';

export default function commissionModels(appConfig: AppResponse) {
  const resource = new Resource<CommissionModel>('Commission Models')
    .withSchema(commissionModelPayloadSchema)
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.belongsTo('business', 'businesses').sortable('business.name'),
      FieldFactory.select('method', {
        gp: 'Gross Profit Split (Default)',
        cogs: 'COGS Markup',
      }),
      new CardLayout('Levels')
        .withSubheader('Will pay the highest % of gross profit that meets the min/max criteria.')
        .withoutDefaultLayout()
        .withFields([
          FieldFactory.list('levels', [
            FieldFactory.number('min_margin').withColumnSpan(2),
            FieldFactory.number('max_margin').withColumnSpan(2),
            FieldFactory.percent('percent').withLabel('Commission %').withColumnSpan(2),
          ]),
        ]),
      new CardLayout('Late Payment Dings')
        .withSubheader('Will charge highest % of commission that meet the days late criteria.')
        .withoutDefaultLayout()
        .withFields([
          FieldFactory.list('dings', [
            FieldFactory.number('min_days_late').withColumnSpan(3),
            FieldFactory.percent('percent').withLabel('% Dinged').withColumnSpan(3),
          ]),
        ]),
    ]);

  if (appConfig.business) {
    resource.fields = resource.fields.filter((f) => f.name !== 'business');
    resource.columns = resource.columns.filter((f) => f.name !== 'business');
  }

  return resource;
}
