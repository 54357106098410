import trim from 'lodash/trim';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldArrayPath,
  FieldError,
  FieldValues,
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';
import FieldModel from '@/classes/Field';

export function ArrayController<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
>({
  name,
  control,
  render,
}: {
  name: TName;
  control: Control<TFieldValues>;
  render: (props: UseFieldArrayReturn<TFieldValues, TName> & { name: TName }) => React.ReactElement;
}) {
  const props = useFieldArray<TFieldValues>({
    name,
    control,
  });

  return render({ name, ...props });
}

export function NonFormField({
  field,
  value,
  onChange,
  name,
  onBlur = () => null,
  error,
}: {
  field: FieldModel;
  value: unknown;
  onChange: ControllerRenderProps['onChange'];
  name?: string;
  onBlur?: ControllerRenderProps['onBlur'];
  error?: FieldError;
}) {
  return field.renderEditComponent({
    field: {
      onChange,
      onBlur,
      value,
      disabled: field.disabled,
      ref: () => null,
      name: name || field.name,
    },
    fieldState: {
      invalid: Boolean(error),
      isTouched: true,
      isDirty: false,
      isValidating: false,
      error,
    },
    formState: {
      isDirty: false,
      isLoading: false,
      isSubmitted: false,
      isSubmitSuccessful: false,
      isSubmitting: false,
      isValidating: false,
      isValid: false,
      disabled: false,
      submitCount: 0,
      dirtyFields: {},
      touchedFields: {},
      validatingFields: {},
      errors: {},
    },
  });
}

export default function FormField(props: {
  field: FieldModel;
  parentName?: string;
  isCreate?: boolean;
}) {
  const form = useFormContext();
  const { field, parentName } = props;

  let { name } = field;
  if (parentName) {
    name = trim(`${parentName}.${name}`, '.');
  }

  if (field.isArray) {
    return (
      <ArrayController
        key={name}
        name={name}
        control={form.control}
        render={(p) => field.renderArrayComponent(p)}
      />
    );
  }

  return (
    <Controller
      key={name}
      name={name}
      control={form.control}
      render={(p) => field.renderEditComponent(p)}
    />
  );
}
