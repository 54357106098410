import { CloudDownload } from '@mui/icons-material';
import { Action, AnchorAction, CustomAction, FieldFactory, Resource } from '@/classes';
import PrintMenu from '@/components/Print/PrintMenu';
import ShippingForOrder from '@/components/Shipping/ShippingForOrder';
import { AppResponse, Order } from '@/types';
import getApiUrl from '@/utils/getApiUrl';
import { getOrderColumns, INITIAL_ORDER_COLUMNS } from './orders';

export default function ordersToShip(appConfig: AppResponse) {
  return new Resource<Order>('Orders to Ship')
    .getTitleUsing((o) => `Shipping: ${o.increment_id}`)
    .withApiEndpoint('/api/orders')
    .getSingleActionsUsing((v) => {
      const actions: Action[] = [
        new CustomAction('Print', () => <PrintMenu model="order" id={v.id} />),
      ];
      if (v.shipping_mode === 'kits') {
        actions.push(
          new AnchorAction(
            'Download Addresses',
            getApiUrl(`/api/orders/${v.id}/addresses/download`),
          ).withIcon(CloudDownload),
        );
      }
      return actions;
    })
    .setReadOnly()
    .setCanExport()
    .withDefaultSort('committed_ship_date')
    .withInitialColumns(INITIAL_ORDER_COLUMNS)
    .withDefaultFilters({
      bucket: 'to_ship',
    })
    .withFilters([
      FieldFactory.radio('bucket', {
        to_ship: 'All',
        ready_to_ship: 'Ready',
        blanks_to_ship: 'Blanks',
        drop_ship_to_ship: 'Drop Shipped',
      }).asQuickFilter(),
      FieldFactory.select(
        'shipping_method',
        appConfig.shippingMethods.map((m) => m.name),
      ).withFilterKey('shipping_method'),
    ])
    .withColumns(getOrderColumns())
    .editUsing(ShippingForOrder);
}
