import {
  CircularProgress,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useGetInventoryLevelsForVariant } from '@/api/inventoryLevels';
import { FieldProps } from '@/classes/types';
import { INVENTORY_LOCATION_TYPES } from '@/constants';
import InventoryLocationLabel from './InventoryLocationLabel';

export default function ChooseLocationForVariant({ field }: FieldProps) {
  const variantId = useFormContext<{ variant_id?: number }>().watch('variant_id');

  if (!variantId) {
    throw new Error('Please initialize variant_id');
  }

  const { data: levels = [], isLoading } = useGetInventoryLevelsForVariant(variantId);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (levels.length === 0) {
    return (
      <Typography variant="body2" color="textSecondary">
        There are no alternate locations for this SKU.
      </Typography>
    );
  }

  return (
    <Table size="small" style={{ whiteSpace: 'nowrap' }}>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>Location</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Qty</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {levels.map((level) => (
          <TableRow key={level.id}>
            <TableCell>
              <Radio
                checked={field.value?.id === level.location.id}
                onClick={() => field.onChange(level.location)}
              />
            </TableCell>
            <TableCell>
              <InventoryLocationLabel location={level.location} />
            </TableCell>
            <TableCell>{INVENTORY_LOCATION_TYPES[level.location.type]}</TableCell>
            <TableCell>{level.qty}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
