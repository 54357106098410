import AddBoxIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { z } from 'zod';
import { ButtonAction, CardLayout, CustomAction, FieldFactory, Resource } from '@/classes';
import PrintMenu from '@/components/Print/PrintMenu';
import { InventoryLocationGroup, inventoryLocationGroupPayloadSchema } from '@/types';

export default function inventoryLocationGroups(): Resource<InventoryLocationGroup> {
  return new Resource<InventoryLocationGroup>('Inventory Location Groups')
    .withSchema(inventoryLocationGroupPayloadSchema)
    .getSingleActionsUsing((values) => [
      new CustomAction('Print', () => <PrintMenu model="location_group" id={values.id} />),
      new ButtonAction('Add New Section', ({ dialogs, toast }) => {
        dialogs
          .prompt({
            title: 'Add New Section',
            fields: [FieldFactory.text('section').withHelp('e.g. G42')],
            schema: z.object({
              section: z.string().min(1),
            }),
            onSubmit: (v) => axios.post(`/api/inventory-location-groups/${values.id}/bins`, v),
          })
          .then(() => {
            toast('Section successfully created');
          });
      }).withIcon(AddBoxIcon),
    ])
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.belongsTo('business', 'businesses').sortable('business.name').filterable(),
    ])
    .addFields([
      new CardLayout('bin_locations')
        .setCreatable(false)
        .withoutPadding()
        .withoutDefaultLayout()
        .withFields([
          FieldFactory.hasManyCRUD('locations', 'inventoryLocations')
            .withColumnSpan(12)
            .getQueryParamsUsing((group) => ({
              'filter[location_group_id]': group.id,
            })),
        ]),
    ]);
}
