import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/OutlinedInput';
import { red } from '@mui/material/colors';
import { UseControllerReturn } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { FieldProps } from '@/classes/types';
import InputWrapper from '@/components/Form/InputWrapper';
import Field from '../Field';

function Percent({ field, fieldState, fieldModel }: FieldProps<PercentField>) {
  return (
    <InputWrapper fieldState={fieldState} fieldModel={fieldModel}>
      <MaskedInput
        onChange={field.onChange}
        value={field.value}
        mask={createNumberMask({
          allowDecimal: true,
          prefix: '',
          allowNegative: false,
        })}
        placeholder={fieldModel.placeholder}
        render={(ref, props) => (
          <Input
            {...props}
            inputRef={ref}
            label={fieldModel.label}
            size={fieldModel.size}
            margin={fieldModel.margin}
            disabled={fieldModel.disabled}
            required={fieldModel.required}
            fullWidth={fieldModel.fullWidth}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        )}
      />
    </InputWrapper>
  );
}

export default class PercentField extends Field {
  renderEditComponent(props: UseControllerReturn) {
    return <Percent {...props} fieldModel={this} />;
  }

  renderCell(value: any) {
    return (
      <span style={{ color: value < 0 ? red[500] : 'inherit' }}>{Number(value).toFixed(2)}%</span>
    );
  }
}
