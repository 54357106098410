import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useGetVariantMutation } from '@/api/variants';
import { FieldProps } from '@/classes/types';
import SkuLabel from '@/components/Products/SkuLabel';
import ArrayBullets from '@/components/Text/ArrayBullets';
import { ProductVariant, SkuResource } from '@/types';
import InventoryText from './InventoryText';
import ProductFinderDialog from './ProductFinderDialog';

export default function VariantField({ field }: FieldProps) {
  const [isAdvanced, setIsAdvanced] = useState(false);
  const variant = field.value as SkuResource | null;
  const skuRequest = useGetVariantMutation();
  const search = () => setIsAdvanced(true);

  const onProduct = (variant: ProductVariant) => {
    skuRequest.mutateAsync(variant.id).then((data) => {
      setIsAdvanced(false);
      field.onChange(data);
    });
  };

  return (
    <div>
      {variant ? (
        <SkuLabel variant={variant}>
          <Typography variant="body2" color="textSecondary">
            <ArrayBullets
              elements={[variant.product.style_number, variant.product.color_code, variant.size]}
            />
          </Typography>
          <InventoryText variant={field.value} />
        </SkuLabel>
      ) : (
        <Typography color="textSecondary">No SKU Selected</Typography>
      )}

      <Button size="small" onClick={search} style={{ marginTop: 8 }}>
        {variant ? 'Change' : 'Choose'} SKU
      </Button>

      <ProductFinderDialog
        open={isAdvanced}
        mode="variant"
        onSuccess={onProduct}
        onCancel={() => setIsAdvanced(false)}
        isLoading={skuRequest.isLoading}
      />
    </div>
  );
}
