import Field from '@/classes/Field';
import Layout from '@/classes/Layout';
import FieldsetLayout from '@/classes/Layouts/FieldsetLayout';
import GroupLayout from '@/classes/Layouts/GroupLayout';
import { Fieldable } from '@/classes/types';
import FormField from '@/components/Form/FormField';
import { useRequiresPermissionAndRole } from '@/hooks/permissions';

const shouldBeWrapped = (fieldable: Fieldable) => {
  if (fieldable instanceof Field) {
    return fieldable;
  }
  return fieldable instanceof GroupLayout || fieldable instanceof FieldsetLayout;
};

const wrapInDefaultLayout = (fieldables: Fieldable[], LayoutModel: typeof Layout) => {
  const fields = fieldables.filter(shouldBeWrapped);
  let found = false;
  return fieldables.reduce((agg, currentField) => {
    if (shouldBeWrapped(currentField)) {
      if (!found) {
        found = true;
        return [...agg, new LayoutModel('default').withFields(fields).withoutLabel()];
      }
      return agg;
    }
    return [...agg, currentField];
  }, [] as Fieldable[]);
};

export interface EditFormFieldsProps {
  fields: Fieldable[];
  defaultLayout?: typeof Layout;
  parentName?: string;
  isCreate?: boolean;
}

export default function EditFormFields({
  fields,
  defaultLayout,
  parentName,
  isCreate,
}: EditFormFieldsProps) {
  const requiresPermissionAndRole = useRequiresPermissionAndRole();
  let filteredFields = fields.filter(
    (f) => requiresPermissionAndRole(f) && (isCreate ? f.creatable : f.editable),
  );

  if (defaultLayout) {
    filteredFields = wrapInDefaultLayout(filteredFields, defaultLayout);
  }

  return (
    <>
      {filteredFields.map((model) => (
        <div key={model.name}>
          {model instanceof Layout ? (
            model.renderLayout({
              isCreate,
              parentName,
            })
          ) : (
            <FormField field={model} parentName={parentName} isCreate={isCreate} />
          )}
        </div>
      ))}
    </>
  );
}
