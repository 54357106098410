import {
  Avatar,
  Checkbox,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import Label from '@/components/Shared/Label';
import { ShippoRate } from '@/types';
import curr from '@/utils/curr';

export default function RateList({
  rates,
  selectedRate,
  setSelectedRate,
}: {
  rates: ShippoRate[];
  selectedRate?: string;
  setSelectedRate: (r?: string) => void;
}) {
  return (
    <List>
      {rates.map((rate) => (
        <ListItemButton key={rate.id} onClick={() => setSelectedRate(rate.id)}>
          <ListItemAvatar>
            <Tooltip title={rate.provider}>
              <Avatar alt={rate.provider} src={rate.provider_image} />
            </Tooltip>
          </ListItemAvatar>
          <ListItemText>
            <Typography>
              {rate.service_level} - {curr(rate.cost)}
            </Typography>
            <Tooltip title={rate.duration_terms}>
              <Typography variant="body2" color="textSecondary">
                Estimated Days: {rate.estimated_days}
              </Typography>
            </Tooltip>
            {rate.attributes.map((a) => (
              <Label key={a}>{a}</Label>
            ))}
          </ListItemText>
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              checked={selectedRate === rate.id}
              onChange={(e) => setSelectedRate(e.target.checked ? rate.id : undefined)}
            />
          </ListItemSecondaryAction>
        </ListItemButton>
      ))}
    </List>
  );
}
