import { BrokenImage, Redo, Refresh } from '@mui/icons-material';
import {
  Button,
  CardActions,
  CardContent,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { useGetInventoryLevels } from '@/api/inventoryLevels';
import { FieldFactory } from '@/classes';
import InventoryLocationLabel from '@/components/Inventory/InventoryLocationLabel';
import MoveInventoryLocation from '@/components/Inventory/MoveInventoryLocation';
import Can from '@/components/Permissions/Can';
import SkuLabel from '@/components/Products/SkuLabel';
import TextLink from '@/components/Text/TextLink';
import { useDialogs } from '@/contexts/DialogContext';
import { useHasPermission } from '@/hooks/permissions';
import {
  cycleCountPayloadSchema,
  genericModelReferenceSchema,
  InventoryLevel,
  InventoryAdjustmentPayload,
  CycleCountPayload,
} from '@/types';

export function getCycleCountFields() {
  return [
    FieldFactory.number('qty').withSize('medium'),
    FieldFactory.belongsTo('expense_account', 'accounts')
      .withHelp('This will override the default "Inventory Defects" account')
      .withPermission('read:accounts'),
    FieldFactory.textarea('note'),
  ];
}

const cycleCountSchema = cycleCountPayloadSchema
  .pick({
    qty: true,
    note: true,
  })
  .extend({
    expense_account: genericModelReferenceSchema.nullish(),
  });

export default function InventoryLevels({
  filterKey,
  filterValue,
  label,
}: {
  filterKey: 'variant_id' | 'location_id';
  filterValue: number;
  label: string;
}) {
  const hasPermission = useHasPermission();
  const { prompt } = useDialogs();
  const queryClient = useQueryClient();

  const { data: levels, isFetching, refetch } = useGetInventoryLevels(filterKey, filterValue);

  const invalidate = () => {
    refetch();
    queryClient.invalidateQueries(['dataTable', 'inventoryEntries']);
  };

  const onAdjust = (l: InventoryLevel) => {
    prompt({
      title: 'Cycle Count',
      description: `How many ${l.variant.sku} are in ${l.location.path}?`,
      initialValues: { qty: l.qty },
      fields: getCycleCountFields(),
      schema: cycleCountSchema,
      onSubmit: (v) => {
        const payload: CycleCountPayload = {
          ...v,
          location_id: l.location.id,
          variant_id: l.variant.id,
          expense_account_id: v.expense_account?.id,
        };
        return axios.post('/api/inventory-adjustments/cycle', payload);
      },
    }).then(() => {
      invalidate();
    });
  };

  const onMove = (l: InventoryLevel) => {
    prompt({
      title: 'Move Inventory',
      description: <MoveInventoryLocation level={l} />,
      fields: [],
      initialValues: { qty: l.qty },
      schema: z.object({
        end_location: z.object({
          id: z.number(),
          path: z.string(),
        }),
        qty: z.coerce.number().int().max(l.qty),
      }),
      onSubmit: (v) => {
        const payload: InventoryAdjustmentPayload = {
          ...v,
          variant: l.variant,
          start_location: l.location,
        };
        return axios.post('/api/inventory-adjustments', payload);
      },
    }).then(() => {
      invalidate();
    });
  };

  const onScrap = (l: InventoryLevel) => {
    prompt({
      title: 'Scrap Items',
      description: `How many of the ${l.qty} ${l.variant.sku} in ${l.location.path} should be scrapped?`,
      fields: [FieldFactory.number('qty').withSize('medium'), FieldFactory.textarea('note')],
      schema: cycleCountSchema,
      onSubmit: (v) => {
        const payload: InventoryAdjustmentPayload = {
          ...v,
          start_location: l.location,
          variant: l.variant,
          is_scrap: true,
        };
        return axios.post('/api/inventory-adjustments', payload);
      },
    }).then(() => {
      invalidate();
    });
  };

  const onAddLocation = () => {
    prompt({
      title: `Add Location for ${label}`,
      fields: [FieldFactory.belongsTo('location', 'inventoryLocations'), ...getCycleCountFields()],
      schema: cycleCountSchema.extend({
        location: genericModelReferenceSchema,
      }),
      onSubmit: (v) => {
        const payload: CycleCountPayload = {
          ...v,
          variant_id: filterValue,
          location_id: v.location.id,
          expense_account_id: v.expense_account?.id,
        };
        return axios.post('/api/inventory-adjustments/cycle', payload);
      },
    }).then(() => {
      invalidate();
    });
  };

  const onAddVariant = () => {
    prompt({
      title: `Add SKU to ${label}`,
      fields: [
        FieldFactory.belongsTo('variant', 'inventoryVariants'),
        FieldFactory.number('qty'),
        FieldFactory.textarea('note'),
      ],
      schema: cycleCountSchema.extend({
        variant: genericModelReferenceSchema,
      }),
      onSubmit: (v) => {
        const payload: CycleCountPayload = {
          ...v,
          location_id: filterValue,
          variant_id: v.variant.id,
        };
        return axios.post('/api/inventory-adjustments/cycle', payload);
      },
    }).then(() => {
      invalidate();
    });
  };

  return (
    <>
      {isFetching && <LinearProgress />}

      {levels?.length === 0 && (
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            There is no inventory{' '}
            {filterKey === 'location_id' ? 'in this location' : 'for this SKU'}.
          </Typography>
        </CardContent>
      )}

      {levels && levels.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              {filterKey !== 'variant_id' && <TableCell>SKU</TableCell>}
              {filterKey !== 'location_id' && <TableCell>Location</TableCell>}
              <TableCell>Qty</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {levels.map((l) => (
              <TableRow key={l.id}>
                {filterKey !== 'variant_id' && (
                  <TableCell>
                    <SkuLabel variant={l.variant} />
                  </TableCell>
                )}
                {filterKey !== 'location_id' && (
                  <TableCell>
                    <TextLink
                      to={`/inventory-locations/${l.location.id}`}
                      disabled={!hasPermission('read:inventory_locations')}
                    >
                      <InventoryLocationLabel location={l.location} />
                    </TextLink>
                  </TableCell>
                )}
                <TableCell>{l.qty}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                  <Can permission="write:inventory_adjustments">
                    <Tooltip title="Move to a different location">
                      <IconButton onClick={() => onMove(l)}>
                        <Redo fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Can>
                  <Can permission="inventory_adjustments:add_subtract">
                    <Tooltip title="Scrap">
                      <IconButton onClick={() => onScrap(l)}>
                        <BrokenImage fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cycle Count">
                      <IconButton onClick={() => onAdjust(l)}>
                        <Refresh fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Can>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <Can permission="inventory_adjustments:add_subtract">
        {filterKey === 'location_id' && (
          <CardActions>
            <Button onClick={onAddVariant} sx={{ mt: 1 }}>
              Add Variant
            </Button>
          </CardActions>
        )}
        {filterKey === 'variant_id' && (
          <CardActions>
            <Button onClick={onAddLocation} sx={{ mt: 1 }}>
              Add Location
            </Button>
          </CardActions>
        )}
      </Can>
    </>
  );
}
