import { CustomAction, FieldFactory, Resource } from '@/classes';
import PrintMenu from '@/components/Print/PrintMenu';
import PurchasingScreen from '@/components/Purchasing/PurchasingScreen';
import { Order } from '@/types';
import { getOrderColumns } from './orders';

export default function ordersToPurchase() {
  return new Resource<Order>('Orders to Purchase')
    .withRelations(['purchase_order_items', 'inventory_pick_items'])
    .getTitleUsing((o) => `Purchasing: ${o.increment_id}`)
    .withApiEndpoint('/api/orders')
    .getSingleActionsUsing((v) => [
      new CustomAction('Print', () => <PrintMenu model="order" id={v.id} />),
    ])
    .setReadOnly()
    .setCanExport()
    .withDefaultSort('order_approved_at')
    .withQueryParams({
      index: {
        to_purchase: '1',
      },
    })
    .withInitialColumns([
      'increment_id',
      'order_approved_at',
      'customer',
      'sales_rep',
      'order_type',
      'status',
      'committed_ship_date',
      'qty_to_purchase',
    ])
    .withFilters([FieldFactory.boolean('to_stage', 'Ready to Stage')])
    .withColumns([
      ...getOrderColumns(),
      FieldFactory.timestamp('order_approved_at').sortable(),
      FieldFactory.number('qty_to_purchase').sortable(),
    ])
    .editUsing(PurchasingScreen);
}
