import { HTMLAttributes } from 'react';
import { styled } from '@mui/material';

const StyledList = styled('ul')({
  padding: 0,
  margin: 0,
  '& li': {
    listStyle: 'none',
    display: 'inline',
  },
  '& li::after': {
    content: '"\\2022"',
    padding: '0 4px',
  },
  '& li:last-child::after': {
    content: 'none',
  },
});

export default function BulletSeparatedList({
  children,
  ...other
}: HTMLAttributes<HTMLUListElement>) {
  return <StyledList {...other}>{children}</StyledList>;
}
