import { Grid2 as Grid, Stack } from '@mui/material';
import uniq from 'lodash/uniq';
import IssuesCard from '@/components/Issues/IssuesCard';
import PurchaseOrderSummaryCard from '@/components/Purchasing/PurchaseOrderSummaryCard';
import ItemsToReceive from '@/components/Receiving/ItemsToReceive';
import ReceivingsList from '@/components/Receiving/ReceivingsList';
import { useRecord } from '@/utils/genericResource';
import truthy from '@/utils/truthy';

export default function ReceivingByPurchaseOrder() {
  const record = useRecord('purchaseOrdersToReceive');
  const orderIds = uniq(truthy(record.items.map((i) => i.order_id)));
  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, lg: 9 }}>
        <Stack spacing={2}>
          <ItemsToReceive
            receivableId={record.id}
            receivableType="purchase_order"
            purchaseOrderIds={[record.id]}
            orderIds={orderIds}
            items={record.items}
          />
          <ReceivingsList receivableId={record.id} receivableType="purchase_order" />
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, lg: 3 }}>
        <Stack spacing={2}>
          <PurchaseOrderSummaryCard purchaseOrder={record} />
          <IssuesCard purchaseOrderId={record.id} orderIds={orderIds} canCreate type="receiving" />
        </Stack>
      </Grid>
    </Grid>
  );
}
