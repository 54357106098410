import pick from 'lodash/pick';
import { getItemsText, useUpdateOrderItemRequest } from '@/api/orderItems';
import { FieldFactory } from '@/classes';
import CompareVendors from '@/components/Orders/Items/CompareVendors';
import TextButton from '@/components/Text/TextButton';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderItem, orderItemPayloadSchema } from '@/types';
import { useIsOrderLocked } from '@/utils/orders';

export default function OrderItemVendor({ items }: { items: OrderItem[] }) {
  const { prompt } = useDialogs();
  const vendor = items[0]!.vendor;
  const isOrderLocked = useIsOrderLocked();
  const allSame = items.every((item) => item.vendor?.id === vendor?.id);
  const updateOrderItemRequest = useUpdateOrderItemRequest();

  const cannotUpdate = items.length > 1 && items.some((i) => !i.is_custom);

  const onUpdate = () => {
    if (items.length === 1 && !items[0]!.is_custom) {
      prompt({
        title: 'Compare Vendors',
        initialValues: pick(items[0], 'variant'),
        maxWidth: 'lg',
        fields: [FieldFactory.custom('variant', CompareVendors)],
        schema: orderItemPayloadSchema.pick({ variant: true }),
        onSubmit: (v) =>
          Promise.all(
            items.map((item) => updateOrderItemRequest.mutateAsync({ id: item.id, ...v })),
          ),
      });
    } else {
      prompt({
        title: 'Update Vendor',
        description: `What should the vendor of ${getItemsText(items)} be?`,
        fields: [FieldFactory.belongsTo('vendor', 'vendors').withSize('medium')],
        schema: orderItemPayloadSchema.pick({ vendor: true }),
        initialValues: { vendor },
        onSubmit: (v) =>
          Promise.all(
            items.map((item) => updateOrderItemRequest.mutateAsync({ id: item.id, ...v })),
          ),
      });
    }
  };

  return (
    <TextButton
      disabled={
        isOrderLocked ||
        cannotUpdate ||
        items.some((i) => i.qty_sourced != 0) ||
        items.some((i) => i.is_customer_supplied)
      }
      onClick={onUpdate}
    >
      {allSame ? vendor?.name : <span>&mdash;</span>}
    </TextButton>
  );
}
