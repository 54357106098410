import { useState } from 'react';
import { ChevronLeft, ChevronRight, Settings } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  Hidden,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useGetKitItemsForPackout } from '@/api/kitting';
import { ButtonAction } from '@/classes';
import EventableCard from '@/components/Notes/EventableCard';
import PackoutCompleteStatus from '@/components/Packouts/PackoutCompleteStatus';
import PackoutPendingStatus from '@/components/Packouts/PackoutPendingStatus';
import PackoutShippingStatus from '@/components/Packouts/PackoutShippingStatus';
import PackoutStartedStatus from '@/components/Packouts/PackoutStartedStatus';
import StatusChip from '@/components/Shared/StatusChip';
import SubMenu from '@/components/Shared/SubMenu';
import Text from '@/components/Text/Text';
import { PACKOUT_STATUS_COLOR } from '@/constants';
import { useShowLoading } from '@/contexts/DialogContext';
import { useDialogs } from '@/contexts/DialogContext';
import { Packout } from '@/types';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';
import NoteViewer from '../Notes/NoteViewer';
import OrderSummaryCard from '../Orders/OrderSummaryCard';

export default function PackoutPage() {
  const { id, order, status, readyStatus } = useRecord('orderPackouts', true);
  const onReload = useOnReloadRecord('orderPackouts');
  const showLoading = useShowLoading();
  const queryClient = useQueryClient();
  const { confirm } = useDialogs();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const goToStatus = (action: Packout['status']) => {
    showLoading(axios.post(`/api/order-packouts/${id}/status/${action}`)).then(() => {
      onReload();
      queryClient.invalidateQueries(['kitsForOrder', order.id]);
    });
  };
  const { data: items = [] } = useGetKitItemsForPackout(order.id);
  const start = () => goToStatus('started');
  const startShipping = async () => {
    if (items.some((i) => i.picked_qty < i.qty)) {
      await confirm({
        title: 'Not all items have been picked',
        description:
          'Some items have not been picked. Are you sure you want to continue to shipping?',
        color: 'warning',
      });
    }
    goToStatus('shipping');
  };
  const complete = () => goToStatus('completed');
  const reset = () => goToStatus('pending');

  const [primaryAction, ...secondaryActions] = (() => {
    if (status === 'pending') {
      return [[readyStatus === 'ready' ? 'Start Packout' : 'Start Packout Anyways', start]];
    }
    if (status === 'started') {
      return [
        ['Start Shipping', startShipping],
        ['Reset', reset],
      ];
    }
    if (status === 'shipping') {
      return [
        ['Complete', complete],
        ['Back to Started', start],
        ['Reset', reset],
      ];
    }
    if (status === 'completed') {
      return [['Reset', reset]];
    }
    return [];
  })() as [string, () => void][];

  const renderForStatus = () => {
    if (status === 'pending') {
      if (readyStatus === 'ready') {
        return (
          <Text primary="All items are ready to pack out. Bin numbers will be generated upon starting the packout." />
        );
      }

      return (
        <Text
          primary="The items in these kits are not ready to packout."
          secondary={
            readyStatus === 'blocked'
              ? 'There is at least 1 production order that is not complete.'
              : readyStatus === 'misconfigured'
                ? 'Items have not been assigned to kits properly. Please check the kitting configuration for this order.'
                : 'The order is not approved.'
          }
        />
      );
    }

    if (status === 'started') {
      return (
        <Text
          primary={`
          The packout has started. Please put all items in their bin. Once complete, click "Start
          Shipping" to move to the next step.`}
        />
      );
    }

    if (status === 'shipping') {
      return (
        <Text
          primary={`Please ship each kit. Once complete, click "Complete" to close out the packout.`}
        />
      );
    }

    if (status === 'completed') {
      return <Text primary="The packout is complete" />;
    }

    return null;
  };
  return (
    <>
      <NoteViewer tag="packout" resource="orders" resourceId={order.id} />

      <Grid container spacing={3}>
        <Grid size={{ xs: 12, lg: sidebarCollapsed ? 'grow' : 9 }}>
          <Stack spacing={2}>
            {status === 'pending' ? (
              <PackoutPendingStatus />
            ) : status === 'started' ? (
              <PackoutStartedStatus />
            ) : status === 'shipping' ? (
              <PackoutShippingStatus />
            ) : status === 'completed' ? (
              <PackoutCompleteStatus />
            ) : null}

            <EventableCard resource="packout" resourceId={id} />
          </Stack>
        </Grid>

        {sidebarCollapsed ? (
          <Grid>
            <IconButton onClick={() => setSidebarCollapsed(false)} sx={{ mr: 1, mt: 1.5 }}>
              <ChevronLeft />
            </IconButton>
          </Grid>
        ) : (
          <Grid size={{ xs: 12, lg: 3 }}>
            <Stack spacing={2}>
              <Card>
                <CardHeader
                  title="Packout"
                  action={
                    <>
                      <Tooltip title="Order Kitting Configuration">
                        <IconButton component={Link} to={`/orders/${order.id}?tab=shipping`}>
                          <Settings />
                        </IconButton>
                      </Tooltip>
                      <Hidden mdDown>
                        <IconButton onClick={() => setSidebarCollapsed(true)}>
                          <ChevronRight />
                        </IconButton>
                      </Hidden>
                    </>
                  }
                />
                <CardContent style={{ textAlign: 'center' }}>
                  <StatusChip
                    status={status}
                    colors={PACKOUT_STATUS_COLOR}
                    style={{ marginBottom: 16, minWidth: 200 }}
                  />
                  {renderForStatus()}
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  {primaryAction && (
                    <Button variant="contained" onClick={primaryAction[1]}>
                      {primaryAction[0]}
                    </Button>
                  )}

                  {secondaryActions.length > 0 && (
                    <SubMenu items={secondaryActions.map((a) => new ButtonAction(a[0], a[1]))} />
                  )}
                </CardActions>
              </Card>
              <OrderSummaryCard order={order} />
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
}
