import { ReactNode } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export default function AuthLayout({ title, children }: { title: string; children: ReactNode }) {
  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Link to="/">
        <Avatar sx={{ m: 1, width: 64, height: 64 }} src="/apple-touch-icon.png">
          AV
        </Avatar>
      </Link>
      <Typography component="h1" variant="h5">
        {title}
      </Typography>
      <Card sx={{ width: 700, maxWidth: '100%', mt: 2 }}>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
}
