import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import AddressBlock from '@/components/Addresses/AddressBlock';
import { Address } from '@/types';

export default function AddressListItem({
  address,
  onToggle,
  isSelected,
  showMethod,
}: {
  address: Address;
  onToggle?: (a: Address) => void;
  isSelected?: boolean;
  showMethod?: boolean;
}) {
  const main = (
    <ListItemText>
      <div style={{ fontSize: '.9rem' }}>
        <AddressBlock address={address} showMethod={showMethod} />
      </div>
    </ListItemText>
  );

  if (onToggle) {
    return (
      <ListItemButton onClick={() => onToggle(address)}>
        <ListItemIcon>
          <Checkbox edge="start" checked={isSelected} tabIndex={-1} />
        </ListItemIcon>
        {main}
      </ListItemButton>
    );
  }
  return <ListItem>{main}</ListItem>;
}
