import { CompareArrows } from '@mui/icons-material';
import axios from 'axios';
import startCase from 'lodash/startCase';
import { z } from 'zod';
import {
  ButtonAction,
  CardLayout,
  FieldFactory,
  LinkAction,
  Resource,
  Tab,
  TabLayout,
} from '@/classes';
import Ledger from '@/components/Accounting/Ledger';
import PlaidConnection from '@/components/Accounting/PlaidConnection';
import { ACCOUNT_TYPES } from '@/constants';
import {
  AccountDetail,
  AppResponse,
  genericModelReferenceSchema,
  accountPayloadSchema,
} from '@/types';
import curr from '@/utils/curr';
import { getSingleDisableAction } from '@/utils/disabled';

export default function accounts(appConfig: AppResponse) {
  return new Resource<AccountDetail>('Accounts')
    .withSchema(accountPayloadSchema)
    .getTitleUsing((a) => a.path)
    .getLabelUsing((a) => a.path)
    .getSubtitleUsing((a) => (
      <div>
        <span>
          #{a.number} - {startCase(a.type)}
        </span>
        {a.cached_balance != null && <span> - Balance: {curr(a.cached_balance)}</span>}
      </div>
    ))
    .withDefaultSort('number')
    .withDefaultValues({
      type: 'expense',
    })
    .withDefaultFilters({
      hierarchy: true,
    })
    .withFilters([
      FieldFactory.boolean('hierarchy', 'Show as Hierarchy').withFilterKey('hierarchy'),
    ])
    .getSingleActionsUsing((values) => [
      getSingleDisableAction('Account', 'accounts', values),
      new LinkAction('Reconcile', `/reconciliation?account_id=${values.id}`).withIcon(
        CompareArrows,
      ),
      new ButtonAction('Create Child Account', ({ dialogs, navigate }) => {
        dialogs
          .prompt({
            title: 'Create Child Account',
            fields: [FieldFactory.text('name').sortable(), FieldFactory.text('number')],
            schema: accountPayloadSchema,
            initialValues: {
              name: '',
              number: '',
              type: values.type,
              parent_id: values.id,
            },
            onSubmit: (v) => axios.post<AccountDetail>('/api/accounts', v).then(({ data }) => data),
          })
          .then((a) => {
            navigate(`/accounts/${a.id}`);
          });
      }),
      new ButtonAction('Move Account Under', ({ dialogs, onReloadRecord }) => {
        dialogs
          .prompt({
            title: 'Move Account Under',
            fields: [FieldFactory.belongsTo('account', 'accounts').withLabel('New Parent Account')],
            schema: z.object({
              account: genericModelReferenceSchema,
            }),
            onSubmit: (v) => axios.put(`/api/accounts/${values.id}`, { parent_id: v.account.id }),
          })
          .then(() => {
            onReloadRecord();
          });
      }),
    ])
    .withColumns([
      FieldFactory.text('name')
        .renderCellUsing((value, row: AccountDetail) => (
          <span style={{ paddingLeft: row.depth * 16 }}>{value}</span>
        ))
        .sortable(),
      FieldFactory.text('number').sortable(),
      FieldFactory.select('type', ACCOUNT_TYPES).sortable(),
      FieldFactory.select('cost_category', appConfig.costCategories)
        .withLabel('Job Cost Category')
        .sortable(),
      FieldFactory.curr('cached_balance').withLabel('Balance').sortable(),
      FieldFactory.boolean('is_disabled').sortable('disabled_at').filterable('is_disabled'),
    ])
    .addFieldsAndColumns([
      new TabLayout('tab', [
        new Tab('account_setup', [
          new CardLayout('Account Setup', [
            FieldFactory.text('name').sortable(),
            FieldFactory.text('number'),
            FieldFactory.select('type', ACCOUNT_TYPES),
            FieldFactory.select('cost_category', appConfig.costCategories)
              .with({ includeBlank: 'None' })
              .withLabel('Job Cost Category'),
          ]),
        ]),
        new Tab('bank_info', [
          new CardLayout('Bank Info', [
            FieldFactory.text('routing_number'),
            FieldFactory.text('account_number'),
            FieldFactory.custom('plaid_item_id', PlaidConnection).withColumnSpan(7),
          ]),
        ]).setCreatable(false),
        new Tab('ledger_entries', [FieldFactory.custom('ledger-entries', Ledger)]).setCreatable(
          false,
        ),
      ]),
    ]);
}
