import sumBy from 'lodash/sumBy';
import { useFormContext } from 'react-hook-form';
import { JournalEntryPayload } from '@/types';
import curr from '@/utils/curr';

export default function JournalEntryTotals() {
  const items = useFormContext<JournalEntryPayload>().watch('items');

  return (
    <div>
      <div>
        Debit Total: <b>{curr(sumBy(items, (v) => Number(v.debit_amount || 0)))}</b>
      </div>
      <div>
        Credit Total: <b>{curr(sumBy(items, (v) => Number(v.credit_amount || 0)))}</b>
      </div>
    </div>
  );
}
