import { Box, styled, Typography } from '@mui/material';
import Logo from '@/assets/logo.svg?react';

const LinkButton = styled('button')(({ theme }) => ({
  textDecoration: 'underline',
  background: 'none',
  border: 0,
  fontSize: 'inherit',
  padding: 0,
  cursor: 'pointer',
  color: theme.palette.primary.main,
}));

export default function ErrorPage() {
  return (
    <Box py={3} textAlign="center">
      <Logo style={{ height: 150, width: 'auto', margin: '24px auto' }} />
      <Typography variant="h4" gutterBottom>
        Something went wrong.
      </Typography>
      <Typography>
        <span>Please try to </span>
        <LinkButton type="button" onClick={() => window.location.reload()}>
          reload the page
        </LinkButton>
        <span>. If this keeps happening, please contact support.</span>
      </Typography>
    </Box>
  );
}
