import { Dialog } from '@mui/material';
import { AlertDialogConfig } from '@/contexts/DialogContext';
import AlertDialog from './AlertDialog';

export default function AlertDialogContainer({
  alert,
  setAlertDialog,
}: {
  alert: AlertDialogConfig | undefined;
  setAlertDialog: (state: AlertDialogConfig) => void;
}) {
  if (!alert) {
    return null;
  }

  const onClose = () => {
    setAlertDialog({ ...alert, open: false });
  };

  const onSuccess = () => {
    alert.promise.resolve();
    onClose();
  };

  const onCancel = () => {
    alert.promise.reject();
    onClose();
  };

  const { options } = alert;

  return (
    <Dialog
      open={alert.open}
      onClose={onCancel}
      fullWidth
      maxWidth={alert.options.maxWidth || 'xs'}
    >
      <AlertDialog {...options} onSuccess={onSuccess} />
    </Dialog>
  );
}
