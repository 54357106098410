import { ReactNode } from 'react';
import { CardContent, Box, Skeleton } from '@mui/material';
import StatCardNumber, { StatCardNumberProps } from './StatCardNumber';

export interface StatCardContentProps extends StatCardNumberProps {
  children?: ReactNode;
  loading?: boolean;
}

export default function StatCardContent({
  number,
  format,
  children,
  loading,
}: StatCardContentProps) {
  return (
    <>
      <CardContent
        sx={{
          pt: 0,
          fontSize: '2rem',
          fontWeight: 500,
        }}
      >
        {loading ? (
          <Skeleton variant="text" sx={{ maxWidth: '100px' }} />
        ) : (
          <StatCardNumber number={number} format={format} />
        )}
      </CardContent>
      {children && !loading && (
        <Box px={2} py={1} borderTop={1} borderColor="grey.300">
          {children}
        </Box>
      )}
    </>
  );
}
