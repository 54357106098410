import { useCallback } from 'react';
import { RequiresPermission } from '@/classes/types';
import { useConfig } from '@/contexts/AppConfigContext';

export function useHasRole() {
  const { roles } = useConfig();
  return useCallback((role: string) => roles.includes(role), [roles]);
}

export function useHasPermission() {
  const { permissions } = useConfig();
  return useCallback((permission: string) => permissions.includes(permission), [permissions]);
}

export function useRequiresRole() {
  const hasRole = useHasRole();

  return useCallback(
    (object: RequiresPermission) => {
      if (!object.requiresRole) {
        return true;
      }
      return hasRole(object.requiresRole);
    },
    [hasRole],
  );
}

export function useRequiresPermission() {
  const hasPermission = useHasPermission();

  return useCallback(
    (object: RequiresPermission) => {
      if (!object.requiresPermission) {
        return true;
      }
      return hasPermission(object.requiresPermission);
    },
    [hasPermission],
  );
}

export function useRequiresPermissionAndRole() {
  const requiresRole = useRequiresRole();
  const requiresPermission = useRequiresPermission();
  return useCallback(
    (object: RequiresPermission) => {
      return requiresRole(object) && requiresPermission(object);
    },
    [requiresRole, requiresPermission],
  );
}
