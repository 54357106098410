import { useMemo, useState } from 'react';
import { Edit } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import axios from 'axios';
import filter from 'lodash/filter';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AddressBlock from '@/components/Addresses/AddressBlock';
import AddressDrawer from '@/components/Addresses/AddressDrawer';
import PaginatedTable from '@/components/DataTable/PaginatedTable';
import EditFulfillmentOrderItem from '@/components/FulfillmentOrders/EditFulfillmentOrderItem';
import { FulfillmentOrderItemActions } from '@/components/FulfillmentOrders/FulfillmentOrderItemActions';
import PickItemAvailablility from '@/components/Inventory/PickItemAvailability';
import EventableCard from '@/components/Notes/EventableCard';
import SkuLabel from '@/components/Products/SkuLabel';
import StatusChip from '@/components/Shared/StatusChip';
import WithLabel from '@/components/Shared/WithLabel';
import ShipmentsCard from '@/components/Shipping/ShipmentsCard';
import TextLink from '@/components/Text/TextLink';
import { WEBSTORE_ORDER_STATUS_COLORS } from '@/constants';
import { AddressPayload, FulfillmentOrderItem } from '@/types';
import { useRecord, useUpdateRecord } from '@/utils/genericResource';

const columnHelper = createColumnHelper<FulfillmentOrderItem>();

export default function FulfillmentOrderPage() {
  const order = useRecord('fulfillmentOrders');
  const updateRecord = useUpdateRecord('fulfillmentOrders');
  const [editing, setEditing] = useState(false);
  const { status } = order;
  const canEdit = status === 'backordered' || status === 'pending' || status === 'waiting';

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('variant.sku', {
        header: 'SKU',
        cell: ({ row }) => <SkuLabel variant={row.original.variant} withoutDescription />,
      }),
      columnHelper.accessor('description', {
        header: 'Description',
      }),
      columnHelper.accessor('qty', {
        header: 'Qty',
        cell: ({ row }) => (
          <EditFulfillmentOrderItem
            canEdit={canEdit}
            item={row.original}
            rowKey="qty"
            type="number"
          />
        ),
      }),
      columnHelper.display({
        id: 'availability',
        header: 'Available',
        cell: ({ row }) => <PickItemAvailablility item={row.original} />,
      }),
      columnHelper.accessor('notes', {
        header: 'Notes',
        cell: ({ row }) => (
          <EditFulfillmentOrderItem
            canEdit={canEdit}
            item={row.original}
            rowKey="notes"
            type="text"
          />
        ),
      }),
      canEdit &&
        columnHelper.display({
          id: 'edit',
          header: '',
          cell: ({ row }) => <FulfillmentOrderItemActions item={row.original} order={order} />,
        }),
    ].filter((col) => !!col);
  }, [canEdit]);

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 9 }}>
        <Stack spacing={2}>
          <Card>
            <CardHeader
              title={order.number}
              subheader={order.webstore_name}
              action={<StatusChip status={order.status} colors={WEBSTORE_ORDER_STATUS_COLORS} />}
            />
            <CardContent>
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 3 }}>
                  <WithLabel label="Date">
                    <Typography>{moment(order.date).format('lll')}</Typography>
                    {order.earliest_ship_date && (
                      <Typography color="textSecondary">
                        Wait Until: {moment(order.earliest_ship_date).format('l')}
                      </Typography>
                    )}
                  </WithLabel>
                </Grid>

                <Grid size={{ xs: 12, md: 3 }}>
                  <WithLabel label="Avail Customer">
                    {order.customer ? (
                      <TextLink to={`/customers/${order.customer.id}`}>
                        {order.customer.name}
                      </TextLink>
                    ) : (
                      '(none)'
                    )}
                  </WithLabel>
                </Grid>

                <Grid size={{ xs: 12, md: 3 }}>
                  <WithLabel label="Contact Information">
                    <Typography>{order.name}</Typography>
                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                      {order.email}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                      {order.phone}
                    </Typography>
                  </WithLabel>
                </Grid>

                <Grid size={{ xs: 12, md: 3 }}>
                  <Box display="flex" alignItems="center">
                    <WithLabel label="Shipping Address">
                      <AddressBlock address={order.address} showMethod />
                    </WithLabel>
                    {order.status !== 'shipped' && order.status !== 'voided' && (
                      <Box ml="auto">
                        <IconButton onClick={() => setEditing(true)}>
                          <Edit />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <PaginatedTable
              storageKey="fulfillmentOrderItems"
              rows={order.items}
              columns={columns}
              initialState={{
                columnVisibility: {
                  availability: order.status === 'pending' || order.status === 'backordered',
                },
              }}
            />
          </Card>
          <ShipmentsCard shippableId={order.id} shippableType="fulfillment_order" showActions />
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <Stack spacing={2}>
          <Card>
            <CardHeader title="Picks" />

            {order.inventory_picks.length === 0 ? (
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  There are no picks for this order.
                </Typography>
              </CardContent>
            ) : (
              <List>
                {order.inventory_picks.map((pick) => (
                  <ListItemButton key={pick.id} component={Link} to={`/inventory-picks/${pick.id}`}>
                    <ListItemText primary={pick.number} secondary={startCase(pick.status)} />
                  </ListItemButton>
                ))}
              </List>
            )}
          </Card>
          <EventableCard resource="fulfillment-orders" resourceId={order.id} size="small" />
        </Stack>
      </Grid>
      <AddressDrawer
        open={editing}
        onClose={() => setEditing(false)}
        initialValues={order.address}
        onSubmit={(address: AddressPayload) =>
          axios.put(`/api/fulfillment-orders/${order.id}/address`, { address }).then(({ data }) => {
            updateRecord(data);
            setEditing(false);
          })
        }
        excludeFields={filter(['is_billing', 'is_taxable_address'])}
        showShippingOptions
      />
    </Grid>
  );
}
