import { useState } from 'react';
import { LinearProgress, styled } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { onUpload } from '@/AuthenticatedApp';
import { UploadedFile } from '@/utils/attachments';

const Dropzone = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ active, theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  borderWidth: 1,
  borderStyle: active ? 'solid' : 'dashed',
  borderColor: active ? theme.palette.secondary.main : theme.palette.action.disabled,
  borderRadius: 4,
  color: '#777',
  fontSize: 14,
}));

export default function AttachmentDropzone({
  onFiles,
}: {
  onFiles: (files: UploadedFile[]) => void;
}) {
  const [uploading, setUploading] = useState<number | false>(false);

  const handleProgress = (progress: number) => {
    setUploading(Math.round(progress * 100));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (files: File[]) => {
      setUploading(0);

      return onUpload(files, 'file', handleProgress)
        .then((res) =>
          onFiles(
            res.map((f, i) => ({
              ...f,
              name: files[i].name,
              size: files[i].size,
            })),
          ),
        )
        .finally(() => {
          setUploading(false);
        });
    },
  });

  return (
    <Dropzone active={isDragActive} {...getRootProps()}>
      <input {...getInputProps()} />

      {uploading !== false ? (
        <LinearProgress variant="determinate" value={uploading} />
      ) : (
        <div>Drag files here or click to upload.</div>
      )}
    </Dropzone>
  );
}
