import { z } from 'zod';
import { FieldFactory, Resource } from '@/classes';
import { SpecialDesignType } from '@/types';
import { AppResponse } from '@/types';

export default function specialDesignTypes(appConfig: AppResponse) {
  return new Resource<SpecialDesignType>('Special Design Types')
    .withSchema(
      z.object({
        name: z.string().min(1),
        cost: z.coerce.number().min(0),
      }),
    )
    .withStaticOptions(appConfig.specialDesignTypes)
    .setUseDrawer()
    .withFieldsAndColumns([FieldFactory.text('name').sortable(), FieldFactory.curr('cost')]);
}
