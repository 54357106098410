import { Add } from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import startCase from 'lodash/startCase';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useCreateIssue, useGetIssues } from '@/api/issues';
import StatusChip from '@/components/Shared/StatusChip';
import { ISSUE_STATUS_COLORS } from '@/constants';
import { useDialogs } from '@/contexts/DialogContext';
import { getIssueFields, getIssueSchema } from '@/resources/receivingIssues';
import { Issue } from '@/types';
import IssueDialog from './IssueDialog';

export default function IssuesCard({
  orderId,
  purchaseOrderId,
  type,
  canCreate,
  orderIds,
}: {
  orderId?: number;
  purchaseOrderId?: number;
  type?: Issue['type'];
  canCreate?: boolean;
  orderIds?: number[];
}) {
  const [selectedIssue, setSelectedIssue] = useQueryParam('selected_issue', NumberParam);
  const { prompt } = useDialogs();

  const params = { orderId, purchaseOrderId, type };
  const { data: issues = [], isLoading, isFetching } = useGetIssues(params);
  const createRequest = useCreateIssue(params);

  const handleCreate = () => {
    if (!type) return;

    prompt({
      title: `Create ${startCase(type)} Issue`,
      fields: getIssueFields(params, orderIds),
      schema: getIssueSchema(),
      initialValues: {
        order_id: orderId || orderIds?.[0],
      },
      onSubmit: (v) => createRequest.mutateAsync(v),
    });
  };

  const action = canCreate ? (
    <IconButton onClick={handleCreate} size="large">
      <Add />
    </IconButton>
  ) : null;

  return (
    <Card>
      <CardHeader title="Issues" action={action} />
      {isFetching && <LinearProgress />}

      {!isLoading && issues.length === 0 && (
        <Box color="text.secondary" px={2}>
          There are no {type} issues.
        </Box>
      )}

      <List>
        {issues.map((issue) => (
          <ListItemButton key={issue.id} onClick={() => setSelectedIssue(issue.id)}>
            <ListItemText primary={issue.label} secondary={issue.preview} />
            <ListItemSecondaryAction>
              <StatusChip status={issue.status} colors={ISSUE_STATUS_COLORS} />
            </ListItemSecondaryAction>
          </ListItemButton>
        ))}
      </List>

      <IssueDialog
        open={!!selectedIssue}
        onClose={() => setSelectedIssue(undefined)}
        issue={issues.find((i) => i.id === selectedIssue)}
      />
    </Card>
  );
}
