import axios from 'axios';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { ButtonAction, CardLayout, FieldFactory, Resource, Tab, TabLayout } from '@/classes';
import { FieldProps } from '@/classes/types';
import FormField from '@/components/Form/FormField';
import VendorAddresses from '@/components/Vendors/VendorAddresses';
import { VENDOR_PAYMENT_METHODS } from '@/constants';
import { useConfig } from '@/contexts/AppConfigContext';
import { AppResponse, Vendor, vendorPayloadSchema, vendorContactPayloadSchema } from '@/types';
import { getEventableConfigForResource } from '@/utils/resources';

function IntegrationVariables({ field }: FieldProps) {
  const { vendorIntegrations } = useConfig();
  const integration = useFormContext().watch('integration');
  const variables = vendorIntegrations[integration] || [];

  return (
    <div>
      {variables.map((variable) => (
        <FormField
          key={variable}
          field={FieldFactory.text(`${field.name}.${variable}`).withLabel(variable)}
        />
      ))}
    </div>
  );
}

export default function vendors(appConfig: AppResponse) {
  return new Resource<Vendor>('Vendors')
    .withSchema(vendorPayloadSchema)
    .setCanExport()
    .withDefaultSort('-id')
    .getSingleActionsUsing((values) => [
      new ButtonAction('Configure Integration', ({ dialogs, toast }) => {
        axios.get(`/api/vendors/${values.id}/integration`).then(({ data }) => {
          dialogs
            .prompt({
              title: 'Configure Integration',
              fields: [
                FieldFactory.select('integration', Object.keys(appConfig.vendorIntegrations)).with({
                  includeBlank: true,
                }),
                FieldFactory.custom('integration_variables', IntegrationVariables),
              ],
              schema: z.object({
                integration: z.string().nullish(),
                integration_variables: z.any(),
              }),
              initialValues: data,
              onSubmit: (v) => axios.put(`/api/vendors/${values.id}/integration`, v),
            })
            .then(() => {
              toast('Updated Integration Configuration');
            });
        });
      }).withPermission('vendors:integration'),
    ])
    .withColumns([FieldFactory.text('id').withLabel('#').sortable()])
    .addFieldsAndColumns([
      new TabLayout('tab', [
        new Tab('setup', [
          new CardLayout('', [
            FieldFactory.text('name').sortable(),
            FieldFactory.text('code').sortable(),
            FieldFactory.belongsTo('terms', 'terms').sortable().filterable('filter[terms_id]'),
            FieldFactory.text('url').withLabel('URL'),
            FieldFactory.phone('phone'),
            FieldFactory.email('email'),
            FieldFactory.text('ppai_number').withLabel('PPAI Number').sortable(),
            FieldFactory.text('asi_number').withLabel('ASI Number').sortable(),
            FieldFactory.text('account_number').sortable(),
          ]),
          new CardLayout('Purchasing and Receiving', [
            FieldFactory.email('auto_po_email')
              .withLabel('Auto-Send POs to Email')
              .withHelp(
                'For subcontractors, we will automatically send artwork and the PO to this email',
              )
              .sortable(),
            FieldFactory.number('days_to_receive').sortable(),
            FieldFactory.boolean('is_sports_inc', 'Is Sports Inc').filterable(),
          ]),
          new CardLayout('Contacts')
            .withoutDefaultLayout()
            .withoutPadding()
            .withFields([
              FieldFactory.table(
                'contacts',
                [
                  FieldFactory.text('first_name'),
                  FieldFactory.text('last_name'),
                  FieldFactory.text('title'),
                  FieldFactory.email('email'),
                  FieldFactory.phone('phone'),
                ],
                vendorContactPayloadSchema,
              ),
            ])
            .withoutDefaultLayout(),
          getEventableConfigForResource('vendors'),
          new CardLayout('Notes', [
            FieldFactory.textarea('notes_internal').withLabel('Internal Notes'),
            FieldFactory.textarea('notes_requirements').withLabel('Vendor Requirements'),
            FieldFactory.textarea('notes_to_purchasing').withLabel('Notes to Purchasing'),
          ]),
        ]),
        new Tab('addresses', [FieldFactory.custom('addresses', VendorAddresses)]).setCreatable(
          false,
        ),
        new Tab('accounting', [
          new CardLayout('', [
            FieldFactory.hasMany('default_accounts', 'accounts').withColumnSpan(12).filterable(),
            FieldFactory.select('default_payment_method', VENDOR_PAYMENT_METHODS),
            FieldFactory.text('bank_routing_number'),
            FieldFactory.text('bank_account_number'),
            FieldFactory.text('tin')
              .sortable()
              .filterable()
              .withHelp('Please only use this field for EINs. Enter "N/A" if their TIN is a SSN.')
              .withLabel('Tax ID Number (TIN)'),
            FieldFactory.select('form_1099_type', ['1099-MISC', '1099-NEC', '1099-INT', '1099-DIV'])
              .with({
                includeBlank: '1099 Not Required',
              })
              .sortable()
              .filterable(),
          ]),
        ]).withPermission('write:transactions'),
      ]),
    ]);
}
