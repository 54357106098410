import { Card, CardContent, CardHeader, Chip, Stack, SxProps } from '@mui/material';
import ColorBadge from '@/components/Designs/ColorBadge';
import { StockColor } from '@/types';

export default function StockColorCard({
  stockColor,
  sx = { minWidth: 300 },
}: {
  stockColor: StockColor;
  sx?: SxProps;
}) {
  return (
    <Card sx={sx}>
      <CardHeader
        title={stockColor.name}
        titleTypographyProps={{ variant: 'h6' }}
        subheader={`Code: ${stockColor.code}`}
        avatar={<ColorBadge color={stockColor.hex || '#ccc'} size={42} />}
      />
      <CardContent sx={{ pt: 0 }}>
        <Stack direction="row" spacing={1}>
          {stockColor.hex && <Chip size="small" label={stockColor.hex} />}
          {stockColor.priority === 1 && <Chip size="small" label="Priority Color" />}
          {stockColor.priority === 2 && <Chip size="small" label="Secondary Color" />}
        </Stack>
      </CardContent>
    </Card>
  );
}
