import { Alert, Button } from '@mui/material';
import axios from 'axios';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import { useDialogs } from '@/contexts/DialogContext';
import downloadFile from '@/utils/downloadFile';
import { useRecord } from '@/utils/genericResource';

export default function ScreenprintNumberingAlert() {
  const { id: orderId, hasRepeatDrops } = useRecord('ordersToProduce', true);
  const { prompt } = useDialogs();

  const onDownloadScreenprint = () => {
    prompt({
      title: 'Download Screenprint Numbering Reports',
      description:
        'This will download a series of reports that will break down the items in the order by number and allow each number to be printed as if it was a separate screenprint design. This report can be downloaded as an Excel spreadsheet or as a PDF, which you can choose below.',
      fields: [FieldFactory.select('file_format', ['xlsx', 'pdf'])],
      schema: z
        .object({
          file_format: z.enum(['xlsx', 'pdf']),
        })
        .required(),
      onSubmit: (v) => {
        return axios
          .get(`/api/orders/${orderId}/screenprint-export`, {
            params: { file_format: v.file_format },
            responseType: 'blob',
          })
          .then((response) => {
            downloadFile(response, orderId, v.file_format);
          });
      },
    });
  };

  if (!hasRepeatDrops) {
    return;
  }

  return (
    <Alert
      severity="info"
      sx={{ mb: 1 }}
      action={<Button onClick={onDownloadScreenprint}>Download Reports</Button>}
    >
      The order has at least 1 number that is bring printed at least 10 times. Consider screen
      printing as a more efficient way of producing these numbers.
    </Alert>
  );
}
