import { useMemo } from 'react';
import { Paper } from '@mui/material';
import DataTable from '@/components/DataTable/DataTable';
import useGetResource from '@/hooks/useGetResource';
import { useRecordId } from '@/utils/genericResource';

export default function Ledger() {
  const accountId = useRecordId();
  const getResource = useGetResource();

  const resource = useMemo(() => {
    const resource = getResource('ledgerEntries').cloneWith({
      queryParams: {
        index: {
          'filter[account_id]': String(accountId),
        },
        single: {},
      },
    });

    resource.columns.map((col) => {
      if (col.name === 'account') col.isFilterable = false;
    });

    return resource;
  }, [accountId, getResource]);

  return (
    <div>
      <Paper>
        <DataTable key="ledgerEntries" resource={resource} />
      </Paper>
    </div>
  );
}
