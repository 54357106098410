import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import axios from 'axios';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import { useDialogs } from '@/contexts/DialogContext';
import { useShowLoading } from '@/contexts/DialogContext';
import { FulfillmentOrder, FulfillmentOrderItem, genericModelReferenceSchema } from '@/types';
import { useOnReloadRecord } from '@/utils/genericResource';

export function FulfillmentOrderItemActions({
  item,
  order,
}: {
  item: FulfillmentOrderItem;
  order: FulfillmentOrder;
}) {
  const { confirm, prompt } = useDialogs();
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();

  const onDelete = () => {
    confirm({
      title: 'Remove Item',
      description: 'Are you sure you want to remove this item from the fulfillment order?',
    }).then(() => {
      showLoading(axios.delete(`/api/fulfillment-orders/${order.id}/items/${item.id}`)).then(() => {
        onReload();
      });
    });
  };

  const promptModal = () =>
    prompt({
      title: 'Update Fulfillment Order Item',
      fields: [
        FieldFactory.belongsTo('variant', 'inventoryVariants'),
        FieldFactory.number('qty'),
        FieldFactory.textarea('notes'),
      ],
      initialValues: item,
      schema: z.object({
        variant: genericModelReferenceSchema.required(),
        qty: z.coerce.number().int().positive(),
        notes: z.string().nullish(),
      }),
      onSubmit: (payload) =>
        showLoading(
          axios.put<FulfillmentOrderItem>(`/api/fulfillment-orders/${order.id}/items/${item.id}`, {
            variant_id: payload.variant.id,
            ...payload,
          }),
        ),
    }).then(() => onReload());

  return (
    <>
      <IconButton size="small" onClick={() => promptModal()}>
        <EditIcon />
      </IconButton>
      <IconButton size="small" onClick={() => onDelete()}>
        <DeleteIcon />
      </IconButton>
    </>
  );
}
