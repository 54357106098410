import { CSSProperties, useCallback, useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Close, Edit, OpenInNew } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, styled, Tooltip } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { UseControllerReturn } from 'react-hook-form';
import { onUpload } from '@/AuthenticatedApp';
import { FieldProps } from '@/classes/types';
import StaticFormControl from '@/components/Form/StaticFormControl';
import wrap from '@/utils/wrap';
import Field from '../Field';

const Dropzone = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDragActive',
})<{ isDragActive?: boolean }>(({ theme, isDragActive }) => ({
  padding: theme.spacing(2),
  borderWidth: 2,
  borderStyle: 'dashed',
  borderRadius: 4,
  color: '#777',
  fontSize: '.85rem',
  maxWidth: 500,
  borderColor: isDragActive ? theme.palette.secondary.main : 'rgba(0, 0, 0, 0.23)',
  cursor: 'pointer',
  textAlign: 'center',
  '&:focus': {
    outline: 'none',
  },
}));

const ImageCard = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: 4,
  borderWidth: 1,
  borderColor: 'rgba(0,0,0,.08)',
  borderStyle: 'solid',
  display: 'inline-block',
  minWidth: 60,
  maxWidth: '100%',
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  position: 'relative',
  overflow: 'hidden',
  textAlign: 'center',
}));

type ImageType = { url: string } | string;

function getUrl(i: ImageType): string {
  if (typeof i === 'string') {
    return i;
  }
  return i.url;
}

function SortableImage({
  url,
  onRemove,
  onEdit,
}: {
  url: string;
  onRemove: () => void;
  onEdit?: () => void;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: url,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ImageCard key={url} ref={setNodeRef} style={style}>
      <Box height={100} minWidth={60} {...attributes} {...listeners}>
        <img
          alt=""
          src={`${url}?w=100`}
          style={{
            height: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-around" p={1}>
        {onEdit && (
          <Tooltip title="Edit">
            <IconButton size="small" onClick={() => onEdit()}>
              <Edit fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Open In New Tab">
          <IconButton size="small" component="a" target="_blank" href={url}>
            <OpenInNew fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove">
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
    </ImageCard>
  );
}

function Images({ field, fieldModel, fieldState }: FieldProps<ImagesField>) {
  const [loading, setLoading] = useState<number | false>(false);
  const images = wrap(field.value) as ImageType[];
  const imageUrls = images.map(getUrl);

  const handleProgress = (progress: number) => {
    setLoading(Math.round(progress * 100));
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setLoading(0);
      onUpload(acceptedFiles, fieldModel.uploadType, handleProgress)
        .then((newImages) => {
          field.onChange([...images, ...newImages]);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [images],
  );

  const onSortEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over.id) {
      return;
    }
    const oldIndex = imageUrls.findIndex((i) => i === active.id);
    const newIndex = imageUrls.findIndex((i) => i === over.id);
    field.onChange(arrayMove(images, oldIndex, newIndex));
  };

  const onRemove = (urlToDelete: string) => {
    field.onChange(images.filter((i) => getUrl(i) !== urlToDelete));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: fieldModel.accept,
  });

  const getOnEdit = useCallback(
    (image: ImageType) => {
      if (!fieldModel.onEdit) {
        return undefined;
      }
      return () => fieldModel.onEdit?.(image);
    },
    [fieldModel.onEdit],
  );

  return (
    <StaticFormControl fieldModel={fieldModel} fieldState={fieldState}>
      <Box px={1}>
        <DndContext onDragEnd={onSortEnd}>
          <SortableContext items={imageUrls} strategy={horizontalListSortingStrategy}>
            {images.map((image) => {
              const url = getUrl(image);
              return (
                <SortableImage
                  key={url}
                  url={url}
                  onRemove={() => onRemove(url)}
                  onEdit={getOnEdit(image)}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      </Box>

      <Box p={1}>
        <Dropzone isDragActive={isDragActive} {...getRootProps()}>
          <input {...getInputProps()} />
          {loading !== false ? (
            <LinearProgress variant="determinate" value={loading} />
          ) : (
            <div>Click or Drag Files to Upload</div>
          )}
        </Dropzone>
      </Box>
    </StaticFormControl>
  );
}

export default class ImagesField extends Field {
  accept = 'image/*';
  onEdit?: (image: ImageType) => void;
  uploadType = 'file';

  renderEditComponent(props: UseControllerReturn) {
    return <Images {...props} fieldModel={this} />;
  }
}
