import { ReactNode } from 'react';
import { Typography, styled } from '@mui/material';

const FormSection = styled('div')(({ theme }) => ({
  border: '1px solid rgba(0,0,0,.23)',
  borderRadius: '4px',
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  padding: theme.spacing(2),
  marginTop: -6,
  marginBottom: theme.spacing(1),
}));

export default function DrawerFormSection({
  title,
  children,
}: {
  title?: ReactNode;
  children: ReactNode;
}) {
  return (
    <div>
      {title && <Typography variant="overline">{title}</Typography>}
      <FormSection>{children}</FormSection>
    </div>
  );
}
