import { useParams } from 'react-router-dom';
import ReconciliationForm from '@/components/Reconciliation/ReconciliationForm';
import ReconciliationShow from '@/components/Reconciliation/ReconciliationShow';

export default function Reconciliation() {
  const { id } = useParams<{ id: string }>();
  if (id) {
    return <ReconciliationShow id={Number(id)} />;
  }

  return <ReconciliationForm />;
}
