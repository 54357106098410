import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  GroupingState,
  InitialTableState,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import omit from 'lodash/omit';
import hash from 'object-hash';
import ReactTable from '@/components/DataTable/ReactTable';
import { returnFalse } from '@/constants';
import useOnChangeEffect from '@/hooks/useOnChangeEffect';

interface PaginatedTableProps<T extends Record<string, any>> {
  rows: T[];
  columns: ColumnDef<T, any>[];
  definedGrouping?: GroupingState;
  initialIsGrouped?: boolean;
  getRowCanExpand?: (row: Row<T>) => boolean;
  size?: 'small' | 'medium';
  filename?: string;
  slots?: Partial<Record<'leftActions' | 'rightActions', React.ReactNode>>;
  refetch?: () => void;
  isFetching?: boolean;
  initialState?: InitialTableState;
  storageKey: string;
  autoResetPageIndex?: boolean;
  enableToolbar?: boolean;
  enableGlobalFilter?: boolean;
  getBulkActions?: (rows: Row<T>[]) => ReactNode;
}

function PaginatedTableImpl<T extends Record<string, any>>({
  rows,
  columns: columnsProp,
  definedGrouping,
  initialIsGrouped = false,
  getRowCanExpand,
  size,
  filename,
  slots,
  refetch,
  isFetching,
  initialState,
  storageKey,
  autoResetPageIndex = true,
  enableToolbar = true,
  enableGlobalFilter = true,
  getBulkActions,
}: PaginatedTableProps<T>) {
  const columns = useMemo(
    () =>
      columnsProp.map((c) => ({
        aggregationFn: 'same',
        ...c,
      })) as ColumnDef<T, any>[],
    [columnsProp],
  );

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    groupedColumnMode: false,
    enableRowSelection: getBulkActions != null,
    enableGlobalFilter,
    columnResizeMode: 'onChange',
    aggregationFns: {
      same: (columnId, leafRows) => {
        if (leafRows.every((r) => r.getValue(columnId) === leafRows[0].getValue(columnId))) {
          return leafRows[0].getValue(columnId);
        }
        return null;
      },
    },
    autoResetExpanded: false,
    getRowCanExpand: getRowCanExpand || returnFalse,
    autoResetPageIndex,
    initialState: {
      ...initialState,
      grouping: initialIsGrouped ? definedGrouping : [],
      expanded: getRowCanExpand ? {} : true,
    },
  });

  const [state, setState] = useState(table.initialState);

  table.setOptions((prev) => ({
    ...prev,
    state,
    onStateChange: setState,
  }));

  useEffect(() => {
    const storedState = localStorage.getItem(storageKey);
    if (storedState) {
      setState((prev) => ({
        ...prev,
        ...omit(JSON.parse(storedState), ['rowSelection', 'pagination']),
      }));
    }
  }, [setState, storageKey]);

  useOnChangeEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      rowSelection: {},
    }));
  }, [setState, table.getFilteredRowModel().rows]);

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    filename, // export file name (without .csv)
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const onDownload = () => {
    const rowData = table.getRowModel().rows.map((row) => {
      const columns: Record<string, any> = {};
      row.getAllCells().forEach(({ column, getValue }) => {
        if (!('accessorKey' in column.columnDef)) {
          return;
        }
        const value = getValue();
        columns[typeof column.columnDef.header === 'string' ? column.columnDef.header : column.id] =
          value == null ? '' : getValue();
      });
      return columns;
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  return (
    <ReactTable
      table={table}
      size={size}
      refetch={refetch}
      definedGrouping={definedGrouping}
      isFetching={isFetching}
      onDownload={onDownload}
      slots={slots}
      enableToolbar={enableToolbar}
      getBulkActions={getBulkActions}
    />
  );
}

export default function PaginatedTable<T extends Record<string, any>>({
  storageKey: storageKeyProp,
  initialState = {
    pagination: {
      pageSize: 25,
    },
  },
  ...props
}: PaginatedTableProps<T>) {
  const storageKey = `paginatedTableState.${storageKeyProp}.${hash(initialState)}`;

  return (
    <PaginatedTableImpl
      key={storageKey}
      storageKey={storageKey}
      initialState={initialState}
      {...props}
    />
  );
}
