import { createContext, useContext } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { Table } from '@tanstack/react-table';

export const DataTableContext = createContext<{ table?: Table<unknown>; query?: UseQueryResult }>(
  {},
);

export function useDataTableContext() {
  return useContext(DataTableContext);
}
