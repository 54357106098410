import { Dialog } from '@mui/material';
import { ConfirmDialogConfig } from '@/contexts/DialogContext';
import ConfirmDialog from './ConfirmDialog';

export default function ConfirmDialogContainer({
  confirm,
  setConfirmDialog,
}: {
  confirm: ConfirmDialogConfig | undefined;
  setConfirmDialog: (state: ConfirmDialogConfig) => void;
}) {
  if (!confirm) {
    return null;
  }

  const onClose = () => {
    setConfirmDialog({ ...confirm, open: false });
  };

  const onSuccess = () => {
    confirm.promise.resolve();
    onClose();
  };

  const onCancel = () => {
    confirm.promise.reject();
    onClose();
  };

  const { options } = confirm;

  return (
    <Dialog
      open={confirm.open}
      onClose={onCancel}
      fullWidth
      maxWidth={confirm.options.maxWidth || 'xs'}
    >
      <ConfirmDialog {...options} onSuccess={onSuccess} onCancel={onCancel} />
    </Dialog>
  );
}
