import { useState } from 'react';
import Input from '@mui/material/OutlinedInput';
import { useUpdateFulfillmentOrderItem } from '@/api/fulfillment-order';
import TextButton from '@/components/Text/TextButton';
import { FulfillmentOrderItem } from '@/types';

export default function EditFulfillmentOrderItem({
  canEdit = true,
  item,
  rowKey,
  type = 'text',
}: {
  canEdit: boolean;
  item: FulfillmentOrderItem;
  rowKey: 'qty' | 'notes';
  type: 'number' | 'text';
}) {
  const update = useUpdateFulfillmentOrderItem(item.id);
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(item[rowKey]);

  const handleBlur = () => {
    if (value !== item[rowKey]) {
      update.mutate({
        [rowKey]: value,
      });
    }
    setIsEditing(false);
  };

  const handleEdit = () => {
    setValue(item[rowKey] || '');
    setIsEditing(true);
  };

  if (isEditing) {
    return (
      <Input
        autoFocus
        type={type}
        value={value}
        size="small"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget.blur();
          }
        }}
        onBlur={handleBlur}
        onChange={(e) => setValue(e.target.value)}
      />
    );
  }

  return (
    <TextButton disabled={!canEdit} onClick={handleEdit}>
      {item[rowKey] ?? '—'}
    </TextButton>
  );
}
