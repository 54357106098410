import { useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DoNotDisturb, DragHandle, PushPin, ViewColumn } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { Column, Table as ReactTable } from '@tanstack/react-table';

function ColumnRow({ column: c }: { column: Column<any> }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: c.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRow ref={setNodeRef} style={style}>
      <TableCell padding="checkbox">
        <DragHandle {...attributes} {...listeners} style={{ verticalAlign: 'middle' }} />
      </TableCell>
      <TableCell>{c.columnDef.header as string}</TableCell>
      <TableCell>
        <Switch
          disabled={!c.getCanHide()}
          checked={c.getIsVisible()}
          onClick={() => c.toggleVisibility()}
        />
      </TableCell>
      <TableCell>
        <ToggleButtonGroup
          size="small"
          value={c.getIsPinned()}
          exclusive
          onChange={(e, p) => c.pin(p)}
          aria-label="pinned"
        >
          <ToggleButton value={false} aria-label="none">
            <DoNotDisturb fontSize="small" />
          </ToggleButton>
          <ToggleButton value="left" aria-label="left">
            <PushPin sx={{ transform: 'rotate(-25deg)' }} fontSize="small" />
          </ToggleButton>
          <ToggleButton value="right" aria-label="right">
            <PushPin sx={{ transform: 'rotate(25deg)' }} fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </TableCell>
    </TableRow>
  );
}

export default function ColumnsDialog<T>({ table }: { table: ReactTable<T> }) {
  const [columnsOpen, setColumnsOpen] = useState(false);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldOrder = table.getAllLeafColumns().map((c) => c.id);
      const oldIndex = oldOrder.indexOf(active.id as string);
      const newIndex = oldOrder.indexOf(over.id as string);
      const newColumnOrder = arrayMove(
        table.getAllLeafColumns().map((c) => c.id),
        oldIndex,
        newIndex,
      );
      table.setColumnOrder(newColumnOrder);
    }
  };

  const columns = table.getAllLeafColumns();

  return (
    <>
      <Tooltip title="Show/Hide Columns" placement="top">
        <IconButton onClick={() => setColumnsOpen(true)} size="large">
          <ViewColumn />
        </IconButton>
      </Tooltip>

      <Dialog open={columnsOpen} onClose={() => setColumnsOpen(false)}>
        <DialogTitle id="dialog-title">Visible Columns</DialogTitle>

        <DialogContent
          style={{
            width: 600,
            maxWidth: '100%',
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell>Column</TableCell>
                <TableCell>
                  <Switch
                    onChange={(e) => table.toggleAllColumnsVisible(e.currentTarget.checked)}
                    checked={table.getIsAllColumnsVisible()}
                  />
                  Visible
                </TableCell>
                <TableCell>Pinned</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                <SortableContext
                  items={columns.map((c) => c.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {columns.map((c) => (
                    <ColumnRow key={c.id} column={c} />
                  ))}
                </SortableContext>
              </DndContext>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => table.reset()}>Reset</Button>
          <Button onClick={() => setColumnsOpen(false)} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
