import { useState } from 'react';
import { Delete } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import StatusChip from '@/components/Shared/StatusChip';
import { useDialogs } from '@/contexts/DialogContext';
import useMutateQueryData from '@/hooks/useMutateQueryData';
import { ShipmentBatch } from '@/types';
import numString from '@/utils/numString';
import BatchShipmentResults from './BatchShipmentResults';
import CreateBatchShipment from './CreateBatchShipment';
import ReviewBatchShipment from './ReviewBatchShipment';

export default function BatchShippingDrawer({
  open,
  onClose,
  orderId,
  customerId,
}: {
  open: boolean;
  onClose: () => void;
  orderId: number;
  customerId: number;
}) {
  const [isCreating, setIsCreating] = useState(false);
  const [batch, setBatch] = useState<ShipmentBatch>();
  const { confirm } = useDialogs();

  const setBatches = useMutateQueryData<ShipmentBatch[]>(['orderShipmentBatches', orderId]);
  const {
    data: batches = [],
    isLoading,
    refetch,
  } = useQuery(
    ['orderShipmentBatches', orderId],
    () =>
      axios
        .get<{ data: ShipmentBatch[] }>(`/api/orders/${orderId}/shipment-batches`)
        .then(({ data }) => data.data),
    {
      enabled: open,
    },
  );

  const checkAgainLater = (b: ShipmentBatch) => {
    if (b.shippo.status === 'VALIDATING' || b.shippo.status === 'PURCHASING') {
      // eslint-disable-next-line no-use-before-define
      setTimeout(() => refreshBatchShipment(b), 7000);
    }
  };

  const refreshBatchShipment = (b: ShipmentBatch) => {
    axios.get(`/api/orders/${orderId}/shipment-batches/${b.id}`).then(({ data }) => {
      setBatch(data);
      checkAgainLater(data);
    });
  };

  const onBatchCreated = (b: ShipmentBatch) => {
    setBatch(b);
    checkAgainLater(b);
  };

  const onPurchasing = (b: ShipmentBatch) => {
    setBatch(b);
    checkAgainLater(b);
  };

  const onDeleteBatch = (batchId: number) => {
    confirm({
      title: 'Delete Batch',
      description: 'Are you sure you want to delete this batch',
    }).then(() => {
      axios.delete(`/api/orders/${orderId}/shipment-batches/${batchId}`).then(() => {
        setBatches((prev) => prev.filter((p) => p.id !== batchId));
      });
    });
  };

  const onSelectBatch = (batchId: number) => {
    axios.get(`/api/orders/${orderId}/shipment-batches/${batchId}`).then(({ data }) => {
      onBatchCreated(data);
    });
  };

  const onDiscard = () => {
    setBatch(undefined);
    setIsCreating(false);
    refetch();
  };

  const getInner = () => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (!batch) {
      if (batches.length === 0 || isCreating) {
        return (
          <CreateBatchShipment
            orderId={orderId}
            customerId={customerId}
            onBatch={onBatchCreated}
            onDiscard={batches.length > 0 ? onDiscard : undefined}
          />
        );
      }

      return (
        <Box mx={-2}>
          <List>
            {batches.map((b) => (
              <ListItemButton key={b.id} onClick={() => onSelectBatch(b.id)}>
                <ListItemText
                  primary={`Batch #${b.id}`}
                  secondary={numString(b.shippo.batch_shipments.count, 'kits')}
                />
                <ListItemSecondaryAction>
                  <StatusChip status={b.shippo.status} />
                  {b.shippo.status === 'INVALID' && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onDeleteBatch(b.id);
                      }}
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItemButton>
            ))}
            <ListItemButton onClick={() => setIsCreating(true)}>
              <ListItemText>Create New Batch +</ListItemText>
            </ListItemButton>
          </List>
        </Box>
      );
    }

    if (batch.shippo.status === 'VALIDATING') {
      return (
        <div>
          <Typography variant="body2" gutterBottom>
            Creating Shipments
          </Typography>
          <LinearProgress color="primary" />
        </div>
      );
    }

    if (['VALID', 'INVALID'].includes(batch.shippo.status)) {
      return (
        <ReviewBatchShipment
          orderId={orderId}
          batch={batch}
          onDiscard={onDiscard}
          onPurchasing={onPurchasing}
        />
      );
    }

    if (batch.shippo.status === 'PURCHASING') {
      return (
        <div>
          <Typography variant="body2" gutterBottom>
            Purchasing Labels
          </Typography>
          <LinearProgress color="primary" />
        </div>
      );
    }

    if (batch.shippo.status === 'PURCHASED') {
      return <BatchShipmentResults orderId={orderId} batch={batch} onDiscard={onDiscard} />;
    }

    return null;
  };

  return (
    <ClosableDrawer title="Batch Ship" open={open} onClose={onClose}>
      {getInner()}
    </ClosableDrawer>
  );
}
