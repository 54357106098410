import { ReactNode } from 'react';
import { Card, CardHeader } from '@mui/material';
import StatCardContent, { StatCardContentProps } from './StatCardContent';

interface StatCardProps extends StatCardContentProps {
  title: ReactNode;
}

export default function StatCard({ title, ...other }: StatCardProps) {
  return (
    <Card>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }}
      />
      <StatCardContent {...other} />
    </Card>
  );
}
