import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItemProps,
  TablePagination,
  useMediaQuery,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';
import { EditorHandle } from '@/components/Notes/MentionTextArea';
import { useConfig } from '@/contexts/AppConfigContext';
import { useDialogs } from '@/contexts/DialogContext';
import useMutateQueryData from '@/hooks/useMutateQueryData';
import { Note, NotePayload } from '@/types';
import { getReplyText, MentionUser } from '@/utils/notes';
import NoteForm from './NoteForm';
import NoteListItem from './NoteListItem';

const ALLOWED_TAGS: Record<string, string[]> = {
  orders: [
    'production',
    'receiving',
    'shipping',
    'art',
    'purchasing',
    'invoicing',
    'packout',
    'scheduling',
  ],
  'purchase-orders': ['receiving', 'accounting'],
  customers: ['invoicing'],
  vendors: ['purchasing', 'accounting'],
  designs: ['art', 'production'],
  transactions: ['purchasing'],
};

export default function NoteManagerCard({
  resource,
  resourceId,
  title,
  listItemProps = {},
  elevation,
  size: rawSize,
}: {
  resource: string;
  resourceId: string | number;
  title?: string | null;
  listItemProps?: ListItemProps;
  elevation?: number;
  size?: 'small' | 'medium';
}) {
  const { user } = useConfig();
  const { confirm } = useDialogs();
  const [editingNote, setEditingNote] = useState<Note>();
  const [page, setPage] = useState(0);
  const endpoint = `/api/${resource}/${resourceId}`;
  const allowedTags = ALLOWED_TAGS[resource] || [];

  const size = useMediaQuery('(max-width: 960px)') ? 'small' : rawSize;
  const PER_PAGE = size === 'small' ? 7 : 12;
  const start = page * PER_PAGE;
  const editorRef = useRef<EditorHandle | null>(null);

  const setNotes = useMutateQueryData<Note[]>(['notes', resource, resourceId]);
  const { data: notes = [], isLoading } = useQuery(['notes', resource, resourceId], () =>
    axios.get<{ data: Note[] }>(`${endpoint}/notes`).then(({ data }) => data.data),
  );

  const { data: users = [] } = useQuery(['taggableList', resource, resourceId], () =>
    axios.get<{ data: MentionUser[] }>(`${endpoint}/taggables`).then(({ data }) => data.data),
  );

  const onNoteCreated = useCallback(
    (note: Note) => {
      setNotes((prev) => uniqBy([note, ...prev], 'id'));
    },
    [setNotes],
  );

  const onNoteUpdated = useCallback(
    (note: Note) => {
      setNotes((prev) =>
        prev.map((n) => {
          if (n.id === note.id) {
            return note;
          }
          return n;
        }),
      );
    },
    [setNotes],
  );

  const onNoteDeleted = useCallback(
    (note_id: number) => {
      setNotes((prev) => prev.filter((n) => n.id !== note_id));
    },
    [setNotes],
  );

  const reactionRequest = useMutation(
    ({ noteId, reaction }: { noteId: number; reaction: string }) => {
      return axios
        .post<Note>(`${endpoint}/notes/${noteId}/reactions`, { reaction })
        .then(({ data }) => onNoteUpdated(data));
    },
  );

  useEffect(() => {
    const channel = window.Echo.private(`notes.${resource}.${resourceId}`);

    channel
      .listen('NoteCreated', (e: { note: Note }) => {
        onNoteCreated(e.note);
      })
      .listen('NoteEdited', (e: { note: Note }) => {
        onNoteUpdated(e.note);
      })
      .listen('NoteDeleted', (e: { note_id: number }) => {
        onNoteDeleted(e.note_id);
      });

    return () => {
      channel.stopListening('NoteCreated');
      channel.stopListening('NoteDeleted');
      channel.stopListening('NoteEdited');
      window.Echo.leave(`notes.${resource}.${resourceId}`);
    };
  }, [setNotes, resource, resourceId]);

  const onSubmit = (values: NotePayload) => {
    if (values.note) {
      return axios.post<Note>(`${endpoint}/notes`, values).then(({ data }) => {
        onNoteCreated(data);
      });
    }
    return Promise.resolve();
  };

  const onEdit = (values: NotePayload) => {
    if (editingNote) {
      return axios.put<Note>(`${endpoint}/notes/${editingNote.id}`, values).then(({ data }) => {
        onNoteUpdated(data);
        setEditingNote(undefined);
      });
    }
    return Promise.resolve();
  };

  const onDelete = (note: Note) => {
    confirm({
      title: 'Delete Note',
      description: 'Are you sure you want to delete this note?',
      color: 'error',
    }).then(() => {
      axios.delete(`${endpoint}/notes/${note.id}`).then(() => {
        onNoteDeleted(note.id);
      });
    });
  };

  const createReplyHandler = (note: Note) => () => {
    editorRef.current?.insertText(getReplyText(note, user) + ' ');
  };

  const createReactionHandler = (note: Note) => (reaction: string) => {
    return reactionRequest.mutateAsync({ noteId: note.id, reaction });
  };

  return (
    <Card elevation={elevation} sx={{ mb: 2 }}>
      {title && (
        <CardHeader
          title={title}
          titleTypographyProps={{ variant: size === 'small' ? 'h6' : 'h5' }}
        />
      )}

      <CardContent>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <NoteForm
            ref={editorRef}
            formKey={`note-${resource}-${resourceId}`}
            placeholder={notes.length === 0 ? 'Add the first note' : 'Add a note'}
            onSubmit={onSubmit}
            allowedTags={allowedTags}
            users={users}
          />
        )}
      </CardContent>

      {notes.length > 0 && (
        <List>
          {notes.slice(start, start + PER_PAGE).map((note) => (
            <NoteListItem
              {...listItemProps}
              key={note.id}
              note={note}
              onDelete={() => onDelete(note)}
              onEdit={() => setEditingNote(note)}
              onReply={createReplyHandler(note)}
              onReaction={createReactionHandler(note)}
              size={size}
            />
          ))}
        </List>
      )}

      {notes.length > PER_PAGE && (
        <TablePagination
          component="div"
          count={notes.length}
          onPageChange={(e, p) => setPage(p)}
          rowsPerPageOptions={[PER_PAGE]}
          page={page}
          rowsPerPage={PER_PAGE}
        />
      )}

      <Dialog open={Boolean(editingNote)} onClose={() => setEditingNote(undefined)} maxWidth="md">
        <DialogTitle>Edit Note</DialogTitle>
        <DialogContent style={{ width: 500, minHeight: 100, paddingTop: 6 }}>
          {editingNote && (
            <NoteForm
              formKey={`editing-note-${editingNote.id}-${editingNote.updated_at}`}
              onSubmit={onEdit}
              initialValue={editingNote.note}
              initialAttachments={editingNote.attachments}
              allowedTags={allowedTags}
              users={users}
            />
          )}
        </DialogContent>
      </Dialog>
    </Card>
  );
}
