import { useState } from 'react';
import { RequestQuote, Warning } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Chip, CircularProgress, IconButton, Tooltip } from '@mui/material';
import pick from 'lodash/pick';
import round from 'lodash/round';
import startCase from 'lodash/startCase';
import { useUpdateOrderRequest, useUpdateTaxRequest } from '@/api/orderItems';
import { FieldFactory } from '@/classes';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { TotalLabel, TotalTable, TotalValue } from '@/components/Shared/Label';
import EstimateShippingCost from '@/components/Shipping/EstimateShippingCost';
import TextButton from '@/components/Text/TextButton';
import { useDialogs } from '@/contexts/DialogContext';
import { useHasPermission } from '@/hooks/permissions';
import { Order, orderUpdatePayloadSchema, Quote } from '@/types';
import curr from '@/utils/curr';
import { useRecord } from '@/utils/genericResource';
import { useIsOrderLocked } from '@/utils/orders';
import OrderPreCollectedTaxButton from './OrderPreCollectedTaxButton';
import OrderTaxCalculation from './OrderTaxCalculation';

export default function OrderTotalsBlock() {
  const order = useRecord<Quote | Order>();
  const [showingTax, setShowingTax] = useState(false);
  const [estimating, setEstimating] = useState(false);
  const hasPermission = useHasPermission();
  const { id, invoiced_at, precollected_tax } = useRecord<Order>();
  const isOrderLocked = useIsOrderLocked();
  const { prompt } = useDialogs();

  const { isLoading, mutate } = useUpdateTaxRequest();
  const updateOrderRequest = useUpdateOrderRequest();

  const taxNeedsRecalc = order.last_tax_hash !== order.current_tax_hash || !order.last_tax_hash;
  const subtotalForTax = order.subtotal + order.shipping + order.inbound_freight;
  const totalTaxable = subtotalForTax - order.total_exempt;

  const onEdit = (field: 'shipping' | 'inbound_freight') => {
    const initialValues = pick(order, field);
    prompt({
      title: `Update ${startCase(field)}`,
      description: `How much should be charged for ${startCase(field)}?`,
      fields: [FieldFactory.curr(field).withSize('medium')],
      schema: orderUpdatePayloadSchema
        .pick({
          shipping: true,
          inbound_freight: true,
        })
        .partial(),
      initialValues,
      onSubmit: (v) => updateOrderRequest.mutateAsync(v),
    });
  };

  return (
    <Box width={350} flexShrink={0} flexGrow={0}>
      <TotalTable>
        <tbody>
          <tr>
            <TotalLabel>Subtotal</TotalLabel>
            <TotalValue>{curr(order.subtotal)}</TotalValue>
          </tr>
          <tr>
            <TotalLabel>Shipping</TotalLabel>
            <TotalValue>
              <Tooltip title="Estimate Outbound Shipping">
                <IconButton size="small" sx={{ mr: 1 }} onClick={() => setEstimating(true)}>
                  <RequestQuote fontSize="small" />
                </IconButton>
              </Tooltip>
              <TextButton onClick={() => onEdit('shipping')} disabled={isOrderLocked}>
                {curr(order.shipping || 0)}
              </TextButton>
            </TotalValue>
          </tr>
          <tr>
            <TotalLabel>Inbound Freight</TotalLabel>
            <TotalValue>
              <TextButton onClick={() => onEdit('inbound_freight')} disabled={isOrderLocked}>
                {curr(order.inbound_freight || 0)}
              </TextButton>
            </TotalValue>
          </tr>
          {'precollected_tax' in order && Boolean(order.precollected_tax) && (
            <tr>
              <TotalLabel>Tax Collected on Store</TotalLabel>
              <TotalValue>{curr(order.precollected_tax)}</TotalValue>
            </tr>
          )}
          <tr>
            <TotalLabel>Tax</TotalLabel>
            <TotalValue>
              {isLoading && <CircularProgress size={8} />}
              {taxNeedsRecalc && (
                <Tooltip title="The calculated tax is not up-to-date.">
                  <IconButton size="small" sx={{ mr: 1 }} onClick={() => mutate()}>
                    <Warning color="warning" fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <TextButton
                disabled={!hasPermission('orders:tax_exempt')}
                onClick={() => setShowingTax(true)}
              >
                {curr(order.tax)}
              </TextButton>
            </TotalValue>
          </tr>
          <tr>
            <TotalLabel>Total</TotalLabel>
            <TotalValue>{curr(order.total)}</TotalValue>
          </tr>

          {'paid' in order && (
            <>
              <tr>
                <td colSpan={2} style={{ height: 12 }} />
              </tr>
              <tr>
                <TotalLabel>Paid</TotalLabel>
                <TotalValue>{curr(order.paid)}</TotalValue>
              </tr>
              <tr>
                <TotalLabel>Balance</TotalLabel>
                <TotalValue>{curr(order.balance)}</TotalValue>
              </tr>
            </>
          )}
        </tbody>
      </TotalTable>
      <ClosableDrawer
        open={showingTax}
        onClose={() => setShowingTax(false)}
        title="Sales Tax for Order"
      >
        {'precollected_tax' in order && (
          <>
            <DrawerFormSection title="Tax Exemptions">
              <OrderTaxCalculation />
            </DrawerFormSection>
            {Boolean(precollected_tax) && (
              <OrderPreCollectedTaxButton orderId={id} preCollectedTax={order.precollected_tax} />
            )}
          </>
        )}

        <DrawerFormSection title="Taxable Amount">
          <TotalTable style={{ background: 'none' }}>
            <tr>
              <TotalLabel style={{ textAlign: 'left' }}>Subtotal &amp; Shipping</TotalLabel>
              <TotalValue style={{ textAlign: 'left' }}>{curr(subtotalForTax)}</TotalValue>
            </tr>
            <tr>
              <TotalLabel style={{ textAlign: 'left' }}>Exempt</TotalLabel>
              <TotalValue style={{ textAlign: 'left' }}>
                {order.total_exempt ? curr(order.total_exempt * -1) : <span>&mdash;</span>}
              </TotalValue>
            </tr>
            <tr>
              <TotalLabel style={{ textAlign: 'left' }}>Total</TotalLabel>
              <TotalValue style={{ textAlign: 'left' }}>{curr(totalTaxable)}</TotalValue>
            </tr>
          </TotalTable>
        </DrawerFormSection>

        <DrawerFormSection title="Sales Tax Breakdown">
          <TotalTable style={{ background: 'none' }}>
            {'precollected_tax' in order && (
              <tr>
                <TotalLabel style={{ textAlign: 'left' }}>Pre-collected Tax</TotalLabel>
                <TotalValue style={{ textAlign: 'left' }}>
                  {order.precollected_tax ? curr(order.precollected_tax) : <span>&mdash;</span>}
                </TotalValue>
              </tr>
            )}
            <tr>
              <TotalLabel style={{ textAlign: 'left' }}>Calculated Tax</TotalLabel>
              <TotalValue style={{ textAlign: 'left' }}>
                {curr(order.tax)}
                {order.tax > 0 ? (
                  <Chip sx={{ ml: 1 }} label={`${round((order.tax / totalTaxable) * 100, 2)}%`} />
                ) : null}
              </TotalValue>
            </tr>
            <tr>
              <TotalLabel style={{ textAlign: 'left' }}>Total</TotalLabel>
              <TotalValue style={{ textAlign: 'left' }}>{curr(order.total_tax)}</TotalValue>
            </tr>
          </TotalTable>

          {!invoiced_at && (
            <LoadingButton onClick={() => mutate()} loading={isLoading}>
              Recalculate
            </LoadingButton>
          )}
        </DrawerFormSection>
      </ClosableDrawer>
      <ClosableDrawer
        open={estimating}
        onClose={() => setEstimating(false)}
        title="Estimate Shipping"
      >
        {estimating && <EstimateShippingCost />}
      </ClosableDrawer>
    </Box>
  );
}
