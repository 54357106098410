import { getItemsText, useUpdateOrderItemRequest } from '@/api/orderItems';
import { FieldFactory } from '@/classes';
import TextButton from '@/components/Text/TextButton';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderItem, orderItemPayloadSchema } from '@/types';
import curr from '@/utils/curr';
import { useIsOrderLocked } from '@/utils/orders';

export default function OrderItemPrice({
  items,
  children,
}: {
  items: OrderItem[];
  children?: (v: { allSame: boolean; price: number; onUpdate: () => void }) => React.ReactNode;
}) {
  const { prompt } = useDialogs();
  const price = items[0]!.price;
  const isOrderLocked = useIsOrderLocked();
  const allSame = items.every((item) => item.price === price);
  const updateOrderItemRequest = useUpdateOrderItemRequest();

  const onUpdate = () => {
    prompt({
      title: 'Update Price',
      description: `What should the unit price of ${getItemsText(items)} be?`,
      fields: [FieldFactory.curr('price').withSize('medium').with({ maxDecimals: 4 })],
      schema: orderItemPayloadSchema.pick({ price: true }),
      initialValues: { price },
      onSubmit: (v) =>
        Promise.all(items.map((item) => updateOrderItemRequest.mutateAsync({ id: item.id, ...v }))),
    });
  };

  if (children) {
    return children({ allSame, price, onUpdate });
  }

  return (
    <TextButton disabled={isOrderLocked} onClick={onUpdate}>
      {allSame ? curr(price, { maxDecimals: 4 }) : <span>&mdash;</span>}
    </TextButton>
  );
}
