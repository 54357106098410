import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import truncate from 'lodash/truncate';
import moment from 'moment';
import OrderLabel from '@/components/Orders/OrderLabel';
import TextLink from '@/components/Text/TextLink';
import { ReceivablesInvoice } from '@/types';
import curr from '@/utils/curr';
import numString from '@/utils/numString';

export default function InvoiceTable({ invoices }: { invoices: ReceivablesInvoice[] }) {
  return (
    <TableContainer>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell>Invoice</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell align="right">Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice) => (
            <TableRow key={invoice.id}>
              <TableCell>
                <Typography variant="body2">
                  {invoice.source_type === 'order' ? (
                    <OrderLabel orderId={invoice.source_id}>{invoice.label}</OrderLabel>
                  ) : (
                    <TextLink to={`/projects/${invoice.source_id}`}>{invoice.label}</TextLink>
                  )}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {truncate(invoice.description, { length: 24 })}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{moment(invoice.due_date).format('L')}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {invoice.terms.name}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2">{curr(invoice.balance)}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {numString(invoice.days_past_due, 'days')} past due
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
