import moment from 'moment/moment';
import { Attachment, AttachmentPayload } from '@/types';

export type UploadedFile = {
  file: string;
  url: string;
  name: string;
  size: number;
};

export function uploadedFileToPayload(file: UploadedFile, group?: string): AttachmentPayload {
  return {
    name: file.name,
    file: file.url,
    size: file.size,
    group,
  };
}

export function payloadToAttachment(payload: AttachmentPayload, i: number): Attachment {
  return {
    id: 0 - i,
    name: payload.name || null,
    file: payload.file,
    url: payload.file,
    download_url: payload.file,
    group: payload.group || null,
    size: payload.size || null,
    created_at: moment().toISOString(),
  };
}

export function attachmentToPayload(a: Attachment): AttachmentPayload {
  return {
    name: a.name || a.file.split('/').at(-1)!,
    file: a.file,
    group: a.group || undefined,
    size: a.size || undefined,
  };
}
