import { Close, Link } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import moment from 'moment';
import UserLabel from '@/components/Users/UserLabel';
import { ActivityEvent, OrderScrap, Shipment, PurchaseOrderStatus } from '@/types';
import { formatDate } from '@/utils/dates';
import NoteRenderer from './NoteRenderer';
import UserAvatar from './UserAvatar';

const SECONDARY_TEXT: Record<ActivityEvent['event_type'], string> = {
  issue_created: 'details',
  issue_resolved: 'resolution',
  proof_requested: 'note',
  release_requested: 'note',
  proof_rejected: 'rejected_reason',
  release_rejected: 'rejected_reason',
  approval_sent: 'note',
  approval_rejected: 'rejection_note',
  note_created: 'note',
  collections_note: 'note',
};

const getRelatedSection = (e: ActivityEvent) => {
  if (e.related_type === 'shipment') {
    const shipment = e.related as Shipment;
    if (shipment.tracking_number && shipment.tracking_url) {
      return (
        <div>
          Tracking Number:{' '}
          <a href={shipment.tracking_url} target="_blank" rel="noreferrer">
            {shipment.tracking_number}
          </a>
        </div>
      );
    }
  }
  if (e.related_type === 'order_scrap') {
    const scrap = e.related as OrderScrap;
    return (
      <div>
        {scrap.items.map((i) => (
          <div key={i.id}>
            <span>{i.qty} x </span>
            <span>
              {i.order_item.description} - {i.order_item.size}
            </span>
          </div>
        ))}
        {scrap.reason && <Box mt={1}>Reason: {scrap.reason}</Box>}
      </div>
    );
  }
  if (e.related_type === 'po_status') {
    const poStatus = e.related as PurchaseOrderStatus;
    return (
      <div>
        {poStatus.trackings.map((t) => (
          <div>
            Tracking:{' '}
            <a href={t.tracking_url || '#'} target="_blank" rel="noreferrer">
              {t.tracking_number}
            </a>
            {t.eta && <div>ETA: {formatDate(t.eta)}</div>}
          </div>
        ))}
      </div>
    );
  }

  if (e.related && SECONDARY_TEXT[e.event_type]) {
    const note = e.related[SECONDARY_TEXT[e.event_type]];
    if (note) {
      return <NoteRenderer note={`"${note}"`} />;
    }
  }

  return null;
};

interface ActivityItemProps extends ListItemProps {
  event: ActivityEvent;
  onDelete?: () => void;
  size?: 'small' | 'medium';
}

export default function ActivityEventListItem({
  event: e,
  onDelete,
  size,
  ...other
}: ActivityItemProps) {
  const userName = e.user_name || e.user?.name || 'System';

  return (
    <ListItem {...other}>
      {size !== 'small' && (
        <ListItemAvatar>
          <UserAvatar user={e.user} name={userName} />
        </ListItemAvatar>
      )}
      <ListItemText>
        <Box mb={0.5}>
          <Box display="inline-block" mr={2}>
            <span style={{ fontWeight: 500 }}>
              {e.user ? <UserLabel user={e.user} style={{ fontWeight: 500 }} /> : userName}
            </span>
            <span> </span>
            <span>{e.action}.</span>
          </Box>
          <Box display="inline" color="text.secondary">
            {moment(e.created_at).format('lll')}
          </Box>
        </Box>
        {e.related && (
          <Typography color="textSecondary" variant="body2">
            {getRelatedSection(e)}
          </Typography>
        )}
      </ListItemText>
      <ListItemSecondaryAction>
        {e.url && (
          <IconButton component="a" href={e.url} target="_blank" size={size}>
            <Link />
          </IconButton>
        )}
        {onDelete && (
          <IconButton onClick={onDelete} size={size}>
            <Close fontSize="small" />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
