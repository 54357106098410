import {
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import filter from 'lodash/filter';
import get from 'lodash/get';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import TextButton from '@/components/Text/TextButton';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderDesign, OrderItem } from '@/types';

const TableRowWithState = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }) => {
  if (disabled) {
    return {
      textDecoration: 'line-through',
      color: theme.palette.action.disabled,
      '& .MuiTableCell-root': {
        color: theme.palette.action.disabled,
      },
      '& .MuiTypography-root': {
        color: theme.palette.action.disabled,
      },
    };
  }
  return {};
});

export default function ItemDesignTable({
  orderDesign,
  onToggle,
  onSelectAll,
  onDeselectAll,
  onQtyChange,
  items,
}: {
  orderDesign: OrderDesign;
  items: OrderItem[];
  onToggle?: (i: number) => void;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  onQtyChange?: (i: OrderItem, qty: number | null) => void;
}) {
  const { prompt } = useDialogs();
  const showScrapped = !onQtyChange && items.some((i) => i.qty_scrapped != 0);
  const filteredItems = items
    .filter((i) => i.can_apply_designs)
    .map((item) => {
      const selected = !!get(item.order_design_ids, orderDesign.id);
      return {
        ...item,
        selected,
      };
    });

  const isAllSelected = filter(filteredItems, ['selected', true]).length === filteredItems.length;
  const isSomeSelected = filter(filteredItems, ['selected', true]).length > 0;

  const getQtyDisplayForItem = (item: OrderItem) => {
    const qty = get(item, 'qty');
    const overridden = get(item, `order_design_ids[${orderDesign.id}].qty`);
    if (overridden) {
      return (
        <span>
          <Typography
            color="textSecondary"
            variant="body2"
            component="span"
            style={{ textDecoration: 'line-through' }}
          >
            {qty}
          </Typography>
          <span style={{ marginLeft: 8 }}>{overridden}</span>
        </span>
      );
    }
    return qty;
  };

  const handleQtyChange = (item: OrderItem) => {
    if (onQtyChange) {
      prompt({
        title: 'Override Imprint Qty',
        fields: [FieldFactory.number('qty').withSize('medium')],
        initialValues: { qty: get(item, `order_design_ids[${orderDesign.id}].qty`) || item.qty },
        schema: z.object({
          qty: z.coerce.number().int().min(1).max(item.qty),
        }),
      }).then(({ qty }) => {
        onQtyChange(item, Number(qty) === Number(item.qty) ? null : qty);
      });
    }
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {onSelectAll && (
              <Tooltip title={`Toggle All for ${orderDesign.design.increment_id}`}>
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={!isAllSelected && isSomeSelected}
                  onChange={isAllSelected ? onDeselectAll : onSelectAll}
                  size="small"
                />
              </Tooltip>
            )}
          </TableCell>
          <TableCell>Item</TableCell>
          <TableCell>Qty</TableCell>
          {showScrapped && <TableCell>Scrapped</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredItems.map((item, index) => (
          <TableRowWithState key={item.id} disabled={!item.selected}>
            <TableCell padding="checkbox">
              <Checkbox
                checked={item.selected}
                onChange={onToggle ? () => onToggle(index) : undefined}
                disabled={!onToggle}
                size="small"
              />
            </TableCell>
            <TableCell>
              <OrderItemProduct item={item} showSize />
            </TableCell>
            <TableCell>
              <TextButton onClick={() => handleQtyChange(item)} disabled={!onQtyChange}>
                {getQtyDisplayForItem(item)}
              </TextButton>
            </TableCell>
            {showScrapped && <TableCell>{item.qty_scrapped}</TableCell>}
          </TableRowWithState>
        ))}
      </TableBody>
    </Table>
  );
}
