import { Resource, CardLayout, FieldFactory } from '@/classes';
import {
  ProductionMachine,
  productionMachinePayloadSchema,
  productionMachineTimePayloadSchema,
} from '@/types';
import { getBulkDisableAction, getSingleDisableAction } from '@/utils/disabled';

export default function productionMachines(): Resource<ProductionMachine> {
  return new Resource<ProductionMachine>('Production Machines')
    .withSchema(productionMachinePayloadSchema)
    .withBulkActions([getBulkDisableAction('Production Machines', 'production-machines')])
    .getSingleActionsUsing((values) => [
      getSingleDisableAction('Production Machine', 'production-machines', values),
    ])
    .withFieldsAndColumns([
      new CardLayout('', [
        FieldFactory.text('name').sortable(),
        FieldFactory.hasMany('event_types', 'productionEventTypes').filterable(),
        FieldFactory.number('max_heads').withLabel('# Heads (Max)'),
      ]),
      new CardLayout('Rates & Times')
        .withoutPadding()
        .withoutDefaultLayout()
        .withFields([
          FieldFactory.table(
            'times',
            [
              FieldFactory.number('rate').withHelp(
                'Either stitches per minute (emb) or units per hour (scr)',
              ),
              FieldFactory.number('minutes_setup')
                .withLabel('Setup Time')
                .withHelp('How long each event takes to setup in minutes'),
              FieldFactory.number('num_colors')
                .withLabel('# Colors')
                .withHelp('Only for screenprint'),
              FieldFactory.number('seconds_between_runs')
                .withLabel('Seconds Between Runs')
                .withHelp('How many seconds it takes to setup all embroidery heads for a new run'),
            ],
            productionMachineTimePayloadSchema,
          ),
        ]),
    ])
    .addColumns([
      FieldFactory.boolean('is_disabled').sortable('disabled_at').filterable('is_disabled'),
    ]);
}
