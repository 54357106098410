import { HTMLAttributes } from 'react';
import { FormProvider, SubmitHandler, FieldValues, UseFormReturn } from 'react-hook-form';

export default function Form<TFieldValues extends FieldValues>({
  form,
  onSubmit,
  children,
  ...props
}: Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'> & {
  form: UseFormReturn<TFieldValues>;
  onSubmit: SubmitHandler<TFieldValues>;
}) {
  const { handleSubmit } = form;
  return (
    <FormProvider {...form}>
      <form
        {...props}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          return handleSubmit(onSubmit)(e);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
}
