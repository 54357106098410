import {
  Box,
  Card,
  CardHeader,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import pick from 'lodash/pick';
import { FieldFactory } from '@/classes';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import { useDialogs } from '@/contexts/DialogContext';
import useMutateQueryData from '@/hooks/useMutateQueryData';
import { UserDetail, userPayloadSchema } from '@/types';

export default function BusinessUsers() {
  const { drawerPrompt } = useDialogs();

  const setUsers = useMutateQueryData<UserDetail[]>(['myBusinessUsers']);
  const { data: users = [] } = useQuery(['myBusinessUsers'], () =>
    axios
      .get<{
        data: UserDetail[];
      }>('/api/users', {
        params: {
          with: 'roles,commission_model,artist,account_managers',
          with_commission_due: 1,
          sort: 'name',
          is_disabled: 0,
        },
      })
      .then(({ data }) => data.data),
  );

  const isSales = (u: UserDetail) => u.roles?.some((role) => role.name === 'Sales');
  const sales = users.filter(isSales);
  const staff = users.filter((u) => !isSales(u));

  const onEditUser = (user: UserDetail) => {
    drawerPrompt({
      title: 'Edit User',
      initialValues: pick(user, [
        'name',
        'email',
        'artist',
        'account_managers',
        'commission_model',
        'commission_percent',
      ]),
      fields: [
        FieldFactory.text('name'),
        FieldFactory.email('email'),
        FieldFactory.belongsTo('artist', 'users').withLabel('Assigned Artist'),
        FieldFactory.hasMany('account_managers', 'users'),
        FieldFactory.belongsTo('commission_model', 'commissionModels').withLabel(
          'Default Commission Model',
        ),
        FieldFactory.percent('commission_percent').withLabel('Default Commission %'),
      ],
      schema: userPayloadSchema.pick({
        name: true,
        email: true,
        artist: true,
        account_managers: true,
        commission_model: true,
        commission_percent: true,
      }),
      onSubmit: (v) =>
        axios.put<UserDetail>(
          `/api/users/${user.id}?with=roles,commission_model,artist,account_managers`,
          v,
        ),
    }).then(({ data }) => {
      setUsers((prev) =>
        prev.map((p) => {
          if (p.id === data.id) {
            return { ...p, ...data };
          }
          return p;
        }),
      );
    });
  };

  return (
    <Card>
      <CardHeader title="Users" />

      {sales.length > 0 && (
        <List
          dense
          subheader={
            <Box px={2}>
              <Typography variant="subtitle2" color="textSecondary">
                Sales Reps
              </Typography>
            </Box>
          }
        >
          {sales.map((u) => (
            <ListItemButton key={u.id} onClick={() => onEditUser(u)}>
              <ListItemText secondary={u.email}>
                <Typography>{u.name}</Typography>
              </ListItemText>
              <ListItemSecondaryAction style={{ textAlign: 'right' }}>
                <Typography color="textSecondary" variant="caption">
                  Commission Due
                </Typography>
                <Typography>
                  <ColoredCurrency amount={u.commission_due || 0} />
                </Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      )}
      {staff.length > 0 && (
        <List
          dense
          subheader={
            <Box px={2}>
              <Typography variant="subtitle2" color="textSecondary">
                Internal Staff
              </Typography>
            </Box>
          }
        >
          {staff.map((u) => (
            <ListItemButton key={u.id} onClick={() => onEditUser(u)}>
              <ListItemText secondary={u.email}>
                <Typography>{u.name}</Typography>
              </ListItemText>
            </ListItemButton>
          ))}
        </List>
      )}
    </Card>
  );
}
