import { CustomAction, Resource } from '@/classes';
import PrintMenu from '@/components/Print/PrintMenu';
import ReceivingByOrder from '@/components/Receiving/ReceivingByOrder';
import { Order } from '@/types';
import { getOrderColumns } from './orders';

export default function ordersToReceive() {
  return new Resource<Order>('Orders to Receive')
    .getTitleUsing((o) => `Receiving: ${o.increment_id}`)
    .withApiEndpoint('/api/orders')
    .withRelations(['purchase_order_items'])
    .getSingleActionsUsing((v) => [
      new CustomAction('Print', () => <PrintMenu model="order" id={v.id} />),
    ])
    .setReadOnly()
    .setCanExport()
    .withDefaultSort('committed_ship_date')
    .withQueryParams({
      index: {
        to_receive: '1',
      },
    })
    .withInitialColumns([
      'increment_id',
      'customer',
      'order_type',
      'status',
      'drop_dead_date',
      'committed_ship_date',
      'actions',
    ])
    .withColumns(getOrderColumns())
    .editUsing(ReceivingByOrder);
}
