import {
  Typography,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  Box,
  Alert,
  styled,
} from '@mui/material';
import startCase from 'lodash/startCase';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { OrderAnalysisElement, OrderAnalysisNumber } from '@/types';

const DESCRIPTIONS: Record<string, string> = {
  product_purchased: 'Items on the order that already have a PO issued or pulled from inventory.',
  product_to_purchase: 'Items on the order that still need to be purchased.',
  decoration: 'Total production costs for this order given the Production Spec.',
  inbound_shipping: "This order's share of the inbound shipping for all related POs.",
  outbound_shipping: 'The cost of shipping this order via FedEx, USPS, or other method.',
  payment_fees:
    'If this order was paid via credit card, this is the fee charged by Stripe to process that card.',
};

const TooltipContent = styled('span')(({ theme }) => ({
  borderBottom: `1px dashed ${theme.palette.text.secondary}`,
  cursor: 'help',
}));

export default function AnalysisDetails({
  open,
  onClose,
  item,
  itemKey,
}: {
  open: boolean;
  onClose: () => void;
  item?: OrderAnalysisElement;
  itemKey?: string;
}) {
  const items =
    item && 'items' in item
      ? (Object.values(item.items).filter((i) => 'formatted' in i) as OrderAnalysisNumber[])
      : [];

  return (
    <ClosableDrawer open={open} onClose={onClose} title={startCase(itemKey)}>
      {itemKey && DESCRIPTIONS[itemKey] && (
        <Typography variant="body2" color="textSecondary" sx={{ mt: -2, mb: 3 }}>
          {DESCRIPTIONS[itemKey]}
        </Typography>
      )}
      {item && items.length > 0 ? (
        <Box mx={-1}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((i) => (
                <TableRow key={i.name}>
                  <TableCell>
                    <Typography variant="body2">{i.name}</Typography>
                    {i.description && (
                      <Typography variant="body2" color="textSecondary">
                        {i.description}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {i.unit_formatted ? (
                      <Tooltip title={`Per Unit: ${i.unit_formatted}`}>
                        <TooltipContent>{i.formatted}</TooltipContent>
                      </Tooltip>
                    ) : (
                      <span>{i.formatted}</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">Total</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{item.formatted}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Alert severity="info">There are no items for {startCase(itemKey)}.</Alert>
      )}
    </ClosableDrawer>
  );
}
