import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import keys from 'lodash/keys';
import map from 'lodash/map';

export default function Review({
  fileName,
  onProcess,
  onError,
  importing,
}: {
  fileName: string;
  onProcess: () => void;
  onError: () => void;
  importing: boolean;
}) {
  const { data } = useQuery(
    ['fileReview', fileName],
    () =>
      axios.get<{ data: string[][] }>(`/api/file-review/${fileName}`).then(({ data }) => data.data),
    {
      onError,
    },
  );

  if (!data) {
    return (
      <Card>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  if (data.length === 0) {
    return (
      <Card>
        <CardContent>
          <Alert severity="warning">There are no items in that file</Alert>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title="Review"
        subheader={`${data.length} Rows`}
        action={
          <LoadingButton type="button" variant="contained" onClick={onProcess} loading={importing}>
            Import
          </LoadingButton>
        }
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {keys(data[0]).map((key) => (
                <TableCell key={key}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, ri) => (
              <TableRow key={ri}>
                {map(row, (v, k) => (
                  <TableCell key={k}>{v}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
