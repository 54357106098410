import { useEffect, useState } from 'react';
import {
  CircularProgress,
  CardHeader,
  List,
  Box,
  FormControlLabel,
  Switch,
  Typography,
  CardContent,
  Toolbar,
  useMediaQuery,
  TablePagination,
  Card,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';
import useMutateQueryData from '@/hooks/useMutateQueryData';
import { ActivityEvent } from '@/types';
import ActivityEventListItem from './ActivityEventListItem';

export default function ActivityCard({
  resource,
  resourceId,
  title = 'Activity',
  size: rawSize,
  elevation,
  setAttachmentsCount,
}: {
  resource: string;
  resourceId: string | number;
  title?: string | null;
  size?: 'small' | 'medium';
  elevation?: number;
  setAttachmentsCount: (n: number) => void;
}) {
  const [page, setPage] = useState(0);
  const [isFollowing, setIsFollowing] = useState(false);
  const size = useMediaQuery('(max-width: 960px)') ? 'small' : rawSize;

  const setEvents = useMutateQueryData<ActivityEvent[]>(['activityEvents', resource, resourceId]);
  const { data: events = [], isLoading } = useQuery(['activityEvents', resource, resourceId], () =>
    axios
      .get<{
        data: ActivityEvent[];
        is_following: boolean;
        attachments_count?: number;
      }>(`/api/${resource}/${resourceId}/activity-events`)
      .then(({ data }) => {
        setIsFollowing(data.is_following);
        if (data.attachments_count != null && setAttachmentsCount) {
          setAttachmentsCount(data.attachments_count);
        }
        return data.data;
      }),
  );

  useEffect(() => {
    const channel = window.Echo.private(`events.${resource}.${resourceId}`);

    channel.listen('ActivityEventCreated', (e: { event: ActivityEvent }) => {
      setEvents((prev) => uniqBy([e.event, ...prev], 'id'));
    });

    return () => {
      channel.stopListening('ActivityEventCreated');
      window.Echo.leave(`events.${resource}.${resourceId}`);
    };
  }, [resource, resourceId]);

  const onToggle = () => {
    axios.post(`/api/${resource}/${resourceId}/follow`).then(({ data }) => {
      setIsFollowing(data.is_following);
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  const PER_PAGE = size === 'small' ? 7 : 15;
  const start = page * PER_PAGE;

  return (
    <Card elevation={elevation}>
      {title && (
        <CardHeader
          title={title}
          titleTypographyProps={{ variant: size === 'small' ? 'h6' : 'h5' }}
        />
      )}

      {events.length > 0 ? (
        <List dense={size === 'small'}>
          {events.slice(start, start + PER_PAGE).map((e) => (
            <ActivityEventListItem key={e.id} event={e} size={size} />
          ))}
        </List>
      ) : (
        <CardContent>
          <Typography color="textSecondary" variant={size === 'small' ? 'body2' : 'body1'}>
            There has been no activity yet.
          </Typography>
        </CardContent>
      )}

      <Toolbar>
        <Box flexGrow={1}>
          <FormControlLabel
            control={<Switch checked={isFollowing} onChange={onToggle} size={size} />}
            label={
              <Typography variant="body2">Subscribed{size !== 'small' && ' to Updates'}</Typography>
            }
          />
        </Box>

        <TablePagination
          component="div"
          count={events.length}
          onPageChange={(e, p) => setPage(p)}
          page={page}
          rowsPerPage={PER_PAGE}
          rowsPerPageOptions={[PER_PAGE]}
        />
      </Toolbar>
    </Card>
  );
}
