import { Key, MutableRefObject, useRef, useState } from 'react';
import { TablePagination } from '@mui/material';
import 'swiper/css';
import { Navigation, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperInstance } from 'swiper/types';

export default function ImageCarousel({
  children,
  renderChild,
}: {
  children: string[];
  renderChild: (child: string) => JSX.Element;
}) {
  const swiperRef = useRef<SwiperInstance | null>(null) as MutableRefObject<SwiperInstance | null>;
  const [page, setPage] = useState<number>(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    swiperRef.current?.slideTo(newPage);
  };

  return (
    <>
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(swiper) => setPage(swiper.activeIndex)}
      >
        {children.map((child: any, index: Key | null | undefined) => (
          <SwiperSlide key={index}>{renderChild(child)}</SwiperSlide>
        ))}
      </Swiper>
      {children.length > 1 ? (
        <TablePagination
          component="div"
          count={children.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={1}
          rowsPerPageOptions={[1]}
        />
      ) : null}
    </>
  );
}
