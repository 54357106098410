import { Help, Warning } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { Order } from '@/types';
import { formatDate } from '@/utils/dates';

export default function ProductionDates({
  order,
}: {
  order: Pick<Order, 'committed_ship_date' | 'committed_is_estimate' | 'drop_dead_date'>;
}) {
  const committed = formatDate(order.committed_ship_date);

  return (
    <Box display="inline-flex" alignItems="center" gap={0.75}>
      <span>{committed} </span>
      {order.committed_is_estimate && (
        <Tooltip title="This date is an estimate">
          <Help color="info" fontSize="small" sx={{ verticalAlign: 'bottom' }} />
        </Tooltip>
      )}
      {order.drop_dead_date && (
        <Tooltip title={`Drop Dead Date: ${formatDate(order.drop_dead_date)}`}>
          <Warning color="error" fontSize="small" sx={{ verticalAlign: 'bottom' }} />
        </Tooltip>
      )}
    </Box>
  );
}
