import { useMemo, useState } from 'react';
import { Add, Download, Sort, SwapVert } from '@mui/icons-material';
import { Box, Button, Card, CardHeader, Fab, IconButton, Tooltip } from '@mui/material';
import get from 'lodash/get';
import { StringParam, useQueryParam } from 'use-query-params';
import { useGetOrderItems, useSortOrderItemsRequest } from '@/api/orderItems';
import { AnchorAction, ButtonAction } from '@/classes';
import CreateReturnDrawer from '@/components/Orders/Items/CreateReturnDrawer';
import OrderItemsSortingTable from '@/components/Orders/Items/OrderItemsSortingTable';
import Can from '@/components/Permissions/Can';
import SubMenu from '@/components/Shared/SubMenu';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderItem, OrderWithDetails } from '@/types';
import { useRecord } from '@/utils/genericResource';
import getApiUrl from '@/utils/getApiUrl';
import { useIsOrderLocked } from '@/utils/orders';
import CreateBackorderDrawer from './CreateBackorderDrawer';
import OrderItemEditDrawer from './OrderItemEditDrawer';
import OrderItemWizardDrawer from './OrderItemWizardDrawer';
import OrderItemsTable from './OrderItemsTable';
import OrderTotalsBlock from './OrderTotalsBlock';

export default function OrderItemsField() {
  const { id: orderId, order_type: orderType } = useRecord<OrderWithDetails>();
  const isOrderLocked = useIsOrderLocked();
  const { confirm } = useDialogs();
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState<OrderItem>();
  const [showWizard, setShowWizard] = useState(false);
  const [sorting, setSorting] = useState(false);
  const [action, setAction] = useQueryParam('action', StringParam);

  const itemsQuery = useGetOrderItems();
  const items = itemsQuery.data || [];

  const sortRequest = useSortOrderItemsRequest();

  const DEFAULTS = {
    show_on_invoice: get(orderType, 'default_show_on_invoice', true),
    can_apply_designs: get(orderType, 'default_can_apply_designs', true),
    is_purchasable: get(orderType, 'default_is_purchasable', true),
    is_shippable: get(orderType, 'default_is_shippable', true),
    can_drop_ship: get(orderType, 'default_can_drop_ship', true),
    classification: 'product',
    is_customer_supplied: false,
    use_database_cost: true,
    is_custom: false,
  } as const;

  const onCreate = () => setCreating(true);

  const onNextItem = useMemo(() => {
    if (!editing) {
      return undefined;
    }
    const index = items.findIndex((i) => i.id === editing.id);
    if (index !== -1 && index < items.length - 1) {
      return () => setEditing(items[index + 1]);
    }
    return undefined;
  }, [editing, items, setEditing]);

  const onPrevItem = useMemo(() => {
    if (!editing) {
      return undefined;
    }
    const index = items.findIndex((i) => i.id === editing.id);
    if (index > 0) {
      return () => setEditing(items[index - 1]);
    }
    return undefined;
  }, [editing, items, setEditing]);

  const onSortBySize = () => {
    confirm({
      title: 'Sort Items By Size',
      description: 'Are you sure you want to do this? This will reorder all items in the order.',
    }).then(() => {
      sortRequest.mutate();
    });
  };

  const leftActions = (
    <Tooltip title="Sort Items">
      <IconButton onClick={() => setSorting(true)}>
        <SwapVert />
      </IconButton>
    </Tooltip>
  );

  const actions = isOrderLocked ? null : (
    <div>
      <Can permission="write:orders">
        <Button variant="contained" onClick={() => setShowWizard(true)} sx={{ mr: 1 }}>
          Add Product
        </Button>
      </Can>
      <SubMenu
        items={[
          new ButtonAction('Add Line Item', onCreate).withIcon(Add).withPermission('write:orders'),
          new ButtonAction('Sort Items by Size', onSortBySize)
            .withIcon(Sort)
            .withPermission('write:orders'),
          new AnchorAction(
            'Download Excel',
            getApiUrl(`/api/orders/${orderId}/items?format=xlsx`),
          ).withIcon(Download),
        ]}
      />
    </div>
  );

  return (
    <Card sx={{ position: 'relative' }}>
      <CardHeader title="Line Items" action={actions} />

      {sorting ? (
        <OrderItemsSortingTable initialItems={items} onClose={() => setSorting(false)} />
      ) : (
        <OrderItemsTable
          items={items}
          refetch={itemsQuery.refetch}
          isFetching={itemsQuery.isFetching}
          setEditing={setEditing}
          leftActions={leftActions}
        />
      )}

      <Fab
        size="small"
        sx={{ position: 'fixed', bottom: 8, right: 56 }}
        color="primary"
        onClick={() => setShowWizard(true)}
      >
        <Add />
      </Fab>

      <Box display="flex" justifyContent="flex-end" p={2}>
        <OrderTotalsBlock />
      </Box>

      <OrderItemEditDrawer
        open={creating}
        onClose={() => setCreating(false)}
        title="Add Line Item"
        item={{ ...DEFAULTS, qty: 12 }}
      />

      <OrderItemEditDrawer
        open={!!editing}
        onClose={() => setEditing(undefined)}
        title="Edit Line Item"
        item={editing}
        onNext={onNextItem}
        onPrev={onPrevItem}
      />

      <OrderItemWizardDrawer
        open={showWizard}
        onClose={() => setShowWizard(false)}
        initialValues={{
          ...DEFAULTS,
          items: [],
        }}
      />

      <CreateBackorderDrawer
        open={action === 'backorder'}
        onClose={() => setAction('')}
        items={items}
        orderId={orderId}
      />

      <CreateReturnDrawer
        open={action === 'return'}
        onClose={() => setAction('')}
        onSuccess={() => itemsQuery.refetch()}
        items={items}
        orderId={orderId}
      />
    </Card>
  );
}
