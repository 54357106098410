import { SyntheticEvent, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import startCase from 'lodash/startCase';
import moment from 'moment';
import PurchaseOrderTrackingItems from '@/components/Purchasing/PurchaseOrderTrackingItems';
import StatusChip from '@/components/Shared/StatusChip';
import { useConfig } from '@/contexts/AppConfigContext';
import { PurchaseOrderTracking } from '@/types';

const getETAText = (tracking: PurchaseOrderTracking) => {
  if (tracking.delivered_at) {
    return `Delivered: ${moment(tracking.delivered_at).format('l LT')}`;
  }
  if (tracking.eta) {
    return `ETA: ${moment(tracking.eta).add(7, 'hours').format('l')}`;
  }
  return null;
};

export default function PurchaseOrderTrackings({
  trackings,
}: {
  trackings: PurchaseOrderTracking[];
}) {
  const [popover, setPopover] = useState<{ anchorEl: Element; tracking: PurchaseOrderTracking }>();
  const { carriers } = useConfig();

  const getCarrierName = (t: PurchaseOrderTracking) => carriers[t.carrier] || startCase(t.carrier);

  const handleClick = (tracking: PurchaseOrderTracking) => (e: SyntheticEvent) => {
    setPopover({
      anchorEl: e.currentTarget,
      tracking,
    });
  };

  return (
    <>
      {trackings.length > 0 ? (
        <List disablePadding>
          {trackings.map((tracking) => (
            <ListItemButton key={tracking.id} onClick={handleClick(tracking)}>
              <ListItemText>
                <Typography variant="body2">
                  {getCarrierName(tracking)} #{tracking.tracking_number}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {getETAText(tracking)}
                </Typography>
              </ListItemText>

              <ListItemSecondaryAction>
                <StatusChip
                  status={tracking.status}
                  size="small"
                  colors={{
                    Delivered: 'green',
                    'In Transit': 'amber',
                    Pending: 'gray',
                  }}
                />
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      ) : (
        <CardContent>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            There are no tracking numbers.
          </Typography>
        </CardContent>
      )}

      <Popover
        id="tracking-details"
        open={popover != null}
        anchorEl={popover?.anchorEl}
        onClose={() => setPopover(undefined)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popover && (
          <Card>
            <CardContent>
              {popover.tracking.tracking_url && (
                <Typography sx={{ mb: 2 }}>
                  <a href={`${popover.tracking.tracking_url}`} target="_blank" rel="noreferrer">
                    {getCarrierName(popover.tracking)} #{popover.tracking.tracking_number}
                  </a>
                </Typography>
              )}

              {popover.tracking.in_transit_at && (
                <Typography variant="body2" gutterBottom>
                  In Transit: {moment(popover.tracking.in_transit_at).format('l LT')}
                </Typography>
              )}

              <Typography variant="body2" gutterBottom>
                {getETAText(popover.tracking)}
              </Typography>
            </CardContent>

            {popover.tracking.items.length > 0 && (
              <>
                <CardHeader
                  title="What's in the box?"
                  titleTypographyProps={{ variant: 'subtitle2' }}
                  sx={{ pb: 0 }}
                />
                <PurchaseOrderTrackingItems items={popover.tracking.items} />
              </>
            )}
          </Card>
        )}
      </Popover>
    </>
  );
}
