import { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

export default function OverrideReferenceField({
  reference,
  onChange,
  fieldLabel,
}: {
  reference: string | null;
  onChange: (a: string | null) => void;
  fieldLabel: string;
}) {
  const [hasOverride, setHasOverride] = useState(!!reference);

  useEffect(() => {
    if (reference) {
      setHasOverride(true);
    } else {
      setHasOverride(false);
    }
  }, [reference]);

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setHasOverride(true);
    } else {
      setHasOverride(false);
      onChange(null);
    }
  };

  return (
    <div>
      <FormControlLabel
        label={`Override ${fieldLabel}`}
        control={<Checkbox checked={hasOverride} size="small" onChange={handleCheckbox} />}
      />

      {hasOverride && (
        <TextField
          fullWidth
          size="small"
          sx={{ mb: 1, mt: 0.5 }}
          label={fieldLabel}
          value={reference || ''}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </div>
  );
}
