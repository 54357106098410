import { useQueryClient } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OnClickProps } from '@/classes/types';
import { useDataTableContext } from '@/components/DataTable/DataTableContext';
import { useAppContext } from '@/contexts/AppContext';
import { useShowLoading, useDialogs, useToast } from '@/contexts/DialogContext';
import useGetResource from '@/hooks/useGetResource';
import {
  useMaybeCurrentResource,
  useOnReloadRecord,
  useRecordId,
  useUpdateRecord,
} from '@/utils/genericResource';

export default function useOnClickProps(): OnClickProps {
  const { appBar } = useAppContext();
  const showLoading = useShowLoading();
  const dialogs = useDialogs();
  const navigate = useNavigate();
  const toast = useToast();
  const form = useFormContext();
  const queryClient = useQueryClient();

  const { table, query } = useDataTableContext();

  const resourceKey = useMaybeCurrentResource();
  const getResource = useGetResource();
  const resource = resourceKey ? getResource(resourceKey) : undefined;
  const recordId = useRecordId();
  const updateRecord = useUpdateRecord();
  const onReloadRecord = useOnReloadRecord();

  return {
    change: form ? form.setValue : () => {},
    dialogs,
    navigate,
    back: appBar.back,
    toast,
    showLoading,

    selected: table ? table.getSelectedRowModel().rows.map((r) => r.id) : [],
    onReloadTable: query?.refetch || (() => {}),

    resource,
    form: resource?.getFormName(),
    onReloadRecord,

    queryClient,
    recordId,
    updateRecord,
  };
}
