import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export default function ResetButton() {
  const form = useFormContext();
  const { isDirty, isSubmitting } = form.formState;
  if (isDirty) {
    return (
      <Button disabled={isSubmitting} type="button" onClick={() => form.reset()}>
        Reset
      </Button>
    );
  }

  return null;
}
