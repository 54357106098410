import {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ClassNames } from '@emotion/react';
import {
  Alert,
  Card,
  FormControl,
  InputLabel,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import trimStart from 'lodash/trimStart';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { thumbnail } from '@/components/Shared/ProductImage';
import useDebounce from '@/hooks/useDebounce';
import { containsTrackingNumber, MentionUser } from '@/utils/notes';

export interface EditorHandle {
  insertText: (t: string) => void;
}

interface MentionTextAreaProps {
  onChange: (text: string) => void;
  value: string;
  placeholder?: string;
  allowedTags: string[];
  users: MentionUser[];
}

interface MentionsInputType {
  setState: (state: { selectionStart: number; selectionEnd: number }) => void;
  updateMentionsQueries: (newValue: string, position: number) => void;
  updateHighlighterScroll: () => void;
}

function MentionTextArea(
  { value, onChange, placeholder, allowedTags = [], users }: MentionTextAreaProps,
  ref: ForwardedRef<EditorHandle>,
) {
  const mentionsRef = useRef<MentionsInputType | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const [trackingUrlDetected, setTrackingUrlDetected] = useState<boolean>(false);
  const shouldDetectTrackingNumbers = allowedTags.includes('receiving'); // Orders or POs
  const debouncedValue = useDebounce(value, 1000);
  useEffect(() => {
    if (shouldDetectTrackingNumbers) {
      setTrackingUrlDetected(containsTrackingNumber(debouncedValue));
    } else {
      setTrackingUrlDetected(false);
    }
  }, [shouldDetectTrackingNumbers, debouncedValue]);

  const insertText = (text: string) => {
    setTimeout(() => {
      inputRef.current!.focus();
      const newValue = trimStart(value + ' ' + text);
      onChange(newValue);
      setTimeout(() => {
        mentionsRef.current?.setState({
          selectionStart: newValue.length,
          selectionEnd: newValue.length,
        });
        mentionsRef.current?.updateMentionsQueries(newValue, newValue.length);
        mentionsRef.current?.updateHighlighterScroll();
      });
    }, 50);
  };

  useImperativeHandle(ref, () => ({
    insertText,
  }));

  const getTextArea = useCallback(
    forwardRef(
      (
        { className = '', ...props }: HTMLAttributes<HTMLTextAreaElement>,
        ref: ForwardedRef<HTMLTextAreaElement>,
      ) => {
        const inputRef = useRef<HTMLTextAreaElement | null>(null);

        useImperativeHandle(ref, () => inputRef.current!);

        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.className = className;
          }
        }, [className]);

        return (
          <ClassNames>
            {({ css }) => (
              <MentionsInput
                // @ts-ignore
                ref={mentionsRef}
                inputRef={inputRef}
                {...props}
                className="mention"
                style={{
                  width: '100%',
                  control: {
                    minHeight: '5rem',
                  },
                }}
                suggestionsPortalHost={document.body}
                customSuggestionsContainer={(c) => (
                  <Card sx={{ maxHeight: '20rem', overflowY: 'auto' }}>{c}</Card>
                )}
              >
                <Mention
                  trigger="@"
                  markup="@[__display__]{__id__}"
                  displayTransform={(id, display) => `@${display}`}
                  data={users}
                  className={css({ background: 'rgba(0,0,0,.07)', borderRadius: 3 })}
                  renderSuggestion={(i, _search, highlightedDisplay, _index, focused) => {
                    const item = i as SuggestionDataItem & MentionUser;
                    return (
                      <ListItemButton key={item.id} selected={focused} dense>
                        <ListItemAvatar sx={{ minWidth: 42 }}>
                          <Avatar
                            sx={{ height: 32, width: 32 }}
                            src={item.avatar ? thumbnail(item.avatar) : undefined}
                          />
                        </ListItemAvatar>
                        <ListItemText>{highlightedDisplay}</ListItemText>
                      </ListItemButton>
                    );
                  }}
                />
                <Mention
                  trigger="#"
                  markup="#[__display__]"
                  displayTransform={(id) => `#${id}`}
                  className={css({ background: 'rgba(0,0,0,.07)', borderRadius: 3 })}
                  data={allowedTags.map((t) => ({
                    id: t,
                    display: t,
                  }))}
                  renderSuggestion={(i, _search, highlightedDisplay, _index, focused) => {
                    return (
                      <ListItemButton key={i.id} selected={focused}>
                        <ListItemText>{highlightedDisplay}</ListItemText>
                      </ListItemButton>
                    );
                  }}
                />
              </MentionsInput>
            )}
          </ClassNames>
        );
      },
    ),
    [users],
  );

  return (
    <>
      <FormControl fullWidth ref={divRef}>
        <InputLabel>Note</InputLabel>
        <OutlinedInput
          label="Note"
          multiline
          fullWidth
          rows={5}
          inputRef={inputRef}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          placeholder={placeholder}
          inputComponent={getTextArea}
        />
      </FormControl>
      {trackingUrlDetected && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          It looks like you're trying to add tracking information to this note. Please add this
          information to the PO status section instead.
        </Alert>
      )}
    </>
  );
}

export default forwardRef(MentionTextArea);
