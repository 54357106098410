import { Business } from '@/types';

export default function getBusinessName(business?: Business, defaultName?: string): string {
  return business?.internal_name || business?.name || defaultName || '';
}

export function getBusinessNameById(
  businesses: Business[],
  businessId: number | null | undefined,
  defaultName = '',
): string {
  if (!businessId) {
    return defaultName;
  }
  return getBusinessName(
    businesses.find((b) => b.id === businessId),
    defaultName,
  );
}
