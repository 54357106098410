import { Order, Quote } from '@/types';
import { useRecord } from '@/utils/genericResource';

export function useIsOrderLocked(): boolean {
  const order = useRecord<Quote | Order>();
  if ('invoiced_at' in order) {
    return Boolean(order.invoiced_at || order.cancelled_at);
  }
  return false;
}
