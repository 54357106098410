import pickBy from 'lodash/pickBy';

export default function getDirtyProperties(
  values: Record<string, any>,
  initial: Record<string, any>,
  whitelist: string[] = [],
) {
  return pickBy(values, (v, k) => {
    if (Array.isArray(whitelist) && whitelist.includes(k)) {
      return true;
    }
    if (initial[k] === undefined) {
      return true;
    }
    return JSON.stringify(v) !== JSON.stringify(initial[k]);
  });
}
