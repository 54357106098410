import { Button, Typography, Alert, Box } from '@mui/material';
import { ShipmentBatch } from '@/types';
import getApiUrl from '@/utils/getApiUrl';

export default function BatchShipmentResults({
  batch,
  onDiscard,
  orderId,
}: {
  batch: ShipmentBatch;
  onDiscard: () => void;
  orderId: number;
}) {
  const results = batch.shippo.object_results;
  return (
    <div>
      <Box display="flex" mb={1}>
        <Typography variant="subtitle1">Results</Typography>
        <Button size="small" onClick={onDiscard} sx={{ ml: 'auto' }}>
          Back to List
        </Button>
      </Box>

      {results.purchase_succeeded > 0 && (
        <Alert severity="success" sx={{ mb: 1 }}>
          {results.purchase_succeeded} labels purchased.
        </Alert>
      )}

      {results.purchase_succeeded === 0 && results.creation_succeeded > 0 && (
        <Alert severity="success" sx={{ mb: 1 }}>
          {results.creation_succeeded} labels created.
        </Alert>
      )}

      {results.purchase_failed > 0 && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          {results.purchase_failed} labels failed to be purchased.
        </Alert>
      )}

      {results.purchase_failed === 0 && results.creation_failed > 0 && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          {results.creation_failed} labels failed to be created.
        </Alert>
      )}

      {batch.shippo.status === 'PURCHASED' && (
        <Button
          sx={{ mt: 2 }}
          component="a"
          href={getApiUrl(`/api/orders/${orderId}/shipment-batches/${batch.id}/print`)}
          target="_blank"
          variant="contained"
        >
          Print All Labels
        </Button>
      )}
    </div>
  );
}
