import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useHasPermission } from '@/hooks/permissions';
import { Business, OrderPayout } from '@/types';
import { makeResourceQueryKey } from '@/utils/genericResource';

interface BusinessGroup {
  business: Business;
  earned: number;
  realized: number;
  pending: number;
  adjustments: number;
  avail_percent: number;
  data: OrderPayout[];
}

export function useGetOrderPayouts(orderId: number) {
  const hasPermission = useHasPermission();

  return useQuery(
    makeResourceQueryKey('orders', orderId, 'payouts'),
    () =>
      axios
        .get<{ data: BusinessGroup[] }>(`/api/orders/${orderId}/payouts`)
        .then(({ data }) => data.data),
    {
      enabled: hasPermission('read:order_payouts') || hasPermission('write:orders'),
    },
  );
}

export function useCreateOrderPayout(orderId: number) {
  const queryClient = useQueryClient();
  return useMutation((payload: { amount: number; description: string }) =>
    axios.post<OrderPayout>(`/api/orders/${orderId}/payouts`, payload).then(() => {
      queryClient.invalidateQueries(makeResourceQueryKey('orders', orderId, 'payouts'));
    }),
  );
}

export function useDeleteOrderPayout(orderId: number) {
  const queryClient = useQueryClient();

  return useMutation((payoutId: number) =>
    axios.delete(`/api/orders/${orderId}/payouts/${payoutId}`).then(() => {
      queryClient.invalidateQueries(makeResourceQueryKey('orders', orderId, 'payouts'));
    }),
  );
}
