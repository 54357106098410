import { ImportExport, MenuOpen, PlaylistAdd, RemoveCircleOutline } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import axios from 'axios';
import { z } from 'zod';
import {
  useBulkCreateKitItems,
  useCreateKitItem,
  useKitProductionOrders,
  useSwitchItem,
} from '@/api/kitting';
import { ButtonAction, FieldFactory } from '@/classes';
import ChooseKitItemsField from '@/components/Kitting/ChooseKitItemsField';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import SubMenu from '@/components/Shared/SubMenu';
import { useDialogs } from '@/contexts/DialogContext';
import { Kit, OrderItem } from '@/types';

export default function ProductionOrderItem({
  item,
  orderId,
  selectedKit,
}: {
  item: OrderItem;
  orderId: number;
  selectedKit?: Kit;
}) {
  const { prompt, confirm } = useDialogs();

  const items = useKitProductionOrders(orderId).data?.flatMap((i) => i.shippable_items) || [];
  const createItemRequest = useCreateKitItem(item);
  const bulkCreateItemRequest = useBulkCreateKitItems(orderId, item);
  const switchRequest = useSwitchItem(orderId, item);

  const onAssign = async () => {
    if (!selectedKit) {
      return;
    }

    let dropsOptions: string[][] = [];
    if (item.drops_count) {
      dropsOptions = await axios
        .get<{ data: string[][] }>(`/api/orders/${item.order_id}/items/${item.id}/drops`)
        .then(({ data }) => data.data);
    }
    prompt({
      title: 'Assign Qty to Selected Kit',
      fields: [
        FieldFactory.number('qty').withSize('medium'),
        item.drops_count
          ? FieldFactory.select(
              'dropIndex',
              dropsOptions.map((arr, idx) => ({
                value: idx,
                label: arr.join(' / '),
              })),
            ).with({
              label: 'Name/Number',
              includeBlank: true,
            })
          : null,
      ],
      schema: z.object({
        qty: z.coerce.number().int().positive(),
        dropIndex: z.coerce.number().int().nullish(),
      }),
      initialValues: {
        qty: 1,
        dropIndex: 0,
      },
      onSubmit: (v) =>
        createItemRequest.mutateAsync({
          kit_id: selectedKit!.id,
          qty: v.qty,
          drops: v.dropIndex != null ? dropsOptions[v.dropIndex]! : [],
        }),
    });
  };

  const onBulkAssign = () => {
    prompt({
      title: 'Assign Qty to Each Kit',
      fields: [FieldFactory.number('qty').withSize('medium')],
      initialValues: {
        qty: 1,
      },
      schema: z.object({
        qty: z.coerce.number().int().positive(),
      }),
      onSubmit: (v) => bulkCreateItemRequest.mutateAsync(v),
    });
  };

  const onRemoveFromAll = () => {
    confirm({ title: 'Remove Line Item from All Kits', description: 'Are you sure?' }).then(() => {
      bulkCreateItemRequest.mutate({ qty: 0 });
    });
  };

  const onSwitch = () => {
    prompt({
      title: 'Switch Assigned Qty to Different Line Item',
      description: (
        <div>
          <Typography variant="subtitle2">Switching From:</Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>{item.order_id}</TableCell>
                <TableCell>
                  <OrderItemProduct item={item} showSize />
                </TableCell>
                <TableCell>
                  {item.qty_assigned} / {item.qty}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="subtitle2" sx={{ mt: 3 }}>
            Switching To:
          </Typography>
        </div>
      ),
      maxWidth: 'lg',
      fields: [new ChooseKitItemsField('to_id', items)],
      schema: z.object({
        to_id: z.number(),
      }),
      onSubmit: (v) => switchRequest.mutateAsync(v.to_id),
    });
  };

  return (
    <TableRow key={item.id}>
      <TableCell>
        <OrderItemProduct item={item} showSize />
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {item.qty_assigned} / {item.qty}
      </TableCell>
      <TableCell padding="checkbox">
        <SubMenu
          size="small"
          items={[
            selectedKit &&
              new ButtonAction('Assign Qty to Selected Kit', onAssign)
                .withIcon(MenuOpen)
                .setDisabled(item.qty === item.qty_assigned),
            item.drops_count === 0 &&
              new ButtonAction('Assign Qty to Each Kit', onBulkAssign).withIcon(PlaylistAdd),
            Number(item.qty_assigned) > 0 &&
              new ButtonAction('Switch to Different Item', onSwitch).withIcon(ImportExport),
            new ButtonAction('Remove from Each Kit', onRemoveFromAll).withIcon(RemoveCircleOutline),
          ]}
        />
      </TableCell>
    </TableRow>
  );
}
