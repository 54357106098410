import { useMemo, useRef } from 'react';
import { CardHeader, Button } from '@mui/material';
import axios from 'axios';
import { z } from 'zod';
import { FieldFactory, Resource } from '@/classes';
import DataTable, { DataTableHandle } from '@/components/DataTable/DataTable';
import { useDialogs } from '@/contexts/DialogContext';
import { ProductionEventPrice } from '@/types';
import { useRecordId } from '@/utils/genericResource';

export default function ProductionEventPrices() {
  const id = useRecordId();
  const dataTableRef = useRef<DataTableHandle | null>(null);
  const { prompt } = useDialogs();

  const apiEndpoint = `/api/production-event-types/${id}/prices`;

  const onImport = () => {
    prompt({
      title: 'Import Production Event Prices',
      fields: [
        FieldFactory.file('file').with({
          accept:
            '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        }),
        FieldFactory.boolean('delete_existing', 'Replace Existing Price Breaks'),
      ],
      schema: z.object({
        file: z.string(),
        delete_existing: z.boolean().default(false),
      }),
      onSubmit: (v) => axios.post(`${apiEndpoint}/import`, v),
    }).then(() => {
      dataTableRef.current?.onReload();
    });
  };

  const resource = useMemo(
    () =>
      new Resource<ProductionEventPrice>('Production Event Prices')
        .withApiEndpoint(apiEndpoint)
        .setCanExport()
        .withFieldsAndColumns([
          FieldFactory.number('qty').setRequired().withColumnSpan(12),
          FieldFactory.number('complexity')
            .withHelp('Either the stitch count or number of colors (can be blank)')
            .withColumnSpan(12),
          FieldFactory.curr('unit_price').setRequired().withColumnSpan(12),
        ]),
    [apiEndpoint],
  );

  const onEdit = (v: Partial<ProductionEventPrice>) => {
    prompt({
      title: 'Edit Production Price Break',
      fields: resource.fields,
      initialValues: v,
      schema: z.object({
        qty: z.coerce.number().int().positive(),
        complexity: z.coerce.number().nullish(),
        unit_price: z.coerce.number().positive(),
      }),
      onSubmit: (values: Partial<ProductionEventPrice>) => {
        if (v.id) {
          return axios.put(`${apiEndpoint}/${v.id}`, values);
        }
        return axios.post(apiEndpoint, values);
      },
    }).then(() => {
      dataTableRef.current?.onReload();
    });
  };

  const action = (
    <>
      <Button onClick={onImport} sx={{ mr: 1 }}>
        Import
      </Button>
      <Button variant="contained" onClick={() => onEdit({})}>
        Create
      </Button>
    </>
  );

  return (
    <>
      <CardHeader title="Variable Pricing" action={action} />

      <DataTable ref={dataTableRef} onEdit={(v) => onEdit(v)} resource={resource} />
    </>
  );
}
