import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  List,
  ListSubheader,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import OrderListItem from '@/components/Orders/OrderListItem';
import useGetResource from '@/hooks/useGetResource';
import usePushWithContext from '@/hooks/usePushWithContext';
import { Order } from '@/types';
import { makeResourceQueryKey } from '@/utils/genericResource';
import truthy from '@/utils/truthy';

export default function RelatedOrders({ orderId }: { orderId: number }) {
  const getResource = useGetResource();

  const { data, isFetching } = useQuery(makeResourceQueryKey('orders', orderId, 'related'), () =>
    axios
      .get<{
        parent: Order | null;
        children: Order[];
        siblings: Order[];
        webstore_parent: Order | null;
        webstore_children: Order[];
        webstore_siblings: Order[];
      }>(`/api/orders/${orderId}/related`)
      .then(({ data }) => data),
  );

  const orderResource = getResource('orders');
  const combinedOrders = data
    ? truthy([
        data.parent,
        ...data.siblings,
        ...data.children,
        data.webstore_parent,
        ...data.webstore_siblings,
        ...data.webstore_children,
      ])
    : [];

  const push = usePushWithContext(
    combinedOrders.map((o) => ({
      key: o.id,
      label: orderResource.getTitle(o),
      href: `/orders/${o.id}`,
    })),
  );

  const renderSection = (title: string, orders: Order[]) => {
    if (orders.length === 0) {
      return null;
    }

    return (
      <>
        <ListSubheader>{title}</ListSubheader>
        {orders.map((o) => (
          <OrderListItem key={o.id} order={o} push={push} />
        ))}
      </>
    );
  };

  return (
    <Card>
      <CardHeader title="Related Orders" />

      {isFetching && <LinearProgress />}

      {data && (
        <>
          {combinedOrders.length > 0 ? (
            <List dense>
              {renderSection('Parent', data.parent ? [data.parent] : [])}
              {renderSection('Siblings', data.siblings)}
              {renderSection('Children', data.children)}
              {(data.parent || data.children.length > 0) &&
                (data.webstore_parent || data.webstore_children.length > 0) && (
                  <Divider sx={{ my: 2 }} />
                )}
              {renderSection('Webstore Parent', data.webstore_parent ? [data.webstore_parent] : [])}
              {renderSection('Webstore Siblings', data.webstore_siblings)}
              {renderSection('Webstore Children', data.webstore_children)}
            </List>
          ) : (
            <CardContent>
              <Typography color="textSecondary">There are no related orders.</Typography>
            </CardContent>
          )}
        </>
      )}
    </Card>
  );
}
