import { Backdrop, CircularProgress } from '@mui/material';

export default function LoadingBackdrop({ isLoading }: { isLoading: boolean }) {
  return (
    <Backdrop
      sx={{
        color: 'background.paper',
        zIndex: 'drawer',
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
