import { ChangeEvent, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material';
import { useConfig } from '@/contexts/AppConfigContext';

export default function ChooseServiceLevel({
  serviceLevel,
  onChange,
}: {
  serviceLevel: string | null;
  onChange: (a: string | null) => void;
}) {
  const { shippoCarrierAccounts } = useConfig();
  const [hasServiceLevel, setHasServiceLevel] = useState(!!serviceLevel);

  useEffect(() => {
    if (serviceLevel) {
      setHasServiceLevel(true);
    } else {
      setHasServiceLevel(false);
    }
  }, [serviceLevel]);

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setHasServiceLevel(true);
    } else {
      setHasServiceLevel(false);
      onChange(null);
    }
  };

  return (
    <div>
      <FormControlLabel
        label="Specify Service Level"
        control={<Checkbox checked={hasServiceLevel} size="small" onChange={handleCheckbox} />}
      />

      {hasServiceLevel && (
        <FormControl fullWidth size="small" sx={{ mb: 1, mt: 0.5 }}>
          <InputLabel id="third-party-account-label">Service Level</InputLabel>
          <Select
            id="service-level-select"
            value={serviceLevel}
            onChange={(e) => onChange(e.target.value)}
            label="Service Level"
          >
            {shippoCarrierAccounts.flatMap((account) => [
              <ListSubheader>{account.carrier.toUpperCase()}</ListSubheader>,
              ...Object.entries(account.service_levels).map(([key, label]) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              )),
            ])}
          </Select>
        </FormControl>
      )}
    </div>
  );
}
