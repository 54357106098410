import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ProductDetail } from '@/types';

export function useGetProduct(productId: number | undefined) {
  return useQuery(
    ['product', productId],
    () =>
      axios
        .get<
          Required<ProductDetail>
        >(`/api/products/${productId}?with=vendor,brand,variants,tags,images`)
        .then(({ data }) => data),
    { enabled: !!productId },
  );
}
