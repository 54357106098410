import { Attachment as AttachmentIcon } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { Attachment } from '@/types';

export default function AttachmentsListSimple({ attachments }: { attachments: Attachment[] }) {
  return (
    <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
      {attachments.map((a) => {
        return (
          <li key={a.id || a.url}>
            <Box
              component="a"
              href={a.url}
              target="_blank"
              display="flex"
              alignItems="center"
              gap={0.5}
              fontSize="small"
            >
              <AttachmentIcon fontSize="small" />
              {a.name || a.file}
            </Box>
          </li>
        );
      })}
    </ul>
  );
}
