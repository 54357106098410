import { useCallback, useMemo } from 'react';
import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import groupBy from 'lodash/groupBy';
import pluralize from 'pluralize';
import { z } from 'zod';
import { useChangeProductRequest } from '@/api/orderItems';
import PaginatedTable from '@/components/DataTable/PaginatedTable';
import OrderItemActions from '@/components/Orders/Items/OrderItemActions';
import OrderItemCost from '@/components/Orders/Items/OrderItemCost';
import OrderItemDiscount from '@/components/Orders/Items/OrderItemDiscount';
import OrderItemPrice from '@/components/Orders/Items/OrderItemPrice';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import OrderItemQty from '@/components/Orders/Items/OrderItemQty';
import OrderItemSettings from '@/components/Orders/Items/OrderItemSettings';
import OrderItemSize from '@/components/Orders/Items/OrderItemSize';
import OrderItemVendor from '@/components/Orders/Items/OrderItemVendor';
import OrderItemsBulkActions from '@/components/Orders/Items/OrderItemsBulkActions';
import ProductField from '@/components/Products/ProductField';
import SkuLabel from '@/components/Products/SkuLabel';
import StatusChip from '@/components/Shared/StatusChip';
import { PARTY_COLORS } from '@/constants';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderItem, genericModelReferenceSchema } from '@/types';
import curr from '@/utils/curr';
import { buildIncrementId } from '@/utils/notes';
import { useIsOrderLocked } from '@/utils/orders';

const columnHelper = createColumnHelper<OrderItem>();
export default function OrderItemsTable({
  items,
  setEditing,
  refetch,
  isFetching,
  leftActions,
}: {
  items: OrderItem[];
  setEditing: (item: OrderItem) => void;
  refetch: () => void;
  isFetching: boolean;
  leftActions?: React.ReactNode;
}) {
  const { prompt } = useDialogs();
  const isOrderLocked = useIsOrderLocked();

  const changeProductRequest = useChangeProductRequest();

  const handleChangeProduct = useCallback(
    (rows: OrderItem[]) => {
      prompt({
        title: 'Change Product',
        description: `This will change the style & color of ${pluralize('item', rows.length, true)} if the chosen new product has the same size.`,
        fields: [new ProductField('new_product')],
        schema: z.object({
          new_product: genericModelReferenceSchema,
        }),
        onSubmit: (v) =>
          changeProductRequest.mutateAsync({
            order_item_ids: rows.map((r) => r.id),
            new_product_id: v.new_product.id,
          }),
      });
    },
    [changeProductRequest.mutateAsync],
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('description', {
        header: 'Item',
        size: 350,
        cell: ({ row }) => (
          <OrderItemProduct
            item={row.original}
            actions={
              row.original.qty_sourced === 0 &&
              row.subRows.length > 0 && (
                <IconButton onClick={() => handleChangeProduct(row.subRows.map((r) => r.original))}>
                  <Edit />
                </IconButton>
              )
            }
          />
        ),
      }),
      columnHelper.accessor('number', {
        header: 'Number',
        size: 100,
      }),
      columnHelper.accessor('color', { header: 'Color', size: 100 }),
      columnHelper.accessor('vendor.name', {
        header: 'Vendor',
        size: 125,
        aggregatedCell: ({ row }) => <OrderItemVendor items={row.subRows.map((r) => r.original)} />,
        cell: ({ row }) => <OrderItemVendor items={[row.original]} />,
      }),
      columnHelper.accessor('size', {
        header: 'Size',
        size: 75,
        aggregatedCell: ({ row }) => <OrderItemSize items={row.subRows.map((r) => r.original)} />,
        cell: ({ row }) => <OrderItemSize items={[row.original]} />,
      }),
      columnHelper.accessor('qty', {
        header: 'Qty',
        size: 60,
        aggregatedCell: ({ row, getValue }) => (
          <OrderItemQty items={row.subRows.map((r) => r.original)} value={getValue()} />
        ),
        cell: ({ row, getValue }) => <OrderItemQty items={[row.original]} value={getValue()} />,
        meta: {
          aggregatable: true,
        },
      }),
      columnHelper.accessor('variant.sku', {
        header: 'SKU',
        size: 100,
        cell: ({ row }) => row.original.variant && <SkuLabel variant={row.original.variant} />,
      }),
      columnHelper.accessor('design_layout_id', {
        header: 'Design Layout',
        size: 100,
        cell: ({ getValue }) => buildIncrementId('LAY', getValue()),
      }),
      columnHelper.accessor('price', {
        header: 'Price',
        size: 75,
        aggregatedCell: ({ row }) => <OrderItemPrice items={row.subRows.map((r) => r.original)} />,
        cell: ({ row }) => <OrderItemPrice items={[row.original]} />,
      }),
      columnHelper.accessor('discount', {
        header: 'Discount',
        size: 50,
        aggregatedCell: ({ row }) => (
          <OrderItemDiscount items={row.subRows.map((r) => r.original)} />
        ),
        cell: ({ row }) => <OrderItemDiscount items={[row.original]} />,
      }),
      columnHelper.accessor('subtotal', {
        header: 'Line Price',
        size: 75,
        cell: ({ getValue }) => curr(getValue()),
        meta: {
          aggregatable: true,
        },
      }),
      columnHelper.accessor('cost', {
        header: 'Cost',
        size: 75,
        aggregatedCell: ({ row }) => <OrderItemCost items={row.subRows.map((r) => r.original)} />,
        cell: ({ row }) => <OrderItemCost items={[row.original]} />,
      }),
      columnHelper.accessor('ship_to_party', {
        header: 'PO Ship To',
        size: 110,
        aggregatedCell: ({ getValue }) => <StatusChip status={getValue()} colors={PARTY_COLORS} />,
        cell: ({ getValue, row }) =>
          row.original.is_purchasable && <StatusChip status={getValue()} colors={PARTY_COLORS} />,
      }),
      columnHelper.accessor('classification', {
        header: 'Classification',
        size: 100,
      }),
      columnHelper.accessor('upc', {
        header: 'UPC',
        size: 100,
      }),
      columnHelper.accessor('note', {
        header: 'Note',
        size: 200,
      }),
      columnHelper.accessor('is_tax_exempt', {
        header: 'Tax Exempt',
        size: 100,
        cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
      }),
      columnHelper.accessor('sort_order', {
        header: 'Sort',
      }),
      columnHelper.accessor('qty_sourced', {
        header: 'Qty Pur',
        size: 50,
        meta: {
          aggregatable: true,
        },
      }),
      columnHelper.accessor('qty_received', {
        header: 'Qty Rec',
        size: 50,
        meta: {
          aggregatable: true,
        },
      }),
      columnHelper.accessor('qty_shipped', {
        header: 'Qty Shp',
        size: 50,
        meta: {
          aggregatable: true,
        },
      }),
      columnHelper.display({
        id: 'settings',
        enableSorting: false,
        enableHiding: false,
        header: 'Settings',
        size: 175,
        cell: ({ row }) => <OrderItemSettings item={row.original} />,
      }),
      columnHelper.display({
        id: 'actions',
        size: 75,
        cell: ({ row }) => (
          <OrderItemActions item={row.original} onEdit={() => setEditing(row.original)} />
        ),
      }),
    ];
  }, [setEditing, handleChangeProduct]);

  return (
    <PaginatedTable
      storageKey="orderItems"
      rows={items}
      columns={columns}
      definedGrouping={['description']}
      initialIsGrouped={Object.keys(groupBy(items, 'description')).length < items.length}
      refetch={refetch}
      isFetching={isFetching}
      getBulkActions={
        isOrderLocked
          ? undefined
          : (rows) => <OrderItemsBulkActions items={rows.map((r) => r.original)} />
      }
      initialState={{
        pagination: {
          pageSize: 50,
        },
        columnVisibility: {
          number: false,
          color: false,
          design_layout_id: false,
          sort_order: false,
          discount: false,
          cost: false,
          qty_sourced: false,
          qty_received: false,
          qty_shipped: false,
          classification: false,
          upc: false,
          note: false,
          is_tax_exempt: false,
          variant_sku: false,
        },
        sums: {
          qty: true,
          qty_sourced: true,
          qty_received: true,
          qty_shipped: true,
        },
      }}
      slots={{
        leftActions,
      }}
    />
  );
}
