import { ReactNode } from 'react';
import { FontDownload, Image, Info, Settings } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  IconButton,
  LinearProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import map from 'lodash/map';
import { Link } from 'react-router-dom';
import { useGetCustomerBrand } from '@/api/customers';
import BrandColorField from '@/components/Customers/BrandColorField';
import Can from '@/components/Permissions/Can';
import { CustomerBrandFile } from '@/types';
import { maybeAddQueryParams } from '@/utils/query';

export default function BrandGuidelinesCard({ customerId }: { customerId: number }) {
  const { data } = useGetCustomerBrand(customerId);

  if (!data) {
    return <LinearProgress />;
  }

  const { brand_colors, brand_files, id, is_parent_data, name } = data;

  const brandData: Record<string, ReactNode> = {
    Colors: <BrandColorField colors={brand_colors} />,
    Logos: renderFilesByType(brand_files, 'logo'),
    Fonts: renderFilesByType(brand_files, 'font'),
  };

  return (
    <Card>
      <CardHeader
        title="Brand Guidelines"
        subheader={renderCustomer({ is_parent_data, name })}
        action={
          <Can permission="write:customers">
            <IconButton component={Link} to={`/customers/${id}/?tab=brand`}>
              <Settings />
            </IconButton>
          </Can>
        }
      />
      <TableContainer>
        <Table>
          <TableBody>
            {map(brandData, (value, label) => (
              <TableRow key={label}>
                <TableCell component="th">
                  <Typography color="textSecondary" variant="body2">
                    {label}
                  </Typography>
                </TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

function renderFilesByType(files: CustomerBrandFile[] | undefined, type: string) {
  if (!files) {
    return null;
  }

  const filteredFiles = files.filter((f) => f.type === type);

  if (filteredFiles.length === 0) {
    return (
      <Typography variant="body2" color="textSecondary">
        No {type} files found for this customer.
      </Typography>
    );
  }

  return (
    <List disablePadding>
      {files
        .filter((f) => f.type === type)
        .map((file) => (
          <ListItemButton
            key={file.id}
            dense
            disableGutters
            component="a"
            href={file.file}
            target="_blank"
            rel="noreferrer"
          >
            <ListItemAvatar>
              <Avatar src={maybeAddQueryParams(file.image, { w: 200 })}>
                {file.type === 'font' ? <FontDownload /> : <Image />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText>{file.name || file.file.split('/').pop()}</ListItemText>
          </ListItemButton>
        ))}
    </List>
  );
}

function renderCustomer({ is_parent_data, name }: { is_parent_data: boolean; name: string }) {
  return (
    <Box display="flex" alignItems="center" mt={1}>
      Customer: {name}
      {is_parent_data && (
        <Tooltip arrow title="Using franchise parent brand data" sx={{ ml: 1 }}>
          <Info fontSize="small" color="info" />
        </Tooltip>
      )}
    </Box>
  );
}
