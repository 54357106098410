import { useCallback } from 'react';
import { QueryKey, useQueryClient } from '@tanstack/react-query';

export default function useMutateQueryData<T extends object>(key: QueryKey) {
  const queryClient = useQueryClient();

  return useCallback(
    (func: ((prev: T) => T) | T) => {
      if (typeof func !== 'function') {
        queryClient.setQueryData(key, func);
      } else {
        const oldData = queryClient.getQueryData(key) as T | undefined;
        if (oldData) {
          queryClient.setQueryData(key, func(oldData));
        }
      }
    },
    [key, queryClient],
  );
}
