import { createContext, ReactNode, useContext, useState } from 'react';

const SchedulingContext = createContext<{
  eventIds: number[];
  setEventIds: (eventIds: number[]) => void;
}>({
  eventIds: [],
  setEventIds: () => {},
});

export function SchedulingProvider({ children }: { children: ReactNode }) {
  const [eventIds, setEventIds] = useState<number[]>([]);

  return (
    <SchedulingContext.Provider value={{ eventIds, setEventIds }}>
      {children}
    </SchedulingContext.Provider>
  );
}

export function useSchedulingState() {
  return useContext(SchedulingContext);
}
