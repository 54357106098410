import { BaseSyntheticEvent } from 'react';

function isEvent(e: any): e is BaseSyntheticEvent {
  return e && typeof e === 'object' && 'currentTarget' in e;
}

export default function getValueFromEvent(e: any): string | boolean | undefined {
  if (isEvent(e)) {
    if (e.currentTarget) {
      if (e.currentTarget instanceof HTMLInputElement && e.currentTarget.type === 'checkbox') {
        return e.currentTarget.checked as boolean;
      }
      if ('value' in e.currentTarget) {
        return e.currentTarget.value as string;
      }
    }
    if (e.target) {
      if (e.target instanceof HTMLInputElement && e.target.type === 'checkbox') {
        return e.target.checked as boolean;
      }
      if ('value' in e.target) {
        return e.target.value as string;
      }
    }
  }
  return e;
}
