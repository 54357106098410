import { CardLayout, FieldFactory, Resource } from '@/classes';
import { DepartmentSet, departmentSetPayloadSchema, departmentPayloadSchema } from '@/types';

export default function departmentSets() {
  return new Resource<DepartmentSet>('Department Sets')
    .withSchema(departmentSetPayloadSchema)
    .withQueryParams({
      index: {},
      single: {
        with: 'departments',
      },
    })
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      new CardLayout('Department Options')
        .withoutPadding()
        .withoutDefaultLayout()
        .withFields([
          FieldFactory.table(
            'departments',
            [FieldFactory.text('name')],
            departmentPayloadSchema,
          ).withColumnSpan(12),
        ]),
    ]);
}
