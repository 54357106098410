import { Box, Card } from '@mui/material';
import partition from 'lodash/partition';
import { PurchaseOrderItem } from '@/types';
import NoteViewer from '../Notes/NoteViewer';
import ItemsToReceiveTable from './ItemsToReceiveTable';

export default function ItemsToReceive({
  orderIds,
  purchaseOrderIds,
  receivableId,
  receivableType,
  items,
}: {
  orderIds: number[];
  purchaseOrderIds: number[];
  receivableId: number;
  receivableType: 'order' | 'purchase_order';
  items: PurchaseOrderItem[];
}) {
  const [received, toReceive] = partition(items, 'is_received');

  return (
    <>
      {orderIds.map((orderId) => (
        <NoteViewer key={orderId} tag="receiving" resource="orders" resourceId={orderId} />
      ))}
      {purchaseOrderIds.map((poId) => (
        <NoteViewer key={poId} tag="receiving" resource="purchase-orders" resourceId={poId} />
      ))}
      <Card>
        <Box mb={2}>
          <ItemsToReceiveTable
            title="To Receive"
            items={toReceive}
            receivableId={receivableId}
            receivableType={receivableType}
            canReceive
          />
        </Box>
        <ItemsToReceiveTable
          title="Received"
          items={received}
          receivableId={receivableId}
          receivableType={receivableType}
        />
      </Card>
    </>
  );
}
