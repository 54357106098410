import { useCreateShipment } from '@/api/shipping';
import { FieldFactory } from '@/classes';
import ConditionallyRenderField from '@/components/Shared/ConditionallyRenderField';
import { useConfig } from '@/contexts/AppConfigContext';
import { useDialogs } from '@/contexts/DialogContext';
import { Shipment, ShipmentBasePayload, shipmentCreatePayloadSchema } from '@/types';

interface UseMarkAsShippedOptions {
  title: string;
  onSuccess: (s: Shipment) => void;
}

const useMarkAsShipped = ({ title, onSuccess }: UseMarkAsShippedOptions) => {
  const { carriers } = useConfig();
  const { prompt } = useDialogs();
  const createShipmentRequest = useCreateShipment();

  const markAsShipped = (payload: ShipmentBasePayload, qty: number) => {
    prompt({
      title,
      description: `You will be marking ${qty} items as shipped. You can optionally add tracking information if you have it.`,
      fields: [
        FieldFactory.select('carrier', carriers),
        new ConditionallyRenderField(
          'tracking_number',
          FieldFactory.text('tracking_number'),
          (v) => v.carrier !== 'courier',
        ),
        FieldFactory.curr('cost').withHelp('Only add if shipping cost is not on a PO'),
        FieldFactory.file('file'),
      ],
      schema: shipmentCreatePayloadSchema.pick({
        carrier: true,
        tracking_number: true,
        cost: true,
        file: true,
      }),
      onSubmit: async (v) => {
        try {
          const shipmentData = await createShipmentRequest.mutateAsync({
            ...payload,
            ...v,
          });
          onSuccess(shipmentData);
        } catch (error) {
          console.error('Failed to mark as shipped:', error);
        }
      },
    });
  };

  return {
    markAsShipped,
    isLoading: createShipmentRequest.isLoading,
    error: createShipmentRequest.error,
  };
};

export default useMarkAsShipped;
