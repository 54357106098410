import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export default function useChangeEffect(callback: EffectCallback, deps: DependencyList) {
  const initial = useRef(true);
  return useEffect(() => {
    if (initial.current) {
      initial.current = false;
    } else {
      callback();
    }
  }, deps);
}
