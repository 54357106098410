import { Grid2 as Grid } from '@mui/material';
import { LayoutProps } from '@/classes/types';
import EditFormFields, { EditFormFieldsProps } from '@/components/Form/EditFormFields';
import Layout from '../Layout';

function Component({ model, forwardProps = {} }: LayoutProps<GridLayout>) {
  return (
    <Grid container spacing={model.spacing} columns={model.columns}>
      {model.fields.map((field) => (
        <Grid key={field.name} size={{ xs: 12, lg: field.columnSpan }}>
          <EditFormFields fields={[field]} defaultLayout={model.defaultLayout} {...forwardProps} />
        </Grid>
      ))}
    </Grid>
  );
}

export default class GridLayout extends Layout {
  columns = 12;

  withColumns(c: number) {
    this.columns = c;
    return this;
  }

  renderLayout(props: Partial<EditFormFieldsProps>): React.ReactElement | null {
    return <Component model={this} forwardProps={props} />;
  }
}
