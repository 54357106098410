import {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Page } from '@/resources/types';

interface AppState {
  routes: Page[];
  isMenuOpen: boolean;
  appBar: {
    title?: ReactNode;
    actions?: ReactNode;
    back?: string | null;
  };
  appBarRef: MutableRefObject<HTMLDivElement | null>;
  setAppBarState: (state: AppState['appBar']) => void;
  setIsMenuOpen: (t: boolean) => void;
}

export const AppContext = createContext<AppState>({
  routes: [],
  isMenuOpen: false,
  appBar: {},
  appBarRef: { current: null },
  setAppBarState: () => {},
  setIsMenuOpen: () => {},
});

export function useAppContext(): AppState {
  return useContext(AppContext);
}

export function useTitle(title: string, back?: string, actions?: ReactNode) {
  const { setAppBarState } = useAppContext();

  useEffect(() => {
    setAppBarState({ title, back, actions });
  }, [title, back]);
}

export function AppProvider({
  children,
  routes,
  title,
}: {
  children: ReactNode;
  routes: Page[];
  title: string;
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [appBar, setAppBarState] = useState<AppState['appBar']>({});
  const appBarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (appBar.title) {
      document.title = `${appBar.title} | ${title}`;
    } else {
      document.title = title;
    }
  }, [appBar.title, title]);

  return (
    <AppContext.Provider
      value={{
        routes,
        isMenuOpen,
        setIsMenuOpen,
        setAppBarState,
        appBar,
        appBarRef,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
