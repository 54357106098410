import { AddShoppingCart, FileCopy, Merge } from '@mui/icons-material';
import axios from 'axios';
import { z } from 'zod';
import { Action, ButtonAction, CustomAction, FieldFactory, LinkAction } from '@/classes';
import { OnClickProps } from '@/classes/types';
import { Customer } from '@/types';
import { genericModelReferenceSchema } from '@/types';
import { getSingleHeldAction } from '@/utils/disabled';
import PrintMenu from '../Print/PrintMenu';

export default function CustomerActions(customer: Customer) {
  const onMerge = ({ dialogs, navigate }: OnClickProps) => {
    dialogs
      .prompt({
        title: 'Merge Customer Into',
        description:
          "The customer's orders, projects, design, products, and more will be moved to the new customer and the current customer will be deleted.",
        fields: [
          FieldFactory.belongsTo('into_customer', 'customers').withRequestParams({
            filter: {
              business_id: customer.business_id,
            },
          }),
        ],
        schema: z.object({
          into_customer: genericModelReferenceSchema,
        }),
        onSubmit: (v) =>
          axios.post<Customer>(`/api/customers/${customer.id}/merge`, {
            into_customer_id: v.into_customer.id,
          }),
      })
      .then(({ data }) => {
        navigate(`/customers/${data.id}`);
      });
  };

  const actions: Action[] = [
    new CustomAction('Print', () => <PrintMenu model="customer" id={customer.id} />),
  ];

  if (!customer.is_disabled) {
    actions.push(
      new LinkAction('New Order', `/orders/create?customer_id=${customer.id}`)
        .withPermission('write:orders')
        .withIcon(AddShoppingCart),
    );
  }

  actions.push(
    new ButtonAction('Duplicate', ({ dialogs, navigate }) => {
      dialogs
        .confirm({
          title: 'Duplicate Customer',
          description: 'Are you sure you want to create a copy of this customer?',
        })
        .then(() => {
          axios.post(`/api/customers/${customer.id}/duplicate`).then(({ data }) => {
            navigate(`/customers/${data.id}`);
          });
        });
    })
      .withIcon(FileCopy)
      .withPermission('write:customers'),
    new ButtonAction('Merge Customer', onMerge).withIcon(Merge).withPermission('write:customers'),
    getSingleHeldAction('Customer', 'customers', customer),
  );

  return actions;
}
