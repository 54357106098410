import { Avatar, SxProps } from '@mui/material';
import { User } from '@/types';

export default function UserAvatar({
  user,
  name,
  sx = {},
}: {
  user?: Pick<User, 'name' | 'avatar'> | null;
  name?: string | null;
  sx?: SxProps;
}) {
  const userName = name || user?.name || '?';
  const src = user?.avatar ? `${user.avatar}?w=100&h=100&fit=cover` : undefined;
  return (
    <Avatar src={src} sx={sx}>
      {userName[0]}
    </Avatar>
  );
}
