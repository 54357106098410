import { useState, useEffect, forwardRef, ForwardedRef } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import useDebounce from '@/hooks/useDebounce';

interface DebouncedTextFieldProps extends Omit<TextFieldProps, 'ref' | 'onChange'> {
  onChange: (s: string) => void;
  initialValue?: string;
}

function DebouncedTextField(
  { onChange, initialValue = '', ...props }: DebouncedTextFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [value, setValue] = useState(initialValue);
  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextField {...props} inputRef={ref} onChange={(e) => setValue(e.target.value)} value={value} />
  );
}

export default forwardRef(DebouncedTextField);
