import { Delete, OpenInNew } from '@mui/icons-material';
import { Card, CardContent, CardHeader, IconButton, Skeleton, Tooltip } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import axios from 'axios';
import moment from 'moment';
import PaginatedTable from '@/components/DataTable/PaginatedTable';
import StatusChip from '@/components/Shared/StatusChip';
import { APPLIED_STATUS_COLORS, APPLIED_STATUS_LABELS } from '@/constants';
import { useDialogs } from '@/contexts/DialogContext';
import { PartiallyRequired, Payment } from '@/types';
import curr from '@/utils/curr';

const columnHelper = createColumnHelper<PartiallyRequired<Payment, 'order_payments'>>();

export default function BusinessPayoutDeductions({
  payments,
  refetch,
}: {
  payments: PartiallyRequired<Payment, 'order_payments'>[];
  refetch: () => void;
}) {
  const { confirm } = useDialogs();

  const onDelete = (payment: Payment) => {
    confirm({
      title: 'Delete Payout Deduction',
      description: 'Are you sure you want to delete this payout deduction?',
    }).then(() => {
      axios.delete(`/api/payments/${payment.id}`).then(() => {
        refetch();
      });
    });
  };

  return (
    <Card>
      <CardHeader title="Payout Deductions" />

      {payments ? (
        <PaginatedTable
          storageKey="businessPayoutDeductions"
          rows={payments}
          columns={[
            columnHelper.accessor('payment_date', {
              header: 'Date',
              cell: ({ getValue }) => moment(getValue()).format('l'),
            }),
            columnHelper.accessor('amount', {
              header: 'Amount',
              cell: ({ getValue }) => curr(getValue()),
            }),
            columnHelper.accessor('payment_number', {
              header: 'Number',
            }),
            columnHelper.accessor('customer.name', {
              header: 'Customer',
            }),
            columnHelper.accessor('applied_status', {
              header: 'Status',
              cell: ({ getValue }) => (
                <StatusChip
                  status={getValue()}
                  colors={APPLIED_STATUS_COLORS}
                  labels={APPLIED_STATUS_LABELS}
                />
              ),
            }),
            columnHelper.display({
              header: '',
              id: 'actions',
              cell: ({ row }) => (
                <>
                  {row.original.order_payments
                    .filter((op) => op.order_id)
                    .map((op) => (
                      <Tooltip key={op.id} title={`View Order #${op.order_id}`}>
                        <IconButton
                          component="a"
                          href={`/orders/${op.order_id}?tab=payments`}
                          target="_blank"
                        >
                          <OpenInNew fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    ))}

                  {row.original.order_payments.length === 0 && (
                    <IconButton onClick={() => onDelete(row.original)}>
                      <Delete fontSize="small" />
                    </IconButton>
                  )}
                </>
              ),
            }),
          ]}
        />
      ) : (
        <CardContent>
          <Skeleton />
        </CardContent>
      )}
    </Card>
  );
}
