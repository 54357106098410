import { SyntheticEvent } from 'react';
import { Download } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid2 as Grid,
  Hidden,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import groupBy from 'lodash/groupBy';
import pickBy from 'lodash/pickBy';
import sumBy from 'lodash/sumBy';
import { useSessionStorage } from 'usehooks-ts';
import { useGetProductionEvents } from '@/api/production';
import FieldFactory from '@/classes/FieldFactory';
import { NonFormField } from '@/components/Form/FormField';
import ProductionEventTable from '@/components/Production/ProductionEventTable';
import MinutesText from '@/components/Shared/MinutesText';
import Paper from '@/components/Shared/Paper';
import Text from '@/components/Text/Text';
import { useTitle } from '@/contexts/AppContext';
import getApiUrl from '@/utils/getApiUrl';
import getValueFromEvent from '@/utils/getValueFromEvent';
import numString from '@/utils/numString';

export default function ProductionSchedule() {
  useTitle('Production Schedule');

  const [filters, setFilters] = useSessionStorage<Record<string, any>>('prodScheduleFilters', {
    date: new Date().toISOString().slice(0, 10),
    eventType: undefined,
  });

  const handleFilterChange = (name: string) => (e: SyntheticEvent) => {
    const value = getValueFromEvent(e);
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const params = pickBy({
    with: 'machine,order_design.design,order',
    sort: '-priority,order.committed_ship_date',
    count: '500',
    'filter[scheduled_date]': filters.date,
    'filter[event_type_id]': filters.eventType ? String(filters.eventType.id) : undefined,
    'filter[machine_id]': filters.machine ? String(filters.machine.id) : undefined,
    unit: filters.unit,
    incomplete: filters.incomplete ? '1' : '0',
  }) as Record<string, string>;

  const getEventsUrl = (additional: Record<string, string>) => {
    return getApiUrl(
      `/api/production-events?${new URLSearchParams({ ...params, ...additional }).toString()}`,
    );
  };

  const { data, isLoading } = useGetProductionEvents(params, Boolean(filters.date));

  const events = data?.data || [];
  const grouped = groupBy(events, 'machine.id');

  return (
    <Box>
      <Paper>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6, lg: 3 }}>
            <NonFormField
              onChange={handleFilterChange('date')}
              value={filters.date}
              field={FieldFactory.date('date')}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 3 }}>
            <NonFormField
              onChange={handleFilterChange('eventType')}
              value={filters.eventType}
              field={FieldFactory.belongsTo('event_type', 'productionEventTypes')}
            />
          </Grid>
          <Hidden lgDown>
            <Grid size={{ xs: 12, md: 6, lg: 3 }}>
              <NonFormField
                onChange={handleFilterChange('machine')}
                value={filters.machine}
                field={FieldFactory.belongsTo('machine', 'productionMachines')}
              />
            </Grid>
          </Hidden>
          <Hidden lgDown>
            <Grid size={{ xs: 12, md: 6, lg: 3 }}>
              <NonFormField
                onChange={handleFilterChange('unit')}
                value={filters.unit}
                field={FieldFactory.select('unit', [
                  { value: 'colors', label: 'Screenprint' },
                  { value: 'stitches', label: 'Embroidery' },
                ]).with({
                  includeBlank: true,
                  label: 'Department',
                })}
              />
            </Grid>
          </Hidden>
          <Grid style={{ paddingTop: 0 }} size={12}>
            <NonFormField
              onChange={handleFilterChange('incomplete')}
              value={filters.incomplete}
              field={FieldFactory.boolean('incomplete', 'Show Only Incomplete')}
            />
          </Grid>
        </Grid>
      </Paper>
      {!filters.date ? (
        <Typography>Please select a date</Typography>
      ) : (
        <div>
          <CardHeader
            title="Events by Machine"
            action={
              <Tooltip title="Download Schedule">
                <IconButton component="a" href={getEventsUrl({ format: 'pdf' })} target="_blank">
                  <Download />
                </IconButton>
              </Tooltip>
            }
          />

          {isLoading && <CircularProgress />}

          <Stack spacing={2}>
            {Object.values(grouped).map((groupedEvents) => {
              const { machine } = groupedEvents[0];
              return (
                <Card key={machine?.id || 'none'}>
                  <CardHeader
                    title={machine?.name || '(Unassigned)'}
                    titleTypographyProps={{ variant: 'h6' }}
                    sx={{ pb: 0 }}
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Text
                      primary={`Total: ${numString(sumBy(groupedEvents, 'quantity'), 'imprints')}`}
                      secondary={<MinutesText minutes={sumBy(groupedEvents, 'job_minutes')} />}
                    />
                  </CardContent>
                  <ProductionEventTable events={groupedEvents} />
                </Card>
              );
            })}
          </Stack>

          {Object.keys(grouped).length === 0 && !isLoading && (
            <Typography>There are no events scheduled.</Typography>
          )}
        </div>
      )}
    </Box>
  );
}
