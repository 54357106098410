import { Dialog } from '@mui/material';
import { PromptDialogConfig } from '@/contexts/DialogContext';
import PromptDialog from './PromptDialog';

export default function PromptDialogContainer({
  prompt,
  setPromptDialog,
}: {
  prompt: PromptDialogConfig<any, any> | undefined;
  setPromptDialog: (state: PromptDialogConfig<any, any>) => void;
}) {
  if (!prompt) {
    return null;
  }

  const onClose = () => {
    setPromptDialog({ ...prompt, open: false });
  };

  const onSuccess = (v?: any) => {
    prompt.promise.resolve(v);
    onClose();
  };

  const onCancel = () => {
    prompt.promise.reject();
    onClose();
  };

  const { options } = prompt;
  const { fields, ...nonFields } = options;

  return (
    <Dialog
      open={prompt.open}
      onClose={onCancel}
      fullWidth
      maxWidth={prompt.options.maxWidth || 'xs'}
    >
      <PromptDialog
        {...nonFields}
        promptFields={fields}
        onSuccess={onSuccess}
        onCancel={onCancel}
        mode="dialog"
        open={prompt.open}
      />
    </Dialog>
  );
}
