import { FieldFactory, StackLayout } from '@/classes';
import EventableCard from '@/components/Notes/EventableCard';
import { useRecordId } from '@/utils/genericResource';

export function getEventableConfigForResource(resource: string) {
  return new StackLayout('_activity', [
    FieldFactory.custom('_activity', () => (
      <EventableCard resource={resource} resourceId={useRecordId()} />
    )).setCreatable(false),
  ]);
}
