import { styled } from '@mui/material';
import { UseControllerReturn } from 'react-hook-form';
import { FieldProps } from '@/classes/types';
import StaticFormControl from '@/components/Form/StaticFormControl';
import renderCell from '@/utils/renderCell';
import Field from '../Field';

const ReadOnlyWrapper = styled('div')({
  fontSize: 14,
  padding: '0 14px 10.5px 14px',
  whiteSpace: 'pre-line',
});

export function Read({ field, fieldModel, fieldState }: FieldProps) {
  return (
    <StaticFormControl fieldModel={fieldModel} fieldState={fieldState}>
      <ReadOnlyWrapper style={fieldModel.style}>
        {renderCell(fieldModel, field.value, {}, '(none)')}
      </ReadOnlyWrapper>
    </StaticFormControl>
  );
}

export default class ReadField extends Field {
  readOnly = true;

  renderEditComponent(props: UseControllerReturn) {
    return <Read {...props} fieldModel={this} />;
  }
}
