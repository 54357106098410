import { useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import pick from 'lodash/pick';
import { createPortal } from 'react-dom';
import type { FieldValues } from 'react-hook-form';
import Layout from '@/classes/Layout';
import Resource from '@/classes/Resource';
import SaveButton from '@/components/Buttons/SaveButton';
import EditFormFields from '@/components/Form/EditFormFields';
import Form from '@/components/Form/Form';
import FormErrors from '@/components/Form/FormErrors';
import PrevNextNavigation from '@/components/Form/PrevNextNavigation';
import { useAppContext } from '@/contexts/AppContext';
import { handleLaravelErrors, useZodForm } from '@/utils/form';

interface EditFormProps<T extends Resource> {
  resource: T;
  allowPrevNext?: boolean;
  defaultLayout?: typeof Layout;
  isCreate?: boolean;
  onSuccess: (values: FieldValues) => void;
  initialValues?: FieldValues;
}

export default function EditForm<T extends Resource>({
  resource,
  initialValues,
  isCreate,
  defaultLayout,
  allowPrevNext = false,
  onSuccess,
}: EditFormProps<T>) {
  const { appBarRef } = useAppContext();
  const form = useZodForm(isCreate ? resource.createSchema : resource.schema, initialValues);
  const {
    reset,
    formState: { isSubmitting, dirtyFields },
  } = form;
  const isDirty = Object.keys(dirtyFields).length > 0;

  useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  const onSubmit = (values: FieldValues) => {
    // Only get keys that are dirty to update
    const body = isCreate ? values : pick(values, Object.keys(dirtyFields));
    if (!isCreate && initialValues) {
      body[resource.primaryKey] = initialValues[resource.primaryKey];
    }
    const promise = isCreate ? resource.getStoreRequest(body) : resource.getUpdateRequest(body);

    return promise
      .then(({ data }) => {
        reset({ ...values, ...data });
        onSuccess(data);
      })
      .catch(handleLaravelErrors(form));
  };

  const renderInner = () => {
    if (isCreate && resource.createComponent) {
      const Component = resource.createComponent;
      return <Component isCreate />;
    }
    if (!isCreate && resource.editComponent) {
      const Component = resource.editComponent;
      return <Component />;
    }

    return (
      <EditFormFields fields={resource.fields} isCreate={isCreate} defaultLayout={defaultLayout} />
    );
  };

  return (
    <Form form={form} onSubmit={onSubmit} style={{ marginBottom: 200 }}>
      {renderInner()}

      <FormErrors form={form} />

      <Box display="flex" justifyContent="end">
        {(isDirty || isCreate) && (
          <Box my={2}>
            <Button type="button" onClick={() => reset()} sx={{ mr: 2 }}>
              Reset
            </Button>
            <LoadingButton type="submit" loading={isSubmitting} variant="contained">
              Save
            </LoadingButton>
          </Box>
        )}
      </Box>

      {appBarRef.current &&
        createPortal(<SaveButton form={form} onSubmit={onSubmit} />, appBarRef.current)}

      {allowPrevNext && <PrevNextNavigation />}
    </Form>
  );
}
