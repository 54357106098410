import { CheckCircle, Undo } from '@mui/icons-material';
import axios from 'axios';
import { z } from 'zod';
import { Resource, ButtonAction, FieldFactory } from '@/classes';
import RebatePage from '@/components/Rebates/RebatePage';
import { Rebate, rebateCreatePayloadSchema, rebateUpdatePayloadSchema } from '@/types';

export default function rebates() {
  return new Resource<Rebate>('Rebates')
    .withSchema(rebateUpdatePayloadSchema, rebateCreatePayloadSchema)
    .setCanExport()
    .getTitleUsing((r) => `${r.order.business.name} Rebate #${r.id}`)
    .getSingleActionsUsing((values) => {
      return [
        values.processed_at
          ? new ButtonAction('Unprocess', ({ dialogs, onReloadRecord, showLoading }) => {
              dialogs
                .confirm({
                  title: 'Unprocess Rebate',
                  description: 'This will delete any credits and/or checks. Are you sure?',
                })
                .then(() => {
                  showLoading(axios.post(`/api/rebates/${values.id}/unprocess`)).then(() => {
                    onReloadRecord();
                  });
                });
            }).withIcon(Undo)
          : new ButtonAction('Process', ({ dialogs, onReloadRecord }) => {
              dialogs
                .prompt({
                  title: 'Process Rebate',
                  description: 'This will automatically credit a check or a credit.',
                  fields: [FieldFactory.boolean('send_email', 'Send Email to Customer')],
                  schema: z.object({
                    send_email: z.boolean().default(false),
                  }),
                  onSubmit: (v) => axios.post(`/api/rebates/${values.id}/process`, v),
                })
                .then(() => {
                  onReloadRecord();
                });
            }).withIcon(CheckCircle),
      ];
    })
    .withDefaultSort('-id')
    .withDefaultFilters({
      bucket: 'unprocessed',
    })
    .withFilters([
      FieldFactory.radio('bucket', {
        to_process: 'To Process',
        unprocessed: 'Unprocessed',
        processed: 'Processed',
        all: 'All',
      }).asQuickFilter(),
    ])
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.timestamp('created_at').withLabel('Date').sortable().filterable(),
      FieldFactory.belongsTo('order', 'orders').sortable('order_id').filterable('filter[order_id]'),
      FieldFactory.status(
        'status',
        {
          processed: 'green',
          unprocessed: 'gray',
        },
        {
          processed: 'Processed',
          unprocessed: 'Not Processed',
        },
      ),
      FieldFactory.curr('amount').sortable().setAggregatable(true),
      FieldFactory.select('calculation_method', {
        fulfillment: 'Fulfillment',
        fundraise: 'Fundraise',
      })
        .sortable()
        .filterable(),
    ])
    .withFields([
      FieldFactory.belongsTo('order', 'orders')
        .withRequestParams({
          'filter[balance][lt]': 0,
        })
        .withHelp('Must have a negative balance'),
      FieldFactory.select('calculation_method', {
        fulfillment: 'Fulfillment',
        fundraise: 'Fundraise',
      }),
    ])
    .editUsing(RebatePage);
}
