import { CloudDownload, PictureAsPdf } from '@mui/icons-material';
import { Card, CardHeader, CardMedia } from '@mui/material';
import filter from 'lodash/filter';
import moment from 'moment';
import { Action, AnchorAction } from '@/classes';
import ImageCarousel from '@/components/Designs/ImageCarousel';
import SubMenu from '@/components/Shared/SubMenu';
import { Proof } from '@/types';
import { maybeAddQueryParams } from '@/utils/query';

const getExtension = (url: string, fallback = '') => url.match(/\.([a-z]{2,5})$/i)?.[1] || fallback;

const getProofPdfUrl = (proof: Proof, dl = false): string => {
  const url = proof.pdf;
  const params: Record<string, string> = {};

  if (!url) {
    return '';
  }

  if (proof.file_name) {
    const ext = getExtension(url, 'pdf');
    params.name = `${proof.file_name.replace(/\.([a-z]{2,5})$/i, '')}.${ext}`;
  }

  if (dl) {
    params.dl = '1';
  }

  const queryParams = new URLSearchParams(params);
  return `${url}?${queryParams.toString()}`;
};

export default function ProofCard({ proof, actions = [] }: { proof: Proof; actions?: Action[] }) {
  return (
    <Card>
      <CardHeader
        action={
          <SubMenu
            items={
              filter([
                proof.pdf &&
                  new AnchorAction('View PDF', getProofPdfUrl(proof, false)).withIcon(PictureAsPdf),
                proof.pdf &&
                  new AnchorAction('Download PDF', getProofPdfUrl(proof, true)).withIcon(
                    CloudDownload,
                  ),
                ...actions,
              ]) as Action[]
            }
          />
        }
        title={proof.file_name}
        titleTypographyProps={{ variant: 'subtitle2', sx: { wordBreak: 'break-word' } }}
        subheader={moment(proof.created_at).format('lll')}
        subheaderTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
      />
      <ImageCarousel
        children={proof.images}
        renderChild={(image: string) => {
          return (
            <CardMedia
              image={maybeAddQueryParams(image, { w: 800 })}
              title={proof.file_name}
              style={{ width: '100%', aspectRatio: '8.5 / 11' }}
              component="a"
              href={image}
              target="_blank"
            />
          );
        }}
      />
    </Card>
  );
}
