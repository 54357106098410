import LoadingButton from '@mui/lab/LoadingButton';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios, { AxiosError } from 'axios';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import sumBy from 'lodash/sumBy';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FieldFactory, StackLayout } from '@/classes';
import EditFormFields from '@/components/Form/EditFormFields';
import Form from '@/components/Form/Form';
import FormErrors from '@/components/Form/FormErrors';
import ChooseSavedCard from '@/components/Payments/ChooseSavedCard';
import { Payment } from '@/types';
import curr from '@/utils/curr';

export interface MakePaymentPayload {
  payment_method: string | null;
  customer: { id: number; name: string };
  amount: number;
  email: string | null;
  orders: { id: number; balance: number | null }[];
}

export default function PaymentForm() {
  const form = useForm<MakePaymentPayload>();
  const navigate = useNavigate();
  const values = form.watch();
  const stripe = useStripe();
  const elements = useElements();

  const orderTotal = sumBy(values.orders, (o) => Number(o.balance));

  const onSubmit = async (values: MakePaymentPayload) => {
    let paymentMethodString = values.payment_method;

    if (paymentMethodString === 'new') {
      if (!elements || !stripe) {
        return null;
      }

      const card = elements.getElement(CardElement);
      if (!card) {
        throw new Error('Unable to get card element');
      }
      const res = await stripe.createToken(card, {});
      if (res.error) {
        throw new Error(res.error.message);
      }
      paymentMethodString = res.token.id;
    }

    try {
      const { data } = await axios.post<Payment>(`/api/customers/${values.customer.id}/charge`, {
        amount: values.amount,
        orders: values.orders.map((o) => o.id),
        email: values.email,
        payment_method: paymentMethodString,
      });
      navigate(`/payments/${data.id}`);
    } catch (e) {
      if (e instanceof AxiosError) {
        alert(e.response?.data.message);
      } else if (e instanceof Error) {
        alert(e.message);
      }
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit} style={{ maxWidth: 600 }}>
      <EditFormFields
        defaultLayout={StackLayout}
        fields={[
          FieldFactory.belongsTo('customer', 'customers').withLabel(
            'Which customer is this payment for?',
          ),
          FieldFactory.curr('amount')
            .withLabel('How much would you like to charge?')
            .withHelp(orderTotal ? `Order Total Balance: ${curr(orderTotal)}` : ''),
          FieldFactory.hasMany('orders', 'orders').with({
            label: 'Which orders would you like to apply this payment to?',
            requestParams: pickBy({
              has_balance: 1,
              'filter[customer_id]': get(values, 'customer.id'),
            }),
          }),
          FieldFactory.email('email').withLabel('Email Receipt To (optional)'),
        ]}
      />
      {values.customer && (
        <ChooseSavedCard
          customerId={values.customer.id}
          selected={values.payment_method}
          setSelected={(s) => form.setValue('payment_method', s)}
          allowNew
        />
      )}

      <FormErrors form={form} />

      <LoadingButton
        loading={form.formState.isSubmitting}
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}
      >
        Charge {values.amount ? curr(values.amount) : '?'}
      </LoadingButton>
    </Form>
  );
}
