import { useMemo, useState } from 'react';
import { AccountBalance, AddCircle, Close, CreditCard, Search } from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid2 as Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import axios from 'axios';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { FieldFactory } from '@/classes';
import PaginatedTable from '@/components/DataTable/PaginatedTable';
import AmountToApplyField from '@/components/Payments/AmountToApplyField';
import ChargeCustomerForm from '@/components/Payments/ChargeCustomerForm';
import CommissionHit from '@/components/Payments/CommissionHit';
import CommissionHitIcon from '@/components/Payments/CommissionHitIcon';
import StripeProvider from '@/components/Payments/StripeProvider';
import Can from '@/components/Permissions/Can';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import ConditionallyRenderField from '@/components/Shared/ConditionallyRenderField';
import StatusChip from '@/components/Shared/StatusChip';
import StatCard from '@/components/Stats/StatCard';
import Text from '@/components/Text/Text';
import TextButton from '@/components/Text/TextButton';
import TextLink from '@/components/Text/TextLink';
import { APPLIED_STATUS_COLORS, APPLIED_STATUS_LABELS } from '@/constants';
import { useDialogs, useShowLoading } from '@/contexts/DialogContext';
import { useHasPermission } from '@/hooks/permissions';
import {
  genericModelReferenceSchema,
  OrderPayment,
  Paginated,
  PartiallyRequired,
  Payment,
  PaymentApplyPayload,
  paymentApplyPayloadSchema,
  PaymentDeductionPayload,
  paymentDeductionPayloadSchema,
} from '@/types';
import { makeResourceQueryKey, useOnReloadRecord, useRecord } from '@/utils/genericResource';
import getBusinessName from '@/utils/getBusinessName';
import numString from '@/utils/numString';
import SuggestedPayments from '../Payments/SuggestedPayments';

const columnHelper = createColumnHelper<PartiallyRequired<OrderPayment, 'payment'>>();

export default function OrderPayments() {
  const {
    id: orderId,
    business,
    total,
    paid,
    balance,
    dueDate,
    daysPastDue,
    cancelledAt,
  } = useRecord('orders', true);
  const onReload = useOnReloadRecord('orders');
  const [charging, setCharging] = useState(false);
  const { confirm, prompt } = useDialogs();
  const hasPermission = useHasPermission();
  const showLoading = useShowLoading();
  const dialogs = useDialogs();

  const { data, isLoading } = useQuery(
    makeResourceQueryKey('orders', orderId, 'orderPayments'),
    () =>
      axios
        .get<
          Paginated<PartiallyRequired<OrderPayment, 'payment'>>
        >(`/api/order-payments?order_id=${orderId}&count=5000`)
        .then(({ data }) => data),
  );

  const orderPayments = data?.data || [];
  const totalCount = data?.meta.total || 0;

  const applyPayment = (payload: PaymentApplyPayload) =>
    axios.post('/api/order-payments', payload).then(({ data }) => data);

  const onApplyPayment = (payment: Payment) => {
    const link = (
      <TextButton
        onClick={() => window.open(`/payments/${payment.id}`, '_blank')}
        disabled={!hasPermission('write:payments')}
      >
        {payment.label}
      </TextButton>
    );
    prompt({
      title: `Apply ${payment.label}`,
      description: <div>How much of {link} should be applied?</div>,
      fields: [
        new AmountToApplyField('amount', payment.amount_to_apply, balance),
        payment.payment_type === 'deduction' &&
          FieldFactory.custom('commission_hit', CommissionHit).withPermission(
            'write:order_commissions',
          ),
      ],
      schema: paymentApplyPayloadSchema.pick({
        amount: true,
        commission_hit: true,
      }),
      initialValues: {
        amount: payment.amount_to_apply,
      },
      onSubmit: (v) =>
        applyPayment({
          payment_id: payment.id,
          order_id: orderId,
          ...v,
        }),
    }).then(() => {
      onReload();
    });
  };

  const onFindPayment = () => {
    prompt({
      title: 'Find Payment To Apply',
      fields: [
        FieldFactory.belongsTo('payment', 'payments').withRequestParams({
          has_balance: 1,
        }),
        FieldFactory.curr('amount'),
        new ConditionallyRenderField(
          'commission_hit',
          FieldFactory.custom('commission_hit', CommissionHit),
          (v) => get(v, 'payment.payment_type') === 'deduction',
        ).with({
          requiresPermission: 'write:order_commissions',
        }),
      ],
      schema: paymentApplyPayloadSchema
        .pick({
          amount: true,
          commission_hit: true,
        })
        .extend({
          payment: genericModelReferenceSchema,
        }),
      initialValues: {
        amount: balance,
      },
      onSubmit: (v) =>
        applyPayment({
          ...v,
          payment_id: v.payment.id,
          order_id: orderId,
        }),
    }).then(() => {
      onReload();
    });
  };

  const createDeduction = () => {
    prompt({
      title: 'Create Payout Deduction',
      description: (
        <div>
          <span>This amount will be deducted from</span>
          <b> {`${getBusinessName(business)}'s`} </b>
          <span>next payout, and will be applied to this order.</span>
        </div>
      ),
      fields: [
        FieldFactory.curr('amount').withSize('medium'),
        FieldFactory.custom('commission_hit', CommissionHit).withPermission(
          'write:order_commissions',
        ),
      ],
      schema: paymentDeductionPayloadSchema.omit({ order_id: true }),
      initialValues: {
        amount: balance,
      },
      onSubmit: (v) => {
        const payload: PaymentDeductionPayload = { ...v, order_id: orderId };
        return axios.post('/api/order-payments/deduction', payload);
      },
    }).then(() => {
      onReload();
    });
  };

  const onUnapply = (op: OrderPayment) => {
    confirm({
      title: 'Unapply Payment',
      description: 'Are you sure you want to unapply this payment from the order?',
      color: 'error',
    }).then(() => {
      axios.delete(`/api/order-payments/${op.id}`).then(() => {
        onReload();
      });
    });
  };

  const onAchInstructions = async () => {
    const { data: instructions } = await showLoading(
      axios.get<Record<string, string>>(`/api/orders/${orderId}/ach-instructions`),
    );

    dialogs.alert({
      title: 'ACH Instructions',
      description: (
        <div>
          <Table size="small" sx={{ my: 2 }}>
            {Object.entries(instructions).map(([key, value]) => (
              <TableRow>
                <TableCell variant="head">{key}</TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            ))}
          </Table>
        </div>
      ),
    });
  };

  const hasCardholder = orderPayments && orderPayments.some(({ payment }) => payment.card_name);

  const columns = useMemo(
    () => [
      columnHelper.accessor('payment.payment_date', {
        header: 'Date',
        cell: ({ getValue }) => moment(getValue()).format('L'),
      }),
      columnHelper.accessor('payment.payment_number', {
        header: 'Payment',
        cell: ({
          row: {
            original: { payment },
          },
        }) => (
          <>
            <TextLink disabled={!hasPermission('write:payments')} to={`/payments/${payment.id}`}>
              {payment.label}
            </TextLink>
            {hasCardholder && (
              <Typography variant="body2" color="textSecondary" style={{ marginTop: 2 }}>
                {payment.customer?.name}
              </Typography>
            )}
          </>
        ),
      }),
      hasCardholder
        ? columnHelper.accessor((op) => op.payment.card_name || '', {
            id: 'payment_card_name',
            header: 'Cardholder',
            cell: ({
              getValue,
              row: {
                original: { payment },
              },
            }) => (
              <Text
                primary={getValue()}
                secondary={
                  payment.card_last_four &&
                  `${startCase(payment.card_brand || '')} ****${payment.card_last_four}`
                }
              />
            ),
          })
        : columnHelper.accessor('payment.customer.name', {
            header: 'Customer',
          }),
      columnHelper.accessor('amount_applied', {
        header: 'Amount Applied',
        cell: ({ getValue }) => <ColoredCurrency amount={getValue()} />,
      }),
      columnHelper.accessor('payment.applied_status', {
        header: 'Status',
        cell: ({ row, getValue }) => (
          <>
            <CommissionHitIcon hit={row.original.commission_hit} />
            <StatusChip
              size="small"
              status={getValue()}
              colors={APPLIED_STATUS_COLORS}
              labels={APPLIED_STATUS_LABELS}
            />
          </>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        header: '',
        cell: ({ row }) => (
          <IconButton onClick={() => onUnapply(row.original)} size="small">
            <Close fontSize="small" />
          </IconButton>
        ),
      }),
    ],
    [hasCardholder],
  );

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 9 }}>
        <div>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: 3 }}>
              <StatCard number={total} title="Order Total" format="curr" />
            </Grid>
            <Grid size={{ xs: 12, md: 3 }}>
              <StatCard number={paid} title="Paid" format="curr" />
            </Grid>
            <Grid size={{ xs: 12, md: 3 }}>
              <StatCard number={balance} title="Balance" format="curr" />
            </Grid>
            {dueDate && (
              <Grid size={{ xs: 12, md: 3 }}>
                <Card>
                  <CardHeader
                    title="Due Date"
                    titleTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }}
                  />
                  <CardContent style={{ paddingTop: 0 }}>
                    <Box fontSize="2rem" fontWeight={500}>
                      {moment(dueDate).format('l')}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {daysPastDue > 0 && balance > 0 && (
              <Grid size={12}>
                <Alert severity="error">The balance on this order is past due.</Alert>
              </Grid>
            )}

            <Grid size={12}>
              <Card>
                <CardHeader
                  title="Applied Payments"
                  subheader={orderPayments && `${numString(totalCount, 'Payments')} Applied`}
                />
                {isLoading ? (
                  <CardContent>
                    <CircularProgress />
                  </CardContent>
                ) : (
                  <PaginatedTable
                    storageKey={`orderPayments.${hasCardholder}`}
                    rows={orderPayments}
                    columns={columns}
                    enableToolbar={orderPayments.length > 3}
                    initialState={{
                      sorting: [
                        {
                          id: 'payment_number',
                          desc: true,
                        },
                      ],
                    }}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {!cancelledAt && (
        <Can permission="write:order_payments">
          <Grid size={{ xs: 12, md: 3 }}>
            <Stack spacing={2}>
              <Card>
                <CardHeader
                  title="Actions"
                  titleTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }}
                />
                <List>
                  <ListItemButton onClick={() => setCharging(true)}>
                    <ListItemIcon>
                      <CreditCard />
                    </ListItemIcon>
                    <ListItemText primary="Charge Customer" />
                  </ListItemButton>
                  <ListItemButton onClick={onFindPayment}>
                    <ListItemIcon>
                      <Search />
                    </ListItemIcon>
                    <ListItemText primary="Find Existing Payment to Apply" />
                  </ListItemButton>
                  <ListItemButton onClick={createDeduction}>
                    <ListItemIcon>
                      <AddCircle />
                    </ListItemIcon>
                    <ListItemText primary="Create Payout Deduction" />
                  </ListItemButton>
                  {balance > 0 && (
                    <ListItemButton onClick={onAchInstructions}>
                      <ListItemIcon>
                        <AccountBalance />
                      </ListItemIcon>
                      <ListItemText primary="Get ACH Instructions" />
                    </ListItemButton>
                  )}
                </List>
              </Card>
              <Card>
                <SuggestedPayments orderId={orderId} onApplyPayment={onApplyPayment} />
              </Card>
            </Stack>
          </Grid>
        </Can>
      )}
      <StripeProvider>
        <ClosableDrawer title="Charge Customer" open={charging} onClose={() => setCharging(false)}>
          {charging && (
            <ChargeCustomerForm
              onSuccess={() => {
                onReload();
                setCharging(false);
              }}
            />
          )}
        </ClosableDrawer>
      </StripeProvider>
    </Grid>
  );
}
