import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  TextField,
} from '@mui/material';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import axios from 'axios';
import groupBy from 'lodash/groupBy';
import { useSearchParams } from 'react-router-dom';
import { useGetAddressesForOrder } from '@/api/addresses';
import { useGetShippableItems } from '@/api/orderItems';
import AddressTitle from '@/components/Addresses/AddressTitle';
import PaginatedTable from '@/components/DataTable/PaginatedTable';
import { EMPTY_ARRAY } from '@/constants';
import { useShowLoading } from '@/contexts/DialogContext';
import useScanDetection from '@/hooks/useScanDetection';
import { Address, OrderBox, OrderItem, ShipmentItemPayload } from '@/types';
import { useOnReloadRecord } from '@/utils/genericResource';
import { buildIncrementId } from '@/utils/notes';
import {
  canShipOrderItem,
  getIdFromIncrementId,
  getQtyInputProps,
  orderItemToShipmentItem,
} from '@/utils/shipping';

const columnHelper = createColumnHelper<OrderItem>();

const boxItemToShippableItem = (i: OrderBox['items'][number]): ShipmentItemPayload => ({
  order_item_id: i.order_item.id,
  qty_shipped: i.qty,
});

export default function OrderShipping({
  orderId,
  getActions,
}: {
  orderId: number;
  getActions: (p: {
    selected: ShipmentItemPayload[];
    address: Address;
    onSuccess: () => void;
  }) => React.ReactNode;
}) {
  const showLoading = useShowLoading();
  const reloadRecord = useOnReloadRecord();
  const [selected, setSelected] = useState<ShipmentItemPayload[]>([]);
  const boxesRead = useRef<number[]>([]);
  const [searchParams] = useSearchParams();

  const boxId = searchParams.get('box');
  useEffect(() => {
    boxesRead.current = [];
    setSelected([]);

    if (boxId) {
      axios.get<OrderBox>(`/api/order-boxes/${boxId}`).then(({ data }) => {
        boxesRead.current = [data.id];
        setSelected(data.items.map(boxItemToShippableItem));
      });
    }
  }, [orderId, boxId]);

  useEffect(() => {
    if (selected.length === 0) {
      boxesRead.current = [];
    }
  }, [selected]);

  useScanDetection({
    onComplete: (data) => {
      if (data.startsWith('BOX-')) {
        showLoading(axios.get<OrderBox>(`/api/order-boxes/${getIdFromIncrementId(data)}`)).then(
          ({ data }) => {
            if (!boxesRead.current.includes(data.id)) {
              boxesRead.current.push(data.id);
              setSelected((prev) => {
                const newItems = [...prev, ...data.items.map(boxItemToShippableItem)];
                return Object.values(groupBy(newItems, 'order_item_id')).map((items) =>
                  items.reduce((acc, item) => ({
                    ...item,
                    qty_shipped: acc.qty_shipped + item.qty_shipped,
                  })),
                );
              });
            }
          },
        );
      }
    },
  });

  const addressesRequest = useGetAddressesForOrder('orders', orderId);
  const address = addressesRequest.data?.[0];
  const { data: items = EMPTY_ARRAY, isFetching, refetch } = useGetShippableItems(orderId);

  const onSuccess = () => {
    reloadRecord();
    setSelected([]);
  };

  const columns = useMemo(() => {
    const shippableItems = items.filter(canShipOrderItem);

    const handleCheck = (item: OrderItem) => (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelected((prev) => [...prev, orderItemToShipmentItem(item)]);
      } else {
        setSelected((prev) => prev.filter((s) => s.order_item_id !== item.id));
      }
    };

    const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelected(shippableItems.map(orderItemToShipmentItem));
      } else {
        setSelected([]);
      }
    };

    return [
      columnHelper.display({
        id: 'checkbox',
        enableSorting: false,
        enableHiding: false,
        header: () =>
          shippableItems.length > 0 ? (
            <Checkbox
              onChange={(e) => handleCheckAll(e)}
              checked={selected.length === shippableItems.length}
              indeterminate={selected.length > 0 && selected.length < shippableItems.length}
            />
          ) : null,
        cell: ({ row }) =>
          canShipOrderItem(row.original) && (
            <Checkbox
              onChange={handleCheck(row.original)}
              checked={selected.some((i) => i.order_item_id === row.original.id)}
            />
          ),
      }),
      columnHelper.accessor('number', { header: 'Number' }),
      columnHelper.accessor('color', { header: 'Color' }),
      columnHelper.accessor('size', { header: 'Size' }),
      columnHelper.accessor('description', { header: 'Description' }),
      columnHelper.accessor('design_layout_id', {
        header: 'Design Layout',
        cell: ({ getValue }) => {
          const layoutId = getValue();
          return layoutId ? buildIncrementId('LAY', layoutId) : 'Blank';
        },
      }),
      columnHelper.accessor('qty', {
        header: 'Qty Req',
        meta: {
          aggregatable: true,
        },
      }),
      columnHelper.accessor('qty_scrapped', {
        header: 'Qty Scrap',
        meta: {
          aggregatable: true,
        },
      }),
      columnHelper.accessor('qty_shipped', {
        header: 'Qty Shp',
        meta: {
          aggregatable: true,
        },
      }),
      columnHelper.display({
        id: 'input',
        header: 'Qty to Ship',
        enableSorting: false,
        enableHiding: false,
        cell: ({ row }: CellContext<OrderItem, any>) => {
          const checked = selected.find((i) => i.order_item_id === row.original.id);
          return checked ? (
            <TextField
              size="small"
              sx={{ width: 80, my: -1 }}
              type="number"
              defaultValue={checked.qty_shipped}
              slotProps={{
                htmlInput: getQtyInputProps(row.original.qty_to_ship, row.original.qty),
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSelected((prev) =>
                  prev.map((item) => {
                    if (item.order_item_id === row.original.id) {
                      return {
                        ...item,
                        qty_shipped: Number(e.target.value),
                      };
                    }
                    return item;
                  }),
                );
              }}
            />
          ) : (
            <div style={{ width: 100 }}>&nbsp;</div>
          );
        },
      }),
    ];
  }, [items, setSelected, selected.length]);

  return (
    <Card>
      <CardHeader
        title="Items to Ship"
        action={
          selected.length > 0 && address ? getActions({ selected, address, onSuccess }) : null
        }
      />

      <CardContent>
        {addressesRequest.isLoading ? (
          <CircularProgress />
        ) : address ? (
          <AddressTitle address={address} />
        ) : (
          <Alert severity="error">This order doesn't have a shipping address</Alert>
        )}
      </CardContent>

      <PaginatedTable
        storageKey="itemsToShip"
        refetch={refetch}
        isFetching={isFetching}
        rows={items}
        columns={columns}
        initialState={{
          sums: {
            qty: true,
            qty_scrapped: true,
            qty_shipped: true,
          },
          columnVisibility: {
            qty_scrapped: items.some((i) => i.qty_scrapped > 0),
          },
        }}
      />
    </Card>
  );
}
