import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Address, AddressPayload, AddressWithKits } from '@/types';
import { makeResourceQueryKey } from '@/utils/genericResource';

export function useGetAddressesForOrder(resource: 'orders' | 'quotes', orderId: number) {
  return useQuery(makeResourceQueryKey(resource, orderId, 'addresses'), () =>
    axios
      .get<{ data: AddressWithKits[] }>(`/api/${resource}/${orderId}/addresses`)
      .then(({ data }) => data.data),
  );
}

export function useImportAddressesForOrder(resource: 'orders' | 'quotes', orderId: number) {
  const queryClient = useQueryClient();
  return useMutation((v: { file: string }) =>
    axios.post(`/api/${resource}/${orderId}/addresses/import`, v).then(() => {
      queryClient.invalidateQueries(makeResourceQueryKey(resource, orderId, 'addresses'));
    }),
  );
}

export function useCreateAddressForOrder(resource: 'orders' | 'quotes', orderId: number) {
  const queryClient = useQueryClient();

  return useMutation((address: AddressPayload | { id: number; method: string }) =>
    axios.post<Address>(`/api/${resource}/${orderId}/addresses`, address).then(({ data }) => {
      queryClient.setQueryData<Address[]>(
        makeResourceQueryKey(resource, orderId, 'addresses'),
        (prev) => {
          if (!prev) return prev;
          return [...prev, data];
        },
      );
      return data;
    }),
  );
}

export function useUpdateAddressForOrder(resource: 'orders' | 'quotes', orderId: number) {
  const queryClient = useQueryClient();

  return useMutation(({ id, ...address }: AddressPayload & { id: number }) =>
    axios.put<Address>(`/api/${resource}/${orderId}/addresses/${id}`, address).then(({ data }) => {
      queryClient.setQueryData<Address[]>(
        makeResourceQueryKey(resource, orderId, 'addresses'),
        (prev) => {
          if (!prev) return prev;
          return prev.map((p) => (p.id === data.id ? data : p));
        },
      );
      return data;
    }),
  );
}

export function useDeleteAddressForOrder(resource: 'orders' | 'quotes', orderId: number) {
  const queryClient = useQueryClient();

  return useMutation((addressId: number) =>
    axios.delete(`/api/${resource}/${orderId}/addresses/${addressId}`).then(() => {
      queryClient.setQueryData<Address[]>(
        makeResourceQueryKey(resource, orderId, 'addresses'),
        (prev) => {
          if (!prev) return prev;
          return prev.filter((p) => p.id !== addressId);
        },
      );
      queryClient.invalidateQueries(['kitProductionOrders']);
    }),
  );
}

export const fetchAddresses = async (
  resource: 'orders' | 'quotes',
  orderId: number,
): Promise<AddressWithKits[]> => {
  const response = await axios.get<{ data: AddressWithKits[] }>(
    `/api/${resource}/${orderId}/addresses`,
  );
  return response.data.data;
};
