import { ReactElement } from 'react';
import { ZodSchema } from 'zod';
import { FieldProps, OptionsProp } from '@/classes/types';
import { ResourceName } from '@/resources/types';
import Field from './Field';
import AutocompleteField from './Fields/AutocompleteField';
import ColorField from './Fields/ColorField';
import CurrencyField from './Fields/CurrencyField';
import CustomField from './Fields/CustomField';
import DateField from './Fields/DateField';
import DateRangeField from './Fields/DateRangeField';
import FileField from './Fields/FileField';
import ImagesField from './Fields/ImagesField';
import ListField from './Fields/ListField';
import OptInField from './Fields/OptInField';
import PercentField from './Fields/PercentField';
import PhoneField from './Fields/PhoneField';
import QtyField from './Fields/QtyField';
import ReadField from './Fields/ReadField';
import RelationField from './Fields/RelationField';
import RelationTableField from './Fields/RelationTableField';
import SelectField from './Fields/SelectField';
import StatusField from './Fields/StatusField';
import TableField from './Fields/TableField';
import TextAreaField from './Fields/TextAreaField';
import TextInputField from './Fields/TextInputField';
import ToggleField from './Fields/ToggleField';
import UseDefaultField from './Fields/UseDefaultField';

const FieldFactory = {
  read(name: string) {
    return new ReadField(name);
  },

  text(name: string) {
    return new TextInputField(name);
  },

  number(name: string) {
    return new TextInputField(name, 'number');
  },

  email(name: string) {
    return new TextInputField(name, 'email');
  },

  url(name: string) {
    return new TextInputField(name, 'url');
  },

  textarea(name: string) {
    return new TextAreaField(name);
  },

  custom(name: string, component: (p: FieldProps) => ReactElement | null) {
    return new CustomField(name, component);
  },

  boolean(name: string, label?: string) {
    const field = new ToggleField(name);
    if (label) {
      field.label = label;
    }
    return field;
  },

  select(name: string, options: OptionsProp) {
    return new SelectField(name, options);
  },

  radio(name: string, options: OptionsProp) {
    const field = new SelectField(name, options);
    field.isRadio = true;
    return field;
  },

  autocomplete(name: string, options: string[]) {
    return new AutocompleteField(name, options);
  },

  belongsTo<K extends ResourceName>(name: string, resource: K) {
    return new RelationField<K>(name, resource);
  },

  hasMany<K extends ResourceName>(name: string, resource: K) {
    const field = new RelationField<K>(name, resource);
    field.hasMany = true;
    return field;
  },

  hasManyCRUD<K extends ResourceName>(
    name: string,
    resource: K,
    getApiEndpointFunc?: RelationTableField<K>['getApiEndpointFunc'],
  ) {
    return new RelationTableField<K>(name, resource, getApiEndpointFunc);
  },

  curr(name: string) {
    return new CurrencyField(name);
  },

  status(name: string, colors = {}, labels = {}) {
    return new StatusField(name, colors, labels);
  },

  percent(name: string) {
    return new PercentField(name);
  },

  phone(name: string) {
    return new PhoneField(name);
  },

  date(name: string) {
    return new DateField(name);
  },

  timestamp(name: string) {
    const field = new DateField(name);
    field.format = 'lll';
    return field;
  },

  daterange(name: string) {
    return new DateRangeField(name);
  },

  color(name: string) {
    return new ColorField(name);
  },

  qty(name: string) {
    return new QtyField(name);
  },

  file(name: string) {
    return new FileField(name);
  },

  image(name: string) {
    return this.file(name).with({ image: true });
  },

  images(name: string) {
    return new ImagesField(name);
  },

  useDefault(name: string, subField: Field) {
    return new UseDefaultField(name, subField);
  },

  optIn(name: string, subField: Field) {
    return new OptInField(name, subField);
  },

  list(name: string, children: Field[] = []) {
    return new ListField(name, children);
  },

  table(name: string, children: Field[] = [], schema: ZodSchema<any>) {
    return new TableField(name, children, schema);
  },
};

export default FieldFactory;
