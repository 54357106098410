import { ListItemButton, ListItemText } from '@mui/material';
import axios from 'axios';
import pick from 'lodash/pick';
import { z } from 'zod';
import { FieldFactory, TextInputField } from '@/classes';
import { useConfig } from '@/contexts/AppConfigContext';
import { useDialogs, useToast } from '@/contexts/DialogContext';

export default function UserActions() {
  const { prompt } = useDialogs();
  const { user } = useConfig();
  const toast = useToast();

  const handleUpdate = () => {
    prompt({
      title: 'Edit Profile',
      fields: [FieldFactory.image('avatar')],
      schema: z.object({
        avatar: z.string().nullish(),
      }),
      initialValues: pick(user, ['avatar']),
      onSubmit: (v) => axios.put('/api/me', v),
    }).then(() => {
      window.location.reload();
    });
  };

  const handlePasswordUpdate = () => {
    prompt({
      title: 'Update Password',
      fields: [
        new TextInputField('current_password', 'password').setRequired(),
        new TextInputField('password', 'password').setRequired(),
        new TextInputField('password_confirmation', 'password').setRequired(),
      ],
      schema: z.object({
        current_password: z.string().min(8),
        password: z.string().min(8),
        password_confirmation: z.string().min(8),
      }),
      onSubmit: (v) => axios.post('/api/change-password', v),
    }).then(() => {
      toast('Password updated');
    });
  };

  return (
    <>
      <ListItemButton onClick={handleUpdate}>
        <ListItemText>Edit Profile</ListItemText>
      </ListItemButton>
      <ListItemButton onClick={handlePasswordUpdate}>
        <ListItemText>Update Password</ListItemText>
      </ListItemButton>
    </>
  );
}
