import { ReactNode } from 'react';
import { useOrder } from '@/contexts/OrderContext';

export default function OrderEmail({ children }: { children: ReactNode }) {
  const order = useOrder();

  if (!children || !order) {
    return <span>{children}</span>;
  }

  const query = new URLSearchParams({
    subject: `Your ${order.business.name} Order #${order.increment_id}`,
    body: `Hi ${order.contact_first_name || 'there'},\n\nYou can view your order here: ${
      order.portal_url
    }`,
  });
  const mailTo = `mailto:${children}?${query.toString()}`;

  return (
    <a
      href={mailTo}
      onClick={(e) => e.stopPropagation()}
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      {children}
    </a>
  );
}
