import { ArrowForward } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TotalLabel, TotalValue } from '@/components/Shared/Label';
import StatusChip from '@/components/Shared/StatusChip';
import { TRANSACTION_PAID_COLORS, TRANSACTION_PAID_LABELS } from '@/constants';
import curr from '@/utils/curr';
import { useRecord } from '@/utils/genericResource';

export default function BillPaymentsCard() {
  const { balance, total, paid, paidStatus, payments } = useRecord('transactions', true);

  return (
    <Card>
      <CardHeader title="Bill Payments" />
      <CardContent sx={{ py: 0 }}>
        <StatusChip
          sx={{ width: '100%' }}
          status={paidStatus}
          colors={TRANSACTION_PAID_COLORS}
          labels={TRANSACTION_PAID_LABELS}
        />
      </CardContent>

      <List>
        {payments.map((p) => (
          <ListItemButton key={p.id} component={Link} to={`/transactions/${p.transaction_id}`}>
            <ListItemText
              primary={`Bill Payment #${p.transaction_id}`}
              secondary={moment(p.created_at).format('llll')}
            />
            <ListItemSecondaryAction>
              <ArrowForward sx={{ color: 'text.secondary' }} />
            </ListItemSecondaryAction>
          </ListItemButton>
        ))}
      </List>

      <CardContent>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <TotalLabel>Bill Total</TotalLabel>
              <TotalValue>{curr(total)}</TotalValue>
            </tr>
            <tr>
              <TotalLabel>Paid</TotalLabel>
              <TotalValue>{curr(paid || 0)}</TotalValue>
            </tr>
            <tr>
              <TotalLabel>Balance</TotalLabel>
              <TotalValue>{curr(balance)}</TotalValue>
            </tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}
