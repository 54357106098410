import { SyntheticEvent, useState } from 'react';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  CardContent,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import TextButton from '@/components/Text/TextButton';
import { PurchaseOrderItem, ReceivingItem } from '@/types';

export default function PurchaseOrderItemQtyReceived({ item }: { item: PurchaseOrderItem }) {
  const [anchorEl, setAnchorEl] = useState<Element>();

  const { data: receivingItems } = useQuery(
    ['receivingItemsForPoItem', item.id],
    () =>
      axios
        .get<{
          receiving_items: ReceivingItem[];
        }>(`/api/purchase-order-items/${item.id}?with=receiving_items`)
        .then(({ data }) => data.receiving_items),
    {
      enabled: Boolean(anchorEl),
    },
  );

  const onOpenPopover = (e: SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(undefined);
  };

  return (
    <span style={{ marginRight: 4 }}>
      <TextButton onClick={onOpenPopover} style={{ textAlign: 'left' }}>
        {item.qty_received}
      </TextButton>

      <Popover
        id="receiving-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {receivingItems && receivingItems.length > 0 ? (
          <List>
            {receivingItems.map((ri) => (
              <ListItem key={ri.id}>
                <ListItemText
                  primary={`Receiving #${ri.receiving_id}`}
                  secondary={`${ri.qty_received} received ${moment(ri.created_at).format('lll')}`}
                />
              </ListItem>
            ))}
          </List>
        ) : receivingItems ? (
          <CardContent>
            <Typography variant="body2" sx={{ mt: 2 }}>
              This item has no receivings.
            </Typography>
          </CardContent>
        ) : (
          <CardContent>
            <CircularProgress />
          </CardContent>
        )}
      </Popover>
    </span>
  );
}
