import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormLabel,
  Grid2 as Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FieldFactory } from '@/classes';
import GenericAddressField from '@/components/Addresses/GenericAddressField';
import FormField from '@/components/Form/FormField';
import EventableCard from '@/components/Notes/EventableCard';
import OrderSummaryCard from '@/components/Orders/OrderSummaryCard';
import Paper from '@/components/Shared/Paper';
import StatusChip from '@/components/Shared/StatusChip';
import curr from '@/utils/curr';
import { useRecord } from '@/utils/genericResource';

export default function RebatePage() {
  const rebate = useRecord('rebates');
  const { check, business_check: businessCheck, credit } = rebate;

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 8 }}>
        <Stack spacing={2}>
          <Paper>
            <Grid container spacing={3}>
              <Grid size={{ xs: 12, md: 4 }}>
                <FormLabel>Date</FormLabel>
                <Box mt={1}>{moment(rebate.created_at).format('l')}</Box>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <FormLabel>Calculation Method</FormLabel>
                <Box mt={1}>{startCase(rebate.calculation_method)}</Box>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <FormLabel>Status</FormLabel>
                <Box mt={1}>
                  <StatusChip
                    status={rebate.status}
                    colors={{
                      processed: 'green',
                      unprocessed: 'gray',
                    }}
                    labels={{
                      processed: 'Processed',
                      unprocessed: 'Not Processed',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
          <Paper title="Distribution">
            <Grid container spacing={4}>
              <Grid size={{ xs: 12, md: 6 }}>
                <FormField field={FieldFactory.curr('amount')} />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <FormField
                  field={FieldFactory.curr('credit_amount').withHelp(
                    'Difference between this and rebate amount will be cut as a check',
                  )}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <FormField field={FieldFactory.belongsTo('vendor', 'vendors')} />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <FormField field={new GenericAddressField('address')} />
              </Grid>
            </Grid>
          </Paper>
          <Card>
            <CardHeader title="Debit" subheader="Used to zero-out negative balance on order." />
            {rebate.debit ? (
              <List>
                <ListItemButton component={Link} to={`/payments/${rebate.debit.id}`}>
                  <ListItemText primary={rebate.debit.label} />
                  <ListItemSecondaryAction>
                    <Chip label={curr(rebate.debit.amount)} />
                  </ListItemSecondaryAction>
                </ListItemButton>
              </List>
            ) : (
              <Alert severity="warning">There is no debit payment for this rebate</Alert>
            )}
          </Card>

          <Card>
            <CardHeader
              title="Credit"
              subheader="How the rebate is given to the customer (check or order credit)."
            />

            {rebate.processed_at ? (
              <List>
                {check && (
                  <ListItemButton component={Link} to={`/transactions/${check.id}`}>
                    <ListItemText primary={check.label} />
                    <ListItemSecondaryAction>
                      <Chip label={curr(check.total)} />
                    </ListItemSecondaryAction>
                  </ListItemButton>
                )}
                {credit && (
                  <ListItemButton key={credit.id} component={Link} to={`/payments/${credit.id}`}>
                    <ListItemText primary={credit.label} />
                    <ListItemSecondaryAction>
                      <Chip label={curr(credit.amount)} />
                    </ListItemSecondaryAction>
                  </ListItemButton>
                )}
                {businessCheck && (
                  <ListItem key={businessCheck.id}>
                    <ListItemText primary={`Check for LOB to Write #${businessCheck.id}`} />
                    <ListItemSecondaryAction>
                      <Chip label={curr(businessCheck.amount)} />
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
            ) : (
              <CardContent>
                <Typography color="textSecondary">
                  Credits/checks will show up once the rebate has been processed
                </Typography>
              </CardContent>
            )}
          </Card>
          <EventableCard resource="rebates" resourceId={rebate.id} />
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <OrderSummaryCard order={rebate.order} />
      </Grid>
    </Grid>
  );
}
