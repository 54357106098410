import { Grid2 as Grid } from '@mui/material';
import { FieldFactory } from '@/classes';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import FormField from '@/components/Form/FormField';

export default function VendorSection({
  isCustom,
  hideCost = false,
  title = 'Vendor & Cost',
}: {
  isCustom: boolean;
  hideCost?: boolean;
  title?: string;
}) {
  return (
    <DrawerFormSection title={title}>
      <Grid container spacing={2}>
        <Grid size={12}>
          <FormField field={FieldFactory.belongsTo('vendor', 'vendors').setDisabled(!isCustom)} />
        </Grid>
        {!hideCost && (
          <Grid size={12}>
            <FormField
              field={FieldFactory.curr('cost').with({
                maxDecimals: 4,
              })}
            />
          </Grid>
        )}
      </Grid>
    </DrawerFormSection>
  );
}
