import Action from '../Action';

export default class AnchorAction extends Action {
  href: string;
  openInNew: boolean;

  constructor(label: string, href: string, openInNew = true) {
    super(label);
    this.href = href;
    this.openInNew = openInNew;
  }
}
