import { ChangeEvent, ReactNode } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio as RadioButton,
  RadioGroup,
  SelectChangeEvent,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MaterialSelect from '@mui/material/Select';
import { UseControllerReturn } from 'react-hook-form';
import { FieldProps, OptionsProp, SelectOption } from '@/classes/types';
import InputWrapper from '@/components/Form/InputWrapper';
import { useRequiresPermissionAndRole } from '@/hooks/permissions';
import wrap from '@/utils/wrap';
import Field from '../Field';

function getValueAttr(value: string | number | boolean | null): string | number {
  return typeof value === 'boolean' || value === null ? String(value) : value;
}

function maybeStringToBoolean(e: SelectChangeEvent<unknown> | ChangeEvent<HTMLInputElement>) {
  let value = e.target.value as string | number | boolean | null | undefined;
  if (value === 'true') {
    value = true;
  } else if (value === 'false') {
    value = false;
  } else if (value === 'null') {
    value = null;
  }
  return value;
}

function Radio({ field, fieldModel, fieldState }: FieldProps<SelectField>) {
  const error = fieldState.invalid && fieldState.error;
  const helperText = fieldModel.getHelperText(fieldState);
  const checkPermission = useRequiresPermissionAndRole();

  return (
    <FormControl error={!!error} component="fieldset" variant="standard">
      {fieldModel.label && <FormLabel component="legend">{fieldModel.label}</FormLabel>}
      <RadioGroup
        row={fieldModel.row}
        aria-label={fieldModel.label}
        name={field.name}
        value={field.value}
        onChange={(e) => {
          field.onChange(maybeStringToBoolean(e));
        }}
      >
        {fieldModel
          .getOptionCollection()
          .filter(checkPermission)
          .map(({ label, value }) => (
            <FormControlLabel
              key={getValueAttr(value)}
              value={getValueAttr(value)}
              control={<RadioButton size="small" color={fieldModel.color || 'primary'} />}
              label={label}
              labelPlacement="end"
            />
          ))}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

function Select({ field, fieldModel, fieldState }: FieldProps<SelectField>) {
  const { multiple, native, includeBlank } = fieldModel;
  const OptionComponent = native ? 'option' : MenuItem;
  const inputValue = multiple ? wrap(field.value).map(getValueAttr) : getValueAttr(field.value);
  const checkPermission = useRequiresPermissionAndRole();

  const options = fieldModel
    .getOptionCollection()
    .filter(checkPermission)
    .map(({ label, value }) => (
      <OptionComponent key={getValueAttr(value)} value={getValueAttr(value)}>
        {label}
      </OptionComponent>
    ));

  if (includeBlank) {
    options.unshift(
      <OptionComponent value={getValueAttr(null)} key={0}>
        {includeBlank === true ? ' -- ' : includeBlank}
      </OptionComponent>,
    );
  }

  return (
    <InputWrapper fieldModel={fieldModel} fieldState={fieldState}>
      <MaterialSelect
        native={native}
        multiple={multiple}
        value={inputValue == null ? null : inputValue}
        onChange={(e) => {
          field.onChange(maybeStringToBoolean(e));
        }}
        label={fieldModel.label}
        margin={fieldModel.margin}
        size={fieldModel.size}
        required={fieldModel.required}
        disabled={fieldModel.disabled}
      >
        {options}
      </MaterialSelect>
    </InputWrapper>
  );
}

export default class SelectField extends Field {
  options: OptionsProp;
  isRadio = false;
  row = false;
  multiple = false;
  native = false;
  includeBlank?: string | boolean = false;

  constructor(name: string, options: OptionsProp) {
    super(name);
    this.options = options;
  }

  renderEditComponent(props: UseControllerReturn) {
    if (this.isRadio) {
      return <Radio {...props} fieldModel={this} />;
    }
    return <Select {...props} fieldModel={this} />;
  }

  asQuickFilter() {
    if (this.isRadio) {
      this.row = true;
      this.label = undefined;
    }
    return super.asQuickFilter();
  }

  getFilterFieldForType(): Field {
    const filterField = this.clone();
    filterField.includeBlank = true;
    return filterField;
  }

  renderCell(value: any) {
    return this.getOptionCollection().find((v) => v.value === value)?.label || value;
  }

  getOptionCollection(): SelectOption[] {
    const options = typeof this.options === 'function' ? this.options() : this.options;

    if (Array.isArray(options)) {
      return options.map((o) => {
        if (typeof o === 'string') {
          return {
            label: o,
            value: o,
          };
        }
        return o;
      });
    }
    if (typeof options === 'object') {
      return Object.keys(options).map((k) => ({
        label: options[k],
        value: k,
      }));
    }
    return [];
  }

  getOptionsObject() {
    const options: Record<string | number, ReactNode> = {};
    this.getOptionCollection().forEach(({ value, label }) => {
      options[getValueAttr(value)] = label;
    });
    return options;
  }
}
