import { Menu } from '@mui/icons-material';
import { CustomAction, FieldFactory, LinkAction, Resource } from '@/classes';
import PrintMenu from '@/components/Print/PrintMenu';
import ReceivingByPurchaseOrder from '@/components/Receiving/ReceivingByPurchaseOrder';
import SubMenu from '@/components/Shared/SubMenu';
import { PurchaseOrder } from '@/types';
import purchaseOrders from './purchaseOrders';

export default function purchaseOrdersToReceive() {
  return new Resource<PurchaseOrder>('Purchase Orders to Receive')
    .setReadOnly()
    .withDefaultSort('-id')
    .withRelations(['items'])
    .getTitleUsing((po) => `Receiving: ${po.increment_id}`)
    .withApiEndpoint('/api/purchase-orders')
    .setCanExport()
    .getSingleActionsUsing((values) => [
      new CustomAction('Print', () => <PrintMenu model="po" id={values.id} />),
      new CustomAction('Navigation', () => (
        <SubMenu
          primaryIcon={Menu}
          items={[
            new LinkAction('Purchase Order', `/purchase-orders/${values.id}`),
            new LinkAction('Receiving', '').setDisabled(),
          ]}
        />
      )),
    ])
    .withQueryParams({
      index: {
        to_receive: '1',
      },
    })
    .withInitialColumns([
      'increment_id',
      'vendor',
      'description',
      'invoice_number',
      'status',
      'issued_date',
      'expected_date',
      'type',
      'party',
    ])
    .withFilters([FieldFactory.text('tracking_number').withFilterKey('tracking_number')])
    .withColumns(purchaseOrders().columns)
    .editUsing(ReceivingByPurchaseOrder);
}
