import { useCallback } from 'react';
import { InfiniteData, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import pickBy from 'lodash/pickBy';
import { Notification, CursorPaginated } from '@/types';

export type NotificationsRes = CursorPaginated<Notification, { unread_count: number }>;

export function useInfiniteNotifications({
  query,
  unread_only,
}: {
  query: string;
  unread_only: boolean;
}) {
  return useInfiniteQuery(
    ['notifications', query, unread_only],
    ({ pageParam }) =>
      axios
        .get<NotificationsRes>(`/api/notifications?query=${query}`, {
          params: pickBy({ cursor: pageParam, unread_only }),
        })
        .then(({ data }) => {
          return data;
        }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.meta.next_cursor;
      },
      refetchOnMount: false,
    },
  );
}

export function useMarkRead({ query, unread_only }: { query: string; unread_only: boolean }) {
  const updateReadAt = useUpdateReadAt();
  return useMutation((ids: number[]) =>
    axios
      .post<{ unread_count: number }>('/api/notifications/mark-read', { ids })
      .then(({ data }) => {
        updateReadAt(ids, new Date().toISOString(), data.unread_count, query, unread_only);
      }),
  );
}

export function useMarkUnread({
  query,
  unread_only,
}: {
  query: string | null;
  unread_only: boolean;
}) {
  const updateReadAt = useUpdateReadAt();
  return useMutation((ids: number[]) =>
    axios
      .post<{ unread_count: number }>('/api/notifications/mark-unread', { ids })
      .then(({ data }) => {
        updateReadAt(ids, null, data.unread_count, query, unread_only);
      }),
  );
}

export function useUpdateReadAt() {
  const queryClient = useQueryClient();

  return useCallback(
    (
      ids: number[],
      read_at: string | null,
      unread_count: number,
      query: string | null,
      unread_only: boolean,
    ) => {
      queryClient.setQueryData<InfiniteData<NotificationsRes>>(
        ['notifications', query, unread_only],
        (prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            pages: prev.pages.map((p) => ({
              ...p,
              meta: {
                ...p.meta,
                unread_count,
              },
              data: p.data.map((n) => {
                if (ids.includes(n.id)) {
                  return {
                    ...n,
                    read_at,
                  };
                }
                return n;
              }),
            })),
          };
        },
      );
    },
    [queryClient],
  );
}

export function useAddNotification({
  query,
  unread_only,
}: {
  query: string | null;
  unread_only: boolean;
}) {
  const queryClient = useQueryClient();
  return useCallback(
    (notification: Notification) => {
      queryClient.setQueryData<InfiniteData<NotificationsRes>>(
        ['notifications', query, unread_only],
        (prev) => {
          if (!prev) return prev;
          const [firstPage, ...otherPages] = prev.pages;
          return {
            ...prev,
            pages: [
              {
                ...firstPage,
                data: [notification, ...firstPage.data],
              },
              ...otherPages,
            ].map((p) => ({
              ...p,
              meta: {
                ...p.meta,
                unread_count: p.meta.unread_count + 1,
              },
            })),
          };
        },
      );
    },
    [queryClient, query, unread_only],
  );
}
