import { Card, CardHeader, Grid2 as Grid } from '@mui/material';
import axios from 'axios';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import DesignCard from '@/components/Designs/DesignCard';
import EventableCard from '@/components/Notes/EventableCard';
import TextButton from '@/components/Text/TextButton';
import { useDialogs } from '@/contexts/DialogContext';
import { useHasPermission } from '@/hooks/permissions';
import { ArtRequestActivity, genericModelReferenceSchema } from '@/types';
import { useRecord, useUpdateRecord } from '@/utils/genericResource';
import OrderSummaryCard from '../Orders/OrderSummaryCard';
import ArtActivityCompleteForm from './ArtActivityCompleteForm';

export default function ArtRequestActivityPage() {
  const { prompt } = useDialogs();
  const { id, label, artRequest, design, assignee } = useRecord('artRequestActivities', true);
  const updateRecord = useUpdateRecord('artRequestActivities');
  const hasPermission = useHasPermission();

  const onAssign = () => {
    prompt({
      title: 'Assign Activity',
      fields: [
        FieldFactory.belongsTo('assignee', 'users').withRequestParams({
          permission: 'read:art_request_activities',
        }),
      ],
      schema: z.object({
        assignee: genericModelReferenceSchema.nullish(),
      }),
      initialValues: {
        assignee,
      },
      onSubmit: (v) =>
        axios.put<ArtRequestActivity>(`/api/art-request-activities/${id}`, {
          assigned_to: v.assignee?.id,
        }),
    }).then(({ data }) => {
      updateRecord(data);
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 4 }}>
        <Card>
          <CardHeader
            title={label}
            subheader={
              <div>
                Assigned to{' '}
                <TextButton disabled={!hasPermission('read:users')} onClick={onAssign}>
                  {assignee?.name || '(none)'}
                </TextButton>
              </div>
            }
          />

          <ArtActivityCompleteForm />
        </Card>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <Card>
          <CardHeader title={design.increment_id} />
          <DesignCard design={design} />
        </Card>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        {artRequest?.order && <OrderSummaryCard order={artRequest.order} />}
      </Grid>
      <Grid size={12}>
        <EventableCard resource="art-request-activities" resourceId={id} />
      </Grid>
    </Grid>
  );
}
