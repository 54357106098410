import { useEffect, useState } from 'react';
import { Notifications as BellIcon } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText, Popover, Badge } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useNotificationContext } from '@/components/Notifications/NotificationProvider';
import NotificationList from './NotificationList';

export default function Notifications() {
  const { unreadCount } = useNotificationContext();
  const { search } = useLocation();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setAnchorEl(null);
  }, [search]);

  return (
    <>
      <ListItemButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ flexGrow: 0 }}>
        <ListItemIcon
          sx={{
            color: 'inherit',
            minWidth: 48,
          }}
        >
          <Badge badgeContent={unreadCount > 0 ? unreadCount : null} color="error">
            <BellIcon color="inherit" />
          </Badge>
        </ListItemIcon>
        <ListItemText primary="Updates" />
      </ListItemButton>

      <Popover
        id="notifications-popover"
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <NotificationList />
      </Popover>
    </>
  );
}
