import { useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from '@mui/material';
import { useResortOrderItemsRequest } from '@/api/orderItems';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import { OrderItem } from '@/types';

function OrderItemSortingRow({ item }: { item: OrderItem }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRow style={style} ref={setNodeRef}>
      <TableCell>
        <DragHandle {...attributes} {...listeners} />
      </TableCell>
      <TableCell>
        <OrderItemProduct item={item} />
      </TableCell>
      <TableCell>{item.size}</TableCell>
      <TableCell>{item.qty}</TableCell>
    </TableRow>
  );
}

export default function OrderItemsSortingTable({
  initialItems,
  onClose,
}: {
  initialItems: OrderItem[];
  onClose: () => void;
}) {
  const [items, setItems] = useState<OrderItem[]>(initialItems);
  const sortRequest = useResortOrderItemsRequest();

  const handleDragEnd = (event: DragEndEvent) => {
    if (!event.active || !event.over) return;

    const { active, over } = event;

    setItems((prev) => {
      const activeIndex = prev.findIndex((item) => item.id === active.id);
      const overIndex = prev.findIndex((item) => item.id === over.id);
      return arrayMove(prev, activeIndex, overIndex);
    });
  };

  const handleSave = () => {
    sortRequest.mutateAsync(items.map((item) => item.id)).then(() => {
      onClose();
    });
  };

  return (
    <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
      <Toolbar sx={{ gap: 1, bgcolor: 'action.hover' }}>
        <LoadingButton loading={sortRequest.isLoading} onClick={handleSave} variant="contained">
          Save Order
        </LoadingButton>
        <Button onClick={onClose} disabled={sortRequest.isLoading}>
          Cancel
        </Button>
      </Toolbar>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Item</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Qty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <SortableContext items={items.map((item) => item.id)}>
              {items.map((item) => (
                <OrderItemSortingRow key={item.id} item={item} />
              ))}
            </SortableContext>
          </TableBody>
        </Table>
      </TableContainer>
    </DndContext>
  );
}
