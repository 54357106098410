import { Edit, Settings } from '@mui/icons-material';
import {
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import filter from 'lodash/filter';
import pick from 'lodash/pick';
import { z } from 'zod';
import { Field, FieldFactory } from '@/classes';
import Can from '@/components/Permissions/Can';
import Label from '@/components/Shared/Label';
import Paper from '@/components/Shared/Paper';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderTiming as TimingModel } from '@/types';
import curr from '@/utils/curr';
import { formatDate } from '@/utils/dates';
import { makeResourceQueryKey } from '@/utils/genericResource';

function EstimateLabel({ is }: { is?: boolean }) {
  return is ? <Label>Estimate</Label> : null;
}

export default function OrderTiming({ orderId, disabled }: { orderId: number; disabled: boolean }) {
  const queryClient = useQueryClient();
  const { prompt } = useDialogs();

  const QUERY_KEY = makeResourceQueryKey('orders', orderId, 'timing');
  const { data: timing, isLoading } = useQuery(QUERY_KEY, () =>
    axios.get<TimingModel>(`/api/orders/${orderId}/timing`).then(({ data }) => data),
  );
  const onUpdate = (values: Partial<TimingModel>) =>
    axios.put(`/api/orders/${orderId}/timing`, values).then(({ data }) => {
      queryClient.setQueryData<TimingModel>(QUERY_KEY, data);
    });

  if (isLoading) {
    return (
      <Paper title="Timing">
        <CircularProgress />
      </Paper>
    );
  }

  if (!timing) {
    return null;
  }

  const fields = filter([
    FieldFactory.date('earliest_ship_date')
      .withHelp(
        'Setting this will ensure we do not ship the order before this date. It will also allow us to prioritize other orders ahead of this one.',
      )
      .withColumnSpan(12),

    FieldFactory.date('drop_dead_date')
      .withHelp(
        'Moving this up will prioritize this order.  However, if this date is before our normal turnaround time, you will be charged rush fees.',
      )
      .withColumnSpan(12),

    FieldFactory.date('expected_received_date')
      .withLabel('Expected Received Date')
      .withHelp(
        'Use this date to estimate the committed date and rush fees before all items are received.',
      )
      .setDisabled(!!timing.received_at)
      .withColumnSpan(12),

    timing.has_production &&
      FieldFactory.date('expected_released_at')
        .withLabel('Expected Release Date')
        .withHelp(
          'Use this date to estimate the committed date and rush fees before the art is approved & released.',
        )
        .setDisabled(!!timing.released_at)
        .withColumnSpan(12),
  ]) as Field[];

  const onEdit = () => {
    prompt({
      title: 'Edit Order Dates',
      fields,
      initialValues: pick(timing, [
        'earliest_ship_date',
        'drop_dead_date',
        'expected_received_date',
        'expected_released_date',
      ]),
      schema: z.object({
        earliest_ship_date: z.string().nullish(),
        drop_dead_date: z.string().nullish(),
        expected_received_date: z.string().nullish(),
        expected_released_date: z.string().nullish(),
      }),
      onSubmit: onUpdate,
    });
  };

  const onOverride = () => {
    prompt({
      title: 'Override Order Dates',
      description: (
        <div>
          Only production admins have access to this. Use these fields to adjust the committed date
          (and therefore rush fees) for orders that have the incorrect received or released dates.
        </div>
      ),
      fields: [
        FieldFactory.date('received_at').withLabel('Actual Received Date').withColumnSpan(12),
        FieldFactory.date('released_at').withLabel('Actual Release Date').withColumnSpan(12),
      ],
      initialValues: pick(timing, ['received_at', 'released_at']),
      schema: z.object({
        received_at: z.string().nullish(),
        released_at: z.string().nullish(),
      }),
      onSubmit: onUpdate,
    });
  };

  const actions = disabled ? null : (
    <Can permission="write:orders">
      <IconButton onClick={onEdit} size="large">
        <Edit />
      </IconButton>
      {timing?.is_estimate === false && (
        <Can permission="production_events:admin">
          <IconButton onClick={onOverride} size="large">
            <Settings />
          </IconButton>
        </Can>
      )}
    </Can>
  );

  return (
    <Card>
      <CardHeader title="Timing" action={actions} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Earliest Ship Date</TableCell>
            <TableCell>{formatDate(timing.earliest_ship_date)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Drop Dead Date</TableCell>
            <TableCell>{formatDate(timing.drop_dead_date)}</TableCell>
          </TableRow>

          {timing.received_date && (
            <TableRow>
              <TableCell variant="head">
                Received Date <EstimateLabel is={!timing.received_at} />
              </TableCell>
              <TableCell>{formatDate(timing.received_date)}</TableCell>
            </TableRow>
          )}

          {timing.has_production && timing.released_date && (
            <TableRow>
              <TableCell variant="head">
                Released Date <EstimateLabel is={!timing.released_at} />
              </TableCell>
              <TableCell>{formatDate(timing.released_date)}</TableCell>
            </TableRow>
          )}

          {timing.standard_ship_date && (
            <TableRow>
              <TableCell variant="head">
                Standard Ship Date <EstimateLabel is={timing.is_estimate} />
              </TableCell>
              <TableCell>{formatDate(timing.standard_ship_date)}</TableCell>
            </TableRow>
          )}

          {timing.committed_ship_date && (
            <TableRow>
              <TableCell variant="head">
                Committed Ship Date <EstimateLabel is={timing.is_estimate} />
              </TableCell>
              <TableCell>{formatDate(timing.committed_ship_date)}</TableCell>
            </TableRow>
          )}

          {timing.invoiced_at && (
            <TableRow>
              <TableCell variant="head">Invoice Date</TableCell>
              <TableCell>{formatDate(timing.invoiced_at)}</TableCell>
            </TableRow>
          )}

          {timing.rush_fees > 0 && (
            <TableRow>
              <TableCell variant="head">{timing.rush_days}-Day Rush Fees</TableCell>
              <TableCell>{curr(timing.rush_fees)}</TableCell>
            </TableRow>
          )}

          {timing.scheduled_date.length > 0 && (
            <Can permission="write:production_events">
              <TableRow>
                <TableCell variant="head">Scheduled For</TableCell>
                <TableCell>{timing.scheduled_date.map((d) => formatDate(d)).join(', ')}</TableCell>
              </TableRow>
            </Can>
          )}
        </TableBody>
      </Table>
    </Card>
  );
}
