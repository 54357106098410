import { Box, Typography } from '@mui/material';
import startCase from 'lodash/startCase';
import { FieldProps } from '@/classes/types';

export default function StripePaymentDetails({ field }: FieldProps) {
  const stripe = field.value as Record<string, any> | null;

  if (!stripe) {
    return null;
  }

  return (
    <div>
      <Typography variant="h6">Stripe Details</Typography>
      {stripe.description && <Typography>{stripe.description}</Typography>}
      <Box mt={2}>
        <table>
          <tbody>
            {Object.entries(stripe).map(([key, value]) => {
              if (key === 'description' || value == null) {
                return null;
              }
              return (
                <tr key={key}>
                  <th style={{ textAlign: 'left', paddingRight: 24 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {startCase(key).replace(' Id', ' ID')}
                    </Typography>
                  </th>
                  <td>{String(value)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </div>
  );
}
