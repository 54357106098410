import { useState } from 'react';
import { Grid2 as Grid, Stack, styled, Typography } from '@mui/material';
import OrderPackouts from '@/components/Orders/Dashboard/OrderPackouts';
import OrderShippingAddresses from '@/components/Orders/Shipping/OrderShippingAddresses';
import { Address, Kit } from '@/types';
import KitsForAddress from './KitsForAddress';
import OrderProductionOrders from './OrderProductionOrders';

const EmptyStateContainer = styled('div')({
  border: '1px dashed #aaa',
  padding: 24,
  textAlign: 'center',
  borderRadius: 4,
});

export default function OrderKitting() {
  const [address, setAddress] = useState<Address>();
  const [kit, setKit] = useState<Kit>();

  return (
    <Grid container spacing={1}>
      <Grid size={{ xs: 12, md: 6, lg: 3 }}>
        <OrderShippingAddresses isKit onSelect={setAddress} />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }}>
        {address ? (
          <KitsForAddress address={address} selected={kit} setSelected={setKit} />
        ) : (
          <EmptyStateContainer>
            <Typography variant="body2">
              Select an address to view the kits associated with it.
            </Typography>
          </EmptyStateContainer>
        )}
      </Grid>
      <Grid size={{ xs: 12, lg: 5 }}>
        <Stack spacing={2}>
          <OrderPackouts />
          <OrderProductionOrders kit={kit} />
        </Stack>
      </Grid>
    </Grid>
  );
}
