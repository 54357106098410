import { FieldFactory, Resource } from '@/classes';
import { CustomerDomain, customerDomainPayloadSchema } from '@/types';

export default function customerDomains() {
  return new Resource<CustomerDomain>('Domains')
    .withSchema(customerDomainPayloadSchema)
    .withFieldsAndColumns([
      FieldFactory.text('domain').withColumnSpan(2),
      FieldFactory.select('is_auto', [
        {
          value: true,
          label: 'Auto-Join',
        },
        {
          value: false,
          label: 'Request to Join',
        },
      ]).withLabel('Mode'),
    ]);
}
