import { CSSProperties } from 'react';
import { Delete, Edit, Reply } from '@mui/icons-material';
import { Box, ListItem, ListItemAvatar, Typography } from '@mui/material';
import moment from 'moment';
import { ButtonAction } from '@/classes';
import AttachmentsListSimple from '@/components/Attachments/AttachmentsListSimple';
import AddReactionButton from '@/components/Notes/AddReactionButton';
import { NoteReactions } from '@/components/Notes/NoteReactions';
import SubMenu from '@/components/Shared/SubMenu';
import UserLabel from '@/components/Users/UserLabel';
import { useConfig } from '@/contexts/AppConfigContext';
import { Note } from '@/types';
import NoteRenderer from './NoteRenderer';
import UserAvatar from './UserAvatar';

export default function NoteListItem({
  note,
  onEdit,
  onDelete,
  onReply,
  onReaction,
  size,
  style = {},
}: {
  note: Note;
  onEdit?: () => void;
  onDelete?: () => void;
  onReply?: () => void;
  onReaction?: (reaction: string) => Promise<void>;
  size?: 'small' | 'medium';
  style?: CSSProperties;
}) {
  const { user } = useConfig();
  const createdByName = note.created_by_user?.name || 'System';
  const isMine = note.created_by_user?.id === user.id;
  const canEdit = isMine && moment(note.created_at).isAfter(moment().subtract(15, 'minutes'));

  return (
    <ListItem
      dense={size === 'small'}
      style={style}
      sx={{
        alignItems: 'start',
        '&:hover [data-reactions]': { visibility: 'visible !important' },
        '&:focus-within [data-reactions]': { visibility: 'visible !important' },
      }}
    >
      {size !== 'small' && (
        <ListItemAvatar sx={{ marginTop: 0.5 }}>
          <UserAvatar user={note.created_by_user} name={createdByName} />
        </ListItemAvatar>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, position: 'relative' }}>
        <Box display="inline-flex" alignItems="center">
          <div style={{ fontWeight: 500 }}>
            {note.created_by_user && 'title' in note.created_by_user ? (
              <UserLabel user={note.created_by_user} />
            ) : (
              createdByName
            )}
          </div>
          <Typography ml={1} variant="body2" color="textSecondary">
            {moment(note.created_at).fromNow()}
          </Typography>
        </Box>
        <Box>
          <NoteRenderer note={note.note} />
        </Box>
        {note.attachments && note.attachments.length > 0 && (
          <AttachmentsListSimple attachments={note.attachments} />
        )}
        {onReaction && note.reactions && note.reactions.length > 0 && (
          <NoteReactions reactions={note.reactions} onReaction={onReaction} />
        )}
      </Box>
      {onEdit && onDelete && onReply && (
        <Box display="flex" alignItems="center" ml="auto">
          {onReaction && <AddReactionButton onReaction={onReaction} />}
          <SubMenu
            size={size}
            items={[
              new ButtonAction('Reply', onReply, Reply),
              canEdit && new ButtonAction('Edit', onEdit, Edit),
              isMine && new ButtonAction('Delete', onDelete, Delete),
            ]}
          />
        </Box>
      )}
    </ListItem>
  );
}
