import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress, List, Stack, TextField, Typography } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import pickBy from 'lodash/pickBy';
import { Moment } from 'moment';
import { FieldFactory } from '@/classes';
import BankTransactionListItem from '@/components/Banking/BankTransactionListItem';
import DrawerButtons from '@/components/Form/DrawerButtons';
import { NonFormField } from '@/components/Form/FormField';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { TRANSACTION_TYPES } from '@/constants';
import useDebounce from '@/hooks/useDebounce';
import { BankTransaction, Deposit, Transaction } from '@/types';
import TransactionListItem from './TransactionListItem';

export default function FindMatchDrawer({
  reviewing,
  setReviewing,
  onSuccess,
}: {
  reviewing: BankTransaction | null;
  setReviewing: (t: BankTransaction | null) => void;
  onSuccess: (t: Transaction) => void;
}) {
  const [type, setType] = useState<Transaction['type'] | 'deposit'>('expense');
  const [dates, setDates] = useState<[Moment | null, Moment | null]>([null, null]);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [selected, setSelected] = useState<Transaction | Deposit>();

  useEffect(() => {
    if (reviewing) {
      setType('expense');
      setQuery('');
      setDates([null, null]);
    }
  }, [reviewing?.id]);

  const { data: results } = useQuery(
    ['findMatchResults', reviewing?.account_id, type, debouncedQuery, JSON.stringify(dates)],
    () => {
      let url = '/api/transactions';
      let params = pickBy({
        query,
        'filter[transaction_date][gte]': dates[0]?.format('YYYY-MM-DD'),
        'filter[transaction_date][lte]': dates[1]?.format('YYYY-MM-DD'),
        'filter[type]': type,
        'filter[bank_account_id]': reviewing?.account_id,
        no_bank_transactions: 1,
      });
      if (type === 'deposit') {
        url = '/api/deposits';
        params = pickBy({
          query,
          'filter[bank_account_id]': reviewing?.account_id,
          'filter[deposit_date][gte]': dates[0]?.format('YYYY-MM-DD'),
          'filter[deposit_date][lte]': dates[1]?.format('YYYY-MM-DD'),
          no_bank_transactions: 1,
        });
      }

      return axios
        .get<{ data: Transaction[] | Deposit[] }>(url, { params })
        .then(({ data }) => data.data);
    },
    {
      enabled: Boolean(reviewing),
    },
  );

  const { mutate, isLoading: isSubmitting } = useMutation(() => {
    if (!selected || !reviewing) {
      return Promise.reject();
    }
    const matchKey = type === 'deposit' ? 'deposit_id' : 'transaction_id';
    return axios
      .post(`/api/bank-transactions/${reviewing.id}/match`, {
        [matchKey]: selected.id,
      })
      .then(({ data }) => {
        onSuccess(data);
      });
  });

  return (
    <ClosableDrawer open={!!reviewing} onClose={() => setReviewing(null)} title="Find Match">
      {reviewing && (
        <List subheader="Finding Match For" sx={{ mb: 2 }}>
          <BankTransactionListItem transaction={reviewing} />
        </List>
      )}

      <Stack spacing={2}>
        <NonFormField
          value={type}
          onChange={(t) => setType(t as Transaction['type'])}
          field={FieldFactory.select('type', TRANSACTION_TYPES)}
        />

        <DateRangePicker
          onChange={setDates}
          value={dates}
          slotProps={{
            textField: {
              size: 'small',
            },
          }}
        />

        <TextField
          label="Query"
          size="small"
          type="search"
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          fullWidth
        />
      </Stack>

      {!results ? (
        <CircularProgress />
      ) : (
        <List>
          {results.map((result) => (
            <TransactionListItem
              key={result.id}
              transaction={result}
              onSelect={() => setSelected(result)}
              selected={result.id === selected?.id}
            />
          ))}
        </List>
      )}

      {results?.length === 0 && (
        <Typography variant="body2" color="textSecondary">
          No results found.
        </Typography>
      )}

      <DrawerButtons>
        <LoadingButton
          type="submit"
          variant="contained"
          disabled={!selected}
          loading={isSubmitting}
          onClick={() => mutate()}
        >
          Link Transaction
        </LoadingButton>
      </DrawerButtons>
    </ClosableDrawer>
  );
}
