import { LinearProgress, useMediaQuery } from '@mui/material';
import { drawerColor } from '@/MuiProvider';
import Logo from '@/assets/logo.svg?react';

export default function SplashScreen() {
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        color: isDarkModeEnabled ? 'white' : drawerColor,
      }}
    >
      <div style={{ width: 300, maxWidth: '100%' }}>
        <div style={{ padding: 42, paddingTop: 0 }}>
          <Logo style={{ width: '100%', height: 100 }} />
        </div>
        <LinearProgress color="secondary" />
      </div>
    </div>
  );
}
