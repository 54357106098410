import { Alert } from '@mui/material';
import NoteRenderer from './NoteRenderer';

export default function PartyMessage({ message }: { message: string | null | undefined }) {
  if (!message) {
    return null;
  }
  return (
    <Alert severity="info" sx={{ mb: 2 }}>
      <NoteRenderer note={message} />
    </Alert>
  );
}
