import { ReactElement } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { UseControllerReturn } from 'react-hook-form';
import { FieldProps } from '@/classes/types';
import renderCell from '@/utils/renderCell';
import Field from '../Field';

export default class CustomField extends Field {
  component: (props: FieldProps) => ReactElement | null;

  constructor(name: string, component: (props: FieldProps) => ReactElement | null) {
    super(name);
    this.component = component;
  }

  renderEditComponent(props: UseControllerReturn) {
    const Component = this.component;
    return <Component {...props} fieldModel={this} />;
  }

  forReactTable(): ColumnDef<any, any> {
    return {
      id: this.name,
      header: this.label,
      enableSorting: this.sortableBy != null,
      cell: ({ getValue, row }) => renderCell(this, getValue(), row.original),
    };
  }
}
