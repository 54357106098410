import { useState } from 'react';
import { Delete, Description, Edit, Image, Print } from '@mui/icons-material';
import { Card, CardHeader, Chip, IconButton, Tooltip } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { useDeleteShipment, useGetShipments } from '@/api/shipping';
import AddressBlock from '@/components/Addresses/AddressBlock';
import PaginatedTable from '@/components/DataTable/PaginatedTable';
import Can from '@/components/Permissions/Can';
import UserLabel from '@/components/Users/UserLabel';
import { useDialogs } from '@/contexts/DialogContext';
import { usePrintShipmentLabel } from '@/contexts/PrintContext';
import { Shipment } from '@/types';
import curr from '@/utils/curr';
import ShipmentDetailsDrawer from './ShipmentDetailsDrawer';

const columnHelper = createColumnHelper<Shipment>();

export default function ShipmentsCard({
  shippableId,
  shippableType,
  showActions = true,
}: {
  shippableId: number;
  shippableType: 'order' | 'fulfillment_order' | 'return';
  showActions?: boolean;
}) {
  const [viewing, setViewing] = useState<Shipment>();
  const { confirm } = useDialogs();

  const { data: shipments = [], isFetching, refetch } = useGetShipments(shippableType, shippableId);
  const deleteRequest = useDeleteShipment();
  const getLabelRequest = usePrintShipmentLabel();

  const onDeleteShipment = (shipment: Shipment) => {
    let description = 'Are you sure you want to delete this shipment?';
    if (shipment.external_source === 'shippo') {
      description += ' This will refund the label in Shippo and make it unusable.';
    }

    confirm({ title: 'Delete Shipment', description, color: 'error' }).then(() => {
      deleteRequest.mutate(shipment);
    });
  };

  return (
    <Card>
      <CardHeader title="Shipments for Order" />

      <PaginatedTable
        storageKey={`shipments.${shippableType}`}
        rows={shipments}
        filename={`shipments-${shippableType}-${shippableId}`}
        columns={[
          columnHelper.accessor('carrier_name', {
            header: 'Carrier',
            cell: ({ row, getValue }) => getValue() || `Shipment #${row.original.id}`,
          }),
          columnHelper.accessor('tracking_number', {
            header: 'Tracking',
            cell: ({ row, getValue }) => (
              <>
                {row.original.tracking_url ? (
                  <a href={row.original.tracking_url} target="_blank" rel="noreferrer">
                    {getValue()}
                  </a>
                ) : (
                  getValue()
                )}
              </>
            ),
          }),
          columnHelper.accessor('address.name', {
            header: 'Recipient',
            cell: ({ getValue, row }) =>
              row.original.address && (
                <Tooltip title={<AddressBlock address={row.original.address} />}>
                  <div>{getValue()}</div>
                </Tooltip>
              ),
          }),
          columnHelper.accessor('cost', {
            header: 'Cost',
            cell: ({ getValue, row }) => (
              <>
                {curr(getValue())}
                {row.original.billing_type === 'THIRD_PARTY' && (
                  <Chip sx={{ ml: 1 }} label="3rd Party" size="small" />
                )}
              </>
            ),
          }),
          columnHelper.accessor('created_at', {
            header: 'Date',
            cell: ({ getValue }) => moment(getValue()).format('lll'),
          }),
          columnHelper.accessor('shipped_by.name', {
            header: 'Shipped By',
            enableHiding: false,
            cell: ({ row }) => <UserLabel user={row.original.shipped_by} />,
          }),
          columnHelper.display({
            id: 'actions',
            enableHiding: false,
            header: '',
            cell: ({ row: { original: s } }) => (
              <Can permission="write:shipments">
                <IconButton onClick={() => setViewing(s)} size="small">
                  <Edit fontSize="small" />
                </IconButton>
                {s.file ? (
                  <Tooltip title="View Uploaded File">
                    <IconButton component="a" target="_blank" href={s.file} size="small">
                      <Image fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {s.label_url && moment(s.created_at).diff(moment(), 'months') < 6 ? (
                  <Tooltip title="View Label">
                    <IconButton
                      onClick={() => getLabelRequest.mutate(s.id)}
                      disabled={getLabelRequest.isLoading}
                      size="small"
                    >
                      <Print fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {s.commercial_invoice_url && moment(s.created_at).diff(moment(), 'months') < 6 ? (
                  <Tooltip title="View Commercial Invoice">
                    <IconButton
                      component="a"
                      target="_blank"
                      href={s.commercial_invoice_url}
                      size="small"
                    >
                      <Description fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : null}
                <IconButton onClick={() => onDeleteShipment(s)} size="small">
                  <Delete fontSize="small" />
                </IconButton>
              </Can>
            ),
          }),
        ]}
        refetch={refetch}
        isFetching={isFetching || deleteRequest.isLoading}
        enableToolbar={shipments.length > 3}
        initialState={{
          columnVisibility: {
            actions: showActions,
            shipped_by_name: showActions,
          },
          pagination: {
            pageSize: 10,
          },
        }}
      />

      {showActions && (
        <ShipmentDetailsDrawer onClose={() => setViewing(undefined)} shipment={viewing} />
      )}
    </Card>
  );
}
