import {
  Box,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ButtonAction } from '@/classes';
import { useNotificationContext } from '@/components/Notifications/NotificationProvider';
import SubMenu from '@/components/Shared/SubMenu';
import { Notification } from '@/types';

export default function NotificationListItem({ notification: n }: { notification: Notification }) {
  const { onMarkRead, onMarkUnread } = useNotificationContext();
  let to = n.details.href;
  if (to.includes('?')) {
    to += `&notification_id=${n.id}`;
  } else {
    to += `?notification_id=${n.id}`;
  }
  return (
    <ListItemButton component={Link} to={to}>
      <Box pr={2} display="flex" alignItems="center">
        <Box
          width={10}
          height={10}
          bgcolor={n.read_at ? 'transparent' : 'primary.main'}
          borderRadius="100%"
        />
      </Box>
      <ListItemText>
        <Typography style={{ fontSize: '.925rem' }}>
          <span style={{ fontWeight: 500 }}>{n.details.user}</span>
          <span> </span>
          <span>{n.details.action}</span>
        </Typography>

        <Typography variant="caption" color="textSecondary">
          <Box display="inline-block" pr={1}>
            {moment(n.created_at).format('lll')}
          </Box>
          {n.details.label && (
            <>
              <Box display="inline-block">-</Box>
              <Box display="inline-block" pl={1}>
                {n.details.label}
              </Box>
            </>
          )}
        </Typography>
      </ListItemText>
      <ListItemSecondaryAction onClick={(e) => e.stopPropagation()}>
        <SubMenu
          items={[
            n.read_at
              ? new ButtonAction('Mark Unread', () => onMarkUnread([n.id]))
              : new ButtonAction('Mark Read', () => onMarkRead([n.id])),
          ]}
          size="small"
        />
      </ListItemSecondaryAction>
    </ListItemButton>
  );
}
