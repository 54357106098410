import { ReactElement } from 'react';
import { OnClickProps } from '@/classes/types';
import Action from '../Action';

export default class CustomAction extends Action {
  renderFunc: (p: OnClickProps) => ReactElement;

  constructor(label: string, renderFunc: (p: OnClickProps) => ReactElement) {
    super(label);
    this.renderFunc = renderFunc;
  }
}
