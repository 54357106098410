import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';
import { Issue, IssuePayload } from '@/types';
import { makeResourceQueryKey } from '@/utils/genericResource';

export interface GetIssuesParams {
  purchaseOrderId?: number;
  orderId?: number;
  type?: Issue['type'];
}

export function toSnake(obj: Record<string, any>) {
  return mapKeys(obj, (_, key) => snakeCase(key));
}

export function getQueryKey(params: GetIssuesParams) {
  return ['issues', JSON.stringify(params)];
}

export function useGetIssues(params: GetIssuesParams) {
  return useQuery(getQueryKey(params), () =>
    axios
      .get<{
        data: Issue[];
      }>(`/api/issues`, {
        params: toSnake(params),
      })
      .then(({ data }) => data.data),
  );
}

export function handleIssue(queryClient: QueryClient, issue: Issue) {
  queryClient.invalidateQueries(['issues']);
  queryClient.setQueryData(makeResourceQueryKey('receivingIssues', issue.id), issue);
  if (issue.order && issue.type === 'purchasing') {
    queryClient.invalidateQueries(makeResourceQueryKey('ordersToPurchase', issue.order.id));
  }
}

export function useCreateIssue(params: GetIssuesParams) {
  const queryClient = useQueryClient();

  return useMutation((payload: IssuePayload) =>
    axios.post<Issue>(`/api/issues`, { ...toSnake(params), ...payload }).then(({ data }) => {
      handleIssue(queryClient, data);
      return data;
    }),
  );
}

export function useUpdateIssue(issue: Issue) {
  const queryClient = useQueryClient();

  return useMutation((payload: IssuePayload) =>
    axios.put<Issue>(`/api/issues/${issue.id}`, payload).then(({ data }) => {
      handleIssue(queryClient, data);
      return data;
    }),
  );
}

export function useDeleteIssue(issue: Issue) {
  const queryClient = useQueryClient();

  return useMutation(() =>
    axios.delete<Issue>(`/api/issues/${issue.id}`).then(({ data }) => {
      queryClient.invalidateQueries(['issues']);
      return data;
    }),
  );
}

export function useResolveIssue(issue: Issue) {
  const queryClient = useQueryClient();

  return useMutation((payload: { resolution: string }) =>
    axios.post<Issue>(`/api/issues/${issue.id}/resolve`, payload).then(({ data }) => {
      handleIssue(queryClient, data);
      return data;
    }),
  );
}

export function useChangeIssueStatus(issue: Issue) {
  const queryClient = useQueryClient();

  return useMutation((payload: { status: Issue['status'] }) =>
    axios.post<Issue>(`/api/issues/${issue.id}/status`, payload).then(({ data }) => {
      handleIssue(queryClient, data);
      return data;
    }),
  );
}
