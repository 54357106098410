import { PinDrop } from '@mui/icons-material';
import DesignIcon from '@mui/icons-material/Image';
import ListIcon from '@mui/icons-material/List';
import PurchaseIcon from '@mui/icons-material/LocalOffer';
import ShipIcon from '@mui/icons-material/LocalShipping';
import { Box, IconButton, styled, Tooltip } from '@mui/material';
import { useUpdateOrderItemRequest } from '@/api/orderItems';
import { OrderItem } from '@/types';
import { useIsOrderLocked } from '@/utils/orders';

const ToggleIconButton = styled(IconButton, {
  shouldForwardProp: (p) => p !== 'active' && p !== 'disabled',
})<{ active?: boolean; disabled?: boolean }>(({ theme, active, disabled }) => ({
  color: active ? theme.palette.secondary.main : theme.palette.grey[500],
  fontSize: 20,
  pointerEvents: disabled ? 'none' : 'auto',
}));

export default function OrderItemSettings({ item }: { item: OrderItem }) {
  const isOrderLocked = useIsOrderLocked();
  const canEdit = !isOrderLocked && item.qty_sourced === 0;
  const { mutateAsync } = useUpdateOrderItemRequest();

  const handleToggle = (key: keyof OrderItem) => () => {
    mutateAsync({ id: item.id, [key]: !item[key] });
  };

  return (
    <Box display="flex">
      <Tooltip
        title={item.is_purchasable ? 'Item will be purchased' : 'Item should not be purchased'}
      >
        <ToggleIconButton
          active={item.is_purchasable}
          size="small"
          disabled={!canEdit}
          onClick={handleToggle('is_purchasable')}
        >
          <PurchaseIcon />
        </ToggleIconButton>
      </Tooltip>

      <Tooltip
        title={
          item.can_drop_ship
            ? 'Item can be shipped directly to customer'
            : 'Item will ship to Avail'
        }
      >
        <ToggleIconButton
          active={item.can_drop_ship}
          size="small"
          disabled={isOrderLocked}
          onClick={handleToggle('can_drop_ship')}
        >
          <PinDrop />
        </ToggleIconButton>
      </Tooltip>

      <Tooltip
        title={
          item.can_apply_designs
            ? 'Item can have designs applied'
            : 'Item cannot have designs applied'
        }
      >
        <ToggleIconButton
          active={item.can_apply_designs}
          size="small"
          disabled={isOrderLocked}
          onClick={handleToggle('can_apply_designs')}
        >
          <DesignIcon />
        </ToggleIconButton>
      </Tooltip>

      <Tooltip title={item.is_shippable ? 'Item will be shipped' : 'Item will NOT be shipped'}>
        <ToggleIconButton
          active={item.is_shippable}
          size="small"
          disabled={isOrderLocked || item.qty_shipped !== 0}
          onClick={handleToggle('is_shippable')}
        >
          <ShipIcon />
        </ToggleIconButton>
      </Tooltip>

      <Tooltip
        title={
          item.show_on_invoice ? 'Item will show on invoice' : 'Item will be hidden from invoice'
        }
      >
        <ToggleIconButton
          active={item.show_on_invoice}
          size="small"
          disabled={isOrderLocked}
          onClick={handleToggle('show_on_invoice')}
        >
          <ListIcon />
        </ToggleIconButton>
      </Tooltip>
    </Box>
  );
}
