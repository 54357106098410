import { ReactNode } from 'react';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { ControllerFieldState } from 'react-hook-form';
import Field from '@/classes/Field';

export default function InputWrapper({
  fieldState,
  fieldModel,
  children,
  withoutLabel = false,
}: {
  fieldState: ControllerFieldState;
  fieldModel: Field;
  children: ReactNode;
  withoutLabel?: boolean;
}) {
  const error = fieldState.invalid && fieldState.error;
  const helperText = fieldModel.getHelperText(fieldState);
  return (
    <FormControl
      fullWidth={fieldModel.fullWidth}
      error={!!error}
      size={fieldModel.size}
      margin={fieldModel.margin}
      required={fieldModel.required}
    >
      {!withoutLabel && fieldModel.label && <InputLabel>{fieldModel.label}</InputLabel>}
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
