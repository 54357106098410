import { Grid2 as Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import StatCard from '@/components/Stats/StatCard';
import curr from '@/utils/curr';

export default function CustomerNumbers({ customerId }: { customerId: number | string }) {
  const { data: numbers } = useQuery(['customers', customerId], () =>
    axios
      .get<{
        count: number;
        total: number;
        balance: number;
        past_due: number;
        total_last_year: number;
        count_last_year: number;
      }>(`/api/customers/${customerId}/summary`)
      .then(({ data }) => data),
  );

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 4, lg: 3 }}>
        <StatCard
          title="Invoiced Orders"
          loading={!numbers}
          number={numbers?.count}
          format="number"
        >
          {numbers?.count_last_year} in last year
        </StatCard>
      </Grid>
      <Grid size={{ xs: 12, md: 4, lg: 3 }}>
        <StatCard title="Invoiced Revenue" loading={!numbers} number={numbers?.total} format="curr">
          {curr(numbers?.total_last_year || 0)} in last year
        </StatCard>
      </Grid>
      <Grid size={{ xs: 12, md: 4, lg: 3 }}>
        <StatCard title="Balance" loading={!numbers} number={numbers?.balance} format="curr" />
      </Grid>
      <Grid size={{ xs: 12, md: 4, lg: 3 }}>
        <StatCard
          title="Past-Due Balance"
          loading={!numbers}
          number={numbers?.past_due}
          format="curr"
        />
      </Grid>
    </Grid>
  );
}
