import { ReactNode } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  List,
  ListItem,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import get from 'lodash/get';
import isString from 'lodash/isString';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import startCase from 'lodash/startCase';
import moment from 'moment';
import pluralize from 'pluralize';
import AddressBlock from '@/components/Addresses/AddressBlock';
import Can from '@/components/Permissions/Can';
import UserLabel from '@/components/Users/UserLabel';
import { Order } from '@/types';
import { AddressWithKits } from '@/types';
import PrintMenu from '../Print/PrintMenu';
import OrderNavigation from './OrderNavigation';
import OrderStatus from './OrderStatus';

function OrderAddresses({ addresses }: { addresses?: AddressWithKits[] }) {
  if (!addresses) {
    return <LinearProgress />;
  }

  return (
    <div>
      <Typography color="textSecondary" variant="body2">
        Shipping {pluralize('Addresses', addresses.length)}
      </Typography>
      {addresses.length > 0 ? (
        <List>
          {addresses.map((a) => (
            <ListItem key={a.id}>
              <AddressBlock address={a} showMethod />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body2" sx={{ mt: 2 }}>
          There are no shipping addresses.
        </Typography>
      )}
    </div>
  );
}

export default function OrderSummaryCard({
  order,
  children,
  withAddresses = false,
}: {
  order: Order;
  children?: ReactNode;
  withAddresses?: boolean;
}) {
  const data = pickBy({
    Type: order.order_type?.name,
    'Shipping Mode': order.shipping_mode !== 'bulk' ? startCase(order.shipping_mode) : null,
    Customer: order.customer?.name,
    'Sales Rep': <UserLabel user={order.sales_rep} />,
    Nickname: get(order, 'description'),
    Status: (
      <OrderStatus
        status={order.status}
        style={{
          marginTop: -7,
          marginBottom: -7,
        }}
      />
    ),
    'Commit Date': order.committed_ship_date
      ? moment(order.committed_ship_date).format('ll')
      : null,
    'Drop Dead': order.drop_dead_date ? moment(order.drop_dead_date).format('ll') : null,
    'Earliest Date': order.earliest_ship_date
      ? moment(order.earliest_ship_date).format('ll')
      : null,
  });

  const { data: addresses } = useQuery(
    ['orderAddress', order.id],
    () =>
      axios
        .get<{ data: AddressWithKits[] }>(`/api/orders/${order.id}/addresses?count=3`)
        .then(({ data }) => data.data),
    {
      staleTime: 1000 * 60 * 5,
      enabled: withAddresses,
    },
  );

  return (
    <Card>
      <CardHeader
        title={order.increment_id}
        action={
          <>
            <PrintMenu model="order" id={order.id} />
            <Can permission="read:orders">
              <OrderNavigation orderId={order.id} />
            </Can>
          </>
        }
      />
      <TableContainer>
        <Table>
          <TableBody>
            {map(data, (value, label) => (
              <TableRow key={label}>
                <TableCell component="th">
                  <Typography color="textSecondary" variant="body2">
                    {label}
                  </Typography>
                </TableCell>
                <TableCell>
                  {isString(value) ? <Typography variant="body2">{value}</Typography> : value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {withAddresses && (
        <CardContent>
          <OrderAddresses addresses={addresses} />{' '}
        </CardContent>
      )}
      {children && <CardContent>{children}</CardContent>}
    </Card>
  );
}
