import forOwn from 'lodash/forOwn';
import trimEnd from 'lodash/trimEnd';

export const maybeAddQueryParams = (
  value: string | undefined | null,
  params = {},
): string | undefined => {
  if (!value) {
    return undefined;
  }
  if (value.startsWith('data:') || !value.includes('files.availerp.com')) {
    return value;
  }
  try {
    const url = new URL(value);
    if (!url.search) {
      url.search = `?${new URLSearchParams(params).toString()}`;
    }
    return url.toString();
  } catch (e) {
    return value;
  }
};

export function tryJsonParse(str: string): any {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export function addSearchParams(url: string, object: Record<string, any>): string {
  const [path, search] = url.split('?');
  const searchParams = new URLSearchParams(search);
  forOwn(object, (v, k) => {
    if (v) {
      searchParams.set(k, v);
    }
  });
  return `${path}?${searchParams.toString()}`;
}

export function removeSearchParams(url: string, ...params: string[]): string {
  const [path, search] = url.split('?');
  const searchParams = new URLSearchParams(search);
  params.forEach((k) => {
    searchParams.delete(k);
  });
  return trimEnd(`${path}?${searchParams.toString()}`, '?');
}
