import { List, Typography, Card, CardHeader, CardContent, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ArtRequestListItem from '@/components/ArtRequests/ArtRequestListItem';
import { ArtRequest } from '@/types';
import { makeResourceQueryKey, useRecordId } from '@/utils/genericResource';

export default function OrderArtRequests() {
  const orderId = useRecordId();
  const { data: artRequests, isFetching } = useQuery(
    makeResourceQueryKey('orders', orderId, 'artRequests'),
    () =>
      axios
        .get<{ data: ArtRequest[] }>(`/api/orders/${orderId}/art-requests`)
        .then(({ data }) => data.data),
  );
  return (
    <Card>
      <CardHeader title="Art Requests" />
      {isFetching && <LinearProgress />}
      {artRequests &&
        (artRequests.length === 0 ? (
          <CardContent>
            <Typography color="textSecondary" variant="body2">
              No art requests have been created for this order.
            </Typography>
          </CardContent>
        ) : (
          <List>
            {artRequests.map((r) => (
              <ArtRequestListItem request={r} key={r.id} />
            ))}
          </List>
        ))}
    </Card>
  );
}
