import { Delete } from '@mui/icons-material';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  Radio,
} from '@mui/material';
import { PaymentMethod } from '@stripe/stripe-js';
import StripeBankAccountListItem from '@/components/Payments/StripeBankAccountListItem';
import StripeCardListItem from '@/components/Payments/StripeCardListItem';

export default function StripePaymentMethodListItem({
  method,
  onRemove,
  onSelect,
  selected,
}: {
  method: PaymentMethod;
  onRemove?: (id: string) => void;
  onSelect?: (id: string) => void;
  selected?: string | null;
}) {
  const inner = (
    <>
      {method.card && <StripeCardListItem card={method.card} />}
      {method.us_bank_account && (
        <StripeBankAccountListItem usBankAccount={method.us_bank_account} />
      )}

      <ListItemSecondaryAction>
        {onRemove && (
          <IconButton onClick={() => onRemove(method.id)} size="large">
            <Delete />
          </IconButton>
        )}
        {onSelect && <Radio checked={selected === method.id} />}
      </ListItemSecondaryAction>
    </>
  );

  if (onSelect) {
    return <ListItemButton onClick={() => onSelect(method.id)}>{inner}</ListItemButton>;
  }
  return <ListItem>{inner}</ListItem>;
}
