import axios from 'axios';

export default function getApiUrl(path: string, searchParams = new URLSearchParams()) {
  const url = new URL(path, axios.defaults.baseURL);
  const businessId = axios.defaults.headers.common['X-Business-Id'];
  if (businessId) {
    url.searchParams.set('selected_business_id', String(businessId));
  }
  searchParams.forEach((value, key) => {
    url.searchParams.set(key, value);
  });
  return url.toString();
}
