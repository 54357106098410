import { NavigateOptions, URLSearchParamsInit, useSearchParams } from 'react-router-dom';

type SetURLSearchParams = (
  nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  navigateOpts?: NavigateOptions,
) => void;

export default function useSearchParamsObject(): [Record<string, string>, SetURLSearchParams] {
  const [searchParams, setSearchParams] = useSearchParams();

  const object: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    object[key] = value;
  });

  return [object, setSearchParams];
}
