import { useState } from 'react';
import { AttachFile } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { onUpload } from '@/AuthenticatedApp';
import { UploadedFile } from '@/utils/attachments';

export default function AttachmentButton({
  onFiles,
}: {
  onFiles: (files: UploadedFile[]) => void;
}) {
  const [uploading, setUploading] = useState<number | false>(false);

  const handleProgress = (progress: number) => {
    setUploading(Math.round(progress * 100));
  };

  const handleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const { files } = target;
    if (!files) return;
    setUploading(0);

    return onUpload(files, 'file', handleProgress)
      .then((res) =>
        onFiles(
          res.map((f, i) => ({
            ...f,
            name: files[i].name,
            size: files[i].size,
          })),
        ),
      )
      .finally(() => {
        setUploading(false);
        target.value = '';
      });
  };

  return (
    <IconButton component="label">
      <input type="file" style={{ display: 'none' }} onChange={handleFiles} />
      {uploading !== false ? <CircularProgress size={24} /> : <AttachFile />}
    </IconButton>
  );
}
