import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Vendor } from '@/types';
import { makeResourceQueryKey, useCurrentResource, useRecordId } from '@/utils/genericResource';

export function useGetVendorsForOrder(enabled: boolean) {
  const resource = useCurrentResource();
  const recordId = useRecordId();

  return useQuery(
    makeResourceQueryKey(resource, recordId, 'vendors'),
    () =>
      axios
        .get<{
          data: Vendor[];
        }>(`/api/vendors?${resource === 'quotes' ? 'quote_id' : 'order_id'}=${recordId}`)
        .then(({ data }) => data.data),
    {
      enabled,
    },
  );
}
