import { Alert, Button, Chip, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { FieldFactory } from '@/classes';
import FormField from '@/components/Form/FormField';
import { useShowLoading } from '@/contexts/DialogContext';
import { UserChangeRequest } from '@/types';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';
import getApiUrl from '@/utils/getApiUrl';

function ChangeRequestAlert({ request }: { request: UserChangeRequest }) {
  const onReload = useOnReloadRecord('users');
  const showLoading = useShowLoading();

  const onApprove = () => {
    showLoading(axios.post(`/api/user-change-requests/${request.id}/approve`)).then(() => {
      onReload();
    });
  };

  const onReject = () => {
    showLoading(axios.post(`/api/user-change-requests/${request.id}/reject`)).then(() => {
      onReload();
    });
  };

  return (
    <Alert severity="warning">
      <Typography variant="body2">
        {request.created_by_user.name} submitted a change request for this user. Please review and
        approve or reject the request.
      </Typography>
      <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
        New Roles: {request.roles.join(', ')}
      </Typography>
      <div>
        <Button color="inherit" onClick={onApprove} sx={{ mr: 2 }}>
          Approve
        </Button>
        <Button color="inherit" onClick={onReject}>
          Reject
        </Button>
      </div>
    </Alert>
  );
}

export default function UserAccess() {
  const user = useRecord('users');

  return (
    <Stack spacing={3}>
      {user.has_special_access && <Chip label="Special Access" />}
      <FormField field={FieldFactory.belongsTo('business', 'businesses')} />

      <FormField
        field={FieldFactory.select('access_level', {
          0: 'Individual',
          1: 'Manager',
          10: 'Admin',
        })}
      />

      <div>
        <FormField field={FieldFactory.hasMany('roles', 'roles')} />
        <Typography variant="caption">
          Download a list of roles & their permissions{' '}
          <a href={getApiUrl('api/users/roles.xlsx')}>here</a>.
        </Typography>
      </div>
      {user.outstanding_change_requests!.map((cr) => (
        <ChangeRequestAlert key={cr.id} request={cr} />
      ))}
    </Stack>
  );
}
