import { Button } from '@mui/material';
import { FieldValues, UseFormReturn } from 'react-hook-form';

export default function SaveButton<T extends FieldValues>({
  form,
  onSubmit,
}: {
  form: UseFormReturn<T>;
  onSubmit: (values: T) => Promise<void>;
}) {
  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
  } = form;
  const isDirty = Object.keys(dirtyFields).length > 0;

  if (isDirty) {
    return (
      <Button disabled={isSubmitting} onClick={handleSubmit(onSubmit)} type="button">
        Save
      </Button>
    );
  }
  return null;
}
