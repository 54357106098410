import { Radio, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { UseControllerReturn } from 'react-hook-form';
import { Field } from '@/classes';
import { FieldProps } from '@/classes/types';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import { OrderItem } from '@/types';

function ChooseKitItems({ fieldModel, field }: FieldProps<ChooseKitItemsField>) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>Order #</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Alloc</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fieldModel.items.map((i) => (
          <TableRow key={i.id}>
            <TableCell padding="checkbox">
              <Radio checked={field.value === i.id} onChange={() => field.onChange(i.id)} />
            </TableCell>
            <TableCell>{i.order_id}</TableCell>
            <TableCell>
              <OrderItemProduct item={i} showSize />
            </TableCell>
            <TableCell>
              {i.qty_assigned} / {i.qty}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default class ChooseKitItemsField extends Field {
  items: OrderItem[];

  constructor(name: string, items: OrderItem[]) {
    super(name);
    this.items = items;
  }

  renderEditComponent(props: UseControllerReturn): React.ReactElement {
    return <ChooseKitItems {...props} fieldModel={this} />;
  }
}
