import { ForwardedRef, forwardRef } from 'react';
import { More } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import { Link } from 'react-router-dom';
import Action from '@/classes/Action';
import AnchorAction from '@/classes/Actions/AnchorAction';
import ButtonAction from '@/classes/Actions/ButtonAction';
import LinkAction from '@/classes/Actions/LinkAction';
import useOnClickProps from '@/hooks/useOnClickProps';

interface ActionIconButtonProps extends IconButtonProps {
  model: Action;
}

function ActionIconButton(
  { model, ...other }: ActionIconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const Icon = model.icon || More;
  const toPass = useOnClickProps();

  const props: IconButtonProps = {
    disabled: model.disabled,
    color: model.color,
  };

  if (model instanceof ButtonAction) {
    Object.assign(props, {
      onClick: () => model.onClick(toPass),
    });
  } else if (model instanceof AnchorAction) {
    Object.assign(props, {
      component: 'a',
      href: model.href,
      target: model.openInNew ? '_blank' : '_self',
    });
  } else if (model instanceof LinkAction) {
    Object.assign(props, {
      component: Link,
      to: model.to,
    });
  }

  return (
    <IconButton {...other} {...props} ref={ref}>
      <Icon />
    </IconButton>
  );
}

export default forwardRef(ActionIconButton);
