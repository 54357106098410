import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { AlertColor, Breakpoint } from '@mui/material';
import { DefaultValues, FieldValues } from 'react-hook-form';
import { ZodSchema, ZodTypeDef } from 'zod';
import { NullableFieldable } from '@/classes/types';
import ToastContainer from '@/components/App/ToastContainer';
import AlertDialogContainer from '@/components/Dialogs/AlertDialogContainer';
import ConfirmDialogContainer from '@/components/Dialogs/ConfirmDialogContainer';
import PromptDialogContainer from '@/components/Dialogs/PromptDialogContainer';
import PromptDrawerContainer from '@/components/Dialogs/PromptDrawerContainer';
import LoadingBackdrop from '@/components/Shared/LoadingBackdrop';

export interface ToastState {
  message: ReactNode;
  status: AlertColor;
}

interface GenericDialogOptions {
  title: string;
  description?: ReactNode;
  maxWidth?: Breakpoint;
  width?: number;
}

export interface PromptOptions<T extends FieldValues, Res = T> extends GenericDialogOptions {
  submitText?: string;
  cancelText?: string;
  fields: NullableFieldable[];
  initialValues?: DefaultValues<T>;
  schema: ZodSchema<T, ZodTypeDef, any>;
  onSubmit?: (data: T) => Promise<Res>;
}

export interface PromptDialogConfig<T extends FieldValues, Res> {
  open: boolean;
  promise: {
    resolve: (value: Res) => void;
    reject: () => void;
  };
  options: PromptOptions<T, Res>;
}

export interface AlertOptions extends GenericDialogOptions {
  color?: AlertColor;
  okText?: string;
}

export interface AlertDialogConfig {
  open: boolean;
  promise: {
    resolve: () => void;
    reject: () => void;
  };
  options: AlertOptions;
}

export interface ConfirmOptions extends GenericDialogOptions {
  color?: AlertColor;
  cancelText?: string;
  submitText?: string;
}

export interface ConfirmDialogConfig {
  open: boolean;
  promise: {
    resolve: () => void;
    reject: () => void;
  };
  options: ConfirmOptions;
}

export const DialogContext = createContext<{
  setToast: (t: ToastState | undefined) => void;
  setIsLoading: (t: boolean) => void;
  setAlertDialog: (state: AlertDialogConfig) => void;
  setConfirmDialog: (state: ConfirmDialogConfig) => void;
  setPromptDialog: (state: PromptDialogConfig<any, any>) => void;
  setPromptDrawer: (state: PromptDialogConfig<any, any>) => void;
}>({
  setIsLoading: () => {},
  setToast: () => {},
  setAlertDialog: () => {},
  setConfirmDialog: () => {},
  setPromptDialog: () => {},
  setPromptDrawer: () => {},
});

export function useDialogContext() {
  return useContext(DialogContext);
}

export function useShowLoading() {
  const { setIsLoading } = useDialogContext();

  return useCallback(
    function showLoading<T>(promise: Promise<T>): Promise<T> {
      setIsLoading(true);
      return promise.finally(() => {
        setIsLoading(false);
      });
    },
    [setIsLoading],
  );
}

export function useDialogs() {
  const { setPromptDialog, setConfirmDialog, setAlertDialog, setPromptDrawer } = useDialogContext();

  const prompt = <T extends FieldValues, Res = T>(options: PromptOptions<T, Res>) =>
    new Promise<Res>((resolve, reject) => {
      setPromptDialog({
        options,
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  const drawerPrompt = <T extends FieldValues, Res = T>(options: PromptOptions<T, Res>) =>
    new Promise<Res>((resolve, reject) => {
      setPromptDrawer({
        options,
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  const alert = (options: AlertOptions) =>
    new Promise<void>((resolve, reject) => {
      setAlertDialog({
        options,
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  const confirm = (options: ConfirmOptions) =>
    new Promise<void>((resolve, reject) => {
      setConfirmDialog({
        options,
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  return {
    prompt,
    confirm,
    alert,
    drawerPrompt,
  };
}

export function useToast() {
  const { setToast } = useDialogContext();

  return useCallback(
    (message: string, status?: AlertColor) => {
      setToast({ message, status: status || 'success' });
    },
    [setToast],
  );
}

export function DialogProvider({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState<ToastState>();
  const [alert, setAlertDialog] = useState<AlertDialogConfig>();
  const [confirm, setConfirmDialog] = useState<ConfirmDialogConfig>();
  const [prompt, setPromptDialog] = useState<PromptDialogConfig<any, any>>();
  const [promptDrawer, setPromptDrawer] = useState<PromptDialogConfig<any, any>>();

  const provide = useMemo(
    () => ({
      setIsLoading,
      setToast,
      setAlertDialog,
      setConfirmDialog,
      setPromptDialog,
      setPromptDrawer,
    }),
    [setIsLoading, setToast, setAlertDialog, setConfirmDialog, setPromptDialog, setPromptDrawer],
  );

  return (
    <div>
      <DialogContext.Provider value={provide}>{children}</DialogContext.Provider>

      <LoadingBackdrop isLoading={isLoading} />
      <ToastContainer toast={toast} setToast={setToast} />
      <ConfirmDialogContainer confirm={confirm} setConfirmDialog={setConfirmDialog} />
      <AlertDialogContainer alert={alert} setAlertDialog={setAlertDialog} />
      <PromptDialogContainer prompt={prompt} setPromptDialog={setPromptDialog} />
      <PromptDrawerContainer promptDrawer={promptDrawer} setPromptDrawer={setPromptDrawer} />
    </div>
  );
}
