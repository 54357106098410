import { FieldFactory, Resource } from '@/classes';
import { ProductVariant, productVariantPayloadSchema } from '@/types';

export default function productVariants() {
  return new Resource<ProductVariant>('Product Variants')
    .withSchema(productVariantPayloadSchema)
    .withFieldsAndColumns([
      FieldFactory.text('sku').withLabel('SKU'),
      FieldFactory.text('size'),
      FieldFactory.curr('price'),
      FieldFactory.curr('cost').with({ maxDecimals: 4 }),
    ])
    .addColumns([
      FieldFactory.number('inventory_qty').withLabel('Avail Inv'),
      FieldFactory.number('vendor_inventory_qty').withLabel('Vendor Inv'),
    ])
    .addFieldsAndColumns([
      FieldFactory.text('upc').withLabel('UPC'),
      FieldFactory.curr('value').withHelp('Value of the SKU for insurance and customs purposes'),
      FieldFactory.curr('wholesale'),
      FieldFactory.text('gtin').withLabel('GTIN'),
      FieldFactory.number('sort_order'),
      FieldFactory.number('inventory_threshold'),
    ]);
}
