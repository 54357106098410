import { HTMLInputTypeAttribute, ReactNode } from 'react';
import { InputAdornment, TextField as MaterialTextField } from '@mui/material';
import { UseControllerReturn } from 'react-hook-form';
import { FieldProps } from '@/classes/types';
import Field from '../Field';

export function TextInput({ field, fieldModel, fieldState }: FieldProps<TextInputField>) {
  const InputProps = fieldModel.InputProps || {};
  if (fieldModel.prefix) {
    InputProps.startAdornment = (
      <InputAdornment position="start">{fieldModel.prefix}</InputAdornment>
    );
  }
  if (fieldModel.postfix) {
    InputProps.endAdornment = <InputAdornment position="end">{fieldModel.postfix}</InputAdornment>;
  }

  return (
    <MaterialTextField
      {...field}
      {...fieldModel.getTextFieldProps(fieldState)}
      value={field.value || ''}
      onChange={(e) => {
        field.onChange(e.target.value === '' ? null : e.target.value);
      }}
      type={fieldModel.type}
      InputProps={InputProps}
    />
  );
}

export default class TextInputField extends Field {
  prefix?: ReactNode;
  postfix?: ReactNode;
  type: HTMLInputTypeAttribute;

  constructor(name: string, type: HTMLInputTypeAttribute = 'text') {
    super(name);
    this.type = type;
  }

  renderEditComponent(props: UseControllerReturn) {
    return <TextInput {...props} fieldModel={this} />;
  }
}
