import { ListItemAvatar, ListItemText } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { PaymentMethod } from '@stripe/stripe-js';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';

export default function StripeCardListItem({ card }: { card: PaymentMethod.Card }) {
  return (
    <>
      <ListItemAvatar>
        <Avatar alt={card.brand} src={`/cards/${kebabCase(card.brand)}.png`} />
      </ListItemAvatar>
      <ListItemText
        primary={`${startCase(card.brand)} ****${card.last4}`}
        secondary={`Exp ${card.exp_month}/${card.exp_year}`}
      />
    </>
  );
}
