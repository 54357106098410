import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Fab, Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { getOtherRowContext } from '@/hooks/usePushWithContext';
import useSearchParamsObject from '@/hooks/useSearchParamsObject';
import { addSearchParams } from '@/utils/query';

export default function PrevNextNavigation() {
  const { state: locationState, pathname } = useLocation();
  const [searchParams] = useSearchParamsObject();
  const { tab, ...otherQueryParams } = searchParams;
  const searchParamsWithoutTab = new URLSearchParams(otherQueryParams);
  let nextResource;
  let prevResource;
  if (locationState?.context) {
    const otherRows = getOtherRowContext(locationState.context);
    const currentIndex = otherRows.findIndex((row) => {
      if (row.href.includes('?')) {
        const [rowPathname, rowSearch] = row.href.split('?');
        const rowSearchParams = new URLSearchParams(rowSearch);
        return (
          rowPathname === pathname &&
          rowSearchParams.toString() === searchParamsWithoutTab.toString()
        );
      }
      return row.href === pathname;
    });

    if (currentIndex > -1) {
      prevResource = currentIndex > 0 ? otherRows[currentIndex - 1] : null;
      nextResource = otherRows[currentIndex + 1];
    }
  }

  return (
    <>
      {prevResource && (
        <Tooltip title={prevResource.label}>
          <Fab
            component={Link}
            to={addSearchParams(prevResource.href, { tab })}
            state={locationState}
            size="small"
            sx={(theme) => ({
              position: 'fixed',
              zIndex: 50,
              bottom: theme.spacing(1.5),
              [theme.breakpoints.up('md')]: {
                left: theme.spacing(2) + theme.drawerWidth,
              },
            })}
          >
            <ChevronLeft />
          </Fab>
        </Tooltip>
      )}

      {nextResource && (
        <Tooltip title={nextResource.label}>
          <Fab
            component={Link}
            to={addSearchParams(nextResource.href, { tab })}
            state={locationState}
            size="small"
            sx={(theme) => ({
              position: 'fixed',
              zIndex: 50,
              bottom: theme.spacing(1.5),
              right: theme.spacing(2),
            })}
          >
            <ChevronRight />
          </Fab>
        </Tooltip>
      )}
    </>
  );
}
