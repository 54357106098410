import { SyntheticEvent, useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  colors,
  Grid2 as Grid,
  Menu,
  MenuItem,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import { z } from 'zod';
import {
  useChangeIssueStatus,
  useDeleteIssue,
  useResolveIssue,
  useUpdateIssue,
} from '@/api/issues';
import { ButtonAction, FieldFactory } from '@/classes';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import PrintMenu from '@/components/Print/PrintMenu';
import PurchaseOrderItemsTable from '@/components/Purchasing/PurchaseOrderItemsTable';
import StatusChip from '@/components/Shared/StatusChip';
import SubMenu from '@/components/Shared/SubMenu';
import TextLink from '@/components/Text/TextLink';
import { ISSUE_STATUS_COLORS } from '@/constants';
import { useConfig } from '@/contexts/AppConfigContext';
import { useDialogs, useShowLoading } from '@/contexts/DialogContext';
import { useHasPermission } from '@/hooks/permissions';
import { getIssueFields, getIssueSchema } from '@/resources/receivingIssues';
import { Issue } from '@/types';
import EventableCard from '../Notes/EventableCard';

const StatusButton = styled('button')({
  borderRadius: 20,
  color: 'white',
  paddingLeft: 16,
  paddingRight: 16,
  whiteSpace: 'nowrap',
  border: 'none',
  paddingTop: 10,
  paddingBottom: 10,
});

export default function IssueDetails({
  issue,
  size,
  elevation = 1,
}: {
  issue: Issue;
  size?: 'small' | 'medium';
  elevation?: number;
}) {
  const { user } = useConfig();
  const { prompt, confirm } = useDialogs();
  const [anchorEl, setAnchorEl] = useState<Element>();
  const showLoading = useShowLoading();
  const updateRequest = useUpdateIssue(issue);
  const deleteRequest = useDeleteIssue(issue);
  const resolveRequest = useResolveIssue(issue);
  const statusRequest = useChangeIssueStatus(issue);
  const hasPermission = useHasPermission();

  const onResolve = () => {
    setAnchorEl(undefined);
    prompt({
      title: 'Resolve Issue',
      description: 'Please describe how the issue was resolved?',
      fields: [FieldFactory.textarea('resolution')],
      schema: z.object({
        resolution: z.string(),
      }),
      onSubmit: resolveRequest.mutateAsync,
    });
  };

  const onChangeStatus = (status: Issue['status']) => {
    setAnchorEl(undefined);
    statusRequest.mutate({ status });
  };

  const onEdit = () => {
    prompt({
      title: 'Edit Issue',
      fields: getIssueFields({
        orderId: issue.order?.id,
        purchaseOrderId: issue.purchase_order?.id,
        type: issue.type,
      }),
      initialValues: {
        type: issue.type,
        reason: issue.reason || '',
        details: issue.details,
        tracking_numbers: issue.tracking_numbers,
        location: issue.location,
        fields: issue.fields,
      },
      schema: getIssueSchema(),
      onSubmit: (v) => updateRequest.mutateAsync(v),
    });
  };

  const onDelete = () => {
    confirm({
      title: 'Delete Issue',
      description: 'Are you sure you want to delete this issue?',
      color: 'error',
    }).then(() => {
      showLoading(deleteRequest.mutateAsync());
    });
  };

  const getInner = () => {
    if (resolveRequest.isLoading || statusRequest.isLoading) {
      return <CircularProgress />;
    }

    if (issue.type === 'purchasing') {
      if (!issue.resolved_at) {
        return (
          <Button variant="contained" onClick={onResolve}>
            Resolve
          </Button>
        );
      }
      return <StatusChip status={issue.status} colors={ISSUE_STATUS_COLORS} />;
    }

    return (
      <div>
        <StatusButton
          type="button"
          style={{
            background: colors[ISSUE_STATUS_COLORS[issue.status]][500],
          }}
          onClick={(e: SyntheticEvent) => setAnchorEl(e.currentTarget)}
        >
          {startCase(issue.status)}
        </StatusButton>
        <Menu
          id="status-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(undefined)}
        >
          {Object.keys(omit(ISSUE_STATUS_COLORS, 'resolved')).map((k) => (
            <MenuItem
              key={k}
              onClick={() => onChangeStatus(k as Issue['status'])}
              selected={k === issue.status}
              disabled={k === issue.status}
            >
              {startCase(k)}
            </MenuItem>
          ))}
          <MenuItem
            onClick={onResolve}
            selected={issue.status === 'resolved'}
            disabled={issue.status === 'resolved'}
          >
            Resolved
          </MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <Stack spacing={1}>
      <Card elevation={elevation}>
        <CardHeader
          title={issue.label}
          action={
            <>
              <Box display="inline-flex" mr={2}>
                {getInner()}
              </Box>
              {issue.type === 'receiving' && <PrintMenu model="issue" id={issue.id} />}
              {issue.type === 'receiving' && issue.created_by === user.id && (
                <SubMenu
                  items={[
                    new ButtonAction('Edit', onEdit).withIcon(Edit),
                    new ButtonAction('Delete', onDelete).withIcon(Delete),
                  ]}
                />
              )}
            </>
          }
        />
        <CardContent>
          <Grid spacing={3} container>
            <Grid size={12}>
              <Typography sx={{ whiteSpace: 'pre-wrap', mt: -2 }}>{issue.details}</Typography>
            </Grid>

            <Grid size={{ xs: 12, lg: 6 }}>
              <Typography variant="subtitle2" gutterBottom>
                Order
              </Typography>
              <Typography>
                <TextLink
                  to={`/receiving-order/${issue.order?.id}`}
                  disabled={!issue.order || !hasPermission('read:receivings')}
                >
                  {issue.order?.increment_id || '(none)'}
                </TextLink>
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Typography variant="subtitle2" gutterBottom>
                Purchase Order
              </Typography>
              <Typography>
                <TextLink
                  to={`/receiving-po/${issue.purchase_order?.id}`}
                  disabled={!issue.purchase_order || !hasPermission('read:receivings')}
                >
                  {issue.purchase_order?.increment_id || '(none)'}
                </TextLink>
              </Typography>
            </Grid>
            {issue.reason && (
              <Grid size={{ xs: 12, lg: 6 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Reason
                </Typography>
                <Typography>{issue.reason}</Typography>
              </Grid>
            )}

            {issue.location && (
              <Grid size={{ xs: 12, lg: 6 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Location
                </Typography>
                <Chip variant="outlined" label={issue.location} />
              </Grid>
            )}

            {Object.entries(issue.fields || {}).map(([key, value]) => (
              <Grid size={{ xs: 12, lg: 6 }}>
                <Typography variant="subtitle2" gutterBottom>
                  {startCase(key)}
                </Typography>
                <Typography>{value}</Typography>
              </Grid>
            ))}

            {issue.tracking_numbers && (
              <Grid size={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Tracking Numbers
                </Typography>
                <Typography>{issue.tracking_numbers?.join(', ')}</Typography>
              </Grid>
            )}

            {issue.order_items && issue.order_items.length > 0 ? (
              <Grid size={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Affected Order Items
                </Typography>
                <Table size="small">
                  <TableBody>
                    {issue.order_items.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <OrderItemProduct item={item} showSize />
                        </TableCell>
                        <TableCell>Qty: {item.qty}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            ) : null}

            {issue.purchase_order_items && issue.purchase_order_items.length > 0 ? (
              <Grid size={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Affected PO Items
                </Typography>
                <PurchaseOrderItemsTable items={issue.purchase_order_items} />
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
      </Card>

      <EventableCard resource="issues" resourceId={issue.id} size={size} elevation={elevation} />
    </Stack>
  );
}
