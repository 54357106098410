import includes from 'lodash/includes';
import Numeral from 'numeral';
import curr from './curr';

export type Formattable = string | number | null | undefined;

export type StatFormat =
  | ((v: Formattable) => string)
  | 'short_curr'
  | 'curr'
  | 'currency'
  | 'full_currency'
  | 'full_curr'
  | 'number'
  | 'percent'
  | 'cents';

export function formatNumberWithTitle(
  n: Formattable,
  f?: StatFormat,
): { number: string; title?: string } {
  let number = n;
  let formatter: StatFormat | undefined = f;

  if (typeof formatter === 'function') {
    return {
      number: formatter(number),
      title: undefined,
    };
  }

  if (number == null) {
    return {
      number: '-',
      title: undefined,
    };
  }

  number = Number(n);

  if (formatter === 'cents') {
    formatter = 'curr';
    number = number / 100;
  }

  const numeral = Numeral(number);

  if (numeral.value() !== null) {
    if (includes(['currency', 'curr', 'short_curr'], formatter)) {
      return {
        number: numeral.format('$0.00a'),
        title: curr(number),
      };
    }

    if (includes(['full_currency', 'full_curr'], formatter)) {
      return {
        number: curr(number),
        title: curr(number),
      };
    }

    if (formatter === 'percent') {
      return {
        number: numeral.format('0.0%'),
        title: numeral.format('0.0000%'),
      };
    }

    if (formatter === 'number') {
      return {
        number: numeral.format('0[.]00a'),
        title: Number(number).toLocaleString(),
      };
    }
  }

  return {
    number: number.toLocaleString(),
    title: number.toLocaleString(),
  };
}

export default function formatNumber(number: Formattable, formatter?: StatFormat): string {
  return formatNumberWithTitle(number, formatter).number;
}
