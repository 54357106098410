import { Stack } from '@mui/material';
import { NullableFieldable } from '@/classes/types';
import EditFormFields, { EditFormFieldsProps } from '@/components/Form/EditFormFields';
import Layout from '../Layout';
import GridLayout from './GridLayout';

export default class StackLayout extends Layout {
  constructor(name: string, fields: NullableFieldable[]) {
    super(name, fields);
    this.defaultLayout = GridLayout;
    this.padding = true;
  }

  renderLayout(props: Partial<EditFormFieldsProps>): React.ReactElement | null {
    return (
      <Stack spacing={this.spacing}>
        <EditFormFields fields={this.fields} {...props} />
      </Stack>
    );
  }
}
