import PlusIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { UseControllerReturn } from 'react-hook-form';
import { FieldProps } from '@/classes/types';
import Field from '../Field';

function Qty({ fieldModel, fieldState, field }: FieldProps<QtyField>) {
  return (
    <TextField
      {...field}
      {...fieldModel.getTextFieldProps(fieldState)}
      type="number"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={() => field.onChange(Number(field.value) - 1)} size="small">
              <MinusIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={() => field.onChange(Number(field.value) + 1)} size="small">
              <PlusIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default class QtyField extends Field {
  renderEditComponent(props: UseControllerReturn) {
    return <Qty {...props} fieldModel={this} />;
  }
}
