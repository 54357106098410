import {
  Alert,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Review from '@/components/Importer/Review';
import Upload from '@/components/Importer/Upload';
import StatusChip from '@/components/Shared/StatusChip';
import { MATCH_STATUS_COLORS, MATCH_STATUS_LABELS } from '@/constants';
import { useAssetUrl } from '@/contexts/AppConfigContext';
import { useTitle } from '@/contexts/AppContext';
import { Transaction } from '@/types';
import curr from '@/utils/curr';

export default function TransactionImporter() {
  useTitle('Import Transactions');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const asset = useAssetUrl();

  const fileName = searchParams.get('file');

  const {
    data: imported,
    isLoading: isImporting,
    mutate: onProcess,
  } = useMutation(() =>
    axios
      .post<{ data: Transaction[] }>('/api/transactions/import', { file: fileName })
      .then(({ data }) => data.data),
  );

  if (imported && fileName) {
    return (
      <Card>
        <CardContent>
          <Alert severity="success">Success! The following transactions were created.</Alert>
        </CardContent>

        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>Transaction #</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Vendor</TableCell>
              <TableCell>Ref #</TableCell>
              <TableCell>PO #</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>PO Matching</TableCell>
            </TableHead>
            <TableBody>
              {imported.map((i) => (
                <TableRow key={i.id} hover onClick={() => navigate(`/transactions/${i.id}`)}>
                  <TableCell>{i.id}</TableCell>
                  <TableCell>{i.type}</TableCell>
                  <TableCell>{i.vendor?.name}</TableCell>
                  <TableCell>{i.ref_number}</TableCell>
                  <TableCell>{i.po_number}</TableCell>
                  <TableCell>{curr(i.total)}</TableCell>
                  <TableCell>
                    <StatusChip
                      status={i.match_status}
                      colors={MATCH_STATUS_COLORS}
                      labels={MATCH_STATUS_LABELS}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    );
  }

  if (fileName) {
    return (
      <Review
        fileName={fileName}
        onProcess={onProcess}
        onError={() => setSearchParams({})}
        importing={isImporting}
      />
    );
  }

  return (
    <Upload onSuccess={(file) => setSearchParams({ file })}>
      <Typography gutterBottom>
        <span>Please upload a CSV in accordance with </span>
        <a href={asset('transaction_import_template.csv')}>the template</a>
      </Typography>
    </Upload>
  );
}
