import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ProductImage from '@/components/Shared/ProductImage';
import ArrayBullets from '@/components/Text/ArrayBullets';
import Text from '@/components/Text/Text';
import { ProductWithVariants } from '@/types';
import curr from '@/utils/curr';
import formatNumber from '@/utils/formatNumber';

export default function ProductTable({
  products,
  onSelect,
}: {
  products: ProductWithVariants[];
  onSelect: (v: ProductWithVariants) => void;
}) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell>Product</TableCell>
            <TableCell>MSRP</TableCell>
            <TableCell>Cost</TableCell>
            <TableCell>AV Inv</TableCell>
            <TableCell>Ven Inv</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((p) => (
            <TableRow key={p.id} hover onClick={() => onSelect(p)}>
              <TableCell padding="checkbox">
                <ProductImage product={p} />
              </TableCell>
              <TableCell>
                <Text
                  primary={p.name}
                  secondary={
                    <ArrayBullets
                      elements={{
                        style: p.style_number,
                        color_code: p.color_code,
                        vendor: p.vendor?.name,
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell>{curr(p.price || 0)}</TableCell>
              <TableCell>{curr(p.cost || 0)}</TableCell>
              <TableCell>{formatNumber(p.inventory_qty || 0, 'number')}</TableCell>
              <TableCell>{formatNumber(p.vendor_inventory_qty || 0, 'number')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
