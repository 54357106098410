import { useFormContext } from 'react-hook-form';
import { Design } from '@/types';
import DesignSection from './DesignSection';

export default function DesignField() {
  const form = useFormContext<Pick<Design, 'id' | 'decoration_type'>>();
  const id = form.watch('id');
  const decorationType = form.watch('decoration_type');

  return <DesignSection id={id} decorationType={decorationType} />;
}
