import { ChangeEvent, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  InputLabel,
} from '@mui/material';
import { ThirdPartyShippingAccount } from '@/types';

export default function ChooseAccount({
  accountId,
  onChange,
  carrier,
  accounts,
}: {
  accountId: number | null;
  onChange: (a: number | null) => void;
  carrier?: string;
  accounts: ThirdPartyShippingAccount[];
}) {
  const [isThird, setIsThird] = useState(!!accountId);

  useEffect(() => {
    if (accountId) {
      setIsThird(true);
    } else {
      setIsThird(false);
    }
  }, [accountId]);

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIsThird(true);
    } else {
      setIsThird(false);
      onChange(null);
    }
  };

  return (
    <div>
      <FormControlLabel
        label="Ship using Third Party Account"
        control={<Checkbox checked={isThird} size="small" onChange={handleCheckbox} />}
      />

      {isThird && (
        <FormControl fullWidth size="small" sx={{ mb: 1, mt: 0.5 }}>
          <InputLabel id="third-party-account-label">Third Party Account</InputLabel>
          <Select
            labelId="third-party-account-label"
            id="third-party-account"
            value={accountId}
            onChange={(e) => onChange(Number(e.target.value))}
            label="Third Party Account"
          >
            {accounts
              .filter((a) => !carrier || a.carrier === carrier)
              .map((a) => (
                <MenuItem key={a.id} value={a.id}>
                  <ListItemText
                    sx={{ mb: 0, mt: 0.25 }}
                    primary={a.name}
                    secondary={`${a.carrier.toUpperCase()} ${a.account_number}`}
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}
