import accounts from './accounts';
import addresses from './addresses';
import artCostItems from './artCostItems';
import artRequestActivities from './artRequestActivities';
import artRequests from './artRequests';
import bankAccounts from './bankAccounts';
import bankingRules from './bankingRules';
import boxTypes from './boxTypes';
import brands from './brands';
import businessCredits from './businessCredits';
import businessPayouts from './businessPayouts';
import businesses from './businesses';
import commissionModels from './commissionModels';
import contacts from './contacts';
import customerContacts from './customerContacts';
import customerDomains from './customerDomains';
import customerSegments from './customerSegments';
import customers from './customers';
import decorationLocations from './decorationLocations';
import decorationTypes from './decorationTypes';
import departmentSets from './departmentSets';
import departments from './departments';
import deposits from './deposits';
import designLayouts from './designLayouts';
import designLibrary from './designLibrary';
import designs from './designs';
import fulfillmentOrders from './fulfillmentOrders';
import groups from './groups';
import inboundShipments from './inboundShipments';
import inventoryAdjustments from './inventoryAdjustments';
import inventoryLocationGroups from './inventoryLocationGroups';
import inventoryLocations from './inventoryLocations';
import inventoryLocationsForPage from './inventoryLocationsForPage';
import inventoryPicks from './inventoryPicks';
import inventoryReturns from './inventoryReturns';
import inventoryVariants from './inventoryVariants';
import journalEntries from './journalEntries';
import ledgerEntries from './ledgerEntries';
import orderPackouts from './orderPackouts';
import orderTypes from './orderTypes';
import orders from './orders';
import ordersAtRisk from './ordersAtRisk';
import ordersToProduce from './ordersToProduce';
import ordersToPurchase from './ordersToPurchase';
import ordersToReceive from './ordersToReceive';
import ordersToShip from './ordersToShip';
import payments from './payments';
import productVariants from './productVariants';
import productionEventTypes from './productionEventTypes';
import productionEvents from './productionEvents';
import productionMachines from './productionMachines';
import products from './products';
import projects from './projects';
import purchaseOrderItems from './purchaseOrderItems';
import purchaseOrders from './purchaseOrders';
import purchaseOrdersToReceive from './purchaseOrdersToReceive';
import quotes from './quotes';
import rebates from './rebates';
import receivingIssues from './receivingIssues';
import roles from './roles';
import shipments from './shipments';
import shippingMethods from './shippingMethods';
import specialDesignTypes from './specialDesignTypes';
import stockColors from './stockColors';
import tags from './tags';
import terms from './terms';
import thirdPartyShippingAccounts from './thirdPartyShippingAccounts';
import tokens from './tokens';
import transactions from './transactions';
import users from './users';
import vendors from './vendors';

export default {
  accounts,
  addresses,
  artCostItems,
  artRequestActivities,
  artRequests,
  bankAccounts,
  bankingRules,
  boxTypes,
  brands,
  businessCredits,
  businessPayouts,
  businesses,
  commissionModels,
  contacts,
  customerContacts,
  customerDomains,
  customerSegments,
  customers,
  decorationLocations,
  decorationTypes,
  departmentSets,
  departments,
  deposits,
  designLayouts,
  designLibrary,
  designs,
  fulfillmentOrders,
  groups,
  inboundShipments,
  inventoryAdjustments,
  inventoryLocationGroups,
  inventoryLocations,
  inventoryLocationsForPage,
  inventoryPicks,
  inventoryReturns,
  inventoryVariants,
  journalEntries,
  ledgerEntries,
  orderPackouts,
  orderTypes,
  orders,
  ordersAtRisk,
  ordersToProduce,
  ordersToPurchase,
  ordersToReceive,
  ordersToShip,
  payments,
  productVariants,
  productionEventTypes,
  productionEvents,
  productionMachines,
  products,
  projects,
  purchaseOrderItems,
  purchaseOrders,
  purchaseOrdersToReceive,
  quotes,
  rebates,
  receivingIssues,
  roles,
  shipments,
  shippingMethods,
  specialDesignTypes,
  stockColors,
  tags,
  terms,
  thirdPartyShippingAccounts,
  tokens,
  transactions,
  users,
  vendors,
};
