import { CardHeader, Grid2 as Grid, Card, CardContent, Box, Skeleton } from '@mui/material';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import StatCard from '@/components/Stats/StatCard';
import { RECEIVABLE_BREAKDOWN_LABELS } from '@/constants';
import { ReceivablesBreakdown, ReceivablesSummary as SummaryModel } from '@/types';
import curr from '@/utils/curr';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#cf000f'];

export default function ReceivablesSummary({
  summary,
  mode,
}: {
  summary?: SummaryModel;
  mode: 'all' | 'current' | 'past_due';
}) {
  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 4, lg: 3 }}>
        <Box mb={3}>
          <StatCard
            title="Total Balance ($)"
            number={summary && summary.balance}
            loading={!summary}
            format="curr"
          />
        </Box>
        {mode === 'all' && (
          <StatCard
            title="Past Due ($)"
            number={summary && summary.past_due_balance}
            loading={!summary}
            format="curr"
          />
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 4, lg: 3 }}>
        <Box mb={3}>
          <StatCard
            title="# Invoices"
            number={summary && summary.count}
            loading={!summary}
            format="number"
          />
        </Box>
        {mode === 'all' && (
          <StatCard
            title="# Past Due"
            number={summary && summary.past_due_count}
            loading={!summary}
            format="number"
          />
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 4, lg: 3 }}>
        <Card>
          <CardHeader
            title="Invoice Count Breakdown"
            titleTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }}
          />
          <CardContent>
            {summary ? (
              <PieChart width={300} height={250}>
                <Pie
                  dataKey="count"
                  data={Object.entries(summary.breakdown)
                    .map(([k, v]) => ({
                      ...v,
                      name: RECEIVABLE_BREAKDOWN_LABELS[k as keyof ReceivablesBreakdown],
                    }))
                    .filter((v) => v.count > 0)}
                  outerRadius={60}
                  label
                >
                  {COLORS.map((c, i) => (
                    <Cell key={i} fill={COLORS[i]} />
                  ))}
                </Pie>
                {/* @ts-ignore */}
                <Legend formatter={(label, { payload }) => `${label} (${payload.count})`} />
              </PieChart>
            ) : (
              <Skeleton variant="circular" width={125} height={125} style={{ margin: 25 }} />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid size={{ xs: 12, md: 4, lg: 3 }}>
        <Card>
          <CardHeader
            title="Balance Breakdown"
            titleTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }}
          />
          <CardContent>
            {summary ? (
              <PieChart width={300} height={250}>
                <Pie
                  dataKey="balance"
                  data={Object.entries(summary.breakdown)
                    .map(([k, v]) => ({
                      ...v,
                      name: RECEIVABLE_BREAKDOWN_LABELS[k as keyof ReceivablesBreakdown],
                    }))
                    .filter((v) => v.balance > 0)}
                  outerRadius={60}
                >
                  {COLORS.map((c, i) => (
                    <Cell key={i} fill={COLORS[i]} />
                  ))}
                </Pie>
                {/* @ts-ignore */}
                <Legend formatter={(label, { payload }) => `${label} (${curr(payload.balance)})`} />
              </PieChart>
            ) : (
              <Skeleton variant="circular" width={125} height={125} style={{ margin: 25 }} />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
