import { ReactNode } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/OutlinedInput';
import { red } from '@mui/material/colors';
import { ColumnDef } from '@tanstack/react-table';
import { UseControllerReturn } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { FieldProps } from '@/classes/types';
import InputWrapper from '@/components/Form/InputWrapper';
import curr from '@/utils/curr';
import Field from '../Field';

export const setter = (value: any, divideBy: number) => {
  if (value == null || value === 0 || value === '' || value === '-_') {
    return value;
  }
  return Number(value.replace(/[^0-9.-]/g, '')) * divideBy;
};

export const getter = (value: any, divideBy: number) => {
  if (value == null || value === 0 || value === '' || value === '-_') {
    return value;
  }
  return value / divideBy;
};

function Currency({ fieldState, fieldModel, field }: FieldProps<CurrencyField>) {
  let { onChange, value } = field;
  const divideBy = fieldModel.cents ? 100 : 1;

  if (divideBy !== 1) {
    onChange = (e) => field.onChange(setter(e.target.value, divideBy));
    value = getter(field.value, divideBy);
  }

  const maskOverrides = {
    decimalLimit: fieldModel.maxDecimals || 2,
  };

  const maskOptions = {
    allowDecimal: true,
    allowNegative: true,
    prefix: '',
    thousandsSeparatorSymbol: '',
    ...maskOverrides,
  };

  return (
    <InputWrapper fieldState={fieldState} fieldModel={fieldModel}>
      <MaskedInput
        onChange={onChange}
        value={value}
        mask={createNumberMask(maskOptions)}
        placeholder={fieldModel.placeholder}
        render={(ref, props) => (
          <Input
            {...props}
            inputRef={ref}
            label={fieldModel.label}
            size={fieldModel.size}
            margin={fieldModel.margin}
            disabled={fieldModel.disabled}
            required={fieldModel.required}
            fullWidth={fieldModel.fullWidth}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            endAdornment={fieldModel.postfix}
          />
        )}
      />
    </InputWrapper>
  );
}

export default class CurrencyField extends Field {
  minDecimals = 2;
  maxDecimals = 2;
  postfix?: ReactNode;
  cents = false;

  renderEditComponent(props: UseControllerReturn) {
    return <Currency {...props} fieldModel={this} />;
  }

  renderCell(value: any) {
    const { minDecimals, maxDecimals, cents } = this;
    return (
      <span style={{ color: value < 0 ? red[500] : 'inherit' }}>
        {curr(value, { minDecimals, maxDecimals, divideBy: cents ? 100 : 1 })}
      </span>
    );
  }

  forReactTable(): ColumnDef<any, any> {
    return {
      ...super.forReactTable(),
      size: 75,
    };
  }
}
