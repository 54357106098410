import { ReactNode } from 'react';
import { Box, FormLabel } from '@mui/material';
import mapValues from 'lodash/mapValues';
import startCase from 'lodash/startCase';
import { UseControllerReturn } from 'react-hook-form';
import SelectField from '@/classes/Fields/SelectField';
import StatusChip from '@/components/Shared/StatusChip';
import Field from '../Field';

function Status({ status, fieldModel }: { status: string; fieldModel: StatusField }) {
  return (
    <div>
      {fieldModel.label && <FormLabel>{fieldModel.label}</FormLabel>}
      <Box mt={fieldModel.label ? 1 : 0}>
        <StatusChip
          status={status}
          colors={fieldModel.colors}
          labels={fieldModel.labels}
          startCase={fieldModel.startCase}
        />
      </Box>
    </div>
  );
}

export default class StatusField extends Field {
  colors?: Record<string, string> = {};
  labels?: Record<string, string> = {};
  startCase = true;

  constructor(name: string, colors?: Record<string, string>, labels?: Record<string, string>) {
    super(name);
    this.colors = colors;
    this.labels = labels;
  }

  getFilterFieldForType(): Field {
    let options: Record<string, string> = this.labels || {};
    if (Object.keys(options).length === 0 && this.colors) {
      options = mapValues(this.colors, (v, k) => startCase(k));
    }
    return new SelectField(this.name, options).with({ includeBlank: true });
  }

  renderCell(value: any, row?: Record<string, any>): ReactNode {
    return (
      <StatusChip
        status={value}
        colors={this.colors}
        labels={this.labels}
        startCase={this.startCase}
      />
    );
  }

  renderEditComponent(props: UseControllerReturn) {
    return <Status status={props.field.value} fieldModel={this} />;
  }
}
