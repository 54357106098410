import * as Types from 'avail-types';
import { z } from 'zod';

declare module '@mui/material/styles' {
  interface Theme {
    drawerWidth: number;
    drawerColor?: string;
  }

  interface ThemeOptions {
    drawerWidth?: number;
    drawerColor?: string;
  }
}

export * from 'avail-types';

export interface ReportTypeProps {
  results: Types.ReportResultsRow[];
  compareTo?: Types.CompareTo;
  compareToResults?: Types.ReportResultsRow[];
  summary?: Types.Report['summary'];
  columns?: Types.Report['columns'];
  type?: Types.Report['type'];
}

export type OrderTotals = Pick<
  Types.Order,
  | 'total'
  | 'paid'
  | 'balance'
  | 'subtotal'
  | 'shipping'
  | 'tax'
  | 'inbound_freight'
  | 'last_tax_hash'
  | 'current_tax_hash'
  | 'total_exempt'
  | 'items_sum'
  | 'precollected_tax'
  | 'total_tax'
>;

export type PendingPayout = Types.PartiallyRequired<Types.Business, 'balance' | 'commissions_sum'>;

export type ProductWithVariants = Types.PartiallyRequired<Types.ProductDetail, 'variants'>;

export type DesignLayoutWithProofs = Types.PartiallyRequired<
  Types.DesignLayout,
  'customer_proofs' | 'production_proofs' | 'order_designs'
>;

export type PurchaseOrderWithItems = Types.PartiallyRequired<Types.PurchaseOrder, 'items'>;

export type ShipmentWithDetails = Types.PartiallyRequired<Types.Shipment, 'items' | 'kits'>;

export type InventoryPickWithItems = Types.InventoryPick & { items: Types.InventoryPickItem[] };

export type PaymentWithOps = Types.PartiallyRequired<Types.Payment, 'order_payments'>;

export type OrderWithDetails = Required<Types.Order>;

export type TransactionWithItems = Types.PartiallyRequired<Types.Transaction, 'items'>;

export type PurchaseOrderForMatching = Types.PartiallyRequired<
  Types.PurchaseOrder,
  'match_issues' | 'is_match' | 'items'
> & {
  matched_transactions: TransactionWithItems[];
};

export type ContactWithCustomer = Types.PartiallyRequired<Types.CustomerContact, 'customer'>;

export const orderItemWizardRowSchema = z.object({
  id: z.number().optional(),
  variant: Types.genericModelReferenceSchema.nullish(),
  size: z.string().nullish(),
  qty: z.coerce.number().int(),
  price: z.coerce.number(),
  cost: z.coerce.number().nullish(),
});

export type DesignColorPayloadWithRelations = Types.DesignColorPayload & {
  stock_color?: Types.StockColor | null;
  ink_changes_count?: number;
};

export type DesignPayloadWithRelations = Types.DesignPayload & {
  colors: DesignColorPayloadWithRelations[];
};

export type AddressWithKits = Types.PartiallyRequired<Types.Address, 'kits'>;

export type KitWithAddress = Types.PartiallyRequired<Types.Kit, 'address'>;
