import { Box, BoxProps } from '@mui/material';

export default function Chip({ children, ...props }: BoxProps) {
  return (
    <Box
      display="inline-block"
      px={1.5}
      py={0.5}
      fontSize="small"
      bgcolor="primary.main"
      borderRadius="16px"
      color="white"
      {...props}
    >
      {children}
    </Box>
  );
}
