import { SyntheticEvent, useState } from 'react';
import { CalendarToday, Refresh } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import includes from 'lodash/includes';
import pick from 'lodash/pick';
import moment from 'moment';
import {
  useDeleteProductionEvent,
  useDuplicateProductionEvent,
  useProductionEventAction,
  useResetProductionEvents,
  useUpdateProductionEvent,
} from '@/api/production';
import { ButtonAction, FieldFactory } from '@/classes';
import Can from '@/components/Permissions/Can';
import StatusChip from '@/components/Shared/StatusChip';
import SubMenu from '@/components/Shared/SubMenu';
import Text from '@/components/Text/Text';
import TextButton from '@/components/Text/TextButton';
import { PRODUCTION_EVENT_STATUS_COLORS } from '@/constants';
import { useDialogs } from '@/contexts/DialogContext';
import { useSchedulingState } from '@/contexts/SchedulingContext';
import { useHasPermission } from '@/hooks/permissions';
import { ProductionEvent, productionEventUpdatePayloadSchema } from '@/types';
import OrderDesignLabel from '../Designs/OrderDesignLabel';
import ScheduleDrawer from './ScheduleDrawer';
import ScreenLocation from './ScreenLocation';

class ProdAction extends ButtonAction {
  declare onClick: () => void;
  declare label: string;
  canBePrimary = false;
}

export default function ProductionEventList({
  orderId,
  events,
  selected,
  onSelect,
}: {
  orderId: number;
  events: ProductionEvent[];
  selected?: ProductionEvent;
  onSelect: (e: ProductionEvent) => void;
}) {
  const { setEventIds } = useSchedulingState();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { confirm, prompt } = useDialogs();
  const hasPermission = useHasPermission();

  const resetRequest = useResetProductionEvents(orderId);
  const actionRequest = useProductionEventAction();
  const updateRequest = useUpdateProductionEvent();
  const deleteRequest = useDeleteProductionEvent(orderId);
  const duplicateRequest = useDuplicateProductionEvent();
  const isLoading =
    resetRequest.isLoading ||
    actionRequest.isLoading ||
    updateRequest.isLoading ||
    deleteRequest.isLoading ||
    duplicateRequest.isLoading;

  const resetEvents = () => {
    confirm({
      title: 'Reset Production Events',
      description:
        'Are you sure you want to reset the production events for this order? This will delete and recreate all production events.',
      color: 'error',
    }).then(() => {
      resetRequest.mutate();
    });
  };

  const onPerformAction = (ev: ProductionEvent, action: string) => {
    actionRequest.mutate({ eventId: ev.id, action });
  };

  const onUpdateQty = (ev: ProductionEvent) => {
    prompt({
      title: 'Update Quantity',
      fields: [FieldFactory.number('quantity').withSize('medium')],
      initialValues: pick(ev, 'quantity'),
      schema: productionEventUpdatePayloadSchema.pick({ quantity: true }),
      onSubmit: (v) => updateRequest.mutateAsync({ id: ev.id, ...v }),
    });
  };

  const onDelete = (ev: ProductionEvent) => {
    confirm({
      title: 'Delete Event',
      description: 'Are you sure you want to delete this production event?',
      color: 'error',
    }).then(() => {
      deleteRequest.mutate(ev.id);
    });
  };

  const onDuplicate = (ev: ProductionEvent) => {
    confirm({
      title: 'Duplicate Event',
      description: 'Are you sure you want to duplicate this production event?',
    }).then(() => {
      duplicateRequest.mutate(ev.id);
    });
  };

  const handleSelectAll = (e: SyntheticEvent) => {
    e.stopPropagation();
    setSelectedIds((prev) => {
      if (prev.length > 0) {
        return [];
      }
      return events.map((e) => e.id);
    });
  };

  const handleSelectOne = (e: SyntheticEvent, eventId: number) => {
    e.stopPropagation();
    setSelectedIds((prev) => {
      if (prev.includes(eventId)) {
        return prev.filter((p) => p !== eventId);
      }
      return [...prev, eventId];
    });
  };

  const isScreenprint = (event: ProductionEvent) => event.event_type.unit === 'colors';

  const hasScreenprintEvents = events.some(isScreenprint);

  const eventActions = (
    <div>
      {selectedIds.length > 0 && (
        <Tooltip title="Schedule Events">
          <IconButton onClick={() => setEventIds(selectedIds)} size="large">
            <CalendarToday />
          </IconButton>
        </Tooltip>
      )}
      <Can permission="production_events:admin">
        <Tooltip title="Reset Production Events">
          <IconButton onClick={resetEvents} size="large">
            <Refresh />
          </IconButton>
        </Tooltip>
      </Can>
    </div>
  );

  return (
    <Card>
      <CardHeader title="Events" action={eventActions} />
      {isLoading && <LinearProgress />}
      {events.length === 0 ? (
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            There are no events for this order
          </Typography>
        </CardContent>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <Can permission="write:production_events">
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedIds.length > 0 && selectedIds.length === events.length}
                      indeterminate={selectedIds.length > 0 && selectedIds.length < events.length}
                      onClick={handleSelectAll}
                    />
                  </TableCell>
                </Can>
                <TableCell>Machine</TableCell>
                <TableCell>Design</TableCell>
                {hasScreenprintEvents && <TableCell>Scr Loc</TableCell>}
                <TableCell>Quantity</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => {
                const isSelected = event.id === selected?.id;
                const actions: ProdAction[] = [];

                forOwn(event.actions, (v, k) => {
                  const action = new ProdAction(v, () => onPerformAction(event, k));
                  action.canBePrimary = !['undo', 'restart'].includes(k);
                  actions.push(action);
                });

                actions.push(
                  new ProdAction('Duplicate', () => onDuplicate(event)).withPermission(
                    'production_events:admin',
                  ),
                  new ProdAction('Delete', () => onDelete(event)).withPermission(
                    'production_events:admin',
                  ),
                );

                const getPrimaryAction = () => {
                  if (actions[0]?.canBePrimary) {
                    const action = actions.shift();
                    if (action) {
                      return (
                        <LoadingButton
                          onClick={action.onClick}
                          loading={actionRequest.isLoading}
                          variant="contained"
                          size="small"
                        >
                          {action.label}
                        </LoadingButton>
                      );
                    }
                  }
                  return null;
                };

                return (
                  <TableRow
                    key={event.id}
                    selected={isSelected}
                    hover
                    onClick={() => onSelect(event)}
                  >
                    <Can permission="write:production_events">
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={includes(selectedIds, event.id)}
                          onClick={(e) => handleSelectOne(e, event.id)}
                        />
                      </TableCell>
                    </Can>
                    <TableCell>
                      <Text
                        primary={get(event, 'machine.name', '(none)')}
                        secondary={get(event, 'event_type.name')}
                      />
                    </TableCell>
                    <TableCell>
                      <Text
                        primary={<OrderDesignLabel orderDesign={event.order_design} />}
                        secondary={event.order_design.design.location}
                      />
                    </TableCell>
                    {hasScreenprintEvents && (
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        <ScreenLocation event={event} />
                      </TableCell>
                    )}
                    <TableCell>
                      <TextButton
                        disabled={!hasPermission('production_events:admin')}
                        onClick={() => onUpdateQty(event)}
                      >
                        {event.quantity}
                      </TextButton>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: 1 }}>
                      <StatusChip
                        status={event.status}
                        size="small"
                        colors={PRODUCTION_EVENT_STATUS_COLORS}
                      />
                      <br />
                      {event.scheduled_date && (
                        <Can permission="write:production_events">
                          <Typography variant="caption" color="textSecondary">
                            {moment(event.scheduled_date).format('l')}
                          </Typography>
                        </Can>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ height: 80, whiteSpace: 'nowrap' }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Can permission="write:production_events">
                        <Box mr={2} display="inline-block">
                          {isSelected && getPrimaryAction()}
                        </Box>
                        {actions.length > 0 && <SubMenu items={actions} size="small" />}
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <ScheduleDrawer />
    </Card>
  );
}
