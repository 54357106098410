import { CheckCircle, Error, Info, Warning } from '@mui/icons-material';
import { DialogTitle, DialogContent, DialogActions, Button, AlertColor } from '@mui/material';
import { ConfirmOptions } from '@/contexts/DialogContext';

const getIconForColor = (color?: AlertColor) => {
  if (!color) {
    return null;
  }
  const styles = {
    marginRight: 8,
    marginTop: -2,
    verticalAlign: 'middle',
  };
  const iconMap = {
    error: Error,
    warning: Warning,
    success: CheckCircle,
    info: Info,
  };
  const Icon = iconMap[color];
  if (Icon) {
    return <Icon style={styles} color={color} />;
  }
  return null;
};

export default function ConfirmDialog({
  onCancel,
  onSuccess,
  color,
  title = 'Confirm',
  description,
  cancelText = 'No',
  submitText = 'Yes',
}: {
  onCancel: () => void;
  onSuccess: () => void;
} & ConfirmOptions) {
  return (
    <>
      <DialogTitle>
        {getIconForColor(color)}
        {title}
      </DialogTitle>
      {description && (
        <DialogContent
          sx={{
            pt: 0,
            pb: 2,
          }}
        >
          {description}
        </DialogContent>
      )}
      <DialogActions>
        <Button type="button" color={color || 'primary'} onClick={onCancel}>
          {cancelText}
        </Button>
        <Button type="button" color={color || 'primary'} variant="contained" onClick={onSuccess}>
          {submitText}
        </Button>
      </DialogActions>
    </>
  );
}
