import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { z } from 'zod';
import { useCreatePaymentMethod } from '@/api/paymentMethods';
import { FieldFactory } from '@/classes';
import { useDialogs } from '@/contexts/DialogContext';
import curr from '@/utils/curr';
import { useRecord } from '@/utils/genericResource';
import ChooseSavedCard from './ChooseSavedCard';

export default function ChargeCustomerForm({ onSuccess }: { onSuccess: () => void }) {
  const { customer, balance, id: orderId } = useRecord('orders');
  const [selected, setSelected] = useState<string | null>(null);
  const [loadingText, setLoadingText] = useState('');
  const [error, setError] = useState('');
  const { prompt } = useDialogs();

  const createRequest = useCreatePaymentMethod(customer.id);

  const submit = (amount?: number) => {
    setLoadingText(`Charging ${curr(amount || balance)}`);

    return axios
      .post('/api/order-payments/charge', {
        order_id: orderId,
        payment_method: selected,
        amount,
      })
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        setError(e.response?.data?.message || e.message);
      })
      .finally(() => {
        setLoadingText('');
      });
  };

  const chargeAmount = () => {
    prompt({
      title: 'Charge Specific Amount',
      description: `How much of the ${curr(balance)} balance would you like to charge?`,
      fields: [FieldFactory.curr('amount').withSize('medium')],
      schema: z.object({
        amount: z.coerce.number().positive(),
      }),
      initialValues: { amount: balance },
    }).then((v) => submit(v.amount));
  };

  if (balance <= 0) {
    return (
      <Typography variant="body2" color="textSecondary">
        Nothing is owed on this order.
      </Typography>
    );
  }

  return (
    <div>
      <ChooseSavedCard
        customerId={customer.id}
        selected={selected}
        setSelected={setSelected}
        allowNew={false}
      />

      <LoadingButton
        loading={createRequest.isLoading}
        onClick={() => createRequest.mutate({ url: window.location.href })}
        sx={{ mt: 1 }}
      >
        Add Payment Method
      </LoadingButton>

      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}

      <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" onClick={() => submit()} disabled={!selected || !!loadingText}>
          {loadingText || `Charge ${curr(balance)}`}
        </Button>

        <Button onClick={chargeAmount} disabled={!selected || !!loadingText}>
          Charge Other Amount
        </Button>
      </Box>
    </div>
  );
}
