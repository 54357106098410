import { FieldFactory, GroupLayout, Resource } from '@/classes';
import { CustomerContact, customerContactPayloadSchema } from '@/types';

export default function customerContacts() {
  return new Resource<CustomerContact>('Contacts')
    .withSchema(customerContactPayloadSchema)
    .withInitialColumns([
      'first_name',
      'last_name',
      'department',
      'email',
      'is_approval_contact',
      'is_invoice_contact',
    ])
    .withFieldsAndColumns([
      FieldFactory.text('first_name').withColumnSpan(2),
      FieldFactory.text('last_name').withColumnSpan(2),
      FieldFactory.belongsTo('department', 'departments').withRequestParams(({ recordId }) => ({
        customer: recordId,
        with: 'set',
      })),
      FieldFactory.text('title').withColumnSpan(2),
      FieldFactory.email('email').withColumnSpan(2),
      FieldFactory.phone('phone').withColumnSpan(2),
      new GroupLayout('Settings').withFields([
        FieldFactory.boolean('is_approval_contact', 'CC on Approvals'),
        FieldFactory.boolean('is_invoice_contact', 'Send Invoices To'),
        FieldFactory.boolean('is_statement_contact', 'Sent Statements To'),
      ]),
    ]);
}
