import { Autocomplete as BaseAutocomplete, TextField } from '@mui/material';
import { UseControllerReturn } from 'react-hook-form';
import { FieldProps } from '@/classes/types';
import Field from '../Field';

export function AutocompleteComponent({
  fieldModel,
  field,
  fieldState,
}: FieldProps<AutocompleteField>) {
  return (
    <BaseAutocomplete
      freeSolo
      options={fieldModel.options}
      inputValue={field.value || ''}
      onInputChange={(e, value) => field.onChange(value)}
      disabled={fieldModel.disabled}
      size={fieldModel.size}
      renderInput={(params) => (
        <TextField {...fieldModel.getTextFieldProps(fieldState)} {...params} />
      )}
    />
  );
}

export default class AutocompleteField extends Field {
  options: string[];

  constructor(name: string, options: string[]) {
    super(name);
    this.options = options;
  }

  renderEditComponent(props: UseControllerReturn) {
    return <AutocompleteComponent {...props} fieldModel={this} />;
  }
}
