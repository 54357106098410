import { createContext, useContext } from 'react';
import { Order, Quote } from '@/types';

export const orderContext = createContext<{
  order?: Order | Quote;
}>({});

export function useOrder() {
  return useContext(orderContext).order;
}

export default function OrderProvider({
  order,
  children,
}: {
  order: Order | Quote;
  children: React.ReactNode;
}) {
  return <orderContext.Provider value={{ order }}>{children}</orderContext.Provider>;
}
