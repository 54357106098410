import { Button } from '@mui/material';
import axios from 'axios';
import { useGetOrderPreCollectedTax } from '@/api/orders';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import { useDialogs } from '@/contexts/DialogContext';
import { useShowLoading } from '@/contexts/DialogContext';
import curr from '@/utils/curr';
import { useOnReloadRecord } from '@/utils/genericResource';

export default function OrderPreCollectedTaxButton({
  orderId,
  preCollectedTax,
}: {
  orderId: number;
  preCollectedTax: null | number | undefined;
}) {
  const { confirm } = useDialogs();
  const showLoading = useShowLoading();
  const onReload = useOnReloadRecord('orders');
  const { data, isFetching } = useGetOrderPreCollectedTax(orderId);

  const canUpdate = !isFetching && data != preCollectedTax;

  const confirmUpdate = () => {
    confirm({
      title: 'Update Pre-Collected Tax',
      description: `Are you sure you want to update the pre-collected tax to ${curr(data)} for this order?`,
    }).then(() => {
      showLoading(axios.post(`/api/orders/${orderId}/update-tax`)).then(() => {
        onReload();
      });
    });
  };

  if (canUpdate) {
    return (
      <DrawerFormSection title="Pre-Collected Tax">
        <Button onClick={() => confirmUpdate()}>Update Pre-Collected Tax</Button>
      </DrawerFormSection>
    );
  }

  return null;
}
