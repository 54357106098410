import { Box, List } from '@mui/material';
import { useConfig } from '@/contexts/AppConfigContext';
import { Address } from '@/types';
import AddressListItem from './AddressListItem';

export default function BusinessAddressList({
  onToggle,
  isSelected,
}: {
  onToggle: (a: Address) => void;
  isSelected: (a: Address) => boolean;
}) {
  const address = useConfig().business?.address;

  if (!address) {
    return null;
  }

  return (
    <Box my={2}>
      <List subheader="Business Address">
        <AddressListItem address={address} onToggle={onToggle} isSelected={isSelected(address)} />
      </List>
    </Box>
  );
}
