import { useFormContext } from 'react-hook-form';
import OrderSummaryCard from '@/components/Orders/OrderSummaryCard';
import { Order } from '@/types';

export default function OrderSummaryField() {
  const values = useFormContext().watch();

  let order: Order | undefined;

  if ('order' in values) {
    order = values.order as Order;
  } else {
    order = values as Order;
  }

  if (!order) {
    return null;
  }
  return <OrderSummaryCard order={order} />;
}
