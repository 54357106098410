import { useEffect } from 'react';
import { Card, CardContent, CardHeader, Grid2 as Grid, Stack } from '@mui/material';
import axios from 'axios';
import keyBy from 'lodash/keyBy';
import { Controller, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FieldFactory } from '@/classes';
import FormField from '@/components/Form/FormField';
import Paper from '@/components/Shared/Paper';
import { TRANSACTION_TYPES, VENDOR_PAYMENT_METHODS } from '@/constants';
import { Transaction, TransactionPayload } from '@/types';
import { useRecordId } from '@/utils/genericResource';
import AccountItems from './AccountItems';
import PaymentItems from './PaymentItems';

export default function TransactionFields() {
  const form = useFormContext<TransactionPayload>();
  const id = useRecordId();
  const [type, vendor, method] = form.watch(['type', 'vendor', 'payment_method']);

  const [searchParams] = useSearchParams();
  const typeFromQuery = searchParams.get('type') as Transaction['type'] | null;
  const billId = searchParams.get('bill_id');

  useEffect(() => {
    if (type === 'check') {
      form.setValue('payment_method', 'check');
    } else if (vendor && form.formState.isDirty) {
      form.setValue(
        'payment_method',
        type === 'bill_payment' ? vendor.default_payment_method : null,
      );
    }
  }, [vendor, type]);

  useEffect(() => {
    if (!type && typeFromQuery) {
      setTimeout(() => form.setValue('type', typeFromQuery), 0);
    }
  }, [type, typeFromQuery]);

  useEffect(() => {
    if (billId) {
      axios
        .get<{ data: Transaction[] }>(`/api/transactions`, {
          params: {
            'filter[id][in]': billId,
            'filter[type]': 'bill',
            count: 100,
          },
        })
        .then(({ data }) => {
          if (data.data.length > 0) {
            form.setValue('vendor', data.data[0].vendor);
            form.setValue(
              'items',
              data.data.map((bill) => ({
                transactable: bill,
                description: '',
                amount: bill.balance,
              })),
            );
          }
        });
    }
  }, [billId]);

  const FIELDS = keyBy(
    [
      FieldFactory.belongsTo('bank_account', 'bankAccounts'),
      FieldFactory.text('po_number').withLabel('PO #'),
      FieldFactory.text('ref_number').withLabel('Ref #'),
      FieldFactory.belongsTo('terms', 'terms'),
      FieldFactory.date('due_date'),
      FieldFactory.date('transaction_date'),
      FieldFactory.number('check_number').withHelp('Leave blank to print later'),
      FieldFactory.text('check_payee').withHelp('Uses vendor name by default'),
      FieldFactory.select('payment_method', VENDOR_PAYMENT_METHODS),
      FieldFactory.belongsTo('vendor', 'vendors').withRequestParams({
        with: 'default_accounts',
      }),
    ],
    'name',
  );
  const mainFields = [FIELDS.vendor];
  const secondaryFields = [];

  if (type === 'check') {
    mainFields.push(FIELDS.bank_account);
  } else if (type === 'expense') {
    mainFields.push(FIELDS.bank_account);
    secondaryFields.push(FIELDS.ref_number, FIELDS.po_number);
  } else if (type === 'bill') {
    secondaryFields.push(FIELDS.terms, FIELDS.due_date, FIELDS.ref_number, FIELDS.po_number);
  } else if (type === 'bill_payment') {
    mainFields.push(FIELDS.bank_account);
  } else if (type === 'transfer') {
    mainFields.shift();
    mainFields.push(FIELDS.bank_account);
  }

  if (['check', 'bill_payment'].includes(type)) {
    secondaryFields.push(FIELDS.payment_method);
    if (method === 'check') {
      secondaryFields.push(FIELDS.check_number, FIELDS.check_payee);
    }
  }

  mainFields.push(
    FIELDS.transaction_date.withLabel(type === 'bill' ? 'Bill Date' : 'Payment Date'),
  );

  return (
    <Stack spacing={2}>
      {id == 'create' && (
        <div style={{ maxWidth: 300, marginBottom: 16 }}>
          <FormField field={FieldFactory.select('type', TRANSACTION_TYPES)} />
        </div>
      )}
      {type && (
        <Paper>
          <Grid container spacing={2}>
            {mainFields.map((f) => (
              <Grid key={f.name} size={{ xs: 12, md: 6, lg: 3 }}>
                <FormField field={f} />
              </Grid>
            ))}

            {secondaryFields.map((f) => (
              <Grid key={f.name} size={{ xs: 12, md: 6, lg: 3 }}>
                <FormField field={f} />
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
      {type && (
        <Card>
          <CardHeader title={type === 'bill_payment' ? 'Bills to Pay' : 'Item Details'} />
          <Controller
            name="items"
            control={form.control}
            render={type === 'bill_payment' ? PaymentItems : AccountItems}
          />
          <CardContent>
            <FormField field={FieldFactory.textarea('memo')} />
          </CardContent>
        </Card>
      )}
    </Stack>
  );
}
