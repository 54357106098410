import { FieldFactory, GroupLayout, Resource } from '@/classes';
import { Group, groupPayloadSchema } from '@/types';

export default function groups(): Resource<Group> {
  return new Resource<Group>('Groups')
    .withSchema(groupPayloadSchema)
    .setUseDrawer()
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.belongsTo('business', 'businesses'),
      new GroupLayout('settings', [
        FieldFactory.boolean('taggable', 'Taggable').filterable().sortable(),
      ]),
      FieldFactory.hasMany('users', 'users'),
    ]);
}
