import { ReactNode } from 'react';
import { Box, BoxProps, Typography, TypographyVariant } from '@mui/material';

export default function Text({
  primary,
  secondary,
  variant = 'body2',
  children,
  ...other
}: {
  primary?: ReactNode;
  secondary?: ReactNode;
  variant?: TypographyVariant;
  children?: ReactNode;
} & BoxProps) {
  return (
    <Box {...other}>
      <Typography variant={variant} color="textPrimary">
        {primary || children}
      </Typography>
      {secondary && (
        <Typography variant={variant} color="textSecondary">
          {secondary}
        </Typography>
      )}
    </Box>
  );
}
