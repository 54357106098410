import { FieldFactory, Resource } from '@/classes';
import PackoutPage from '@/components/Packouts/PackoutPage';
import ProductionDates from '@/components/Production/CommittedDate';
import DueDateBar from '@/components/Shared/DueDateBar';
import { PACKOUT_READY_STATUS_COLOR, PACKOUT_STATUS_COLOR } from '@/constants';
import { Packout } from '@/types';

const getTitle = (v: Packout) => `${v.order.increment_id} Packout`;

export default function orderPackouts() {
  return new Resource<Packout>('Order Packouts')
    .setReadOnly(true)
    .getTitleUsing(getTitle)
    .getLabelUsing(getTitle)
    .withDefaultFilters({
      bucket: 'pending',
    })
    .withQueryParams({
      single: {
        with: 'timing',
      },
    })
    .withFilters([
      FieldFactory.radio('bucket', {
        pending: 'Pending',
        blocked: 'Blocked',
        ready: 'Ready',
        inprogress: 'In-Progress',
        all: 'All',
      }).asQuickFilter(),
    ])
    .withInitialColumns([
      'order',
      'order.customer',
      'status',
      'ready_status',
      'kits_count',
      'scheduled_date',
      'committed_ship_date',
    ])
    .withColumns([
      FieldFactory.belongsTo('order', 'orders')
        .filterable()
        .sortable('order.increment_id')
        .withRequestParams({
          'filter[shipping_mode]': 'kits',
        }),
      FieldFactory.belongsTo('order.customer', 'customers')
        .withLabel('Customer')
        .filterable('filter[order.customer_id]'),
      FieldFactory.status('status', PACKOUT_STATUS_COLOR).filterable().sortable(),
      FieldFactory.status('ready_status', PACKOUT_READY_STATUS_COLOR)
        .filterable('ready_status')
        .sortable(),
      FieldFactory.number('kits_count').withLabel('# Kits').sortable(),
      FieldFactory.number('assigned_items_count').withLabel('# Kit Items').sortable(),
      FieldFactory.number('order_items_count').withLabel('# Line Items').sortable(),
      FieldFactory.date('scheduled_date').withLabel('Scheduled').sortable(),
      FieldFactory.date('drop_dead_date').sortable(),
      FieldFactory.date('standard_ship_date').sortable(),
      FieldFactory.date('committed_ship_date')
        .sortable()
        .renderCellUsing((value, row: Packout) => (
          <div>
            <ProductionDates order={row} />
            {value && row.ready_at && <DueDateBar end={value} start={row.ready_at} />}
          </div>
        )),
      FieldFactory.timestamp('started_at').sortable(),
      FieldFactory.timestamp('completed_at').sortable(),
      FieldFactory.timestamp('ready_at').sortable(),
    ])
    .editUsing(PackoutPage);
}
