import { SyntheticEvent } from 'react';
import { z } from 'zod';
import { useBurnProductionEvent } from '@/api/production';
import { FieldFactory } from '@/classes';
import TextButton from '@/components/Text/TextButton';
import { useDialogs } from '@/contexts/DialogContext';
import { ProductionEvent } from '@/types';

export default function ScreenLocation({ event }: { event: ProductionEvent }) {
  const { confirm, prompt } = useDialogs();
  const burnRequest = useBurnProductionEvent();

  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (!event.screen_location) {
      prompt({
        title: 'Burn Screen',
        description: 'What location was the screen placed in?',
        fields: [FieldFactory.text('screen_location').withSize('medium')],
        schema: z.object({
          screen_location: z.string().min(1),
        }),
        onSubmit: (v) => burnRequest.mutateAsync({ eventId: event.id, ...v }),
      });
    } else {
      confirm({
        title: 'Unburn Screen',
        description: 'Are you sure you want to undo the burning of this screen?',
      }).then(() => burnRequest.mutate({ eventId: event.id }));
    }
  };

  return <TextButton onClick={onClick}>{event.screen_location || 'Not Burned'}</TextButton>;
}
