import { useCallback } from 'react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import memoize from 'lodash/memoize';
import resources from '@/resources';
import { ResourceMap, ResourceName } from '@/resources/types';
import { AppResponse } from '@/types';

const getResource = memoize(
  <K extends ResourceName>(resourceName: K, queryClient: QueryClient): ResourceMap[K] => {
    const adminConfig = queryClient.getQueryData(['appConfig']) as AppResponse;
    const resource = resources[resourceName](adminConfig) as ResourceMap[K];
    resource.key = resourceName;
    return resource;
  },
);

export default function useGetResource() {
  const queryClient = useQueryClient();
  return useCallback(
    <K extends ResourceName>(resourceName: K): ResourceMap[K] => {
      return getResource(resourceName, queryClient);
    },
    [queryClient],
  );
}

export function useResource<K extends ResourceName>(resource: K): ResourceMap[K] {
  return useGetResource()(resource);
}
