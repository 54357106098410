import { AxiosResponse } from 'axios';

export default function downloadFile(
  response: AxiosResponse,
  orderId: number,
  fileFormat: 'xlsx' | 'pdf',
) {
  const blob = new Blob([response.data], { type: response.headers['content-type'] });
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', `order-${orderId}.${fileFormat}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
