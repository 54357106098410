import { useState } from 'react';
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import { ProductSearchResponse } from '@/types';

const getFacets = (object: ProductSearchResponse['meta']['facets']) => {
  return sortBy(
    Object.entries(object).map(([facet, options]) => ({
      options,
      facet,
    })),
    ({ facet }) => {
      const index = [
        'vendor',
        'brand',
        'customer',
        'business',
        'type',
        'tags',
        'standard_color',
        'available_sizes',
      ].indexOf(facet);
      return index === -1 ? 100 : index;
    },
  );
};

const getFacetOptions = (object: Record<string, number>, showAll = false) => {
  const options = orderBy(
    Object.entries(object).map(([label, count]) => ({
      count,
      label,
    })),
    ['count', 'label'],
    ['desc', 'asc'],
  );
  return options.slice(0, showAll ? Infinity : 6);
};

export default function ProductSearchFilters({
  onFacetChange,
  results,
  facets,
}: {
  onFacetChange: (facet: string, label: string) => void;
  results: ProductSearchResponse;
  facets: Record<string, string>;
}) {
  const [showAll, setShowAll] = useState<string[]>([]);

  const toggleShowAll = (facet: string) => () => {
    setShowAll((prev) => {
      if (prev.indexOf(facet) > -1) {
        return prev.filter((p) => p !== facet);
      }
      return [...prev, facet];
    });
  };

  if (results) {
    return (
      <div>
        {getFacets(results.meta.facets).map(({ facet, options }) => (
          <Box mt={2} key={facet}>
            <Typography variant="subtitle2" color="textSecondary">
              {startCase(facet)}
            </Typography>

            <List dense>
              {getFacetOptions(options, showAll.indexOf(facet) > -1).map(({ label, count }) => (
                <ListItemButton
                  key={label}
                  onClick={() => onFacetChange(facet, label)}
                  selected={facets[facet] === label}
                >
                  <ListItemText>{label}</ListItemText>
                  <ListItemSecondaryAction>{count}</ListItemSecondaryAction>
                </ListItemButton>
              ))}
            </List>

            {Object.keys(options).length > 6 && (
              <Button type="button" size="small" onClick={toggleShowAll(facet)}>
                Show {showAll.indexOf(facet) > -1 ? 'Less' : 'More'}
              </Button>
            )}
          </Box>
        ))}
      </div>
    );
  }
  return null;
}
