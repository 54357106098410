import { ReactNode, useState } from 'react';
import { Divider, Drawer, Hidden, List, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '@/assets/logo.svg?react';
import { useAppContext } from '@/contexts/AppContext';
import { useRequiresPermissionAndRole } from '@/hooks/permissions';
import { NavItem } from '@/resources/types';
import MenuItem from './NavigationMenuItem';
import UserMenuSection from './UserMenuSection';

const NavDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed',
    width: theme.drawerWidth || 215,
    background: theme.drawerColor || theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.drawerColor || theme.palette.primary.main),
  },
}));

export default function AppNavigation({
  pages,
  children,
}: {
  pages: NavItem[];
  children?: ReactNode;
}) {
  const [itemsOpen, setItemsOpen] = useState<Record<string, boolean>>({});
  const { isMenuOpen, setIsMenuOpen } = useAppContext();
  const onClose = () => setIsMenuOpen(false);
  const requiresPermissionAndRole = useRequiresPermissionAndRole();

  const toggleItemOpen = (name: string) => {
    setItemsOpen((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const drawer = (
    <>
      <Link to="/">
        <Logo
          style={{
            width: '100%',
            height: 40,
            color: 'white',
            margin: '10px 0',
          }}
        />
      </Link>
      <Divider />
      {children}
      <UserMenuSection />
      <Divider />
      <List>
        {pages.filter(requiresPermissionAndRole).map((page) => (
          <MenuItem
            key={page.name}
            level={0}
            toggleOpen={toggleItemOpen}
            open={itemsOpen}
            page={page}
          />
        ))}
      </List>
    </>
  );

  return (
    <>
      <Hidden mdUp>
        <NavDrawer
          variant="temporary"
          anchor="left"
          open={isMenuOpen}
          onClose={onClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </NavDrawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <NavDrawer variant="permanent" anchor="left">
          {drawer}
        </NavDrawer>
      </Hidden>
    </>
  );
}
