import pick from 'lodash/pick';
import { getItemsText, useUpdateOrderItemRequest } from '@/api/orderItems';
import { FieldFactory } from '@/classes';
import SizeSelector from '@/components/Orders/Items/SizeSelector';
import TextButton from '@/components/Text/TextButton';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderItem, orderItemPayloadSchema } from '@/types';
import { useIsOrderLocked } from '@/utils/orders';

export default function OrderItemSize({ items }: { items: OrderItem[] }) {
  const { prompt } = useDialogs();
  const size = items[0]!.size;
  const isOrderLocked = useIsOrderLocked();
  const allSame = items.every((item) => item.size === size);
  const updateOrderItemRequest = useUpdateOrderItemRequest();

  const cannotUpdate = items.length > 1 && items.some((i) => !i.is_custom);

  const onUpdate = () => {
    if (items.length === 1 && !items[0]!.is_custom) {
      prompt({
        title: 'Update Size',
        initialValues: pick(items[0], 'variant'),
        maxWidth: 'lg',
        fields: [FieldFactory.custom('variant', SizeSelector)],
        schema: orderItemPayloadSchema.pick({ variant: true }),
        onSubmit: (v) =>
          Promise.all(
            items.map((item) => updateOrderItemRequest.mutateAsync({ id: item.id, ...v })),
          ),
      });
    } else {
      prompt({
        title: 'Update Size',
        description: `What should the size of ${getItemsText(items)} be?`,
        fields: [FieldFactory.text('size').withSize('medium')],
        schema: orderItemPayloadSchema.pick({ size: true }),
        initialValues: { size },
        onSubmit: (v) =>
          Promise.all(
            items.map((item) => updateOrderItemRequest.mutateAsync({ id: item.id, ...v })),
          ),
      });
    }
  };

  return (
    <TextButton
      disabled={isOrderLocked || cannotUpdate || items.some((i) => i.qty_sourced != 0)}
      onClick={onUpdate}
    >
      {allSame ? size : <span>&mdash;</span>}
    </TextButton>
  );
}
