import { useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { FieldFactory } from '@/classes';
import DrawerButtons from '@/components/Form/DrawerButtons';
import Form from '@/components/Form/Form';
import FormErrors from '@/components/Form/FormErrors';
import FormField from '@/components/Form/FormField';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { useConfig } from '@/contexts/AppConfigContext';
import { OrderCost, OrderCostsUpdatePayload, orderCostsUpdatePayloadSchema } from '@/types';
import { useZodForm } from '@/utils/form';
import { useOnReloadRecord, useRecordId } from '@/utils/genericResource';

export default function EditOrderCostsDrawer({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const form = useZodForm(orderCostsUpdatePayloadSchema);
  const { costCategories } = useConfig();
  const orderId = useRecordId();
  const onReload = useOnReloadRecord();

  useEffect(() => {
    if (open) {
      axios.get<{ data: OrderCost[] }>(`/api/orders/${orderId}/costs`).then(({ data }) => {
        form.reset({ costs: data.data });
      });
    }
  }, [open, orderId]);

  const onSubmit = (values: OrderCostsUpdatePayload) =>
    axios.put(`/api/orders/${orderId}/costs`, values).then(() => {
      onClose();
      onReload();
    });

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Edit Custom Order Costs" width={700}>
      <Form form={form} onSubmit={onSubmit}>
        <FormField
          field={FieldFactory.list('costs', [
            FieldFactory.text('description').withColumnSpan(5),
            FieldFactory.curr('cost').withColumnSpan(2),
            FieldFactory.select('category', costCategories).withColumnSpan(4),
          ])}
        />

        <FormErrors form={form} />

        <DrawerButtons>
          <LoadingButton type="submit" variant="contained" loading={form.formState.isSubmitting}>
            Save
          </LoadingButton>
        </DrawerButtons>
      </Form>
    </ClosableDrawer>
  );
}
