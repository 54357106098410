import { ExpandMore, Undo } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  IconButton,
  Typography,
} from '@mui/material';
import axios from 'axios';
import sumBy from 'lodash/sumBy';
import moment from 'moment/moment';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import PrintMenu from '@/components/Print/PrintMenu';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderBox } from '@/types';
import { useOnReloadRecord } from '@/utils/genericResource';
import numString from '@/utils/numString';

export default function OrderBoxes({ boxes }: { boxes: OrderBox[] }) {
  const { confirm } = useDialogs();
  const onReload = useOnReloadRecord();

  if (boxes.length === 0) {
    return null;
  }

  const onDeleteBox = (box: OrderBox) => {
    confirm({
      title: 'Delete Box',
      description: `Are you sure you want to delete this box?`,
    }).then(() => {
      axios.delete(`/api/order-boxes/${box.id}`).then(() => {
        onReload();
      });
    });
  };

  return (
    <Box mt={2}>
      {boxes.map((b) => (
        <Accordion key={b.id}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="h6">{b.increment_id}</Typography>
              <PrintMenu model="order_box" id={b.id} />
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'block', paddingTop: 0 }}>
            <Box display="flex" alignItems="flex-start" mb={4}>
              <Box flexGrow={1}>
                <Typography variant="body1" gutterBottom>
                  <span>{numString(sumBy(b.items, 'qty'))}</span>
                  <span> packed</span>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {moment(b.created_at).format('llll')}
                </Typography>
              </Box>

              <IconButton onClick={() => onDeleteBox(b)} size="large">
                <Undo />
              </IconButton>
            </Box>
            {b.items.map((i) => (
              <Box display="flex" key={i.order_item.id} justifyContent="space-between" my={2}>
                <OrderItemProduct item={i.order_item} showSize />
                <Chip label={`Packed: ${i.qty}`} />
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
