import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DuplicateIcon from '@mui/icons-material/FileCopy';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useCreateOrderItemRequest, useDeleteOrderItemRequest } from '@/api/orderItems';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderItem } from '@/types';
import { useIsOrderLocked } from '@/utils/orders';

export default function OrderItemActions({
  item,
  onEdit,
}: {
  item: OrderItem;
  onEdit: () => void;
}) {
  const isOrderLocked = useIsOrderLocked();
  const canEdit = !isOrderLocked && item.qty_sourced === 0;
  const { confirm } = useDialogs();
  const createItemRequest = useCreateOrderItemRequest();
  const deleteItemRequest = useDeleteOrderItemRequest();

  const onRemove = (item: OrderItem) => {
    const hasDesigns = Object.keys(item.order_design_ids).length > 0;
    let description = 'Are you sure you want to remove this item from the order?';
    if (hasDesigns) {
      description += ' This item has design imprints associated with it.';
    }
    confirm({
      title: 'Delete Line Item',
      description,
      color: hasDesigns ? 'warning' : undefined,
    }).then(() => {
      deleteItemRequest.mutate(item.id);
    });
  };

  const onDuplicate = (item: OrderItem) => {
    createItemRequest.mutate(item);
  };

  return (
    <Box display="flex">
      {!isOrderLocked && (
        <Tooltip title="Edit">
          <IconButton size="small" onClick={onEdit}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {!isOrderLocked && (
        <Tooltip title="Duplicate">
          <IconButton size="small" onClick={() => onDuplicate(item)}>
            <DuplicateIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {canEdit && (
        <Tooltip title="Delete Line">
          <IconButton size="small" onClick={() => onRemove(item)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
