import { StackLayout, FieldFactory } from '@/classes';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import EditFormFields from '@/components/Form/EditFormFields';

export default function PriceSection({ minQty, maxQty }: { minQty?: number; maxQty?: number }) {
  return (
    <DrawerFormSection title="Pricing and Quantities">
      <EditFormFields
        defaultLayout={StackLayout}
        fields={[
          FieldFactory.number('qty').withInputProps({ min: minQty, max: maxQty }),
          FieldFactory.curr('price').with({ maxDecimals: 4 }),
          FieldFactory.percent('discount'),
        ]}
      />
    </DrawerFormSection>
  );
}
