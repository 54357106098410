import { Card, Grid2 as Grid } from '@mui/material';
import OrderDetails from '@/components/Orders/Dashboard/OrderDetails';
import OrderShippingAddresses from '@/components/Orders/Shipping/OrderShippingAddresses';
import OrderProvider from '@/contexts/OrderContext';
import { useRecord } from '@/utils/genericResource';

export default function QuoteDashboard() {
  const quote = useRecord('quotes');

  return (
    <OrderProvider order={quote}>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Card>
            <OrderDetails order={quote} isReadOnly={false} />
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <OrderShippingAddresses />
        </Grid>
      </Grid>
    </OrderProvider>
  );
}
