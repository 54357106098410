import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useConfig } from '@/contexts/AppConfigContext';
import { useRequiresPermissionAndRole } from '@/hooks/permissions';
import { NavItem } from '@/resources/types';

export default function NavigationMenuItem({
  toggleOpen,
  open,
  page,
  level,
}: {
  toggleOpen: (name: string) => void;
  open: Record<string, boolean>;
  page: NavItem;
  level: number;
}) {
  const config = useConfig();
  const requiresPermissionAndRole = useRequiresPermissionAndRole();
  const Icon = page.icon;
  const isOpen = Boolean(open[page.name]);

  const toggle = () => {
    toggleOpen(page.name);
  };

  if (!requiresPermissionAndRole(page)) {
    return null;
  }

  if ('children' in page && page.children.filter(requiresPermissionAndRole).length === 0) {
    return null;
  }

  const listItemProps =
    'children' in page
      ? { onClick: toggle }
      : {
          component: Link,
          to: page.href,
        };

  return (
    <>
      <ListItemButton {...listItemProps}>
        <ListItemIcon
          sx={{
            color: 'inherit',
            minWidth: 48,
          }}
        >
          {page.image && <Avatar alt={page.name} src={page.image} />}
          {Icon && <Icon />}
        </ListItemIcon>

        <ListItemText>{page.name}</ListItemText>

        {'countKey' in page && page.countKey && config[page.countKey] > 0 && (
          <Badge color="error" sx={{ mr: 2 }} badgeContent={config[page.countKey]} />
        )}
        {'children' in page && (isOpen ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>

      {'children' in page && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            {page.children.map((childPage) => (
              <NavigationMenuItem
                key={childPage.name}
                level={level + 1}
                toggleOpen={toggleOpen}
                open={open}
                page={childPage}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
