import { useState } from 'react';
import axios from 'axios';
import { FieldFactory } from '@/classes';
import { NonFormField } from '@/components/Form/FormField';
import { useToast } from '@/contexts/DialogContext';
import { Account, Transaction } from '@/types';

export default function QuickAccountEditor({ transaction }: { transaction: Transaction }) {
  const toast = useToast();
  const [account, setAccount] = useState<Account | null | undefined>(transaction.account);

  if (transaction.type === 'bill_payment') {
    return <span />;
  }
  if (!account) {
    return <span>-Split-</span>;
  }

  const handleAccount = (v: any) => {
    const newAccount = v as Account | null;
    if (newAccount) {
      setAccount(newAccount);
      axios
        .post(`/api/transactions/${transaction.id}/change-account`, { account_id: newAccount.id })
        .then(() => {
          toast('Successfully updated account');
        });
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={{
        width: 264,
        marginTop: -12,
        marginBottom: -8,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <NonFormField
        value={account}
        onChange={handleAccount}
        field={FieldFactory.belongsTo('account', 'accounts').withoutLabel()}
      />
    </div>
  );
}
