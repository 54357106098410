import { InputHTMLAttributes } from 'react';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { OrderItem, ShipmentItemPayload, ShippingMode } from '@/types';
import { abscomp } from '@/utils/helpers';

export const orderItemToShipmentItem = (i: OrderItem): ShipmentItemPayload => ({
  qty_shipped: i.qty_to_ship,
  order_item_id: i.id,
});

export const canShipOrderItem = (i: OrderItem) =>
  abscomp(i.qty_shipped, '<', i.qty - i.qty_scrapped);

export const getKitQty = (
  selected: ShipmentItemPayload[],
  shippableItems: OrderItem[],
): number | { item: string; reason: string } => {
  const error = {
    item: '',
    reason:
      'does not have the right quantities or quantity per kit to create a whole number of kits',
  };
  const groupedItems = groupBy(shippableItems, 'inventory_variant_id');
  const kits = Object.values(groupedItems).map((value) => {
    const chosenOne = sortBy(value, [
      (selectedItem) => {
        const match = selected.find((s) => s.order_item_id === selectedItem.id)!;
        const qty = match.qty_shipped;
        const qtyPerKit = selectedItem.qty_per_kit || 1;
        return Math.floor(qty / qtyPerKit);
      },
    ])[0];

    const { qty_shipped } = selected.find((s) => s.order_item_id === chosenOne.id)!;
    const { qty_per_kit } = chosenOne;

    if (qty_shipped % (qty_per_kit || 1) > 0) {
      error.item = (chosenOne.variant?.product.name || chosenOne.description).toUpperCase();
      return null;
    }

    return qty_shipped / (qty_per_kit || 1);
  });

  return kits.indexOf(null) > -1
    ? error
    : (kits as number[]).reduce((partialSum, a) => partialSum + a, 0);
};

export const isInventoryKit = (mode: ShippingMode): boolean => mode === 'inventory_kit';

export const isInventory = (mode: ShippingMode): boolean =>
  ['inventory', 'inventory_kit'].includes(mode);

export const getQtyInputProps = (
  remaining: number,
  total: number,
): InputHTMLAttributes<HTMLInputElement> => ({
  step: 1,
  min: total >= 0 ? 0 : remaining,
  max: total >= 0 ? remaining : 0,
  disabled: remaining === 0,
});

export function getIdFromIncrementId(incrementId: string) {
  return Number(incrementId.split('-')[1]);
}
