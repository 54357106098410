import { Card } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProduct } from '@/api/products';
import ProductDetails from '@/components/Products/ProductDetails';
import ProductSearchGrid from '@/components/Products/ProductSearchGrid';
import { useTitle } from '@/contexts/AppContext';

export default function ProductSearch() {
  useTitle('Product Search');

  const navigate = useNavigate();
  const { id: productId } = useParams();
  const { data: selectedProduct } = useGetProduct(productId ? Number(productId) : undefined);

  return (
    <Card>
      {productId ? (
        <ProductDetails
          product={selectedProduct}
          onProductChange={(p) => navigate(`/product-search/${p.id}`)}
          onBack={() => {
            if (window.history.state.usr.fromTable) {
              navigate(-1);
            } else {
              navigate('/product-search');
            }
          }}
        />
      ) : (
        <ProductSearchGrid
          onSelect={(p) =>
            navigate(`/product-search/${p.id}`, {
              state: { fromTable: true },
            })
          }
        />
      )}
    </Card>
  );
}
