import { CancelOutlined, Undo } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import SubmitIcon from '@mui/icons-material/AssignmentTurnedIn';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import axios from 'axios';
import pick from 'lodash/pick';
import { z } from 'zod';
import { ButtonAction, FieldFactory, Resource } from '@/classes';
import { OnClickProps } from '@/classes/types';
import BackorderItemsField from '@/components/FulfillmentOrders/BackorderItemsField';
import FulfillmentOrderPage from '@/components/FulfillmentOrders/FulfillmentOrderPage';
import { WEBSTORE_ORDER_STATUS_COLORS } from '@/constants';
import {
  fulfillmentBackorderPayloadSchema,
  FulfillmentOrder,
  fulfillmentOrderPayloadSchema,
  FulfillmentOrderItem,
  genericModelReferenceSchema,
} from '@/types';

export default function fulfillmentOrders() {
  return new Resource<Required<FulfillmentOrder>>('Fulfillment Orders')
    .withSchema(fulfillmentOrderPayloadSchema)
    .getTitleUsing((v) => v.number)
    .getLabelUsing((v) => v.number)
    .withDefaultSort('-date')
    .setCanExport()
    .withFilters([FieldFactory.belongsTo('invoice', 'orders')])
    .setReadOnly()
    .getSingleActionsUsing((values) => {
      const onIssue = ({ dialogs, onReloadRecord, showLoading }: OnClickProps) => {
        dialogs
          .confirm({
            title: 'Issue Order',
            description:
              'This will attempt to push the order to fulfillment if items are available.',
          })
          .then(() => {
            showLoading(axios.post(`/api/fulfillment-orders/${values.id}/issue`)).then(() => {
              onReloadRecord();
            });
          });
      };

      const onVoid = ({ dialogs, onReloadRecord, showLoading }: OnClickProps) => {
        dialogs
          .confirm({
            title: 'Void Order',
            description:
              'This will cancel the order and delete any associated picks. Are you sure you want to proceed?',
            color: 'warning',
          })
          .then(() => {
            showLoading(axios.post(`/api/fulfillment-orders/${values.id}/void`)).then(() => {
              onReloadRecord();
            });
          });
      };

      const onBackorder = ({ dialogs, onReloadRecord }: OnClickProps) => {
        dialogs
          .drawerPrompt({
            title: 'Create Backorder',
            description: 'Select which items you would like to move to a backorder',
            fields: [FieldFactory.custom('items', BackorderItemsField)],
            schema: fulfillmentBackorderPayloadSchema,
            initialValues: {
              items: values.items!.map((i) => ({
                ...i,
                qty: 0,
                maxQty: i.qty,
              })),
            },
            onSubmit: (v) =>
              axios.post(`/api/fulfillment-orders/${values.id}/backorder`, {
                items: v.items.filter((i) => i.qty > 0),
              }),
          })
          .then(() => {
            onReloadRecord();
          });
      };

      const onAddItem = ({ dialogs, onReloadRecord }: OnClickProps) => {
        dialogs
          .prompt({
            title: 'Add Item To Order',
            description: 'Choose another SKU to add to this order.',
            fields: [
              FieldFactory.belongsTo('variant', 'inventoryVariants'),
              FieldFactory.number('qty'),
              FieldFactory.text('notes'),
            ],
            schema: z.object({
              variant: genericModelReferenceSchema.required(),
              qty: z.coerce.number().int().positive(),
              notes: z.string().nullish(),
            }),
            onSubmit: (payload) =>
              axios.post<FulfillmentOrderItem>(`/api/fulfillment-orders/${values.id}/items`, {
                variant_id: payload.variant.id,
                ...payload,
              }),
          })
          .then(() => onReloadRecord());
      };

      const onReturn = ({ dialogs, navigate }: OnClickProps) => {
        dialogs
          .drawerPrompt({
            title: 'Create Return',
            description: 'Select which items you would like to return to inventory',
            fields: [FieldFactory.custom('items', BackorderItemsField)],
            schema: fulfillmentBackorderPayloadSchema,
            initialValues: {
              items: values.items!.map((i) => ({
                ...i,
                qty: 0,
                maxQty: i.qty,
              })),
            },
            onSubmit: (v) => axios.post(`/api/fulfillment-orders/${values.id}/return`, v),
          })
          .then(({ data }) => {
            navigate(`/inventory-returns/${data.id}`);
          });
      };

      const onEdit = ({ dialogs, updateRecord }: OnClickProps) => {
        dialogs
          .drawerPrompt({
            title: 'Edit Fulfillment Order',
            initialValues: pick(values, [
              'name',
              'email',
              'phone',
              'customer_po',
              'earliest_ship_date',
            ]),
            fields: [
              FieldFactory.text('name'),
              FieldFactory.email('email'),
              FieldFactory.phone('phone'),
              FieldFactory.text('customer_po'),
              FieldFactory.date('earliest_ship_date'),
            ],
            schema: fulfillmentOrderPayloadSchema.pick({
              name: true,
              email: true,
              phone: true,
              customer_po: true,
              earliest_ship_date: true,
            }),
            onSubmit: (v) => axios.put(`/api/fulfillment-orders/${values.id}`, v),
          })
          .then(({ data }) => {
            updateRecord(data);
          });
      };

      if (['pending', 'waiting', 'issued', 'backordered'].includes(values.status)) {
        return [
          new ButtonAction('Issue', onIssue, SubmitIcon),
          new ButtonAction('Create Backorder', onBackorder, WarningIcon),
          new ButtonAction('Edit', onEdit, EditIcon),
          new ButtonAction('Add Item', onAddItem, AddIcon),
          new ButtonAction('Void', onVoid, CancelOutlined),
        ];
      }
      return [new ButtonAction('Create Return', onReturn, Undo)];
    })
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.text('number').sortable(),
      FieldFactory.status('status', WEBSTORE_ORDER_STATUS_COLORS).filterable().sortable(),
      FieldFactory.timestamp('date').sortable(),
      FieldFactory.text('name').sortable(),
      FieldFactory.belongsTo('customer', 'customers').filterable(),
      FieldFactory.text('webstore_name').sortable(),
    ])
    .editUsing(FulfillmentOrderPage);
}
