import { createElement, ElementType } from 'react';
import { Build } from '@mui/icons-material';
import { AlertColor } from '@mui/material';
import { RequiresPermission } from '@/classes/types';

export default abstract class Action implements RequiresPermission {
  label: string;
  icon?: ElementType;
  requiresPermission?: string;
  requiresRole?: string;
  disabled = false;
  color?: AlertColor;

  constructor(label: string, icon?: ElementType) {
    this.label = label;
    this.icon = icon;
  }

  withIcon(icon: ElementType) {
    this.icon = icon;
    return this;
  }

  setDisabled(bool = true) {
    this.disabled = bool;
    return this;
  }

  withPermission(permission: string) {
    this.requiresPermission = permission;
    return this;
  }

  withRole(role: string) {
    this.requiresRole = role;
    return this;
  }

  withColor(color: AlertColor) {
    this.color = color;
    return this;
  }

  renderIcon() {
    return createElement(this.icon || Build);
  }
}
