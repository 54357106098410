import { Divider } from '@mui/material';
import Notifications from '@/components/Notifications/Notifications';
import BusinessChooser, { BusinessChooserProps } from './BusinessChooser';

export default function AdditionalMenuItems(props: BusinessChooserProps) {
  return (
    <>
      <Divider />
      <BusinessChooser {...props} />
      <Notifications />
      <Divider />
    </>
  );
}
