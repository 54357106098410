import { CloudDownload } from '@mui/icons-material';
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useGetPayoutHistory } from '@/api/payouts';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { Business } from '@/types';
import curr from '@/utils/curr';
import getApiUrl from '@/utils/getApiUrl';
import getBusinessName from '@/utils/getBusinessName';

export default function PayoutHistoryDrawer({
  open,
  onClose,
  business,
}: {
  open: boolean;
  onClose: () => void;
  business?: Business;
}) {
  const { data: history, isLoading } = useGetPayoutHistory(
    open && business ? business.id : undefined,
  );

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Payout History">
      {business && (
        <Box mt={-2} mb={3}>
          <Typography variant="subtitle1" gutterBottom color="textSecondary">
            Business: {getBusinessName(business)}
          </Typography>
        </Box>
      )}

      {isLoading && <CircularProgress />}

      {history?.length === 0 && (
        <Typography variant="body2" color="textSecondary">
          No payouts have been made to this business.
        </Typography>
      )}

      {history && history.length > 0 && (
        <List>
          {history.map((p) => (
            <ListItem key={p.id}>
              <ListItemText
                primary={`Payout #${p.id}`}
                secondary={moment(p.created_at).format('lll')}
              />
              <ListItemSecondaryAction>
                <Chip label={curr(p.amount)} />
                <Tooltip title="Download Commission Report">
                  <IconButton
                    component="a"
                    href={getApiUrl(`/api/business-payouts/${p.id}/commissions`)}
                    size="large"
                  >
                    <CloudDownload />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </ClosableDrawer>
  );
}
