import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Paper, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Markdown from 'react-markdown';
import { Agreement } from '@/types';

export default function AgreementScreen({
  agreement,
  onSuccess,
}: {
  agreement: Agreement;
  onSuccess: () => void;
}) {
  const agreeRequest = useMutation(() => axios.post(`/api/agreements/${agreement.id}`), {
    onSuccess,
  });

  return (
    <Box
      display="flex"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      bgcolor="gray.100"
    >
      <Paper sx={{ width: 800, maxWidth: '100%', px: 4, py: 3 }}>
        <Typography variant="h5">Terms &amp; Conditions</Typography>
        <Typography variant="body2">
          Please read and agree to the following policy in order to use Avail:
        </Typography>

        <Box height={400} overflow="auto" mt={3} py={1}>
          <Markdown>{agreement.content}</Markdown>

          <LoadingButton
            sx={{ mt: 3 }}
            variant="contained"
            onClick={() => agreeRequest.mutate()}
            loading={agreeRequest.isLoading}
          >
            Agree
          </LoadingButton>
        </Box>
      </Paper>
    </Box>
  );
}
