import { Box } from '@mui/material';

export default function TableBodyLoadingOverlay({
  show,
  el,
}: {
  show: boolean;
  el: HTMLElement | null;
}) {
  if (!show || !el) {
    return null;
  }

  return (
    <Box
      position="absolute"
      width={el.offsetWidth}
      height={el.offsetHeight}
      left={el.offsetLeft}
      top={el.offsetTop}
      bgcolor="action.hover"
    />
  );
}
