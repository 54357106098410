import { Box, styled, SxProps, Typography } from '@mui/material';
import Label from '@/components/Shared/Label';
import { useConfig } from '@/contexts/AppConfigContext';
import { Address } from '@/types';

const ShipToLabel = styled('div')({
  fontWeight: 500,
  fontSize: '.95rem',
});

export default function AddressBlock({
  address,
  showMethod,
  sx = {},
}: {
  address: Address;
  showMethod?: boolean;
  sx?: SxProps;
}) {
  const { countries } = useConfig();

  return (
    <Box sx={sx}>
      {showMethod && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {showMethod && (
            <div>
              <Label gutterBottom>{address.method || '(no ship method)'}</Label>
            </div>
          )}
        </div>
      )}

      <ShipToLabel>{address.name}</ShipToLabel>
      <Typography variant="body2">
        {address.company && <div>{address.company}</div>}
        <div>{address.address_1}</div>
        {address.address_2 && <div>{address.address_2}</div>}
        <div>
          <span>{address.city}</span>
          {address.state && <span>, {address.state}</span>}
          <span> {address.zip}</span>
        </div>
        {address.country && address.country !== 'US' ? (
          <div>{countries[address.country] || address.country}</div>
        ) : null}
        {address.phone && <div>{address.phone}</div>}
      </Typography>
    </Box>
  );
}
