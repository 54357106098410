import { getItemsText, useUpdateOrderItemRequest } from '@/api/orderItems';
import { FieldFactory } from '@/classes';
import TextButton from '@/components/Text/TextButton';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderItem, orderItemPayloadSchema } from '@/types';
import { useIsOrderLocked } from '@/utils/orders';

export default function OrderItemQty({ items, value }: { items: OrderItem[]; value: number }) {
  const { prompt } = useDialogs();
  const isOrderLocked = useIsOrderLocked();
  const allSame = items.every((item) => item.qty === value);
  const updateOrderItemRequest = useUpdateOrderItemRequest();

  const onUpdate = () => {
    prompt({
      title: 'Update Qty',
      description: `What should the qty of ${getItemsText(items)} be?`,
      fields: [FieldFactory.number('qty').withSize('medium')],
      schema: orderItemPayloadSchema.pick({ qty: true }),
      initialValues: { qty: value },
      onSubmit: (v) =>
        Promise.all(items.map((item) => updateOrderItemRequest.mutateAsync({ id: item.id, ...v }))),
    });
  };

  return (
    <TextButton disabled={isOrderLocked || items.length === 0} onClick={onUpdate}>
      {allSame ? value.toLocaleString() : <span>&mdash;</span>}
    </TextButton>
  );
}
