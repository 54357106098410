import { HTMLAttributes } from 'react';
import { Typography } from '@mui/material';
import filter from 'lodash/filter';
import Label from '@/components/Shared/Label';
import { Address } from '@/types';

interface AddressTitleProps extends HTMLAttributes<HTMLDivElement> {
  address: Address;
  hideMethod?: boolean;
}

export default function AddressTitle({ address: a, hideMethod, ...other }: AddressTitleProps) {
  return (
    <div {...other}>
      <Typography variant="subtitle2">
        <span style={{ fontWeight: 500 }}>{a.name} </span>
        {a.method && !hideMethod && <Label gutterLeft>{a.method}</Label>}
      </Typography>
      <Typography variant="body2">
        {filter([a.address_1, a.city, a.state, a.zip, a.country]).join(', ')}
      </Typography>
    </div>
  );
}
