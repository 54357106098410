import { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';
import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { default as AdminApp } from '@/components/App/App';
import AdditionalMenuItems from '@/components/AppDrawer/AdditionalMenuItems';
import NotificationProvider from '@/components/Notifications/NotificationProvider';
import ScheduleDrawer from '@/components/Production/ScheduleDrawer';
import ImpersonatingBanner from '@/components/Shared/ImpersonatingBanner';
import { AppConfigProvider } from '@/contexts/AppConfigContext';
import { useToast } from '@/contexts/DialogContext';
import PrintProvider from '@/contexts/PrintContext';
import { SchedulingProvider } from '@/contexts/SchedulingContext';
import pages, { direct } from '@/pages';
import { NavItem } from '@/resources/types';
import { AppResponse, FileUpload } from '@/types';

function redirectToLogin(params = {}) {
  const qs = new URLSearchParams(params);
  qs.append('redirect_to', window.location.href);
  window.location.href = `/login?${qs.toString()}`;
}

export const onUpload = async (
  files: FileList | File[],
  type = 'file',
  progress?: (p: number) => void,
) => {
  const promises = [...files].map(async (file) => {
    const { data } = await axios.post<FileUpload>('/api/files', {
      extension: file.name.split('.').pop(),
      content_type: file.type,
      type,
    });

    await axios.put(data.signed_url, file, {
      headers: omit(data.headers, ['Host']),
      onUploadProgress: (progressEvent) => {
        if (progress && progressEvent.total != null) {
          progress(progressEvent.loaded / progressEvent.total);
        }
      },
    });

    return {
      name: data.name,
      file: data.file,
      url: data.url,
      image: data.url,
    };
  });

  return Promise.all(promises);
};

function BootstrapAxios() {
  const toast = useToast();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        const { data, headers } = response;
        if (data.message && Object.keys(data).length < 3) {
          toast(data.message);
        } else if (headers['x-toast-message']) {
          toast(headers['x-toast-message']);
        }
        return response;
      },
      (error: AxiosError) => {
        if (error.status === 401) {
          redirectToLogin();
          return;
        }
        const message = get(error, 'response.data.message');
        const errors = get(error, 'response.data.errors', {});
        const errorText = map(errors, (v) => v[0]).join(', ');

        if (message) {
          toast(errorText || message, 'error');
        }
        throw error;
      },
    );
  }, [toast]);

  return null;
}

export default function AuthenticatedApp({
  config,
  businessId,
  onBusinessChange,
}: {
  config: AppResponse;
  businessId: number | null;
  onBusinessChange: (newId: number | null) => void;
}) {
  useEffect(() => {
    Sentry.setUser(pick(config.user, ['id', 'email', 'name']));
  }, [config]);

  const filteredPages: NavItem[] = businessId
    ? pages
        .map((p) => {
          if ('children' in p) {
            return {
              ...p,
              children: p.children.filter((c) => !c.isGlobal),
            };
          }
          return p;
        })
        .filter((p) => !p.isGlobal)
    : pages;

  return (
    <AppConfigProvider value={config}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <NotificationProvider>
          <SchedulingProvider>
            <PrintProvider>
              <ImpersonatingBanner />
              <AdminApp
                title="Avail ERP"
                pages={filteredPages}
                routes={direct}
                drawerChildren={
                  <AdditionalMenuItems
                    businessId={businessId}
                    onBusinessChange={onBusinessChange}
                  />
                }
              >
                <ScheduleDrawer />
                <BootstrapAxios />
              </AdminApp>
            </PrintProvider>
          </SchedulingProvider>
        </NotificationProvider>
      </LocalizationProvider>
    </AppConfigProvider>
  );
}
