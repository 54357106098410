import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid2 as Grid } from '@mui/material';
import axios from 'axios';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import Form from '@/components/Form/Form';
import FormField from '@/components/Form/FormField';
import Paper from '@/components/Shared/Paper';
import { useTitle } from '@/contexts/AppContext';
import { Account, genericModelReferenceSchema, Reconciliation } from '@/types';
import { useZodForm } from '@/utils/form';

const formSchema = z.object({
  account: genericModelReferenceSchema,
  end_date: z.string(),
  end_balance: z.coerce.number(),
});

export default function ReconciliationForm() {
  useTitle('Reconciliation');

  const form = useZodForm(formSchema);

  const [existing, setExisting] = useState<Reconciliation>();
  const account = form.watch('account');
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('account_id');
  const navigate = useNavigate();

  useEffect(() => {
    setExisting(undefined);
    if (account) {
      const params = { account_id: account.id };
      axios.get('/api/reconciliations', { params }).then(({ data }) => {
        setExisting(data.data[0]);
      });
    }
  }, [account?.id]);

  useEffect(() => {
    if (accountId && account === undefined) {
      axios.get<Account>(`/api/accounts/${accountId}`).then(({ data }) => {
        form.setValue('account', data);
      });
    }
  }, [accountId]);

  const onSubmit = (values: z.infer<typeof formSchema>) =>
    axios
      .post<Reconciliation>('/api/reconciliations', {
        ...values,
        account_id: values.account.id,
      })
      .then(({ data }) => {
        navigate(`/reconciliation/${data.id}`);
      });

  return (
    <Paper>
      <Form form={form} onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 4 }}>
            <FormField
              field={FieldFactory.belongsTo('account', 'accounts').withRequestParams({
                'filter[type][in]': 'bank,credit_card',
              })}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <FormField field={FieldFactory.date('end_date').withHelp('The statement end date')} />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <FormField
              field={FieldFactory.curr('end_balance').withHelp('The statement ending balance')}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          {existing && (
            <Button
              variant="contained"
              component={Link}
              to={`/reconciliation/${existing.id}`}
              sx={{ mr: 2 }}
            >
              Continue Reconciliation
            </Button>
          )}

          {form.formState.isDirty && (
            <LoadingButton type="submit" loading={form.formState.isSubmitting}>
              Start New Reconciliation
            </LoadingButton>
          )}
        </Box>
      </Form>
    </Paper>
  );
}
