import { Box, LinearProgress, List, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FieldProps } from '@/classes/types';
import AddressListItem from '@/components/Addresses/AddressListItem';
import StaticFormControl from '@/components/Form/StaticFormControl';
import { Address } from '@/types';
import { Order, Quote } from '@/types';
import { useRecord } from '@/utils/genericResource';

export default function BillingAddressChooser({ fieldState, field, fieldModel }: FieldProps) {
  const customerId = useRecord<Order | Quote>().customer.id;

  const { data: addresses = [], isLoading } = useQuery(
    ['customerBillingAddresses', customerId],
    () =>
      axios
        .get<{ data: Address[] }>(`/api/customers/${customerId}/addresses?filter[is_billing]=1`)
        .then(({ data }) => data.data),
    {
      staleTime: 1000 * 5,
    },
  );

  return (
    <StaticFormControl fieldModel={fieldModel} fieldState={fieldState}>
      {isLoading ? (
        <LinearProgress />
      ) : addresses.length > 0 ? (
        <List>
          {addresses.map((a) => (
            <AddressListItem
              key={a.id}
              address={a}
              onToggle={() => field.onChange(a)}
              isSelected={a.id === field.value?.id}
            />
          ))}
        </List>
      ) : (
        <Box px={2} pb={2}>
          <Typography variant="body2" color="textSecondary">
            There are no billing addresses for this customer.
          </Typography>
        </Box>
      )}
    </StaticFormControl>
  );
}
