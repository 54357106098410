import LoadingButton from '@mui/lab/LoadingButton';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import FieldFactory from '@/classes/FieldFactory';
import CustomerCommissions from '@/components/Customers/CustomerCommissions';
import Form from '@/components/Form/Form';
import FormField from '@/components/Form/FormField';
import Paper from '@/components/Shared/Paper';
import { useTitle } from '@/contexts/AppContext';
import { useDialogs, useToast } from '@/contexts/DialogContext';
import { CustomerCommission, User } from '@/types';
import { handleLaravelErrors } from '@/utils/form';
import numString from '@/utils/numString';

interface FormShape {
  from_rep?: User;
  to_rep?: User;
  commissions: CustomerCommission[];
}

export default function CustomerUpdate() {
  useTitle('Bulk Customer Update');
  const toast = useToast();
  const { confirm } = useDialogs();
  const form = useForm<FormShape>({
    defaultValues: {
      commissions: [],
    },
  });
  const { watch, setError, reset, formState } = form;

  const fromRep = watch('from_rep');

  const { data: preview, remove } = useQuery(
    ['bulkCustomerUpdatePreview', fromRep?.id],
    () =>
      axios
        .put<{
          customers: string[];
          count: number;
        }>('/api/customers', { from_rep: fromRep, preview: true })
        .then(({ data }) => data),
    {
      enabled: Boolean(fromRep),
    },
  );

  const onSubmit = (values: FormShape) =>
    confirm({
      title: 'Bulk Update Customers',
      description: 'This action is not reversible. Are you sure you want to proceed?',
    }).then(() =>
      axios
        .put('/api/customers', { ...values, preview: false })
        .then(({ data }) => {
          toast(`${numString(data.count, 'customers')} updated`);
          reset();
          remove();
        })
        .catch(handleLaravelErrors({ setError })),
    );

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Paper title="Sales Rep">
        <div style={{ maxWidth: 500 }}>
          <FormField field={FieldFactory.belongsTo('from_rep', 'users')} />
          <FormField field={FieldFactory.belongsTo('to_rep', 'users')} />
        </div>
      </Paper>

      <Paper title="Commissions">
        <FormField
          field={FieldFactory.custom('commissions', CustomerCommissions).withColumnSpan(12)}
        />
      </Paper>

      {preview && (
        <Paper title="Preview">
          <div>Count: {preview.count}</div>
          <ul>
            {preview.customers.slice(0, 5).map((c) => (
              <li key={c}>{c}</li>
            ))}
            {preview.customers.length > 5 && (
              <li>and {numString(preview.customers.length - 5, 'more customers')}</li>
            )}
          </ul>

          {preview.count > 0 && (
            <LoadingButton
              type="submit"
              variant="contained"
              loading={formState.isSubmitting}
              sx={{ mt: 3 }}
            >
              Update {numString(preview.count, 'Customers')}
            </LoadingButton>
          )}
        </Paper>
      )}
    </Form>
  );
}
