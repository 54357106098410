import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import pick from 'lodash/pick';
import { useLocation } from 'react-router-dom';
import {
  Order,
  OrderItem,
  OrderItemPayload,
  OrderWithDetails,
  PartiallyRequired,
  OrderUpdatePayload,
} from '@/types';
import { useCurrentResource, useRecordId, useUpdateRecord } from '@/utils/genericResource';

type OrderItemWithInventoryVariant = PartiallyRequired<OrderItem, 'inventory_variant'>;

export const TOTAL_FIELDS = [
  'tax',
  'precollected_tax',
  'total_tax',
  'total_exempt',
  'shipping',
  'inbound_freight',
  'total',
  'balance',
  'paid',
  'subtotal',
  'items_sum',
  'updated_at',
  'last_tax_hash',
  'current_tax_hash',
] as const;

export function useApiSegment(index: number) {
  const location = useLocation();
  return location.pathname.split('/')[index];
}

export function useOrderableApiUrl(resource = '') {
  const id = useRecordId();
  const segment = useApiSegment(1);

  return `/api/${segment}/${id}/${resource}`.replace(/\/$/, '');
}

function useItemsQueryKey() {
  const segment = useApiSegment(1);
  const recordId = useRecordId();
  return [segment, recordId, 'withItems'];
}

export function getItemsText(items: OrderItem[]) {
  if (items.length === 1) {
    return 'this item';
  }
  return `these ${items.length} items`;
}

export function useHandleUpdatedOrder() {
  const currentResource = useCurrentResource();
  const updateRecord = useUpdateRecord();

  return useCallback(
    (data: Order, onlyTotals = false) => {
      if (currentResource === 'orders' || currentResource === 'quotes') {
        updateRecord(onlyTotals ? pick(data, TOTAL_FIELDS) : data);
      }
    },
    [updateRecord, currentResource],
  );
}

export function useGetOrderItems() {
  const queryKey = useItemsQueryKey();
  const baseUrl = useOrderableApiUrl();
  const handleUpdatedOrder = useHandleUpdatedOrder();

  return useQuery(queryKey, () =>
    axios.get<OrderWithDetails>(`${baseUrl}?with=items&skip=1`).then(({ data }) => {
      handleUpdatedOrder(data, true);
      return data.items;
    }),
  );
}

export function useUpdateOrderRequest() {
  const orderUrl = useOrderableApiUrl();
  const handleUpdatedOrder = useHandleUpdatedOrder();

  return useMutation(
    (v: OrderUpdatePayload) => axios.put<Order>(orderUrl, v).then(({ data }) => data),
    {
      onSuccess: (data) => handleUpdatedOrder(data),
    },
  );
}

export function useUpdateTaxRequest() {
  const orderUrl = useOrderableApiUrl();
  const handleUpdatedOrder = useHandleUpdatedOrder();

  return useMutation(() => axios.post<Order>(`${orderUrl}/tax`).then(({ data }) => data), {
    onSuccess: (data) => handleUpdatedOrder(data, true),
  });
}

export function useCreateOrderItemRequest() {
  const itemsQueryKey = useItemsQueryKey();
  const queryClient = useQueryClient();
  const itemsUrl = useOrderableApiUrl('items');

  return useMutation(
    (v: OrderItemPayload | { items: OrderItemPayload[] }) =>
      axios.post<OrderItem | { data: OrderItem[] }>(itemsUrl, v).then(({ data }) => data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData<OrderItem[]>(itemsQueryKey, (prev) => {
          const newItems = 'data' in res ? res.data : [res];
          return [...(prev || []), ...newItems];
        });
        queryClient.invalidateQueries(itemsQueryKey);
      },
    },
  );
}

export function useChangeProductRequest() {
  const itemsQueryKey = useItemsQueryKey();
  const queryClient = useQueryClient();
  const itemsUrl = useOrderableApiUrl('items');

  return useMutation(
    (payload: { order_item_ids: number[]; new_product_id: number }) =>
      axios.post<OrderItem>(`${itemsUrl}/change-product`, payload).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(itemsQueryKey);
      },
    },
  );
}

export function useUpdateOrderItemRequest() {
  const itemsQueryKey = useItemsQueryKey();
  const queryClient = useQueryClient();
  const itemsUrl = useOrderableApiUrl('items');

  return useMutation(
    ({ id, ...v }: Partial<OrderItemPayload> & { id: number }) =>
      axios.put<OrderItem>(`${itemsUrl}/${id}`, v).then(({ data }) => data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData<OrderItem[]>(itemsQueryKey, (prev) =>
          prev?.map((p) => (p.id === res.id ? res : p)),
        );
        queryClient.invalidateQueries(itemsQueryKey);
      },
    },
  );
}

export function useDeleteOrderItemRequest() {
  const itemsQueryKey = useItemsQueryKey();
  const queryClient = useQueryClient();
  const itemsUrl = useOrderableApiUrl('items');

  return useMutation((itemId: number) => axios.delete(`${itemsUrl}/${itemId}`), {
    onSuccess: (_, itemId) => {
      queryClient.setQueryData<OrderItem[]>(itemsQueryKey, (prev) =>
        prev?.filter((p) => p.id !== itemId),
      );
      queryClient.invalidateQueries(itemsQueryKey);
    },
  });
}

export function useSortOrderItemsRequest() {
  const queryClient = useQueryClient();
  const itemsUrl = useOrderableApiUrl('items');
  const queryKey = useItemsQueryKey();

  return useMutation(
    () => axios.post<{ data: OrderItem[] }>(`${itemsUrl}/sort`).then(({ data }) => data.data),
    {
      onSuccess: (items) => {
        queryClient.setQueryData(queryKey, items);
      },
    },
  );
}

export function useResortOrderItemsRequest() {
  const queryClient = useQueryClient();
  const itemsUrl = useOrderableApiUrl('items');
  const queryKey = useItemsQueryKey();

  return useMutation(
    (ids: number[]) =>
      axios
        .post<{ data: OrderItem[] }>(`${itemsUrl}/resort`, { ids })
        .then(({ data }) => data.data),
    {
      onSuccess: (items) => {
        queryClient.setQueryData(queryKey, items);
      },
    },
  );
}

export function useGetShippableItems(orderId: number) {
  return useQuery(['shippableItems', orderId], () =>
    axios
      .get<{
        data: OrderItemWithInventoryVariant[];
      }>(`/api/orders/${orderId}/items?filter[is_shippable]=1&with=inventory_variant.product`)
      .then(({ data }) => data.data.filter((d) => d.qty != 0)),
  );
}

export function useUpdateShippableOrderItem(orderId: number) {
  const queryClient = useQueryClient();

  return useMutation(({ id, ...payload }: Partial<OrderItemPayload> & { id: number }) =>
    axios
      .put<OrderItemWithInventoryVariant>(
        `/api/orders/${orderId}/items/${id}?with=inventory_variant`,
        payload,
      )
      .then(({ data }) => {
        queryClient.setQueryData<OrderItemWithInventoryVariant[]>(
          ['shippableItems', orderId],
          (prev) => {
            if (!prev) return prev;
            return prev.map((item) => (item.id === data.id ? data : item));
          },
        );
        return data;
      }),
  );
}
