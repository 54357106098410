import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import { z } from 'zod';
import { useCreateOrderPayout, useDeleteOrderPayout, useGetOrderPayouts } from '@/api/orderPayouts';
import { FieldFactory } from '@/classes';
import Can from '@/components/Permissions/Can';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import Label from '@/components/Shared/Label';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderPayout } from '@/types';
import curr from '@/utils/curr';
import { useRecordId } from '@/utils/genericResource';
import getBusinessName from '@/utils/getBusinessName';
import { shortenNum } from '@/utils/helpers';

export default function OrderPayouts() {
  const orderId = Number(useRecordId());
  const { prompt, confirm } = useDialogs();
  const { data: businesses, isFetching } = useGetOrderPayouts(orderId);
  const createRequest = useCreateOrderPayout(orderId);
  const deleteRequest = useDeleteOrderPayout(orderId);

  const onAdjustment = () => {
    prompt({
      title: 'Add Payout Adjustment',
      fields: [FieldFactory.curr('amount'), FieldFactory.text('description')],
      schema: z.object({
        amount: z.coerce.number(),
        description: z.string(),
      }),
      onSubmit: (v) => createRequest.mutateAsync(v),
    });
  };

  const onRemove = (row: OrderPayout) => {
    confirm({ title: 'Delete Payout Adjustment', description: 'Are you sure?' }).then(() => {
      deleteRequest.mutate(row.id);
    });
  };

  if (!businesses) {
    return null;
  }

  return (
    <Box>
      {isFetching && <LinearProgress />}
      <Can permission="read:order_payouts">
        <Box mb={3}>
          {businesses.map((payout) => (
            <Card sx={{ mb: 1 }} key={payout.business.id}>
              <CardContent>
                {payout.earned < 0 && (
                  <Box mb={1}>
                    <Alert severity="warning">
                      This order has a very low margin and therefore a negative payout.
                    </Alert>
                  </Box>
                )}
                <Box display="flex" justifyContent="space-between">
                  <div>
                    <Typography variant="h5">Payout: {getBusinessName(payout.business)}</Typography>
                    {payout.business.is_house ? (
                      <Label>House LOB</Label>
                    ) : (
                      <Typography variant="subtitle1" color="textSecondary">
                        {shortenNum(payout.avail_percent)}% Markup on Cost
                      </Typography>
                    )}
                  </div>
                  <Box textAlign="right">
                    <Typography variant="h3" color="primary" sx={{ mb: 1 }}>
                      {curr(payout.earned)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <span>{curr(payout.realized)} Realized</span>
                      <span> + </span>
                      <span>{curr(payout.pending)} Pending</span>
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              {payout.data.length > 0 && (
                <Table>
                  <TableHead>
                    <TableCell>Date</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell />
                  </TableHead>
                  <TableBody>
                    {payout.data.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{moment(row.created_at).format('lll')}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          <ColoredCurrency bold amount={row.amount} />
                        </TableCell>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                          {row.created_by_user && (
                            <>
                              <Label>Adjustment</Label>
                              <Can permission="write:order_payouts">
                                <IconButton
                                  size="small"
                                  onClick={() => onRemove(row)}
                                  sx={{ ml: 1 }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </Can>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="subtitle2">Total</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {curr(sumBy(payout.data, 'amount'))}
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </Table>
              )}
            </Card>
          ))}

          <Can permission="write:order_payouts">
            <Button type="button" size="small" onClick={onAdjustment}>
              Add Payout Adjustment
            </Button>
          </Can>
        </Box>
      </Can>
    </Box>
  );
}
