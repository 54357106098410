import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ProductDetails from '@/components/Products/ProductDetails';
import { ProductVariant, ProductWithVariants } from '@/types';
import ProductSearchGrid from './ProductSearchGrid';

type BaseDialogProps = {
  open: boolean;
  onCancel: () => void;
  customerId?: number | null;
  defaultFacets?: Record<string, string>;
  isLoading?: boolean;
};

type ProductMode = {
  onSuccess: (p: ProductWithVariants) => void;
  mode: 'product';
} & BaseDialogProps;

type VariantMode = {
  onSuccess: (p: ProductVariant) => void;
  mode: 'variant';
} & BaseDialogProps;

export default function ProductFinderDialog({
  open,
  customerId,
  onCancel,
  onSuccess,
  isLoading,
  mode,
  defaultFacets,
}: ProductMode | VariantMode) {
  const [product, setProduct] = useState<ProductWithVariants>();
  const [variant, setVariant] = useState<ProductVariant>();

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xl">
      <DialogTitle>Advanced Product Search</DialogTitle>
      <DialogContent
        style={{
          width: 1300,
          maxWidth: '100%',
          minHeight: 600,
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        {product ? (
          <ProductDetails
            onProductChange={setProduct}
            product={product}
            onBack={() => setProduct(undefined)}
            onVariantChange={mode === 'variant' ? setVariant : undefined}
          />
        ) : (
          <ProductSearchGrid
            onSelect={setProduct}
            customerId={customerId}
            defaultFacets={defaultFacets}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          type="button"
          variant="contained"
          onClick={() => {
            if (variant && mode === 'variant') {
              onSuccess(variant);
            } else if (product && mode === 'product') {
              onSuccess(product);
            }
          }}
          disabled={mode === 'variant' ? !variant : !product}
          loading={isLoading}
        >
          Choose
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
