import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid2 as Grid,
  LinearProgress,
  List,
  Stack,
  Typography,
} from '@mui/material';
import { Packout, PartiallyRequired } from 'avail-types';
import { useKitProductionOrders } from '@/api/kitting';
import OrderListItem from '@/components/Orders/OrderListItem';
import PackoutTimingTable from '@/components/Packouts/PackoutTimingTable';
import StatusChip from '@/components/Shared/StatusChip';
import { PACKOUT_READY_STATUS_COLOR } from '@/constants';
import { useResource } from '@/hooks/useGetResource';
import usePushWithContext from '@/hooks/usePushWithContext';
import { useRecord } from '@/utils/genericResource';
import numString from '@/utils/numString';

export default function PackoutPendingStatus() {
  const packout = useRecord('orderPackouts') as PartiallyRequired<Packout, 'timing'>;
  const { order } = packout;
  const orderResource = useResource('orders');

  const { data: otherOrders = [], isLoading } = useKitProductionOrders(order.id);

  const push = usePushWithContext(
    otherOrders.map((o) => ({
      key: o.id,
      label: orderResource.getTitle(o),
      href: `/orders/${o.id}`,
    })),
  );

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, xl: 7 }}>
        <Card>
          <CardHeader
            title="Production Orders"
            action={
              <Stack spacing={1} direction="row">
                <Chip label={numString(packout.kits_count, 'Kits')} />
                <Chip label={numString(packout.assigned_items_count, 'Kit Items')} />
                <Chip label={numString(packout.order_items_count, 'Order Items')} />
                <StatusChip status={packout.ready_status} colors={PACKOUT_READY_STATUS_COLOR} />
              </Stack>
            }
          />

          {isLoading ? (
            <LinearProgress />
          ) : otherOrders.length === 0 ? (
            <CardContent>
              <Typography>There are no production orders for this packout.</Typography>
            </CardContent>
          ) : (
            <List>
              {otherOrders.map((o) => (
                <OrderListItem key={o.id} order={o} push={push} />
              ))}
            </List>
          )}
        </Card>
      </Grid>
      <Grid size={{ xs: 12, xl: 5 }}>
        <Card sx={{ height: '100%' }}>
          <CardHeader title="Scheduling" />
          <PackoutTimingTable packout={packout} />
        </Card>
      </Grid>
    </Grid>
  );
}
