import { To } from 'react-router-dom';
import Action from '../Action';

export default class LinkAction extends Action {
  to?: To;
  state?: object;

  constructor(label: string, to: To, state?: object) {
    super(label);
    this.to = to;
    this.state = state;
  }
}
