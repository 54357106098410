import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { AccountDetail } from '@/types';

export function useGetBankAccounts() {
  return useQuery(['bankAccounts'], () =>
    axios
      .get<{
        data: AccountDetail[];
      }>('/api/accounts?filter[type][in]=bank,credit_card&is_disabled=0&sort=number')
      .then(({ data }) => data.data),
  );
}
