import { ReactNode } from 'react';
import { useHasPermission, useHasRole } from '@/hooks/permissions';

export default function Can({
  permission,
  role,
  children,
}: {
  permission?: string;
  role?: string;
  children: ReactNode;
}) {
  const hasPermission = useHasPermission();
  const hasRole = useHasRole();

  if (permission && !hasPermission(permission)) {
    return null;
  }
  if (role && !hasRole(role)) {
    return null;
  }
  return <>{children}</>;
}
