import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthLayout from '@/pages/auth/AuthLayout';

export default function ResetPassword() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const resetRequest = useMutation<
    unknown,
    AxiosError<{ errors: Record<string, string[]>; message: string }>,
    { email: string; password: string; password_confirmation: string; token: string }
  >((v) =>
    axios.post(`/api/reset-password`, v).then(() => {
      navigate('/login');
    }),
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    resetRequest.mutate({
      email: data.get('email') as string,
      password: data.get('password') as string,
      password_confirmation: data.get('password_confirmation') as string,
      token: searchParams.get('token') as string,
    });
  };

  return (
    <AuthLayout title="Reset Password">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {resetRequest.error && (
          <Alert severity="error" sx={{ my: 1 }}>
            {resetRequest.error.response?.data.message || 'An unknown error occurred'}
          </Alert>
        )}
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          defaultValue={searchParams.get('email')}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password_confirmation"
          label="Confirm Password"
          type="password"
          id="password-confirmation"
          autoComplete="current-password"
        />

        <LoadingButton
          loading={resetRequest.isLoading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Reset Password
        </LoadingButton>
      </Box>
    </AuthLayout>
  );
}
