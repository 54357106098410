import { SyntheticEvent, useState } from 'react';
import AddIcon from '@mui/icons-material/CreateNewFolder';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
} from '@mui/material';
import { useCreateAddressForOrder } from '@/api/addresses';
import BusinessAddressList from '@/components/Addresses/BusinessAddressList';
import CustomerAddressList from '@/components/Addresses/CustomerAddressList';
import DrawerButtons from '@/components/Form/DrawerButtons';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { useConfig } from '@/contexts/AppConfigContext';
import { Address } from '@/types';
import numString from '@/utils/numString';

export default function AddressChooser({
  resource,
  recordId,
  customerId,
}: {
  resource: 'orders' | 'quotes';
  recordId: number;
  customerId: number;
}) {
  const [open, setOpen] = useState(false);
  const [method, setMethod] = useState('');
  const [selected, setSelected] = useState<Address[]>([]);
  const { shippingMethods } = useConfig();

  const createRequest = useCreateAddressForOrder(resource, recordId);

  const select = (a: Address) => setSelected((prevState) => [...prevState, a]);
  const deselect = (a: Address) =>
    setSelected((prevState) => prevState.filter((p) => p.id !== a.id));
  const isSelected = (a: Address) => selected.some((i) => i.id === a.id);

  const onToggle = (a: Address) => {
    if (isSelected(a)) {
      deselect(a);
    } else {
      select(a);
    }
  };

  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (selected.length < 1) {
      return null;
    }

    const promises = selected.map((a) => createRequest.mutateAsync({ id: a.id, method }));

    return Promise.all(promises).then(() => {
      setSelected([]);
      onClose();
    });
  };

  return (
    <>
      <Tooltip title="Choose Saved Address">
        <IconButton onClick={onOpen} color="primary" size="large">
          <AddIcon />
        </IconButton>
      </Tooltip>

      <ClosableDrawer title="Choose Saved Address" open={open} onClose={onClose} sticky>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Method</InputLabel>
            <Select
              variant="outlined"
              value={method}
              label="Method"
              onChange={(e) => setMethod(e.target.value)}
            >
              {shippingMethods.map((m) => (
                <MenuItem key={m.id} value={m.name}>
                  {m.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {open && (
            <Stack spacing={2}>
              <BusinessAddressList isSelected={isSelected} onToggle={onToggle} />
              <CustomerAddressList
                customerId={customerId}
                isSelected={isSelected}
                onToggle={onToggle}
              />
            </Stack>
          )}

          <DrawerButtons>
            <LoadingButton
              type="submit"
              disabled={selected.length < 1 || !method}
              variant="contained"
              loading={createRequest.isLoading}
            >
              Choose {numString(selected.length, 'Addresses')}
            </LoadingButton>
          </DrawerButtons>
        </form>
      </ClosableDrawer>
    </>
  );
}
