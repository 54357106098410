import { ExpandMore, Undo } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Chip,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import sumBy from 'lodash/sumBy';
import moment from 'moment/moment';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import Can from '@/components/Permissions/Can';
import UserLabel from '@/components/Users/UserLabel';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderScrap } from '@/types';
import { useOnReloadRecord, useRecordId } from '@/utils/genericResource';
import numString from '@/utils/numString';

export default function OrderScraps({ scraps }: { scraps: OrderScrap[] }) {
  const { confirm } = useDialogs();
  const orderId = useRecordId();
  const onReload = useOnReloadRecord();

  if (scraps.length === 0) {
    return null;
  }

  const onDeleteScrap = (scrap: OrderScrap) => {
    confirm({
      title: 'Delete Scrap',
      description: `Are you sure you want to delete this scrap?`,
    }).then(() => {
      axios.delete(`/api/orders/${orderId}/scraps/${scrap.id}`).then(() => {
        onReload();
      });
    });
  };

  return (
    <Box mt={2}>
      {scraps.map((s) => (
        <Accordion key={s.id}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Scrap #{s.id}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'block', paddingTop: 0 }}>
            <Box display="flex" alignItems="flex-start" mb={4}>
              <Box flexGrow={1}>
                <Typography variant="body1" gutterBottom>
                  <span>{numString(sumBy(s.items, 'qty'))}</span>
                  <span> scrapped </span>
                  {s.created_by_user && (
                    <span>
                      by <UserLabel user={s.created_by_user} />
                    </span>
                  )}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {moment(s.created_at).format('llll')}
                </Typography>
                {s.reason && (
                  <Typography variant="body2" color="textSecondary">
                    Reason: {s.reason}
                  </Typography>
                )}
              </Box>

              <Can permission="orders:scrap_items">
                <IconButton onClick={() => onDeleteScrap(s)} size="large">
                  <Undo />
                </IconButton>
              </Can>
            </Box>
            {s.items.map((i) => (
              <Box display="flex" key={i.id} justifyContent="space-between" my={2}>
                <OrderItemProduct item={i.order_item} showSize />
                <Chip label={`Scrapped: ${i.qty}`} />
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
