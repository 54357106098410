import { ChangeEvent } from 'react';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { FieldFactory } from '@/classes';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import FormField from '@/components/Form/FormField';
import VariantField from '@/components/Products/VariantField';

export default function VariantSection({
  isCustom,
  handleCustomChange,
}: {
  isCustom: boolean;
  handleCustomChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <DrawerFormSection title="Product">
      <FormField field={FieldFactory.text('description').setRequired().withSize('medium')} />
      <RadioGroup
        row
        value={isCustom ? 'custom' : 'database'}
        onChange={handleCustomChange}
        sx={{ mb: 1 }}
      >
        <FormControlLabel control={<Radio />} value="database" label="Database" />
        <FormControlLabel control={<Radio />} value="custom" label="Custom" />
      </RadioGroup>
      <Stack spacing={2}>
        {isCustom ? (
          <>
            <FormField field={FieldFactory.text('number').setRequired()} />
            <FormField field={FieldFactory.text('color')} />
            <FormField field={FieldFactory.text('size')} />
          </>
        ) : (
          <FormField field={FieldFactory.custom('variant', VariantField).withLabel('SKU')} />
        )}
      </Stack>
    </DrawerFormSection>
  );
}
