import { useLayoutEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { BoxOwnProps } from '@mui/system/Box/Box';

export default function DrawerButtons({ children, ...props }: BoxOwnProps) {
  const ref = useRef<HTMLDivElement>();
  const [isPinned, setIsPinned] = useState(false);
  const theme = useTheme();

  useLayoutEffect(() => {
    setTimeout(() => {
      const observer = new IntersectionObserver(([e]) => setIsPinned(e.intersectionRatio < 1), {
        threshold: [1],
      });

      observer.observe(ref.current!);
    }, 1000);
  }, []);

  return (
    <Box
      ref={ref}
      mt={1}
      py={2}
      mb={-3}
      mx={-3}
      px={2}
      display="flex"
      justifyContent="end"
      position="sticky"
      bottom="-1px"
      gap={1}
      bgcolor="background.paper"
      zIndex={1}
      boxShadow={isPinned ? `0px 0 16px ${theme.palette.action.disabledBackground}` : 'none'}
      {...props}
    >
      {children}
    </Box>
  );
}
