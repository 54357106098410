import { CSSProperties, ReactNode } from 'react';
import { Chip, ChipProps, SxProps, useTheme, colors as materialColors } from '@mui/material';
import startCase from 'lodash/startCase';

const MATERIAL_COLORS = materialColors as unknown as Record<string, { [400]: string }>;

export default function StatusChip({
  status,
  sx,
  size,
  variant = 'filled',
  style = {},
  colors = {},
  labels = {},
  startCase: shouldStartCase = true,
  children,
}: {
  status: string | null;
  sx?: SxProps;
  style?: CSSProperties;
  size?: 'small' | 'medium';
  labels?: Record<string, ReactNode>;
  colors?: Record<string, string>;
  variant?: 'filled' | 'outlined';
  startCase?: boolean;
  children?: ReactNode;
}) {
  const theme = useTheme();

  if (!status) {
    return null;
  }

  const color = colors[status];

  const getLabel = () => {
    if (children) {
      return children;
    }
    if (labels[status]) {
      return labels[status];
    }
    if (shouldStartCase) {
      return startCase(status);
    }
    return status;
  };

  const getChipColor = (): { color: ChipProps['color']; style: CSSProperties } => {
    if (!color || color === 'default') {
      return { color: 'default', style: {} };
    }
    if (
      color === 'primary' ||
      color === 'secondary' ||
      color === 'error' ||
      color === 'info' ||
      color === 'success' ||
      color == 'warning'
    ) {
      return { color, style: {} };
    }
    if (color in MATERIAL_COLORS) {
      return {
        color: 'default',
        style: {
          color: theme.palette.getContrastText(MATERIAL_COLORS[color][400]),
          background: MATERIAL_COLORS[color][400],
        },
      };
    }
    return { color: 'default', style: {} };
  };

  return (
    <Chip
      label={getLabel()}
      color={getChipColor().color}
      style={{ ...style, ...getChipColor().style }}
      variant={variant}
      size={size}
      sx={sx}
    />
  );
}
