import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import shortHash from 'short-hash';

export interface MappedRow {
  key: string | number;
  href: string;
  label: string;
}

export function setOtherRowContext(contextKey: string, rows: MappedRow[]) {
  sessionStorage.setItem(`otherRows.${contextKey}`, JSON.stringify(rows));
}

export function getOtherRowContext(contextKey: string): MappedRow[] {
  return JSON.parse(sessionStorage.getItem(`otherRows.${contextKey}`) || '[]');
}

export function useContextKey() {
  return shortHash(useLocation().pathname);
}

export default function usePushWithContext(mappedRows: MappedRow[]) {
  const navigate = useNavigate();
  const contextKey = useContextKey();
  const primaryKeys = mappedRows.map((r) => r.key).join('|');
  return useCallback(
    (targetHref: string) => {
      setOtherRowContext(contextKey, mappedRows);
      navigate(targetHref, { state: { context: contextKey } });
    },
    [contextKey, primaryKeys],
  );
}
