import { createContext, useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import qz from 'qz-tray';
import { useLocalStorage } from 'usehooks-ts';
import cert from '@/assets/digital-certificate.txt?raw';

export type LabelType = 'PDF_4x6' | 'ZPLII';

export const PrintContext = createContext<{
  labelType: LabelType;
  setLabelType: (labelType: LabelType) => void;
  ipAddress: string;
  setIpAddress: (ipAddress: string) => void;
}>({
  labelType: 'PDF_4x6',
  setLabelType: () => {},
  ipAddress: '',
  setIpAddress: () => null,
});

export function usePrintContext() {
  return useContext(PrintContext);
}

export function useLabelType() {
  return usePrintContext().labelType;
}

qz.security.setCertificatePromise(function (resolve) {
  resolve(cert);
});
qz.security.setSignatureAlgorithm('SHA512');
qz.security.setSignaturePromise(function (toSign) {
  return function (resolve, reject) {
    return axios
      .post('/api/printables/sign', toSign, { headers: { 'Content-Type': 'text/plain' } })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err));
  };
});

export function usePrintZpl() {
  const { ipAddress } = usePrintContext();

  return useCallback(
    async (zpl: string) => {
      if (ipAddress) {
        return axios
          .post(`http://${ipAddress.trim()}/pstprnt`, zpl)
          .then(() => {
            console.log('Printed successfully');
          })
          .catch((e) => {
            alert(`Unable to print to "${ipAddress}": ${e.message}`);
            return Promise.reject();
          });
      }

      try {
        if (!qz.websocket.isActive()) {
          await qz.websocket.connect();
        }

        const printer = await qz.printers.find('ZDesigner');
        await qz.print(qz.configs.create(printer as string), [zpl]);
      } catch (e) {
        if (e instanceof Error) {
          alert('Could not print ZPL label: ' + e.message);
        }
        console.error(e);
      }
    },
    [ipAddress],
  );
}

export function usePrintShipmentLabel() {
  const printZpl = usePrintZpl();

  return useMutation((shipmentId: number) =>
    axios
      .get<{ url: string } | { zpl: string }>(`/api/shipments/${shipmentId}/label`)
      .then(({ data }) => {
        if ('zpl' in data) {
          printZpl(data.zpl);
        } else {
          window.open(data.url, '_blank');
        }
      }),
  );
}

export default function PrintProvider({ children }: { children: React.ReactNode }) {
  const [ipAddress, setIpAddress] = useLocalStorage<string>('printer_ip', '');
  const [labelType, setLabelType] = useLocalStorage<LabelType>('label_type', 'PDF_4x6');

  return (
    <PrintContext.Provider value={{ labelType, setLabelType, ipAddress, setIpAddress }}>
      {children}
    </PrintContext.Provider>
  );
}
