import { ArrowBack, Edit } from '@mui/icons-material';
import {
  Box,
  CardContent,
  CircularProgress,
  Grid2 as Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Can from '@/components/Permissions/Can';
import ProductColors from '@/components/Products/ProductColors';
import ProductVariantsTable from '@/components/Products/ProductVariantsTable';
import { ProductVariant } from '@/types';
import { ProductWithVariants } from '@/types';

export default function ProductDetails({
  product,
  onProductChange,
  onBack,
  onVariantChange,
}: {
  product: ProductWithVariants | undefined;
  onProductChange: (v: ProductWithVariants) => void;
  onBack: () => void;
  onVariantChange?: (v: ProductVariant | undefined) => void;
}) {
  if (!product) {
    return (
      <CardContent>
        <CircularProgress />
      </CardContent>
    );
  }
  return (
    <>
      <CardContent>
        <Box display="flex" alignItems="center" mb={3}>
          {onBack && (
            <IconButton onClick={onBack} style={{ marginRight: 8 }} size="large">
              <ArrowBack />
            </IconButton>
          )}
          <Typography variant="h5">{product.name}</Typography>

          <Can permission="write:products">
            <Box ml="auto">
              <IconButton component={Link} to={`/products/${product.id}`} size="large">
                <Edit />
              </IconButton>
            </Box>
          </Can>
        </Box>

        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid size={{ xs: 12, md: 3 }}>
            <Typography variant="subtitle2">Number</Typography>
            <Typography variant="body1">{product.style_number}</Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <Typography variant="subtitle2">Color</Typography>
            <Typography variant="body1">{product.color}</Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <Typography variant="subtitle2">Color Code</Typography>
            <Typography variant="body1">{product.color_code}</Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <Typography variant="subtitle2">Brand</Typography>
            <Typography variant="body1">{product.brand?.name}</Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <Typography variant="subtitle2">Vendor</Typography>
            <Typography variant="body1">{product.vendor?.name}</Typography>
          </Grid>
          {product.customer && (
            <Grid size={{ xs: 12, md: 3 }}>
              <Typography variant="subtitle2">Customer</Typography>
              <Typography variant="body1">{product.customer.name}</Typography>
            </Grid>
          )}
          {product.business && (
            <Grid size={{ xs: 12, md: 3 }}>
              <Typography variant="subtitle2">Business</Typography>
              <Typography variant="body1">{product.business.name}</Typography>
            </Grid>
          )}
        </Grid>

        {product.images && (
          <Box mb={3}>
            {product.images.map((image) => (
              <img
                key={image.id}
                src={image.url}
                alt=""
                style={{
                  display: 'inline-block',
                  width: 150,
                  height: 150,
                  objectFit: 'contain',
                  marginRight: 16,
                  marginBottom: 16,
                }}
              />
            ))}
          </Box>
        )}
      </CardContent>
      <ProductVariantsTable product={product} onSelect={onVariantChange} />
      <ProductColors styleNumber={product.style_number} onSelect={onProductChange} />
    </>
  );
}
