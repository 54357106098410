import { useFormContext } from 'react-hook-form';
import { FieldFactory } from '@/classes';
import { FieldProps } from '@/classes/types';
import FormField from '@/components/Form/FormField';

export default function SalesRepField({ field }: FieldProps) {
  const businessId = useFormContext().watch('business_id');
  return (
    <FormField
      field={FieldFactory.belongsTo(field.name, 'users').withRequestParams({
        business_id: businessId,
        is_disabled: 0,
      })}
    />
  );
}
