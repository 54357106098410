import { ListItemButton, ListItemText, Typography } from '@mui/material';
import StatusChip from '@/components/Shared/StatusChip';
import { APPLIED_STATUS_COLORS, APPLIED_STATUS_LABELS } from '@/constants';
import { Payment } from '@/types';
import curr from '@/utils/curr';

export default function PaymentListItem({
  payment,
  onClick,
}: {
  payment: Payment;
  onClick: () => void;
}) {
  return (
    <ListItemButton key={payment.id} onClick={onClick} dense>
      <ListItemText>
        <Typography style={{ fontWeight: 500 }}>
          {payment.payment_number || `#${payment.id}`}
        </Typography>
        <Typography variant="body2">{payment.type_title}</Typography>
        {payment.customer && (
          <Typography variant="caption" color="textSecondary">
            {payment.customer.name}
          </Typography>
        )}
      </ListItemText>
      <ListItemText style={{ marginLeft: 'auto', flexGrow: 0, textAlign: 'center' }}>
        <div style={{ marginBottom: 4 }}>
          <StatusChip
            size="small"
            status={payment.applied_status}
            colors={APPLIED_STATUS_COLORS}
            labels={APPLIED_STATUS_LABELS}
          />
        </div>
        <Typography variant="caption" component="div" color="textSecondary">
          Balance: {curr(payment.amount_to_apply)}
        </Typography>
        {Number(payment.amount_to_apply) !== Number(payment.amount) && (
          <Typography variant="caption" component="div" color="textSecondary">
            (Original: {curr(payment.amount)})
          </Typography>
        )}
      </ListItemText>
    </ListItemButton>
  );
}
