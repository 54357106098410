import memoize from 'lodash/memoize';
import set from 'lodash/set';
import { ZodError, ZodSchema } from 'zod';

export function transformLaravelErrors(all: Record<string, string[]>) {
  const errors: Record<string, string> = {};
  Object.keys(all).forEach((k) => {
    set(errors, k, all[k][0]);
  });
  return errors;
}

export function transformZodErrors(error: ZodError) {
  const errors = {};
  error.errors.forEach((item) => {
    if (item.path) {
      set(errors, item.path, item.message);
    }
  });
  return errors;
}

export function validateUsingRules(values: object, schema: ZodSchema) {
  const { success, error } = schema.safeParse(values);
  if (!success) {
    return transformZodErrors(error);
  }
  return {};
}

export const memoizedValidateUsingRules = memoize(validateUsingRules, (...args) =>
  JSON.stringify(args),
);
