import { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import FormField from '@/components/Form/FormField';
import { REPORT_FILTER_TYPES } from '@/constants';
import { useConfig } from '@/contexts/AppConfigContext';
import resources from '@/resources';
import ColumnSettings from './ColumnSettings';
import ReportQueryBuilder from './ReportQueryBuilder';

export default function ReportEditor() {
  const form = useFormContext<{ class: string | null }>();
  const className = form.watch('class');
  const [isClass, setIsClass] = useState(!!className);

  useEffect(() => {
    if (!isClass) {
      form.setValue('class', null);
    }
  }, [isClass]);
  const { reportClasses } = useConfig();

  return (
    <div>
      <FormControlLabel
        control={<Checkbox checked={isClass} onChange={(e) => setIsClass(e.target.checked)} />}
        label="Use Built-In Report"
      />

      {isClass ? (
        <div style={{ maxWidth: 400 }}>
          <FormField field={FieldFactory.select('class', reportClasses)} />
        </div>
      ) : (
        <>
          <Box mb={1}>
            <FormField field={FieldFactory.custom('query', ReportQueryBuilder)} />
          </Box>
          <Box mb={1}>
            <FormField field={FieldFactory.custom('columns', ColumnSettings)} />
          </Box>

          <FormField
            field={FieldFactory.table(
              'filters',
              [
                FieldFactory.text('name').setRequired().withHelp('No spaces (e.g CustomerName)'),
                FieldFactory.select('type', REPORT_FILTER_TYPES).setRequired(),
                FieldFactory.text('label'),
                FieldFactory.text('default'),
                FieldFactory.select('resource', Object.keys(resources)).withHelp(
                  'Only if using "belongsTo"',
                ),
              ],
              z.object({
                name: z.string(),
                type: z.string(),
                label: z.string().nullish(),
                default: z.string().nullish(),
                resource: z.string().nullish(),
              }),
            )}
          />
        </>
      )}
    </div>
  );
}
