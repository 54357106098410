import { createContext, useContext } from 'react';
import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';
import { AppResponse } from '@/types';

export const AppConfigContext = createContext<AppResponse | undefined>(undefined);

export const AppConfigProvider = AppConfigContext.Provider;

export function useConfig(): AppResponse {
  const config = useContext(AppConfigContext);
  if (!config) {
    throw new Error('Config not set');
  }
  return config;
}

export function useAssetUrl(): (path: string) => string {
  const { assetUrl } = useConfig();
  return (path) => `${trimEnd(assetUrl, '/')}/${trimStart(path, '/')}`;
}
