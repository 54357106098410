import { FormControl, FormGroup, FormLabel } from '@mui/material';
import { LayoutProps } from '@/classes/types';
import EditFormFields, { EditFormFieldsProps } from '@/components/Form/EditFormFields';
import Layout from '../Layout';

function Component({ model, forwardProps = {} }: LayoutProps) {
  return (
    <FormControl component="fieldset" sx={model.sx} variant="standard">
      {model.label && <FormLabel component="legend">{model.label}</FormLabel>}
      <FormGroup>
        <EditFormFields
          fields={model.fields}
          defaultLayout={model.defaultLayout}
          {...forwardProps}
        />
      </FormGroup>
    </FormControl>
  );
}

export default class GroupLayout extends Layout {
  renderLayout(props: Partial<EditFormFieldsProps>): React.ReactElement | null {
    return <Component model={this} forwardProps={props} />;
  }
}
