import { Alert } from '@mui/material';
import { Note } from '@/types';
import NoteListItem from './NoteListItem';

export default function NoteAlert({ note }: { note: Note }) {
  return (
    <Alert severity="info" sx={{ mb: 1 }}>
      <NoteListItem size="small" note={note} style={{ paddingTop: 0, paddingBottom: 0 }} />
    </Alert>
  );
}
