import { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  Alert,
  Box,
  Collapse,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
} from '@mui/material';
import omit from 'lodash/omit';
import { FieldErrors, UseFormReturn } from 'react-hook-form';

function getMessageArray(errors: FieldErrors, path = ''): { path: string; message: string }[] {
  return Object.entries(errors).flatMap(([key, value]) => {
    if (!value) {
      return [];
    }
    const thisPath = path ? `${path}.${key}` : key;
    if (typeof value.message === 'string') {
      return [{ path: thisPath, message: value.message }];
    }
    return getMessageArray(value as FieldErrors, thisPath);
  });
}

const ExpandMoreButton = styled((props: IconButtonProps & { expanded: boolean }) => {
  return <IconButton size="small" {...omit(props, 'expanded')} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expanded }) => !expanded,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expanded }) => !!expanded,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

function Error({ message, path }: { message: string; path: string }) {
  const hidePath = path === 'root' || message.includes('This field');
  return (
    <span>
      {message.replace('This field', path)} {hidePath ? '' : `(${path})`}
    </span>
  );
}

export default function FormErrors({ form }: { form: UseFormReturn<any> }) {
  const [expanded, setExpanded] = useState(false);
  const { errors } = form.formState;

  if (Object.keys(errors).length === 0) {
    return null;
  }

  let messages = getMessageArray(errors);

  if (messages.length === 1 && messages[0].path === 'root') {
    return <Alert severity="error">{messages[0].message}</Alert>;
  }

  // Filter out root because there will always the root is
  // always the same as the first validation error
  messages = messages.filter((m) => m.path !== 'root');

  return (
    <Alert
      severity="error"
      sx={{
        my: 2,
        '& > .MuiAlert-message': {
          pt: 0.5,
          width: '100%',
        },
      }}
    >
      <Box display="flex" alignItems="center" width="100%">
        <Typography variant="body2">Please fix the following errors before submitting:</Typography>

        <ExpandMoreButton expanded={expanded} onClick={() => setExpanded((prev) => !prev)}>
          <ExpandMore />
        </ExpandMoreButton>
      </Box>

      <Collapse in={expanded}>
        <ul style={{ listStylePosition: 'inside', paddingLeft: 0, marginTop: 12 }}>
          {messages.map((error) => (
            <li key={error.path}>
              <Error {...error} />
            </li>
          ))}
        </ul>
      </Collapse>
    </Alert>
  );
}
