import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Checkbox,
  CircularProgress,
  FormLabel,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import omit from 'lodash/omit';
import moment, { Moment } from 'moment';
import {
  useProductionMachinesForScheduling,
  useScheduleEvents,
  useScheduleEventsPreview,
} from '@/api/production';
import DrawerButtons from '@/components/Form/DrawerButtons';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { useSchedulingState } from '@/contexts/SchedulingContext';
import { ProductionMachineDetail } from '@/types';
import numString from '@/utils/numString';

const ListContainer = styled('div')(({ theme }) => ({
  maxHeight: 400,
  overflowY: 'auto',
  border: `1px solid ${theme.palette.action.disabled}`,
  borderRadius: 4,
}));

export default function ScheduleDrawer() {
  const { eventIds, setEventIds } = useSchedulingState();
  const [machine, setMachine] = useState<ProductionMachineDetail | null>(null);
  const [date, setDate] = useState<Moment | null>(null);

  const payload = {
    date: date?.format('YYYY-MM-DD'),
    machine_id: machine?.id,
    event_ids: eventIds,
  };

  const { data: machines, isLoading: isLoadingMachines } = useProductionMachinesForScheduling(
    omit(payload, 'machine_id'),
  );
  const { data: review, isLoading: isLoadingReview } = useScheduleEventsPreview(payload);
  const { mutateAsync, isLoading: isSubmitting } = useScheduleEvents();

  useEffect(() => {
    setDate(null);
  }, [eventIds]);

  useEffect(() => {
    setMachine(null);
  }, [eventIds, machines]);

  const open = eventIds.length > 0;
  const onClose = () => setEventIds([]);
  const eventsString = numString(eventIds.length || 0, 'Events');

  return (
    <ClosableDrawer title={`Schedule ${eventsString}`} open={open} onClose={onClose}>
      <Stack spacing={2}>
        <div>
          <DatePicker label="Date" value={date} onChange={setDate} />
        </div>

        {machines && (
          <div>
            <FormLabel>Machine</FormLabel>
            <ListContainer>
              <List dense disablePadding>
                {machines.map((m) => (
                  <ListItemButton key={m.id} onClick={() => setMachine(m)}>
                    <ListItemText
                      primary={m.name}
                      secondary={`Scheduled for ${m.minutes_scheduled} minutes`}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox checked={machine?.id === m.id} />
                    </ListItemSecondaryAction>
                  </ListItemButton>
                ))}
              </List>
            </ListContainer>
          </div>
        )}

        {(isLoadingMachines || isLoadingReview) && (
          <div>
            <CircularProgress size="small" />
          </div>
        )}

        {review && machine && (
          <Typography>
            Scheduling {eventsString} on <i>{machine.name}</i> will take
            <b> {review.additional_minutes} minutes</b> and bring the {moment(date).format('l')}{' '}
            total for the machine to
            <b> {review.total_minutes} minutes</b>.
          </Typography>
        )}
      </Stack>

      <DrawerButtons>
        <LoadingButton
          loading={isSubmitting}
          type="button"
          variant="contained"
          onClick={() => mutateAsync(payload).then(onClose)}
        >
          Schedule
        </LoadingButton>
      </DrawerButtons>
    </ClosableDrawer>
  );
}
