import { Delete, Email, OpenInNew } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { ButtonAction } from '@/classes';
import StatusChip from '@/components/Shared/StatusChip';
import SubMenu from '@/components/Shared/SubMenu';
import { useDialogs, useShowLoading } from '@/contexts/DialogContext';
import { OrderApproval } from '@/types';
import { makeResourceQueryKey, useRecordId } from '@/utils/genericResource';

export default function OrderApprovals() {
  const orderId = useRecordId();
  const { confirm } = useDialogs();
  const showLoading = useShowLoading();
  const queryClient = useQueryClient();

  const queryKey = makeResourceQueryKey('orders', orderId, 'approvals');
  const { data: approvals, isFetching } = useQuery(queryKey, () =>
    axios
      .get<{ data: OrderApproval[] }>(`/api/orders/${orderId}/approvals`)
      .then(({ data }) => data.data),
  );

  const onDelete = (a: OrderApproval) => {
    confirm({
      title: 'Delete Approval',
      description: 'Are you sure you want to delete this approval?',
    }).then(() => {
      showLoading(axios.delete(`/api/orders/${a.order_id}/approvals/${a.id}`)).then(() => {
        queryClient.setQueryData<OrderApproval[]>(queryKey, (data) => {
          return data?.filter((r) => r.id !== a.id);
        });
      });
    });
  };

  const onSendReminder = (a: OrderApproval) => {
    showLoading(axios.post(`/api/orders/${a.order_id}/approvals/${a.id}/reminder`));
  };

  return (
    <Card>
      <CardHeader title="Approvals Sent" />
      {isFetching && <LinearProgress />}

      {approvals &&
        (approvals.length === 0 ? (
          <CardContent>
            <Typography color="textSecondary" variant="body2">
              No approvals have been sent for this order.
            </Typography>
          </CardContent>
        ) : (
          <List>
            {approvals.map((r) => (
              <ListItem key={r.id}>
                <Box flexGrow={1}>
                  <Typography variant="body1">{r.label}</Typography>
                  <Typography variant="body2" sx={{ mt: 0.5 }}>
                    {r.to}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                    Sent {moment(r.created_at).format('lll')}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <StatusChip
                    status={r.status}
                    colors={{
                      Approved: 'green',
                      Declined: 'red',
                      Pending: 'gray',
                    }}
                    sx={{ mr: 1 }}
                  />
                  <IconButton component="a" target="_blank" href={r.portal_url}>
                    <OpenInNew />
                  </IconButton>
                  {r.status === 'Pending' && (
                    <SubMenu
                      items={[
                        new ButtonAction('Send Reminder', () => onSendReminder(r)).withIcon(Email),
                        new ButtonAction('Delete', () => onDelete(r)).withIcon(Delete),
                      ]}
                    />
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
        ))}
    </Card>
  );
}
