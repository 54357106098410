import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DuplicateIcon from '@mui/icons-material/FileCopy';
import { Box, IconButton, Tooltip } from '@mui/material';
import {
  getItemsText,
  useCreateOrderItemRequest,
  useDeleteOrderItemRequest,
} from '@/api/orderItems';
import OrderItemPrice from '@/components/Orders/Items/OrderItemPrice';
import { useDialogs, useShowLoading } from '@/contexts/DialogContext';
import { OrderItem } from '@/types';

export default function OrderItemsBulkActions({ items }: { items: OrderItem[] }) {
  const { confirm } = useDialogs();
  const createRequest = useCreateOrderItemRequest();
  const deleteRequest = useDeleteOrderItemRequest();
  const showLoading = useShowLoading();

  const onDuplicate = () => {
    confirm({
      title: 'Duplicate Items',
      description: `Are you sure you want to duplicate ${getItemsText(items)}?`,
    }).then(() => {
      showLoading(Promise.all(items.map((item) => createRequest.mutateAsync(item))));
    });
  };

  const onDelete = () => {
    confirm({
      title: 'Delete Items',
      description: `Are you sure you want to delete ${getItemsText(items)}?`,
    }).then(() => {
      showLoading(Promise.all(items.map((item) => deleteRequest.mutateAsync(item.id))));
    });
  };

  return (
    <Box display="flex">
      <OrderItemPrice items={items}>
        {({ onUpdate }) => (
          <Tooltip title="Edit Price">
            <IconButton size="small" onClick={onUpdate}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </OrderItemPrice>

      <Tooltip title="Duplicate">
        <IconButton size="small" onClick={onDuplicate}>
          <DuplicateIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Delete Line">
        <IconButton size="small" onClick={onDelete}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
