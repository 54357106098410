import { FileCopy } from '@mui/icons-material';
import axios from 'axios';
import { ButtonAction, CardLayout, FieldFactory, Resource } from '@/classes';
import JournalEntryTotals from '@/components/JournalEntries/JournalEntryTotals';
import { JournalEntry, journalEntryPayloadSchema } from '@/types';
import { getEventableConfigForResource } from '@/utils/resources';

const getTitle = (e: JournalEntry) => `Journal Entry #${e.id}`;

export default function journalEntries() {
  return new Resource<JournalEntry>('Journal Entries')
    .withSchema(journalEntryPayloadSchema)
    .getTitleUsing(getTitle)
    .getLabelUsing(getTitle)
    .setCanExport()
    .withDefaultValues({
      journal_date: new Date().toISOString().slice(0, 10),
      items: [],
    })
    .withDefaultSort('-id')
    .getSingleActionsUsing((values) => [
      new ButtonAction(
        'Duplicate',
        ({ dialogs, navigate, toast }) => {
          dialogs
            .confirm({
              title: `Duplicate Journal Entry #${values.id}`,
              description: 'Are you sure you want to duplicate?',
            })
            .then(() => {
              axios.post(`/api/journal-entries/${values.id}/duplicate`).then(({ data }) => {
                toast('Journal Entry has been duplicated');
                navigate(`/journal-entries/${data.id}`);
              });
            });
        },
        FileCopy,
      ),
    ])
    .withColumns([FieldFactory.text('id').withLabel('#').sortable()])
    .addFieldsAndColumns([
      new CardLayout('details')
        .withoutLabel()
        .withFields([FieldFactory.date('journal_date').sortable(), FieldFactory.textarea('memo')]),
      new CardLayout('Debits & Credits', [
        FieldFactory.list('items', [
          FieldFactory.belongsTo('account', 'accounts').withColumnSpan(3),
          FieldFactory.curr('debit_amount').withColumnSpan(2),
          FieldFactory.curr('credit_amount').withColumnSpan(2),
          FieldFactory.text('description'),
        ]).withColumnSpan(12),
        FieldFactory.custom('totals', JournalEntryTotals).withoutLabel().withColumnSpan(12),
      ]),
      getEventableConfigForResource('journal-entries'),
    ]);
}
