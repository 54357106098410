import { ChangeEvent } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import { FieldProps } from '@/classes/types';
import Text from '@/components/Text/Text';
import { FulfillmentOrderItem } from '@/types';
import wrap from '@/utils/wrap';

export default function BackorderItemsField({ field }: FieldProps) {
  const items = wrap(field.value) as (FulfillmentOrderItem & { maxQty: number })[];

  const handleChange = (id: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newItems = cloneDeep(items);
    const newItem = newItems.find((i) => i.id === id);
    if (newItem) {
      newItem.qty = Number(e.target.value);
      field.onChange(newItems);
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Item</TableCell>
          <TableCell>Qty</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id}>
            <TableCell>
              <Text primary={item.description} secondary={item.sku} />
            </TableCell>
            <TableCell>
              <TextField
                value={item.qty}
                onChange={handleChange(item.id)}
                size="small"
                type="number"
                inputProps={{
                  min: 0,
                  max: item.maxQty,
                  step: 1,
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
