import { Resource } from '@/classes';
import { AccountDetail } from '@/types';

export default function bankAccounts() {
  return new Resource<AccountDetail>('Accounts').withQueryParams({
    index: {
      'filter[type][in]': 'bank,credit_card',
    },
    single: {},
  });
}
