import PaymentForm from '@/components/Payments/PaymentForm';
import StripeProvider from '@/components/Payments/StripeProvider';
import Paper from '@/components/Shared/Paper';
import { useTitle } from '@/contexts/AppContext';

export default function CollectPayment() {
  useTitle('Collect a Payment');

  return (
    <Paper>
      <StripeProvider>
        <PaymentForm />
      </StripeProvider>
    </Paper>
  );
}
