import { List, ListItemButton, ListItemText, Grid2 as Grid, Card, CardHeader } from '@mui/material';
import groupBy from 'lodash/groupBy';
import { Link } from 'react-router-dom';
import { useTitle } from '@/contexts/AppContext';
import { useRequiresPermissionAndRole } from '@/hooks/permissions';
import { direct } from '@/pages';

export default function Settings() {
  useTitle('Settings');
  const requiresPermissionAndRole = useRequiresPermissionAndRole();

  return (
    <Grid container spacing={3}>
      {Object.entries(groupBy(direct.filter(requiresPermissionAndRole), 'group')).map(
        ([group, pages]) => (
          <Grid key={group} size={{ xs: 12, md: 6, lg: 3 }}>
            <Card>
              <CardHeader title={group} />
              <List component="nav" dense>
                {pages.map((page) => (
                  <ListItemButton key={page.href} to={page.href} component={Link}>
                    <ListItemText primary={page.name} />
                  </ListItemButton>
                ))}
              </List>
            </Card>
          </Grid>
        ),
      )}
    </Grid>
  );
}
