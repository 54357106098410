import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid2 as Grid, Stack, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import omit from 'lodash/omit';
import { useForm } from 'react-hook-form';
import { FieldFactory } from '@/classes';
import Form from '@/components/Form/Form';
import FormField from '@/components/Form/FormField';
import ReportEditor from '@/components/Reports/ReportEditor';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { REPORT_TYPES } from '@/constants';
import { Report } from '@/types';

interface EditReportDrawerProps {
  dashboardId?: number;
  report?: Report;
  open: boolean;
  onClose: () => void;
  onSuccess: (r: Report) => void;
}

export default function EditReportDrawer({
  dashboardId,
  report,
  open,
  onClose,
  onSuccess,
}: EditReportDrawerProps) {
  const form = useForm<Partial<Report>>();
  const [tab, setTab] = useState('setup');

  useEffect(() => {
    if (report) {
      form.reset(report);
    }
  }, [open]);

  const onSubmit = (values: Partial<Report>) => {
    const url = dashboardId
      ? `/api/dashboards/${dashboardId}/reports/${values.id}`
      : `/api/reports/${values.id}`;

    return axios.put(url, omit(values, 'order')).then(({ data }) => {
      onSuccess(data);
    });
  };

  return (
    <ClosableDrawer
      closeOnClickOutside={false}
      open={open}
      onClose={onClose}
      title="Edit Report"
      width={800}
    >
      <Tabs value={tab} onChange={(e, t) => setTab(t)} sx={{ mt: -2, mb: 2 }}>
        <Tab value="setup" label="Setup" />
        <Tab value="access" label="Access" />
      </Tabs>
      <Form form={form} onSubmit={onSubmit}>
        {tab === 'setup' && (
          <Grid container spacing={2}>
            <Grid size={12}>
              <FormField field={FieldFactory.text('name')} />
            </Grid>
            {dashboardId && (
              <Grid size={12}>
                <FormField field={FieldFactory.text('title').withLabel('Title for Dashboard')} />
              </Grid>
            )}
            <Grid size={6}>
              <FormField field={FieldFactory.select('type', REPORT_TYPES)} />
            </Grid>
            <Grid size={6}>
              {dashboardId && (
                <FormField field={FieldFactory.number('pivot.columns').withLabel('Columns')} />
              )}
            </Grid>
            <Grid size={12}>
              <FormField field={FieldFactory.textarea('description')} />
            </Grid>
            <Grid size={12}>
              <FormField field={FieldFactory.text('group')} />
            </Grid>
            <Grid size={12}>
              <FormField field={FieldFactory.text('summary')} />
            </Grid>
            <Grid size={12}>
              <ReportEditor />
            </Grid>
          </Grid>
        )}

        {tab === 'access' && (
          <Stack spacing={2}>
            <FormField field={FieldFactory.hasMany('roles', 'roles')} />
            <FormField
              field={FieldFactory.boolean('is_global', 'Give all users access to this report')}
            />
          </Stack>
        )}

        <LoadingButton
          type="submit"
          variant="contained"
          loading={form.formState.isSubmitting}
          sx={{ mt: 3 }}
        >
          Submit
        </LoadingButton>
      </Form>
    </ClosableDrawer>
  );
}
