import { CircularProgress, List, Alert } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Address } from '@/types';
import AddressListItem from './AddressListItem';

export default function CustomerAddressList({
  customerId,
  onToggle,
  isSelected,
}: {
  customerId: number;
  onToggle: (a: Address) => void;
  isSelected: (a: Address) => boolean;
}) {
  const { data: addresses = [], isLoading } = useQuery(['customerAddresses', customerId], () =>
    axios
      .get<{ data: Address[] }>(`/api/customers/${customerId}/addresses?count=100`)
      .then(({ data }) => data.data),
  );

  if (isLoading) {
    return <CircularProgress />;
  }
  if (addresses.length === 0) {
    return <Alert severity="warning">There are no addresses set up for this customer.</Alert>;
  }

  return (
    <List subheader="Customer Addresses">
      {addresses.map((a) => (
        <AddressListItem key={a.id} address={a} onToggle={onToggle} isSelected={isSelected(a)} />
      ))}
    </List>
  );
}
