import { SyntheticEvent, useEffect, useState } from 'react';
import { Add, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  TablePagination,
  Typography,
} from '@mui/material';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { z } from 'zod';
import {
  useCreateKit,
  useDeleteKit,
  useDeleteKitItem,
  useKitsForAddress,
  useUpdateKit,
  useUpdateKitItem,
} from '@/api/kitting';
import { FieldFactory } from '@/classes';
import AddressTitle from '@/components/Addresses/AddressTitle';
import KitBlock from '@/components/Kitting/KitBlock';
import StatusChip from '@/components/Shared/StatusChip';
import { PICK_STATUS_COLORS } from '@/constants';
import { useShowLoading } from '@/contexts/DialogContext';
import { useDialogs } from '@/contexts/DialogContext';
import { Address, Kit, KitItem, KitPayload, kitPayloadSchema } from '@/types';

export default function KitsForAddress({
  address,
  selected,
  setSelected,
}: {
  address: Address;
  selected?: Kit;
  setSelected: (k: Kit | undefined) => void;
}) {
  const { confirm, prompt } = useDialogs();
  const [page, setPage] = useState(0);
  const showLoading = useShowLoading();

  const { data: kits = [], isFetching } = useKitsForAddress(address.id);

  const createKitRequest = useCreateKit(address.id);
  const updateKitRequest = useUpdateKit();
  const deleteKitRequest = useDeleteKit();
  const updateKitItemRequest = useUpdateKitItem();
  const deleteKitItemRequest = useDeleteKitItem();

  useEffect(() => {
    setPage(0);
    if (!selected || !kits.find((k) => k.id === selected.id)) {
      setSelected(kits[0]);
    }
  }, [kits]);

  const start = page * 10;
  const kitsForPage = kits.slice(start, start + 10);

  const handleKitSelection = (kit: Kit) => (event: SyntheticEvent, isExpanded: boolean) => {
    setSelected(isExpanded ? kit : undefined);
  };

  const createKitForAddress = () => {
    prompt({
      title: 'Create Kit for Address',
      fields: [
        FieldFactory.text('number'),
        FieldFactory.text('name'),
        FieldFactory.email('email'),
        FieldFactory.text('group'),
      ],
      schema: kitPayloadSchema,
      onSubmit: (v) => createKitRequest.mutateAsync(v),
    });
  };

  const onEditKit = (kit: Kit) => {
    prompt<KitPayload, Kit>({
      title: 'Update Kit',
      fields: [
        FieldFactory.text('number'),
        FieldFactory.text('name'),
        FieldFactory.email('email'),
        FieldFactory.text('group'),
      ],
      schema: kitPayloadSchema,
      initialValues: pick(kit, ['number', 'name', 'group', 'email']),
      onSubmit: (v) => updateKitRequest.mutateAsync({ ...v, id: kit.id }),
    });
  };

  const onDeleteKit = (kit: Kit) => {
    confirm({
      title: 'Delete Kit',
      description:
        'Are you sure you want to delete this kit? All items in this kit will be unallocated.',
    }).then(() => {
      showLoading(deleteKitRequest.mutateAsync(kit.id));
    });
  };

  const onEditKitItem = (i: KitItem) => {
    // TODO: Add validation/options for drops
    const initialValues = {
      is_backordered: i.is_backordered,
      qty: i.qty,
      // Map [jack] to [{drop: 'jack'}]
      drops: (i.drops || []).map((drop) => ({ drop })),
    };

    prompt({
      title: 'Update Kit Item',
      initialValues,
      fields: [
        FieldFactory.number('qty'),
        FieldFactory.boolean(
          'is_backordered',
          'This item is backordered and is part of a different kit order',
        ),
        FieldFactory.custom('help', () => (
          <>
            <Typography variant="subtitle2">Drops</Typography>
            <Typography variant="body2" color="textSecondary">
              Please note: This is not linked to the rosters in the production order(s). You can
              type anything in these fields to help identify which customized items from the
              production order(s) go into this kit.
            </Typography>
          </>
        )),
        FieldFactory.list('drops', [FieldFactory.text('drop').withColumnSpan(9)]),
      ],
      schema: z.object({
        is_backordered: z.boolean(),
        qty: z.coerce.number().int().min(0),
        drops: z.array(z.object({ drop: z.string() })),
      }),
      onSubmit: (v: typeof initialValues) =>
        updateKitItemRequest.mutateAsync({
          ...v,
          drops: v.drops.map((d) => d.drop),
          id: i.id,
        }),
    });
  };

  const onRemoveKitItem = (i: KitItem) => {
    confirm({
      title: 'Remove Item From Kit',
      description: 'Are you sure you want to do this?',
    }).then(() => {
      showLoading(deleteKitItemRequest.mutateAsync(i.id));
    });
  };

  return (
    <div>
      <Card>
        <CardHeader
          title="Kits for Address"
          titleTypographyProps={{ variant: 'h6' }}
          action={
            <IconButton onClick={createKitForAddress}>
              <Add />
            </IconButton>
          }
        />
        <CardContent style={{ paddingTop: 0 }}>
          <AddressTitle address={address} />
        </CardContent>
      </Card>
      {isFetching && <LinearProgress />}
      {kitsForPage.map((kit) => (
        <Accordion
          key={kit.id}
          expanded={get(selected, 'id') === kit.id}
          onChange={handleKitSelection(kit)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              columnGap={1}
              flexGrow={1}
              mr={1}
            >
              <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                {kit.number || kit.name}
              </Typography>
              <StatusChip status={kit.pick_status} colors={PICK_STATUS_COLORS} size="small" />
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ display: 'block', p: 0 }}>
            <KitBlock
              kit={kit}
              onEdit={onEditKit}
              onDelete={onDeleteKit}
              onEditKitItem={onEditKitItem}
              onRemoveKitItem={onRemoveKitItem}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      {kits.length > 10 && (
        <TablePagination
          component="div"
          count={kits.length}
          onPageChange={(e, p) => setPage(p)}
          page={page}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      )}
    </div>
  );
}
