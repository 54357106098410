import { CardLayout, FieldFactory, Resource } from '@/classes';
import GenericAddressField from '@/components/Addresses/GenericAddressField';
import { AppResponse, Business, businessPayloadSchema } from '@/types';
import getBusinessName from '@/utils/getBusinessName';

export default function businesses(appConfig: AppResponse) {
  return new Resource<Business>('Businesses')
    .withSchema(businessPayloadSchema)
    .getTitleUsing(getBusinessName)
    .getLabelUsing(getBusinessName)
    .withStaticOptions(appConfig.businesses)
    .withFieldsAndColumns([
      new CardLayout('Basic Information', [
        FieldFactory.text('name').sortable(),
        FieldFactory.text('internal_name').sortable(),
        FieldFactory.color('color'),
        FieldFactory.text('statement_descriptor').withHelp(
          'Explains charges from this LOB on bank statements',
        ),
        FieldFactory.image('logo'),
        FieldFactory.image('icon'),
      ]),
      new CardLayout('Payouts', [
        FieldFactory.text('tin').withLabel('SSN/EIN'),
        FieldFactory.belongsTo('vendor', 'vendors').withHelp(
          'Will distribute payouts to this vendor as a check',
        ),
        FieldFactory.email('admin_email'),
        FieldFactory.boolean('is_house', 'Is House LOB (No Payouts)'),
      ]),
      new CardLayout('Contact Information', [
        FieldFactory.email('email'),
        FieldFactory.phone('phone'),
        FieldFactory.text('url').withLabel('URL'),
        new GenericAddressField('address'),
      ]),
      new CardLayout('About', [
        FieldFactory.textarea('about').withHelp(
          'Will display on webstore about pages. Markdown is accepted here',
        ),
      ]),
    ]);
}
