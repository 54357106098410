import { ChangeEvent } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid2 as Grid, Typography } from '@mui/material';
import { Field, FieldFactory } from '@/classes';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import { NonFormField } from '@/components/Form/FormField';
import { CustomsPayload } from '@/types';
import getValueFromEvent from '@/utils/getValueFromEvent';

type CustomsItem = CustomsPayload['items'][number];

export const DEFAULT_CUSTOMS_ITEM: CustomsItem = {
  description: '',
  quantity: 1,
  value_amount: 0,
  net_weight: 0,
};
export const DEFAULT_CUSTOMS: CustomsPayload = {
  incoterm: 'DDP',
  items: [DEFAULT_CUSTOMS_ITEM],
};

export default function CustomsDeclarationBuilder({
  customs,
  onChange,
}: {
  customs: CustomsPayload | null;
  onChange: (v: CustomsPayload | null) => void;
}) {
  const ITEM_FIELDS: Field[] = [
    FieldFactory.text('description').withPlaceholder('e.g. T-shirt'),
    FieldFactory.number('quantity'),
    FieldFactory.number('net_weight').with({
      label: 'Weight',
      postfix: 'lbs',
    }),
    FieldFactory.curr('value_amount').withLabel('Value'),
  ];

  const show = customs != null;

  const handleItemChange =
    (index: number, name: keyof CustomsItem) => (e: ChangeEvent<HTMLInputElement>) => {
      if (!customs) {
        return;
      }
      const eventValue = getValueFromEvent(e);
      onChange({
        ...customs,
        items: (customs.items || []).map((i, idx) => {
          if (idx === index) {
            return {
              ...i,
              [name]: eventValue,
            };
          }
          return i;
        }),
      });
    };

  const addItem = () => {
    if (!customs) {
      return;
    }
    onChange({
      ...customs,
      items: [...customs.items, DEFAULT_CUSTOMS_ITEM],
    });
  };

  const removeItem = () => {
    if (!customs) {
      return;
    }
    onChange({
      ...customs,
      items: customs.items.filter((item, i) => i !== customs.items.length - 1),
    });
  };

  return (
    <Box mb={2}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={show}
            onChange={(e) => onChange(e.target.checked ? DEFAULT_CUSTOMS : null)}
          />
        }
        label="Create Customs Declaration"
      />
      {show && customs && (
        <DrawerFormSection title="Customs">
          <NonFormField
            value={customs.incoterm}
            onChange={(e) =>
              onChange({
                ...customs,
                incoterm: e.target.value,
              })
            }
            field={FieldFactory.select('incoterm', {
              DDP: 'Delivery Duty Paid (DDP)',
              DDU: 'Delivery Duty Unpaid (DDU)',
            })}
          />

          {customs.items.map((item, idx) => (
            <div key={idx}>
              <Typography
                color="textSecondary"
                variant="overline"
                component="div"
                sx={{
                  mb: -1.5,
                  mt: 1,
                }}
              >
                Item #{idx + 1}
              </Typography>
              <Grid container spacing={1}>
                {ITEM_FIELDS.map((field) => (
                  <Grid key={field.name} size={6}>
                    <NonFormField
                      field={field}
                      value={item[field.name as keyof CustomsItem]}
                      onChange={handleItemChange(idx, field.name as keyof CustomsItem)}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
          <Box display="flex">
            {customs.items.length > 1 && (
              <Button size="small" onClick={removeItem}>
                Remove
              </Button>
            )}
            <Button size="small" onClick={addItem} sx={{ ml: 'auto' }}>
              Add
            </Button>
          </Box>
        </DrawerFormSection>
      )}
    </Box>
  );
}
