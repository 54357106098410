import { useState } from 'react';
import { Table, TableHead, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import Paper from '@/components/Shared/Paper';
import curr from '@/utils/curr';

export default function PricingResults({
  items,
  decorationUnitCost,
}: {
  items: {
    name: string;
    qty: number;
    cost: number;
  }[];
  decorationUnitCost: number;
}) {
  const [customMargin, setCustomMargin] = useState(55);

  const margins = [0, 30, 35, 40, 45, 50, customMargin];

  const withMarkup = (cost: number, margin: number): string => {
    if (margin < 0 || margin > 99) {
      return ' -- ';
    }
    return curr(cost / (1 - margin / 100));
  };

  return (
    <div>
      {items.map((i, idx) => (
        <Paper title={`${i.name} x ${i.qty}`} key={idx}>
          <Table size="small" style={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>Margin</TableCell>
                <TableCell>Unit Blank Price</TableCell>
                <TableCell>Unit Decoration Price</TableCell>
                <TableCell>Unit Sell Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {margins.map((margin, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {index + 1 === margins.length ? (
                      <TextField
                        name="margin"
                        size="small"
                        onChange={(e) => setCustomMargin(Number(e.target.value))}
                        value={margin}
                      />
                    ) : (
                      <span>{margin}%</span>
                    )}
                  </TableCell>
                  <TableCell>{withMarkup(i.cost, margin)}</TableCell>
                  <TableCell>{withMarkup(decorationUnitCost, margin)}</TableCell>
                  <TableCell>
                    {withMarkup(Number(i.cost) + Number(decorationUnitCost), margin)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      ))}
    </div>
  );
}
