import { Typography } from '@mui/material';
import get from 'lodash/get';
import { plural } from 'pluralize';
import { Link } from 'react-router-dom';
import { FieldFactory, Resource } from '@/classes';
import { LedgerEntry } from '@/types';

export const getAssociatedLinkForEntry = (row: LedgerEntry) => {
  switch (row.associated_type) {
    case 'order_payment':
      return `/payments/${get(row, 'associated.payment_id')}`;
    case 'order_payout':
      return `/orders/${get(row, 'associated.order_id')}`;
    case 'inventory_entry':
      return `/inventory-variants/${get(row, 'associated.variant_id')}`;
    default:
      return `/${plural(row.associated_type.replace('_', '-'))}/${row.associated_id}`;
  }
};

export default function ledgerEntries() {
  return new Resource<LedgerEntry>('Ledger Entries')
    .setCanExport()
    .setDeletable(false)
    .withDefaultSort('-transaction_date')
    .withColumns([
      FieldFactory.belongsTo('account', 'accounts').filterable('account_id'),
      FieldFactory.date('transaction_date').sortable().filterable(),
      FieldFactory.text('description').renderCellUsing((value, row: LedgerEntry) => {
        const link = getAssociatedLinkForEntry(row);
        return link ? (
          <Link to={link} style={{ textDecoration: 'none' }}>
            <Typography variant="body2" color="textPrimary">
              {value}
            </Typography>
          </Link>
        ) : (
          <span>{value}</span>
        );
      }),
      FieldFactory.curr('debit_amount').sortable().setAggregatable(),
      FieldFactory.curr('credit_amount').sortable().setAggregatable(),
      FieldFactory.curr('amount').withLabel('Net Amount').sortable().setAggregatable(),
    ]);
}
