import { MouseEvent, useState } from 'react';
import {
  Avatar,
  Typography,
  Popover,
  Card,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { CustomerBrandColor } from '@/types';

function BrandColorCircle({ color }: { color: CustomerBrandColor }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const avatar = <Avatar style={{ backgroundColor: color.hex || '#ccc' }}>&nbsp;</Avatar>;

  return (
    <li style={{ display: 'inline-block' }}>
      <IconButton onClick={handleClick}>{avatar}</IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Card>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell variant="head">Ink Color</TableCell>
                <TableCell>{color.ink_color}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Thread Color</TableCell>
                <TableCell>{color.thread_color}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Hex</TableCell>
                <TableCell>{color.hex}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">RGB</TableCell>
                <TableCell>{color.rgb}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">CMYK</TableCell>
                <TableCell>{color.cmyk}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Popover>
    </li>
  );
}

export default function BrandColorField({ colors }: { colors: CustomerBrandColor[] | undefined }) {
  if (!colors) {
    return null;
  }

  if (colors.length === 0) {
    return (
      <Typography variant="body2" color="textSecondary">
        No colors found for this customer.
      </Typography>
    );
  }

  return (
    <ul style={{ padding: 0, margin: 0, marginLeft: '-8px' }}>
      {colors.map((color) => (
        <BrandColorCircle key={color.id} color={color} />
      ))}
    </ul>
  );
}
