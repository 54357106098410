import { useState } from 'react';
import { Person } from '@mui/icons-material';
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
} from '@mui/material';
import UserActions from '@/components/AppDrawer/UserActions';
import { useConfig } from '@/contexts/AppConfigContext';
import { usePrintContext } from '@/contexts/PrintContext';
import { useHasPermission } from '@/hooks/permissions';

export default function UserMenuSection() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { labelType, setLabelType, ipAddress, setIpAddress } = usePrintContext();
  const { user } = useConfig();
  const hasPermission = useHasPermission();
  const canPrint =
    hasPermission('write:shipments') ||
    hasPermission('write:production_events') ||
    hasPermission('write:receivings');

  if (!user) {
    return null;
  }

  const open = Boolean(anchorEl);
  const id = open ? 'user-popover' : undefined;

  return (
    <>
      <ListItemButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ flexGrow: 0 }}>
        <ListItemIcon
          sx={{
            color: 'inherit',
            minWidth: 48,
          }}
        >
          <Person />
        </ListItemIcon>
        <ListItemText primary={user.name} color="inherit" />
      </ListItemButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="div" disablePadding>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={user.avatar || undefined}>{user.name.trim()[0]}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.name} secondary={user.email} />
          </ListItem>
          {canPrint && (
            <>
              <ListItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={labelType === 'ZPLII'}
                      onChange={(e) => setLabelType(e.target.checked ? 'ZPLII' : 'PDF_4x6')}
                    />
                  }
                  label="Print ZPL Labels"
                />
              </ListItem>
              {labelType === 'ZPLII' && (
                <ListItem dense>
                  <TextField
                    label="Printer IP Address"
                    defaultValue={ipAddress}
                    onBlur={(e) => setIpAddress(e.target.value)}
                    size="small"
                    placeholder="e.g. 192.168.1.150"
                    helperText="Leave blank unless using IP printing"
                  />
                </ListItem>
              )}
            </>
          )}
          <UserActions />;
          <ListItem href={import.meta.env.VITE_BASE_URL + 'api/logout'} component="a">
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
