import { Assignment, SubdirectoryArrowRight, SwapHoriz } from '@mui/icons-material';
import { Box, SxProps, Tooltip } from '@mui/material';
import { Field, FieldFactory, Resource, Tab, TabLayout } from '@/classes';
import Analysis from '@/components/Orders/Analysis/Analysis';
import OrderArt from '@/components/Orders/Art/OrderArt';
import OrderCreateWizard from '@/components/Orders/Create/OrderCreateWizard';
import OrderDashboard from '@/components/Orders/Dashboard/OrderDashboard';
import OrderItemsField from '@/components/Orders/Items/OrderItemsField';
import OrderActions from '@/components/Orders/OrderActions';
import OrderPayments from '@/components/Orders/OrderPayments';
import OrderShippingTab from '@/components/Orders/Shipping/OrderShippingTab';
import WebstoreTab from '@/components/Orders/Webstore/WebstoreTab';
import ProductionDates from '@/components/Production/CommittedDate';
import DueDateBar from '@/components/Shared/DueDateBar';
import Text from '@/components/Text/Text';
import UserLabel from '@/components/Users/UserLabel';
import {
  APPROVAL_STATUS_COLORS,
  DEPARTMENT_STATUS_COLORS,
  ORDER_STATUS_COLORS,
  SHIPPING_MODE_OPTIONS,
} from '@/constants';
import {
  AppResponse,
  Customer,
  Order,
  OrderType,
  orderUpdatePayloadSchema,
  OrderWithDetails,
} from '@/types';
import getBusinessName from '@/utils/getBusinessName';

const ICON_SX: SxProps = {
  mx: 0.5,
  px: 0.25,
  verticalAlign: 'bottom',
};

const orderTypeCell = (type: OrderType, row: Order) => (
  <Box whiteSpace="nowrap">
    <span>{type?.name}</span>
    {row.project_id && (
      <Tooltip title="Part of Project">
        <Assignment sx={ICON_SX} />
      </Tooltip>
    )}
    {row.parent_id && (
      <Tooltip title="Backorder">
        <SubdirectoryArrowRight sx={ICON_SX} />
      </Tooltip>
    )}
    {row.is_scrap && (
      <Tooltip title="Scrap">
        <SwapHoriz sx={ICON_SX} />
      </Tooltip>
    )}
  </Box>
);

export function getOrderColumns(): Field[] {
  return [
    FieldFactory.text('increment_id')
      .withLabel('#')
      .sortable()
      .renderCellUsing((inc, order: Order) => <Text primary={inc} secondary={order.description} />),
    FieldFactory.belongsTo('customer', 'customers')
      .filterable(
        FieldFactory.hasMany('customers', 'customers').withFilterKey('filter[customer_id][in]'),
      )
      .sortable('customer.name')
      .renderCellUsing((customer: Customer, order: Order) => (
        <Text primary={customer.name} secondary={getBusinessName(order.business)} />
      )),
    FieldFactory.belongsTo('department', 'departments')
      .filterable('filter[department_id]')
      .sortable('department.name'),
    FieldFactory.belongsTo('sales_rep', 'users')
      .filterable('filter[rep_id]')
      .sortable('sales_rep.name')
      .renderCellUsing((v) => <UserLabel user={v} />),
    FieldFactory.belongsTo('created_by_user', 'users')
      .filterable('filter[created_by]')
      .withLabel('Creator')
      .renderCellUsing((v) => <UserLabel user={v} />),
    FieldFactory.text('description').sortable(),
    FieldFactory.belongsTo('order_type', 'orderTypes')
      .renderCellUsing(orderTypeCell)
      .filterable(
        FieldFactory.hasMany('order_types', 'orderTypes').withFilterKey(
          'filter[order_type_id][in]',
        ),
      )
      .sortable('order_type.name'),
    FieldFactory.text('customer_po').withLabel('Customer PO').sortable(),
    FieldFactory.timestamp('placed_at').filterable().sortable(),
    FieldFactory.curr('total').withLabel('Total').sortable().setAggregatable(),
    FieldFactory.status('status', ORDER_STATUS_COLORS).withLabel('Status').filterable().sortable(),
    FieldFactory.status('order_approval_status', APPROVAL_STATUS_COLORS).filterable().sortable(),
    FieldFactory.status('art_approval_status', APPROVAL_STATUS_COLORS).filterable().sortable(),
    FieldFactory.status('purchasing_status', DEPARTMENT_STATUS_COLORS).filterable().sortable(),
    FieldFactory.status('receiving_status', DEPARTMENT_STATUS_COLORS).filterable().sortable(),
    FieldFactory.status('release_status', APPROVAL_STATUS_COLORS).filterable().sortable(),
    FieldFactory.status('staged_status', DEPARTMENT_STATUS_COLORS).filterable().sortable(),
    FieldFactory.status('production_status', DEPARTMENT_STATUS_COLORS).filterable().sortable(),
    FieldFactory.status('subcontract_status', DEPARTMENT_STATUS_COLORS).filterable().sortable(),
    FieldFactory.status('shipping_status', DEPARTMENT_STATUS_COLORS).filterable().sortable(),
    FieldFactory.status('payment_status', DEPARTMENT_STATUS_COLORS).filterable().sortable(),
    FieldFactory.text('contact_first_name').sortable(),
    FieldFactory.text('contact_last_name').sortable(),
    FieldFactory.email('contact_email').sortable(),
    FieldFactory.phone('contact_phone').sortable(),
    FieldFactory.date('committed_ship_date')
      .filterable()
      .sortable()
      .renderCellUsing((value, row: Order) => (
        <div>
          <ProductionDates order={row} />
          {value && <DueDateBar end={value} start={row.created_at} />}
        </div>
      )),
    FieldFactory.date('earliest_ship_date').sortable(),
    FieldFactory.date('drop_dead_date').sortable(),
    FieldFactory.timestamp('staged_at').sortable(),
    FieldFactory.select('shipping_mode', SHIPPING_MODE_OPTIONS).filterable(),
    FieldFactory.belongsTo('terms', 'terms'),
    FieldFactory.curr('paid').withLabel('Paid').sortable().setAggregatable(),
    FieldFactory.curr('balance').withLabel('Balance').sortable().setAggregatable(),
    FieldFactory.date('due_date').sortable(),
    FieldFactory.timestamp('invoiced_at').filterable().sortable(),
    FieldFactory.curr('cached_revenue').sortable().setAggregatable(),
    FieldFactory.curr('cached_costs').sortable().setAggregatable(),
    FieldFactory.curr('gross_profit').sortable().setAggregatable(),
    FieldFactory.percent('margin'),
  ];
}

export const INITIAL_ORDER_COLUMNS = [
  'increment_id',
  'placed_at',
  'customer',
  'sales_rep',
  'order_type',
  'status',
  'total',
];

export default function orders(appConfig: AppResponse) {
  return new Resource<Order>('Orders')
    .withSchema(orderUpdatePayloadSchema)
    .getTitleUsing((o) => o.label)
    .getLabelUsing((o) => o.increment_id)
    .getSubtitleUsing((o) => o.description)
    .setDeletable(false)
    .withDefaultSort('-increment_id')
    .withDefaultFilters({
      bucket: 'open',
    })
    .setCanExport()
    .withQueryParams({
      single: {
        with: 'billing_address,items,owned_purchase_orders',
      },
    })
    .withFilters([
      FieldFactory.radio('bucket', [
        {
          value: 'open',
          label: 'Open',
        },
        {
          value: 'stagnant',
          label: (
            <Tooltip title="Open orders that require action from sales to proceed">
              <div>Stagnant</div>
            </Tooltip>
          ),
        },
        {
          value: 'wip',
          label: (
            <Tooltip title="Orders being worked on by Avail (opposite of Stagnant)">
              <div>In Progress</div>
            </Tooltip>
          ),
        },
        {
          value: 'receivables',
          label: (
            <Tooltip title="Invoiced orders that are awaiting payment">
              <div>Receivables</div>
            </Tooltip>
          ),
        },
        {
          value: 'to_invoice',
          label: 'To Invoice',
          requiresPermission: 'orders:invoice',
        },
        {
          value: 'all',
          label: 'All',
        },
      ]).asQuickFilter(),
      FieldFactory.belongsTo('design', 'designs').withFilterKey('design'),
      FieldFactory.belongsTo('customer_segment', 'customerSegments').withFilterKey(
        'customer_segment',
      ),
      FieldFactory.daterange('payment_date').withFilterKey('payment_date'),
      FieldFactory.belongsTo('project', 'projects').withFilterKey('filter[project_id]'),
    ])
    .getSingleActionsUsing((values) => OrderActions(values as OrderWithDetails, appConfig))
    .withColumns(getOrderColumns())
    .withInitialColumns(INITIAL_ORDER_COLUMNS)
    .createUsing(OrderCreateWizard)
    .withFields([
      new TabLayout('tab', [
        new Tab('dashboard', [
          FieldFactory.custom('_dashboard', OrderDashboard).withColumnSpan(12),
        ]),
        new Tab('items', [FieldFactory.custom('items', OrderItemsField).withColumnSpan(12)]),
        new Tab('art', [
          FieldFactory.custom('design_layouts', OrderArt).withColumnSpan(12),
        ]).shouldRenderUsing((o: Order) => !o.order_type.is_blank),
        new Tab('webstore', [
          FieldFactory.custom('webstore', WebstoreTab).withColumnSpan(12),
        ]).shouldRenderUsing((o: Order) => o.order_type.is_webstore),
        new Tab('shipping', [FieldFactory.custom('shipping', OrderShippingTab).withColumnSpan(12)]),
        new Tab('payments', [
          FieldFactory.custom('order_payments', OrderPayments).withColumnSpan(12),
        ]),
        new Tab('analysis', [FieldFactory.custom('analysis', Analysis).withColumnSpan(12)]),
      ]),
    ]);
}
