import { ChangeEvent } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { FieldProps } from '@/classes/types';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import { useRecord } from '@/utils/genericResource';
import { getQtyInputProps } from '@/utils/shipping';

export default function PackItemsField({ field }: FieldProps) {
  const rows = (field.value || []) as { order_item_id: number; qty: number }[];
  const { items } = useRecord('ordersToProduce');

  const handleChange = (itemId: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newRows = structuredClone(rows);
    const existingRow = newRows.find((r) => r.order_item_id === itemId);
    if (existingRow) {
      existingRow.qty = Number(e.target.value);
    } else {
      newRows.push({ order_item_id: itemId, qty: Number(e.target.value) });
    }
    field.onChange(newRows);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Item</TableCell>
          <TableCell>Qty</TableCell>
          <TableCell>Qty To Pack</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items
          .filter((i) => i.can_apply_designs)
          .map((item) => (
            <TableRow
              key={item.id}
              sx={
                item.qty === item.qty_packed
                  ? {
                      '& .MuiTypography-root, .MuiTableCell-root': {
                        textDecoration: 'line-through',
                        color: 'action.disabled',
                      },
                    }
                  : {}
              }
            >
              <TableCell>
                <OrderItemProduct item={item} showSize />
              </TableCell>
              <TableCell>{item.qty}</TableCell>
              <TableCell>
                {item.qty !== item.qty_packed && (
                  <TextField
                    value={rows.find((r) => r.order_item_id === item.id)?.qty || ''}
                    onChange={handleChange(item.id)}
                    sx={{ width: 80 }}
                    type="number"
                    size="small"
                    slotProps={{
                      htmlInput: getQtyInputProps(item.qty - item.qty_packed, item.qty),
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
