import { ChangeEvent, useState } from 'react';
import { Delete, SwapHoriz, ViewList } from '@mui/icons-material';
import { Box, CardContent, Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import axios from 'axios';
import pick from 'lodash/pick';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import PaginatedTable from '@/components/DataTable/PaginatedTable';
import ChooseLocationForVariant from '@/components/Inventory/ChooseLocationForVariant';
import InventoryLevels from '@/components/Inventory/InventoryLevels';
import InventoryLocationLabel from '@/components/Inventory/InventoryLocationLabel';
import PickItemAvailablility from '@/components/Inventory/PickItemAvailability';
import SkuLabel from '@/components/Products/SkuLabel';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import TextButton from '@/components/Text/TextButton';
import { useDialogs } from '@/contexts/DialogContext';
import { InventoryPick, InventoryPickItem } from '@/types';
import { genericModelReferenceSchema } from '@/types';
import { useOnReloadRecord } from '@/utils/genericResource';

const columnHelper = createColumnHelper<InventoryPickItem>();

export default function InventoryPickItems({
  status,
  showItemStatus = true,
  items,
}: {
  status: InventoryPick['status'];
  showItemStatus?: boolean;
  items: InventoryPickItem[];
}) {
  const { prompt, confirm } = useDialogs();
  const onReload = useOnReloadRecord();
  const [viewing, setViewing] = useState<InventoryPickItem>();

  const onUpdateQty = (item: InventoryPickItem) => {
    prompt({
      title: 'Update Pick Item Qty',
      fields: [FieldFactory.number('qty').withSize('medium')],
      schema: z.object({
        qty: z.coerce.number().int().min(1),
      }),
      initialValues: pick(item, 'qty'),
      onSubmit: (v) => axios.put(`/api/inventory-pick-items/${item.id}`, v),
    }).then(() => {
      onReload();
    });
  };

  const onDeleteItem = (item: InventoryPickItem) => {
    confirm({
      title: 'Delete Pick Item',
      description:
        'Are you sure you want to remove this item from the pick? This will show the line item as still needing to be purchased/fulfilled.',
    }).then(() => {
      axios.delete(`/api/inventory-pick-items/${item.id}`).then(() => {
        onReload();
      });
    });
  };

  const onChangeLocation = (item: InventoryPickItem) => {
    prompt({
      title: 'Pull From Another Location',
      fields: [FieldFactory.custom('location', ChooseLocationForVariant)],
      schema: z.object({
        location: genericModelReferenceSchema,
        variant_id: z.number(),
      }),
      initialValues: {
        location: item.location || undefined,
        variant_id: item.variant_id,
      },
      onSubmit: (v) =>
        axios.put(`/api/inventory-pick-items/${item.id}`, { location_id: v.location.id }),
    }).then(() => {
      onReload();
    });
  };

  const onToggleItem = (item: InventoryPickItem) => (e: ChangeEvent<HTMLInputElement>) => {
    const promise = e.target.checked
      ? confirm({
          title: 'Confirm Item Picked',
          description: (
            <div>
              Please confirm you have picked <b>{item.sku}</b> from <b>{item.location?.path}</b>.
            </div>
          ),
        })
      : confirm({
          title: 'Unpick Item',
          description: 'This will mark this item as no longer picked.',
        });

    promise.then(() => {
      axios.post(`/api/inventory-pick-items/${item.id}/pick`).then(() => {
        // TODO: this is lazy and we should update the item in the list instead
        onReload();
      });
    });
  };

  if (items.length === 0) {
    return (
      <CardContent>
        <Typography variant="body1" color="textSecondary">
          No items found
        </Typography>
      </CardContent>
    );
  }
  return (
    <>
      <PaginatedTable
        storageKey="inventoryPickItems"
        rows={items}
        initialState={{
          columnVisibility: {
            checkbox: status === 'picking',
            location: showItemStatus,
          },
        }}
        columns={[
          columnHelper.display({
            id: 'checkbox',
            header: '',
            cell: ({ row }) => (
              <Checkbox checked={!!row.original.picked_at} onChange={onToggleItem(row.original)} />
            ),
          }),
          columnHelper.accessor('variant.sku', {
            header: 'SKU',
            cell: ({ row }) => <SkuLabel variant={row.original.variant} withoutDescription />,
          }),
          columnHelper.accessor('qty', {
            header: 'Qty',
            cell: ({ row, getValue }) => (
              <TextButton onClick={() => onUpdateQty(row.original)} disabled={status !== 'issued'}>
                {getValue()}
              </TextButton>
            ),
          }),
          columnHelper.accessor('location', {
            header: status === 'issued' ? 'Available' : 'Location',
            cell: ({ row: { original: item } }) =>
              item.location && status !== 'issued' ? (
                <InventoryLocationLabel location={item.location} />
              ) : (
                <PickItemAvailablility item={item} />
              ),
          }),
          columnHelper.display({
            id: 'actions',
            cell: ({ row: { original: item } }) => (
              <>
                {item.location && status === 'picking' ? (
                  <Tooltip title="Pull From Another Location">
                    <IconButton onClick={() => onChangeLocation(item)} size="large">
                      <SwapHoriz fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="View SKU Locations">
                    <IconButton onClick={() => setViewing(item)} size="large">
                      <ViewList fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                {status === 'issued' && (
                  <IconButton onClick={() => onDeleteItem(item)} size="large">
                    <Delete fontSize="small" />
                  </IconButton>
                )}
              </>
            ),
          }),
        ]}
      />

      <ClosableDrawer
        open={!!viewing}
        onClose={() => setViewing(undefined)}
        title="Inventory Locations for SKU"
      >
        {viewing && (
          <>
            <Box mb={2}>
              <Typography variant="h6">SKU: {viewing.sku}</Typography>
              <Typography>{viewing.description}</Typography>
            </Box>
            <Box mx={-2}>
              <InventoryLevels
                filterKey="variant_id"
                filterValue={viewing.variant_id}
                label={viewing.sku}
              />
            </Box>
          </>
        )}
      </ClosableDrawer>
    </>
  );
}
