import { Button, CircularProgress, Typography } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderTaxExemption } from '@/types';
import { useRecordId } from '@/utils/genericResource';

export default function OrderTaxCalculation() {
  const { prompt, confirm } = useDialogs();
  const queryClient = useQueryClient();
  const orderId = useRecordId();

  const { data: taxExemptions = [], isLoading } = useQuery(['taxExemptions', orderId], () =>
    axios
      .get<{ data: OrderTaxExemption[] }>(`/api/orders/${orderId}/tax-exemptions`)
      .then(({ data }) => data.data),
  );

  const onCreateExemption = () => {
    prompt({
      title: 'Make Order Tax-Exempt',
      fields: [FieldFactory.textarea('reason'), FieldFactory.file('file')],
      schema: z.object({
        reason: z.string(),
        file: z.string().nullish(),
      }),
      onSubmit: (v) => axios.post(`/api/orders/${orderId}/tax-exemptions`, v),
    }).then(() => {
      queryClient.invalidateQueries(['orders', orderId, 'withItems']);
      queryClient.invalidateQueries(['taxExemptions', orderId]);
    });
  };

  const onDeleteExemption = (id: number) => {
    confirm({
      title: 'Remove Order Tax Exemption',
      description:
        'Are you sure you want to remove this exemption from the order? This will make the order taxable again.',
    }).then(() => {
      axios.delete(`/api/orders/${orderId}/tax-exemptions/${id}`).then(() => {
        queryClient.invalidateQueries(['orders', orderId, 'withItems']);
        queryClient.invalidateQueries(['taxExemptions', orderId]);
      });
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (taxExemptions[0]) {
    return (
      <div>
        <div>
          <Typography gutterBottom>
            This order is <b>tax exempt</b> for the following reason:
          </Typography>
          <Typography color="textSecondary">&quot;{taxExemptions[0].reason}&quot;</Typography>
          <Button
            onClick={() => onDeleteExemption(taxExemptions[0].id)}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Remove Exemption
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Typography>
        The order has <b>no tax exemptions</b>.
      </Typography>
      <Button onClick={onCreateExemption} variant="contained" sx={{ mt: 2 }}>
        Make Exempt
      </Button>
    </div>
  );
}
