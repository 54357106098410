import { Button } from '@mui/material';
import sumBy from 'lodash/sumBy';
import { useGetShippableItems } from '@/api/orderItems';
import { useCreateShipment } from '@/api/shipping';
import OrderShipping from '@/components/Shipping/OrderShipping';
import { useShowLoading } from '@/contexts/DialogContext';
import { useDialogs } from '@/contexts/DialogContext';
import { Order, ShipmentItemPayload } from '@/types';
import numString from '@/utils/numString';
import { canShipOrderItem, getKitQty, isInventoryKit } from '@/utils/shipping';

export default function OrderShippingInventory({ order }: { order: Order }) {
  const orderId = order.id;
  const isKit = isInventoryKit(order.shipping_mode);
  const { confirm } = useDialogs();
  const showLoading = useShowLoading();
  const createShipmentRequest = useCreateShipment();

  const { data: items = [] } = useGetShippableItems(orderId);
  const shippableItems = items.filter(canShipOrderItem);

  const onAddToInventory = ({
    selected,
    onSuccess,
  }: {
    selected: ShipmentItemPayload[];
    onSuccess: () => void;
  }) => {
    let title = `Add ${numString(sumBy(selected, 'qty_shipped'), 'Items')} to Inventory`;
    let description =
      'This will mark the items as shipped and add them to the default inventory location. Are you sure you want to proceed?';

    if (isKit) {
      const kitQty = getKitQty(selected, shippableItems);
      if (typeof kitQty === 'object') {
        alert(
          `The total quantity selected for ${kitQty.item} and the quantities per kit cannot create an even amount of kits. Please adjust either the quantities selected or the quantity per kit values.`,
        );
        return;
      }
      title = `Add ${numString(kitQty as number, 'Kits')} to Inventory`;
      description =
        'This will mark the kits as shipped and add them to the default inventory location. Are you sure you want to proceed?';
    }

    confirm({ title, description }).then(() => {
      showLoading(
        createShipmentRequest.mutateAsync({
          shippable_id: orderId,
          shippable_type: 'order',
          items: selected,
        }),
      ).then(onSuccess);
    });
  };

  return (
    <OrderShipping
      orderId={orderId}
      getActions={(props) => (
        <Button onClick={() => onAddToInventory(props)} variant="contained" sx={{ ml: 1 }}>
          {`Add ${numString(
            sumBy(props.selected, (s) => s.qty_shipped),
            'Items',
          )} to Inventory`}
        </Button>
      )}
    />
  );
}
