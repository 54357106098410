import { ArrowForward, Delete, Email } from '@mui/icons-material';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import Can from '@/components/Permissions/Can';
import StatusChip from '@/components/Shared/StatusChip';
import { ART_REQUEST_STATUS_COLORS } from '@/constants';
import { useDialogs } from '@/contexts/DialogContext';
import { ArtRequest } from '@/types';
import { useOnReloadRecord } from '@/utils/genericResource';

export default function ArtRequestListItem({ request: r }: { request: ArtRequest }) {
  const onReload = useOnReloadRecord();
  const { confirm } = useDialogs();

  const onDelete = () => {
    confirm({
      title: 'Delete Art Request',
      description: 'Are you sure you want to delete this art request?',
    }).then(() => {
      axios.delete(`/api/orders/${r.order_id}/art-requests/${r.id}`).then(() => {
        onReload();
      });
    });
  };

  return (
    <ListItem>
      <ListItemText>
        <Typography>
          <span>{r.label} </span>
          {r.send_approval_on_complete && (
            <Tooltip
              title={`Will send ${
                r.only_art_approval ? 'art' : 'art & order'
              } approval to customer on completion`}
            >
              <Email style={{ verticalAlign: 'text-bottom' }} />
            </Tooltip>
          )}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
          Sent {moment(r.created_at).format('lll')}
        </Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        <StatusChip status={r.status} colors={ART_REQUEST_STATUS_COLORS} sx={{ mr: 1 }} />
        {r.status === 'pending' && !r.assignee && (
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        )}
        <Can permission="write:art_requests">
          <IconButton component={Link} to={`/art-requests/${r.id}`}>
            <ArrowForward />
          </IconButton>
        </Can>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
