import { z } from 'zod';
import { FieldFactory, Resource } from '@/classes';

export default function tokens() {
  return new Resource<{ name: string }>('API Tokens')
    .withSchema(
      z.object({
        name: z.string(),
      }),
    )
    .withFieldsAndColumns([FieldFactory.text('name')]);
}
