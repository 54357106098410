import { useMemo, useRef, useState } from 'react';
import { Add, AddCard, NotInterested, Search, Undo } from '@mui/icons-material';
import { Box, Button, Card, IconButton, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { FieldFactory, Resource } from '@/classes';
import DataTable, { DataTableHandle } from '@/components/DataTable/DataTable';
import Chip from '@/components/Shared/Chip';
import Text from '@/components/Text/Text';
import { useDialogs } from '@/contexts/DialogContext';
import {
  Account,
  BankTransaction,
  Deposit,
  genericModelReferenceSchema,
  Transaction,
} from '@/types';
import BankCreateTransactionDrawer from './BankCreateTransactionDrawer';
import FindMatchDrawer from './FindMatchDrawer';
import TransactionListItem from './TransactionListItem';

function getDateColumn(value: string, row: BankTransaction) {
  return <Text primary={moment(value).format('ll')} secondary={row.account_owner} />;
}

function getDescriptionColumn(value: string, row: BankTransaction) {
  return <Text primary={value} secondary={row.category?.join(' > ')} />;
}

function isDeposit(model: Deposit | Transaction): model is Deposit {
  return 'deposit_date' in model;
}

export default function BankTransactionTable({ account }: { account: Account }) {
  const { confirm, prompt } = useDialogs();
  const [reviewing, setReviewing] = useState<BankTransaction | null>(null);
  const [linking, setLinking] = useState<BankTransaction | null>(null);
  const navigate = useNavigate();
  const dataTableRef = useRef<DataTableHandle | null>(null);
  const onReload = () => dataTableRef.current?.onReload();

  const onUndo = (trans: BankTransaction) => {
    confirm({
      title: 'Move Back to Review',
      description: 'Are you sure you want to move this transaction back to review?',
    }).then(() => {
      axios.post(`/api/bank-transactions/${trans.id}/undo`).then(() => {
        onReload();
      });
    });
  };

  const onExclude = (trans: BankTransaction) => {
    prompt({
      title: 'Exclude Transaction',
      description: 'Why are you excluding this transaction?',
      fields: [FieldFactory.textarea('reason').withoutLabel()],
      schema: z.object({
        reason: z.string(),
      }),
      onSubmit: (v) => axios.post(`/api/bank-transactions/${trans.id}/exclude`, v),
    }).then(() => {
      onReload();
    });
  };

  const onLink = (trans: BankTransaction) => {
    confirm({
      title: 'Link Transaction',
      description: `Please confirm that you want to link this bank transaction with the existing ${trans.match?.label}`,
    }).then(() => {
      if (!trans.match) {
        return;
      }
      const key = isDeposit(trans.match) ? 'deposit_id' : 'transaction_id';
      axios
        .post(`/api/bank-transactions/${trans.id}/match`, {
          [key]: trans.match?.id,
        })
        .then(() => {
          onReload();
        });
    });
  };

  const onCreateBillPayment = (trans: BankTransaction) => {
    const { match } = trans;
    if (!match || !('ref_number' in match)) {
      throw new Error('Match must be a bill');
    }
    confirm({
      title: 'Create Bill Payment',
      description: `Please confirm that you want to use this bank transaction to create bill payment for ${match.label}`,
    }).then(() => {
      if (!trans.match) {
        return;
      }
      axios
        .post(`/api/bank-transactions/${trans.id}/match`, {
          transaction_id: trans.match.id,
        })
        .then(() => {
          onReload();
        });
    });
  };

  const onCreatePayment = (trans: BankTransaction) => {
    prompt({
      title: 'Create Payment & Deposit',
      fields: [FieldFactory.belongsTo('customer', 'customers')],
      schema: z.object({
        customer: genericModelReferenceSchema,
      }),
      onSubmit: (v) =>
        axios
          .post<BankTransaction>(`/api/bank-transactions/${trans.id}/create-payment`, {
            customer_id: v.customer.id,
          })
          .then(({ data }) => data),
    }).then((updatedTrans) => {
      navigate(`/deposits/${updatedTrans.deposit_id}`);
    });
  };

  const getMatchColumn = (match: BankTransaction['match'], trans: BankTransaction) => {
    if (trans.transaction) {
      return (
        <TransactionListItem
          transaction={trans.transaction}
          onClick={() => navigate(`/transactions/${trans.transaction_id}`)}
        >
          {trans.is_matched && <Chip>Matched</Chip>}
        </TransactionListItem>
      );
    }
    if (trans.deposit) {
      return (
        <TransactionListItem
          transaction={trans.deposit}
          onClick={() => navigate(`/deposits/${trans.deposit_id}`)}
        >
          {trans.is_matched && <Chip>Matched</Chip>}
        </TransactionListItem>
      );
    }
    if (trans.match) {
      return (
        <TransactionListItem transaction={trans.match}>
          {'type' in trans.match && trans.match.type === 'bill' ? (
            <Button size="small" onClick={() => onCreateBillPayment(trans)}>
              Create Bill Payment
            </Button>
          ) : (
            <Button size="small" onClick={() => onLink(trans)}>
              Link
            </Button>
          )}
        </TransactionListItem>
      );
    }

    if (trans.rule) {
      return (
        <div>
          <Typography variant="subtitle2" color="primary">
            Rule: {trans.rule.name}
          </Typography>
          <Typography variant="body2">Account: {trans.rule.account?.name}</Typography>
          <Typography variant="body2">Vendor: {trans.rule.vendor?.name}</Typography>
        </div>
      );
    }
    return null;
  };

  const resource = useMemo(
    () =>
      new Resource<BankTransaction>('Bank Transactions')
        .withDefaultSort('-date')
        .withQueryParams({
          index: {
            'filter[account_id]': String(account?.id || ''),
          },
        })
        .withDefaultFilters({
          mode: 'review',
        })
        .withFilters([
          FieldFactory.radio('mode', [
            {
              value: 'review',
              label: 'To Review',
            },
            {
              value: 'reviewed',
              label: 'Reviewed',
            },
            {
              value: 'excluded',
              label: 'Excluded',
            },
          ]).asQuickFilter(),
        ])
        .withColumns([
          FieldFactory.text('id').withLabel('#').sortable(),
          FieldFactory.date('date').renderCellUsing(getDateColumn).sortable(),
          FieldFactory.text('description').renderCellUsing(getDescriptionColumn).sortable(),
          FieldFactory.curr('amount').sortable(),
          FieldFactory.custom('match', () => null).renderCellUsing(getMatchColumn),
          FieldFactory.custom('actions', () => null)
            .withLabel('Actions')
            .renderCellUsing((value, trans: BankTransaction) => {
              if (trans.reviewed) {
                return (
                  <Tooltip title="Undo">
                    <IconButton onClick={() => onUndo(trans)} size="large">
                      <Undo />
                    </IconButton>
                  </Tooltip>
                );
              }

              return (
                <Box whiteSpace="nowrap">
                  {trans.amount < 0 && (
                    <Tooltip title="Create Payment & Deposit">
                      <IconButton onClick={() => onCreatePayment(trans)} size="large">
                        <AddCard fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Create New Transaction">
                    <IconButton onClick={() => setReviewing(trans)} size="large">
                      <Add fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Find a Matching Transaction">
                    <IconButton onClick={() => setLinking(trans)} size="large">
                      <Search fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Exclude Transaction">
                    <IconButton onClick={() => onExclude(trans)} size="large">
                      <NotInterested fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            }),
        ]),
    [account],
  );

  return (
    <div>
      <Card>
        <DataTable key={`bank.${account?.id}`} ref={dataTableRef} resource={resource} />
      </Card>

      <BankCreateTransactionDrawer
        reviewing={reviewing}
        setReviewing={setReviewing}
        onSuccess={() => {
          onReload();
          setReviewing(null);
        }}
      />

      <FindMatchDrawer
        reviewing={linking}
        setReviewing={setLinking}
        onSuccess={() => {
          onReload();
          setLinking(null);
        }}
      />
    </div>
  );
}
