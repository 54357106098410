import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { NotificationImportant, ReportProblem, Store, SyncAlt } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import { z } from 'zod';
import { useCreateIssue } from '@/api/issues';
import { FieldFactory } from '@/classes';
import Can from '@/components/Permissions/Can';
import { useShowLoading } from '@/contexts/DialogContext';
import { useDialogs } from '@/contexts/DialogContext';
import {
  genericModelReferenceSchema,
  InventoryCheckResponse,
  InventoryPickWithItems,
  OrderItemToPurchase,
} from '@/types';
import { useOnReloadRecord } from '@/utils/genericResource';
import OrderItemsForPurchasing from './OrderItemsForPurchasing';

export default function ItemsToPurchaseCard({
  orderId,
  items,
  issuedPick,
}: {
  orderId: number;
  items: OrderItemToPurchase[];
  issuedPick?: InventoryPickWithItems | null;
}) {
  const { vendor, ship_to: shipTo } = items[0];
  const [inventory, setInventory] = useState<InventoryCheckResponse>();
  const [isCreatingIssue, setCreatingIssue] = useState(false);
  const [selected, setSelected] = useState(
    items.filter((i) => !i.open_issues?.length).map((i) => i.id),
  );
  const [issue, setIssue] = useState('');
  const { prompt } = useDialogs();
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();
  const createIssueRequest = useCreateIssue({
    orderId,
    type: 'purchasing',
  });

  const selectedItems = items.filter((i) => selected.includes(i.id));

  const creatingIssue = () => {
    setCreatingIssue(true);
  };

  const onPull = (orderItems: OrderItemToPurchase[]) => {
    prompt({
      title: 'Pull from Inventory',
      description: orderItems[0].ship_to.party === 'customer' && (
        <Alert severity="info">
          {orderItems.length === 1 ? 'This item is' : 'These items are'} set to be pulled and
          shipped directly to the customer. Please confirm this is expected.
        </Alert>
      ),
      fields: [
        FieldFactory.optIn(
          'inventoryPick',
          FieldFactory.belongsTo('inventoryPick', 'inventoryPicks')
            .withoutLabel()
            .withRequestParams({
              'filter[status]': 'issued',
              order_id: orderId,
            }),
        ).with({
          optInLabel: 'Add items to an existing inventory pick',
        }),
      ],
      schema: z.object({
        inventoryPick: genericModelReferenceSchema.nullish(),
      }),
      initialValues: {
        inventoryPick: issuedPick,
      },
      submitText: 'Pull',
      onSubmit: (v) =>
        axios.post(`/api/orders/${orderId}/pull`, {
          order_item_ids: map(orderItems, 'id'),
          inventory_pick_id: v.inventoryPick?.id,
        }),
    }).then(() => {
      onReload();
    });
  };

  const onPurchase = (orderItems: OrderItemToPurchase[]) => {
    prompt({
      title: 'Purchase from Vendor',
      description: orderItems[0].ship_to.party === 'customer' && (
        <Alert severity="info">
          {orderItems.length === 1 ? 'This item' : 'These items'} are set to drop ship directly to
          the customer. Please confirm this is expected.
        </Alert>
      ),
      fields: [
        FieldFactory.optIn(
          'purchaseOrder',
          FieldFactory.belongsTo('purchaseOrder', 'purchaseOrders').withoutLabel(),
        ).with({
          optInLabel: 'Add items to an existing PO',
        }),
      ],
      schema: z.object({
        purchaseOrder: genericModelReferenceSchema.nullish(),
      }),
      submitText: 'Purchase',
      onSubmit: (v) =>
        axios.post(`/api/orders/${orderId}/purchase`, {
          order_item_ids: map(orderItems, 'id'),
          purchase_order_id: v.purchaseOrder?.id,
        }),
    }).then(() => {
      onReload();
    });
  };

  const checkInventory = () => {
    setInventory(undefined);
    showLoading(
      axios
        .post<InventoryCheckResponse>(`/api/orders/${orderId}/check-inventory`, {
          order_item_ids: selected,
        })
        .then(({ data }) => {
          setInventory(data);
        }),
    );
  };

  const createIssue = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    showLoading(
      createIssueRequest.mutateAsync({
        reason: 'Backordered',
        details: issue,
        order_item_ids: selected,
      }),
    ).then(() => {
      setCreatingIssue(false);
      setIssue('');
    });
  };

  const handleSelect = (id: number, e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected((prev) => uniq([...prev, id]));
    } else {
      setSelected((prev) => prev.filter((p) => p !== id));
    }
  };

  return (
    <Card key={vendor?.id}>
      <Box display="flex" alignItems="center" p={2}>
        <div>
          <Typography variant="body2" color="textSecondary">
            To Purchase or Pull
          </Typography>
          <Typography variant="h5">{vendor?.name || '(No Vendor)'}</Typography>
          {shipTo && (
            <Typography variant="subtitle1" color="textSecondary">
              Ship To {startCase(shipTo.party)}
              {shipTo.party === 'customer' && (
                <span>: {shipTo.address?.name || '(No Address)'}</span>
              )}
              {shipTo.party === 'subcontractor' && <span>: {shipTo.subcontractor?.name}</span>}
            </Typography>
          )}
        </div>
        <Can permission="write:purchase_orders">
          <Box ml="auto">
            <Tooltip title="Purchase from Vendor">
              <IconButton onClick={() => onPurchase(selectedItems)} size="large">
                <Store />
              </IconButton>
            </Tooltip>
            {items.some((i) => Number(i.qty_in_inventory) > 0) && (
              <Tooltip title="Pull from Inventory">
                <IconButton
                  onClick={() =>
                    onPull(selectedItems.filter((i) => Number(i.qty_in_inventory) > 0))
                  }
                  size="large"
                >
                  <NotificationImportant color="primary" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Create Issue">
              <IconButton onClick={creatingIssue} size="large">
                <ReportProblem />
              </IconButton>
            </Tooltip>
            <Tooltip title="Check Inventory">
              <IconButton onClick={checkInventory} size="large">
                <SyncAlt />
              </IconButton>
            </Tooltip>
          </Box>
        </Can>
      </Box>
      {isCreatingIssue && (
        <form onSubmitCapture={createIssue}>
          <CardContent style={{ display: 'flex' }}>
            <TextField
              label="Issue Details"
              variant="outlined"
              multiline
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
              required
              rows={2}
              fullWidth
            />
            <Box ml={3}>
              <LoadingButton
                loading={createIssueRequest.isLoading}
                type="submit"
                variant="contained"
              >
                Submit
              </LoadingButton>
            </Box>
            <Box ml={1}>
              <Button type="button" onClick={() => setCreatingIssue(false)}>
                Cancel
              </Button>
            </Box>
          </CardContent>
        </form>
      )}
      <OrderItemsForPurchasing
        onSelect={handleSelect}
        selected={selected}
        toPurchase
        items={items}
        onPurchase={onPurchase}
        onPull={onPull}
        inventory={inventory}
      />
    </Card>
  );
}
