import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import { FieldFactory } from '@/classes';
import Can from '@/components/Permissions/Can';
import { useDialogs } from '@/contexts/DialogContext';
import { Dashboard } from '@/types';
import { dashboardPayloadSchema } from '@/types';

export default function DashboardAdminActions({ dashboard }: { dashboard: Dashboard }) {
  const { prompt, confirm } = useDialogs();

  const onEdit = () => {
    prompt({
      title: 'Edit Dashboard',
      fields: [FieldFactory.text('name'), FieldFactory.hasMany('roles', 'roles')],
      schema: dashboardPayloadSchema,
      onSubmit: (v) => axios.put(`/api/dashboards/${dashboard.id}`, v),
      initialValues: {
        name: dashboard.name,
        roles: dashboard.roles || [],
      },
    }).then(() => {
      window.location.reload();
    });
  };

  const onDelete = () => {
    confirm({
      title: 'Delete Dashboard',
      description: 'Are you sure? This action is not reversible.',
      color: 'error',
    }).then(() => {
      axios.delete(`/api/dashboards/${dashboard.id}`).then(() => {
        window.location.reload();
      });
    });
  };

  return (
    <Can permission="write:reports">
      <Tooltip title="Edit Dashboard">
        <IconButton onClick={onEdit}>
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Dashboard">
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
    </Can>
  );
}
