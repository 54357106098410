import { useState } from 'react';
import { Box, Tab as MaterialTab, Tabs as MaterialTabs } from '@mui/material';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { LayoutProps } from '@/classes/types';
import EditFormFields, { EditFormFieldsProps } from '@/components/Form/EditFormFields';
import { useRequiresPermissionAndRole } from '@/hooks/permissions';
import { useMaybeRecord } from '@/utils/genericResource';
import Layout from '../Layout';
import CardLayout from './CardLayout';

function Component({ model, forwardProps = {} }: LayoutProps<TabLayout>) {
  const requiresPermissionAndRole = useRequiresPermissionAndRole();
  const record = useMaybeRecord();
  const tabs = model.tabs
    .filter(
      (f) => requiresPermissionAndRole(f) && (forwardProps.isCreate ? f.creatable : f.editable),
    )
    .filter((f) => {
      return f.shouldRender ? f.shouldRender(record) : true;
    });

  const useQs = model.name === 'tab';
  const first = tabs[0]?.name;
  const [activeTab, setTab] = useQs
    ? useQueryParam('tab', withDefault(StringParam, first))
    : useState(first);

  return (
    <Box>
      <MaterialTabs
        value={activeTab}
        onChange={(e, v) => setTab(v as string)}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label={model.label}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <MaterialTab key={tab.name} value={tab.name} label={tab.label} />
        ))}
      </MaterialTabs>

      {tabs
        .filter((t) => t.name === activeTab)
        .map((tab) => (
          <div key={tab.name}>
            <EditFormFields
              fields={tab.fields}
              defaultLayout={tab.defaultLayout}
              {...forwardProps}
            />
          </div>
        ))}
    </Box>
  );
}

export class Tab extends Layout {
  shouldRender?: (record: any) => void;

  shouldRenderUsing(func: Tab['shouldRender']) {
    this.shouldRender = func;
    return this;
  }
}

export default class TabLayout extends Layout {
  tabs: Tab[];

  constructor(name: string, tabs: Tab[] = []) {
    super(name);
    this.tabs = tabs;
    this.defaultLayout = CardLayout;
  }

  withTabs(tabs: Tab[]) {
    this.tabs = tabs;
    return this;
  }

  renderLayout(props: Partial<EditFormFieldsProps>): React.ReactElement | null {
    return <Component model={this} forwardProps={props} />;
  }
}
