import { Menu } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { LinkAction } from '@/classes';
import SubMenu from '@/components/Shared/SubMenu';

const isCurrentPath = (path: string) => window.location.pathname.startsWith(path);

export function getLinksForOrderId(orderId: number, state?: object) {
  return [
    ['Order Entry', `/orders/${orderId}`],
    ['Purchasing', `/purchasing/${orderId}`],
    ['Receiving', `/receiving-order/${orderId}`],
    ['Production', `/production/${orderId}`],
    ['Shipping', `/shipping/${orderId}`],
  ].map(([label, to]) => new LinkAction(label, to, state).setDisabled(isCurrentPath(to)));
}

export default function OrderNavigation({ orderId }: { orderId: number }) {
  const { state } = useLocation();
  return <SubMenu primaryIcon={Menu} items={getLinksForOrderId(orderId, state)} />;
}
