import { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Collapse,
  Grid2 as Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Link } from 'react-router-dom';
import RecentPaymentsForCustomer from '@/components/Payments/RecentPaymentsForCustomer';
import WarningIcon from '@/components/Shared/WarningIcon';
import { RECEIVABLE_BREAKDOWN_KEYS } from '@/constants';
import { useConfig } from '@/contexts/AppConfigContext';
import { CustomerContact, ReceivablesCustomer } from '@/types';
import curr from '@/utils/curr';
import getBusinessName from '@/utils/getBusinessName';
import CustomerReceivableEvents from './CustomerReceivableEvents';
import InvoiceTable from './InvoiceTable';

const Root = styled(TableRow)({
  '& > *': {
    borderBottom: 'unset',
  },
});

export default function CustomerReceivableRow({
  customer,
  onUpdated,
}: {
  customer: ReceivablesCustomer;
  onUpdated: (p: Partial<ReceivablesCustomer>) => void;
}) {
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState(false);
  const { user } = useConfig();

  const { data: contacts = [] } = useQuery(
    ['customerContacts', customer.id],
    () =>
      axios
        .get<{ data: CustomerContact[] }>(`/api/customers/${customer.id}/contacts?count=200`)
        .then(({ data }) => data.data),
    {
      enabled: open,
    },
  );

  const apContacts = contacts.filter((c) => c.is_invoice_contact);

  return (
    <>
      <Root>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {customer.is_disabled && (
              <WarningIcon message="This customer is on hold" sx={{ mr: 2 }} />
            )}
            {customer.is_deleted && (
              <WarningIcon message="This customer is archived" sx={{ mr: 2 }} />
            )}
            <div>
              <Typography>
                <Link
                  to={`/customers/${customer.id}`}
                  style={{ textDecoration: 'none', color: 'inherit', fontWeight: 500 }}
                >
                  {customer.name}
                </Link>
              </Typography>
              {!user.business_id && (
                <Typography variant="body2" color="textSecondary">
                  {getBusinessName(customer.business)}
                </Typography>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell>{customer.sales_rep?.name}</TableCell>
        {RECEIVABLE_BREAKDOWN_KEYS.map((c) => (
          <TableCell align="right" key={c}>
            {curr(customer.breakdown[c].balance)}
          </TableCell>
        ))}
      </Root>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open && (
              <Grid container spacing={6} sx={{ pb: 3, pt: 2 }}>
                <Grid size={{ xs: 12, md: 5 }}>
                  <Tabs value={tab} onChange={(_, v) => setTab(v)}>
                    <Tab label="Unpaid Invoices" />
                    <Tab label="Recent Payments" />
                  </Tabs>
                  {tab === 0 && <InvoiceTable invoices={customer.invoices} />}
                  {tab === 1 && <RecentPaymentsForCustomer customerId={customer.id} />}
                </Grid>

                <Grid size={{ xs: 12, md: 4 }}>
                  <Typography variant="subtitle1" gutterBottom color="textSecondary">
                    Activity
                  </Typography>
                  <CustomerReceivableEvents
                    customer={customer}
                    contacts={contacts}
                    onUpdated={onUpdated}
                  />
                </Grid>

                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography variant="subtitle1" gutterBottom color="textSecondary">
                    AP Contacts
                  </Typography>
                  {apContacts.length > 0 ? (
                    <List dense>
                      {apContacts.map((c) => (
                        <ListItem key={c.id} disableGutters>
                          <ListItemAvatar>
                            <Avatar>{c.name ? c.name[0] : '?'}</Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            <Typography variant="body2" style={{ fontWeight: 500 }}>
                              {c.name}
                            </Typography>
                            {c.email && (
                              <Typography variant="body2">
                                <a href={`mailto:${c.email}`}>{c.email}</a>
                              </Typography>
                            )}
                            {c.phone && (
                              <Typography variant="body2">
                                <a href={`tel:${c.phone}`}>{c.phone}</a>
                              </Typography>
                            )}
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Alert severity="warning">Customer does not have AP contact.</Alert>
                  )}
                </Grid>
              </Grid>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
