import { Button, CircularProgress, Alert } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import 'codemirror/mode/sql/sql';
import keys from 'lodash/keys';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { useFormContext } from 'react-hook-form';
import { FieldProps } from '@/classes/types';
import { ReportResultsRow, ReportPayload } from '@/types';
import ReportTable from './Types/ReportTable';

export default function ReportQueryBuilder({ field }: FieldProps) {
  const form = useFormContext<ReportPayload & { _columns: string[] }>();
  const className = form.watch('class');

  const previewRequest = useMutation<ReportResultsRow[], AxiosError<{ error?: string }>>(
    () =>
      axios
        .post<{ data: ReportResultsRow[] }>('/api/reports/preview', { query: field.value })
        .then(({ data }) => data.data),
    {
      onSuccess: (rows) => {
        const firstRow = rows[0];
        if (firstRow) {
          form.setValue('_columns', keys(firstRow));
        }
      },
    },
  );

  if (className) {
    return <div>This query is controlled by a class, and therefore cannot be edited.</div>;
  }

  return (
    <div>
      <div style={{ height: 600 }}>
        <CodeMirror
          value={field.value}
          options={{
            mode: 'sql',
            theme: 'material',
            lineNumbers: true,
          }}
          onBeforeChange={(editor, data, value) => {
            field.onChange(value);
          }}
        />
      </div>
      <Button variant="outlined" sx={{ my: 2 }} onClick={() => previewRequest.mutate()}>
        Preview
      </Button>

      {previewRequest.isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {previewRequest.isError ? (
            <Alert severity="error">
              {previewRequest.error.response?.data?.error || 'An unknown error occurred'}
            </Alert>
          ) : null}
          {previewRequest.data && <ReportTable results={previewRequest.data} />}
        </div>
      )}
    </div>
  );
}
