import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export default function useOnChangeEffect(func: EffectCallback, deps: DependencyList) {
  const isInitial = useRef(true);

  useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false;
    } else {
      func();
    }
  }, deps);
}
