import { CheckCircle } from '@mui/icons-material';
import { Grid2 as Grid } from '@mui/material';
import { ButtonAction } from '@/classes';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { Proof } from '@/types';
import ProofCard from './ProofCard';

export default function ExistingProofDrawer({
  open,
  onClose,
  proofs,
  onChoose,
}: {
  open: boolean;
  onClose: () => void;
  proofs: Proof[];
  onChoose?: (p: Proof) => void;
}) {
  return (
    <ClosableDrawer title="Existing Proofs" open={open} onClose={onClose} width={700}>
      <Grid container spacing={1}>
        {proofs.map((proof) => (
          <Grid key={proof.id} size={6}>
            <ProofCard
              proof={proof}
              actions={
                onChoose
                  ? [
                      new ButtonAction('Use This Proof', () => onChoose(proof)).withIcon(
                        CheckCircle,
                      ),
                    ]
                  : []
              }
            />
          </Grid>
        ))}
      </Grid>
    </ClosableDrawer>
  );
}
