import { SyntheticEvent, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, LinearProgress } from '@mui/material';
import { AxiosError } from 'axios';
import take from 'lodash/take';
import { useGetShipmentQuote } from '@/api/shipping';
import AddressTitle from '@/components/Addresses/AddressTitle';
import DrawerButtons from '@/components/Form/DrawerButtons';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import RateList from '@/components/Shipping/RateList';
import { ShipmentQuotePayload, Address } from '@/types';

const INIT_LIMIT = 5;

export default function ChooseRates({
  address,
  values,
  onChoose,
  isChoosing,
  onError,
}: {
  address: Address;
  values: ShipmentQuotePayload;
  onChoose: (rateId: string) => void;
  isChoosing: boolean;
  onError: (e: AxiosError) => void;
}) {
  const [showAll, setShowAll] = useState(false);
  const [selectedRate, setSelectedRate] = useState<string>();

  const { isLoading, data: rates = [] } = useGetShipmentQuote(values, onError);

  const ratesMatchingMethod = rates.filter((r) => r.matches_shipping_method);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedRate) {
      onChoose(selectedRate);
    }
  };

  let ratesToShow = rates;

  if (!showAll) {
    ratesToShow = ratesMatchingMethod.length > 0 ? ratesMatchingMethod : take(rates, INIT_LIMIT);
  }

  if (isLoading) {
    return <LinearProgress sx={{ mt: 4 }} />;
  }

  return (
    <form onSubmit={onSubmit}>
      <DrawerFormSection title="Ship To">
        <AddressTitle address={address} />
      </DrawerFormSection>

      {ratesToShow.length === 0 ? (
        <Alert severity="warning">No rates found</Alert>
      ) : (
        <RateList
          rates={ratesToShow}
          selectedRate={selectedRate}
          setSelectedRate={setSelectedRate}
        />
      )}

      {!showAll && rates.length > ratesToShow.length ? (
        <Button size="small" onClick={() => setShowAll(true)}>
          Show All Rates ({rates.length})
        </Button>
      ) : null}

      <DrawerButtons>
        <LoadingButton
          type="submit"
          loading={isChoosing}
          disabled={!selectedRate}
          variant="contained"
        >
          Purchase Label
        </LoadingButton>
      </DrawerButtons>
    </form>
  );
}
