import { Grid2 as Grid } from '@mui/material';
import { Fieldable, LayoutProps, NullableFieldable } from '@/classes/types';
import EditFormFields, { EditFormFieldsProps } from '@/components/Form/EditFormFields';
import truthy from '@/utils/truthy';
import Layout from '../Layout';

function Component({ model, forwardProps = {} }: LayoutProps<SidebarLayout>) {
  return (
    <Grid container spacing={model.spacing}>
      <Grid size={{ xs: 12, md: 9 }}>
        <EditFormFields
          fields={model.fields}
          defaultLayout={model.defaultLayout}
          {...forwardProps}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <EditFormFields
          fields={model.sidebarFields}
          defaultLayout={model.defaultLayout}
          {...forwardProps}
        />
      </Grid>
    </Grid>
  );
}

export default class SidebarLayout extends Layout {
  sidebarFields: Fieldable[];

  constructor(
    name: string,
    fields: NullableFieldable[] = [],
    sidebarFields: NullableFieldable[] = [],
  ) {
    super(name, fields);
    this.sidebarFields = truthy(sidebarFields);
  }

  withSidebarFields(fields: NullableFieldable[]) {
    this.sidebarFields = truthy(fields);
    return this;
  }

  renderLayout(props: Partial<EditFormFieldsProps>): React.ReactElement | null {
    return <Component model={this} forwardProps={props} />;
  }
}
