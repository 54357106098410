import { CalendarToday, Comment } from '@mui/icons-material';
import { Typography, Tooltip, IconButton } from '@mui/material';
import { FieldFactory, Resource } from '@/classes';
import { OnClickProps } from '@/classes/types';
import ProductionDates from '@/components/Production/CommittedDate';
import ProductionOrder from '@/components/Production/ProductionOrder';
import DueDateBar from '@/components/Shared/DueDateBar';
import {
  ORDER_STATUS_COLORS,
  PRODUCTION_EVENT_STATUS_COLORS,
  DEPARTMENT_STATUS_COLORS,
} from '@/constants';
import { useSchedulingState } from '@/contexts/SchedulingContext';
import { Order, ProductionEvent, productionEventUpdatePayloadSchema } from '@/types';

const renderOrder = (value: Order) => {
  const body = (
    <Typography variant="body2">
      <span>
        {value.increment_id} - {value.customer.name}{' '}
      </span>
      {value.message_scheduling && (
        <Comment
          style={{
            verticalAlign: 'bottom',
            color: '#777',
            fontSize: 18,
          }}
        />
      )}
    </Typography>
  );
  return (
    <>
      {value.message_scheduling ? <Tooltip title={value.message_scheduling}>{body}</Tooltip> : body}
      <Typography variant="body2" color="textSecondary">
        {value.description}
      </Typography>
    </>
  );
};

export function ProductionEventBulkActions({ selected }: OnClickProps) {
  const { setEventIds } = useSchedulingState();

  const onSchedule = () => {
    setEventIds(selected as number[]);
  };

  return (
    <IconButton onClick={onSchedule}>
      <CalendarToday />
    </IconButton>
  );
}

export default function productionEvents() {
  return new Resource<ProductionEvent>('Production Events')
    .withSchema(productionEventUpdatePayloadSchema)
    .getTitleUsing((e) => e.label)
    .setReadOnly()
    .withBulkActions(ProductionEventBulkActions)
    .withDefaultFilters({
      bucket: 'open',
    })
    .setCanExport()
    .withDefaultSort('order.committed_ship_date')
    .withFilters([
      FieldFactory.radio('bucket', {
        open: 'Open',
        to_schedule: 'To Schedule',
        to_burn: 'To Burn',
        all: 'All',
      }).asQuickFilter(),
    ])
    .withInitialColumns([
      'event_type',
      'machine',
      'order',
      'order.status',
      'order.committed_ship_date',
      'order_design.design',
      'quantity',
      'status',
    ])
    .withColumns([
      FieldFactory.belongsTo('event_type', 'productionEventTypes').filterable(),
      FieldFactory.belongsTo('machine', 'productionMachines').filterable(),
      FieldFactory.belongsTo('order', 'orders')
        .filterable()
        .renderCellUsing(renderOrder)
        .sortable('order_id'),
      FieldFactory.status('order.status', ORDER_STATUS_COLORS).withLabel('Order Status'),
      FieldFactory.belongsTo('order_design.design', 'designs').withLabel('Design'),
      FieldFactory.number('quantity').sortable().setAggregatable(),
      FieldFactory.status('status', PRODUCTION_EVENT_STATUS_COLORS).filterable(),
      FieldFactory.date('scheduled_date').filterable().sortable(),
      FieldFactory.date('order.committed_ship_date')
        .withLabel('Committed Ship Date')
        .sortable()
        .renderCellUsing((value, row: ProductionEvent) => (
          <div>
            <Typography variant="body2">
              <ProductionDates order={row.order} />
            </Typography>
            {value && row.status !== 'Completed' && (
              <DueDateBar end={value} start={row.created_at} width={80} />
            )}
          </div>
        )),
      FieldFactory.timestamp('status_changed_at').withLabel('Latest Status Change'),
      FieldFactory.text('screen_location'),
      FieldFactory.timestamp('screen_burned_at'),
      FieldFactory.date('order.drop_dead_date').withLabel('Drop Dead Date'),
      FieldFactory.status('order.staged_status', DEPARTMENT_STATUS_COLORS).filterable().sortable(),
      FieldFactory.timestamp('order.staged_at').sortable(),
    ])
    .editUsing(ProductionOrder);
}
