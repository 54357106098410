import { useEffect } from 'react';
import axios from 'axios';
import { useFormContext } from 'react-hook-form';
import { FieldFactory } from '@/classes';
import { FieldProps } from '@/classes/types';
import { ArtRequest, OrderApproval } from '@/types';
import { useRecordId } from '@/utils/genericResource';

export default function ArtRequestNoteField({ fieldModel, ...props }: FieldProps) {
  const orderId = useRecordId();
  const type = useFormContext<Pick<ArtRequest, 'type'>>().watch('type');

  useEffect(() => {
    if (type === 'proof') {
      axios
        .get<{ data: OrderApproval[] }>(`/api/orders/${orderId}/approvals?sort=-rejected_at`)
        .then(({ data }) => {
          const withNotes = data.data.find((a) => a.rejection_note && a.rejection_note.length > 1);
          if (withNotes) {
            props.field.onChange(`From Customer:\n${withNotes.rejection_note}`);
          }
        });
    }
  }, [orderId, type]);

  return (
    <div
      style={{
        width: 500,
        maxWidth: '100%',
      }}
    >
      {FieldFactory.textarea(fieldModel.name)
        .withLabel(fieldModel.label as string)
        .renderEditComponent(props)}
    </div>
  );
}
