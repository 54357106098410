import { useState } from 'react';
import { ArrowDownward, ArrowUpward, PushPin, Segment, VisibilityOff } from '@mui/icons-material';
import MoreIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  TableSortLabel,
} from '@mui/material';
import { flexRender, Header } from '@tanstack/react-table';

const Resizer = styled('div', { shouldForwardProp: (prop) => prop !== 'isResizing' })<{
  isResizing: boolean;
}>(({ isResizing }) => ({
  position: 'absolute',
  top: -16,
  bottom: -16,
  right: 0,
  width: 3,
  background: 'rgba(0, 0, 0, 0.2)',
  display: isResizing ? 'block' : 'none',
  cursor: 'col-resize',
  userSelect: 'none',
  touchAction: 'none',
}));

export default function ColumnHeader<T>({ header }: { header: Header<T, unknown> }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const isCheckbox = header.column.id === 'checkbox';
  const menuItems = [];

  if (header.column.getCanSort()) {
    if (header.column.getIsSorted() !== 'asc') {
      menuItems.push(
        <MenuItem key="sort-asc" onClick={() => header.column.toggleSorting(false)}>
          <ListItemIcon>
            <ArrowUpward />
          </ListItemIcon>
          <ListItemText>Sort Asc</ListItemText>
        </MenuItem>,
      );
    }
    if (header.column.getIsSorted() !== 'desc') {
      menuItems.push(
        <MenuItem key="sort-desc" onClick={() => header.column.toggleSorting(true)}>
          <ListItemIcon>
            <ArrowDownward />
          </ListItemIcon>
          <ListItemText>Sort Desc</ListItemText>
        </MenuItem>,
      );
    }
    if (header.column.getIsSorted()) {
      menuItems.push(
        <MenuItem key="unsort" onClick={() => header.column.clearSorting()}>
          <ListItemIcon />
          <ListItemText>Unsort</ListItemText>
        </MenuItem>,
      );
    }
  }

  if (header.column.getCanHide()) {
    if (menuItems.length > 0) {
      menuItems.push(<Divider key="divider-vis" />);
    }
    menuItems.push(
      <MenuItem key="visibility-toggle" onClick={() => header.column.toggleVisibility()}>
        <ListItemIcon>
          <VisibilityOff />
        </ListItemIcon>
        <ListItemText> {header.column.getIsVisible() ? 'Hide' : 'Show'}</ListItemText>
      </MenuItem>,
    );
  }

  if (header.column.getCanPin()) {
    if (menuItems.length > 0) {
      menuItems.push(<Divider key="divider-pin" />);
    }
    if (header.column.getIsPinned() !== 'left') {
      menuItems.push(
        <MenuItem key="pin-lft" onClick={() => header.column.pin('left')}>
          <ListItemIcon>
            <PushPin sx={{ transform: 'rotate(-20deg)' }} />
          </ListItemIcon>
          <ListItemText>Pin to Left</ListItemText>
        </MenuItem>,
      );
    }
    if (header.column.getIsPinned() !== 'right') {
      menuItems.push(
        <MenuItem key="pin-right" onClick={() => header.column.pin('right')}>
          <ListItemIcon>
            <PushPin sx={{ transform: 'rotate(20deg)' }} />
          </ListItemIcon>
          <ListItemText>Pin to Right</ListItemText>
        </MenuItem>,
      );
    }
    if (header.column.getIsPinned()) {
      menuItems.push(
        <MenuItem key="unpin" onClick={() => header.column.pin(false)}>
          <ListItemIcon />
          <ListItemText>Unpin</ListItemText>
        </MenuItem>,
      );
    }

    if (header.column.columnDef.meta?.aggregatable) {
      const { table } = header.getContext();
      const { sums = {}, avgs = {} } = header.getContext().table.getState();
      if (menuItems.length > 0) {
        menuItems.push(<Divider key="divider-agg" />);
      }
      menuItems.push(
        <MenuItem
          key="sum"
          onClick={() =>
            table.setState((prev) => ({
              ...prev,
              sums: { ...prev.sums, [header.column.id]: !sums[header.column.id] },
            }))
          }
        >
          <ListItemIcon />
          <ListItemText>{sums[header.column.id] ? 'Hide Sum' : 'Show Sum'}</ListItemText>
        </MenuItem>,
      );
      menuItems.push(
        <MenuItem
          key="avg"
          onClick={() =>
            table.setState((prev) => ({
              ...prev,
              avgs: { ...prev.avgs, [header.column.id]: !avgs[header.column.id] },
            }))
          }
        >
          <ListItemIcon />
          <ListItemText>{avgs[header.column.id] ? 'Hide Avg' : 'Show Avg'}</ListItemText>
        </MenuItem>,
      );
    }
  }

  if (header.column.getCanGroup()) {
    if (menuItems.length > 0) {
      menuItems.push(<Divider key="divider-group" />);
    }
    menuItems.push(
      <MenuItem key="group-toggle" onClick={() => header.column.toggleGrouping()}>
        <ListItemIcon>
          <Segment />
        </ListItemIcon>
        <ListItemText>{header.column.getIsGrouped() ? 'Ungroup' : 'Group'}</ListItemText>
      </MenuItem>,
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      position="relative"
      sx={{
        pr: isCheckbox ? 0 : 3.5,
        '& .MuiIconButton-root': {
          display: anchorEl ? 'inline-flex' : 'none',
        },
        '&:hover .MuiIconButton-root': {
          display: 'inline-flex',
        },
        '&:hover .resizer': {
          display: 'block',
        },
      }}
    >
      {header.column.getCanSort() ? (
        <TableSortLabel
          active={Boolean(header.column.getIsSorted())}
          direction={header.column.getIsSorted() || 'asc'}
          onClick={header.column.getToggleSortingHandler()}
        >
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
        </TableSortLabel>
      ) : (
        flexRender(header.column.columnDef.header, header.getContext())
      )}

      {!isCheckbox && (
        <>
          <IconButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            size="small"
            sx={{
              position: 'absolute',
              right: 4,
              '&:hover': {
                bgcolor: 'transparent',
              },
            }}
          >
            <MoreIcon fontSize="small" />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(undefined)}
            onClick={() => setAnchorEl(undefined)}
          >
            {menuItems}
          </Menu>

          <Resizer
            className="resizer"
            onDoubleClick={() => header.column.resetSize()}
            onMouseDown={header.getResizeHandler()}
            onTouchStart={header.getResizeHandler()}
            isResizing={header.column.getIsResizing()}
          />
        </>
      )}
    </Box>
  );
}
