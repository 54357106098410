import { UseFieldArrayWithName } from '@/classes/types';
import ListElement from '@/components/Form/ListElement';
import Field from '../Field';

export default class ListField extends Field {
  children: Field[];
  addNewValue = {};
  addNewLabel = 'Add New';
  isArray = true;

  constructor(name: string, children: Field[]) {
    super(name);
    this.children = children;
  }

  renderArrayComponent(props: UseFieldArrayWithName) {
    return <ListElement {...props} fieldModel={this} />;
  }
}
