import { CircularProgress, Typography } from '@mui/material';
import sumBy from 'lodash/sumBy';
import { useSearchParams } from 'react-router-dom';
import { useGetBankAccounts } from '@/api/accounts';
import BankList from '@/components/Banking/BankList';
import BankTransactions from '@/components/Banking/BankTransactions';
import { useTitle } from '@/contexts/AppContext';
import { Account } from '@/types';
import curr from '@/utils/curr';

export default function Banking() {
  useTitle('Banking');

  const [searchParams, setSearchParams] = useSearchParams();
  const accountId = searchParams.get('account');
  const setAccount = (account: Account) => setSearchParams({ account: account.id.toString() });

  const { data: accounts = [], isLoading } = useGetBankAccounts();
  const account = accountId ? accounts.find((a) => a.id === Number(accountId)) : accounts[0];

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Typography variant="subtitle1">
        Cash Balance:{' '}
        <b>
          {curr(
            sumBy(accounts, (a) =>
              a.type === 'credit_card' ? Number(a.cached_balance) * -1 : Number(a.cached_balance),
            ),
          )}
        </b>
      </Typography>
      <BankList accounts={accounts} onSelect={setAccount} selected={account} />
      {account && <BankTransactions account={account} />}
    </div>
  );
}
