import { useMemo } from 'react';
import { TableState } from '@tanstack/react-table';
import pickBy from 'lodash/pickBy';
import { Resource, FieldFactory } from '@/classes';
import { FieldProps } from '@/classes/types';
import DataTable from '@/components/DataTable/DataTable';
import { PAYMENT_METHODS } from '@/constants';
import wrap from '@/utils/wrap';

export default function DepositPaymentSelector({ field }: FieldProps) {
  const selected = wrap(field.value) as { id: number }[];

  const resource = useMemo(
    () =>
      new Resource('Payments')
        .withQueryParams({
          index: {
            undeposited: 'true',
            'filter[payment_type]': 'payment',
          },
          single: {},
        })
        .withColumns([
          FieldFactory.date('payment_date').filterable().sortable(),
          FieldFactory.belongsTo('customer', 'customers').filterable(),
          FieldFactory.text('payment_number').sortable(),
          FieldFactory.curr('amount').sortable(),
          FieldFactory.select('method', PAYMENT_METHODS).filterable(),
        ]),
    [],
  );

  const onStateUpdated = (s: TableState) => {
    field.onChange(Object.keys(pickBy(s.rowSelection)).map((id) => ({ id: Number(id) })));
  };

  return (
    <DataTable
      resource={resource}
      onStateUpdated={onStateUpdated}
      initialState={{
        rowSelection: selected.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}),
      }}
    />
  );
}
