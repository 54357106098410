import { CSSProperties } from 'react';
import { styled, Tooltip } from '@mui/material';
import { amber, green, orange, red } from '@mui/material/colors';
import moment from 'moment';

const Bar = styled('div')(({ theme }) => ({
  display: 'block',
  width: 150,
  height: 10,
  border: `1px solid ${theme.palette.action.selected}`,
  borderRight: 'none',
  position: 'relative',
  marginRight: 12,
  marginTop: 4,
  background: theme.palette.background.paper,
}));

const Remaining = styled('div')({
  position: 'absolute',
  top: -1,
  bottom: -1,
  right: 0,
  zIndex: 2,
});

export const getColor = (percent: number) => {
  if (percent > 0.66) {
    return green[500];
  }
  if (percent > 0.33) {
    return amber[500];
  }
  return orange[500];
};

export default function DueDateBar({
  width = 150,
  start,
  end,
}: {
  width?: number;
  start: string;
  end: string;
}) {
  const startTime = moment(start);
  const endTime = moment(end);
  const now = moment();

  const total = endTime.diff(startTime);
  const left = endTime.diff(now);
  const percent = left / total;

  let styles: CSSProperties = {
    width: percent * width,
    background: getColor(percent),
  };

  if (left < 0) {
    styles = {
      background: red[600],
      width: 10,
      transform: 'translateX(10px)',
    };
  }

  return (
    <Tooltip title={`Due ${endTime.fromNow()}`}>
      <Bar style={{ width }}>
        <Remaining style={styles} />
      </Bar>
    </Tooltip>
  );
}
