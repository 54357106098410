import { ChangeEvent, ReactNode, SyntheticEvent, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import omit from 'lodash/omit';
import Paper from '@/components/Shared/Paper';
import { FileUpload } from '@/types';

export default function Upload({
  onSuccess,
  children,
}: {
  onSuccess: (fileName: string) => void;
  children: ReactNode;
}) {
  const [file, setFile] = useState<File>();

  const { isLoading, mutateAsync } = useMutation(async (file: File) => {
    const { data } = await axios.post<FileUpload>('/api/files', {
      content_type: 'text/csv',
      extension: 'csv',
    });

    await axios.put(data.signed_url, file, {
      headers: omit(data.headers, ['Host']),
    });
    return data;
  });

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (!file) {
      alert('Please upload a file');
      return;
    }

    mutateAsync(file).then((data) => {
      onSuccess(data.key);
    });
  };

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files![0]);
  };

  return (
    <Paper>
      <form onSubmit={onSubmit}>
        {children}

        <Box mb={3}>
          <input type="file" onChange={handleFile} accept="text/csv" />
        </Box>

        <LoadingButton loading={isLoading} type="submit" variant="contained">
          Submit
        </LoadingButton>
      </form>
    </Paper>
  );
}
